import "bootstrap/dist/css/bootstrap.min.css";
import "./dashboard.css";
import { Button, Card, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import SideMenu from "./Layout/SideMenu";
import "bootstrap/dist/css/bootstrap.min.css";
import "simple-line-icons/css/simple-line-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios, { AxiosRequestConfig } from "axios";
// import { ToastContainer, toast } from 'react-toastify';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BASE_URL = process.env.REACT_APP_API_URL
const Dashboard = () => {
  const userData:any = localStorage.getItem("user_data");
  const user = JSON.parse(userData)
  

  return (
    <>
      <div className="content-wrapper-dashborad">
        <div className="title-header-size p-2">
          <h3>RightProfileTM Dashboard</h3>
        </div>

        <div className="row align-items-center p-2">
          <div className="col-3 form-group">
            <label htmlFor="duration">Duration </label>
            <select
              name="duration"
              className="form-control mb-2 custom-form-control-input"
            >
              <option value="">All</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="year">Year</option>
            </select>
          </div>
          <div className="col-3 form-group">
            <label htmlFor="branch">Branch </label>
            <select
              name="branch"
              className="form-control mb-2 custom-form-control-input"
            >
              <option value="">All</option>
              <option value="sagar">Sagar</option>
              <option value="nasik">Nasik</option>
              <option value="baroda">Baroda</option>
              <option value="indore">Indore</option>
              <option value="jaipur">Jaipur</option>
            </select>
          </div>
          <div className="col-3 form-group">
            <label htmlFor="userId">User Id </label>
            <select
              name="userId"
              className="form-control mb-2 custom-form-control-input"
            >
              <option value="">All</option>
              <option value="0222">0222</option>
              <option value="CM1">CM1</option>
              <option value="CM2">CM2</option>
              <option value="CM3">CM3</option>
            </select>
          </div>
          <div className="col-3 form-group">
            <div className="text-center">
              <Button variant="success">Search</Button>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-xs-12 col-sm-6 col-md-4 col-xl-width">
            <Card className="card equiheight">
              <Card.Header className="card-header-info">
                <h5 className="card-title font-weight-normal">
                  Number of RightProfile<sup>TM</sup> processed
                </h5>
              </Card.Header>
              <Card.Body>
                <h3 className="mb-0 mt-2">487</h3>
              </Card.Body>
            </Card>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-xl-width">
            <Card className="card equiheight">
              <Card.Header className="card-header-rose">
                <h5 className="card-title font-weight-normal">
                  Average RightProfile<sup>TM</sup> Score
                </h5>
              </Card.Header>
              <Card.Body>
                <h3 className="mb-0 mt-2">5.12</h3>
              </Card.Body>
            </Card>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-xl-width">
            <Card className="card equiheight">
              <Card.Header className="card-header-info">
                <h5 className="card-title font-weight-normal">
                  Average Loan Amount
                </h5>
              </Card.Header>
              <Card.Body>
                <h3 className="mb-0 mt-2">₹1,775,635</h3>
              </Card.Body>
            </Card>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-xl-width">
            <Card className="card equiheight">
              <Card.Header className="card-header-rose">
                <h5 className="card-title font-weight-normal">
                  Average Income
                </h5>
              </Card.Header>
              <Card.Body>
                <h3 className="mb-0 mt-2">₹127,291</h3>
              </Card.Body>
            </Card>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-xl-width">
            <Card className="card equiheight">
              <Card.Header className="card-header-info">
                <h5 className="card-title font-weight-normal">Average ROI</h5>
              </Card.Header>
              <Card.Body>
                <h3 className="mb-0 mt-2">10.73</h3>
              </Card.Body>
            </Card>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-xl-width">
            <Card className="card equiheight">
              <Card.Header className="card-header-rose">
                <h5 className="card-title font-weight-normal">
                  Average Tenure
                </h5>
              </Card.Header>
              <Card.Body>
                <h3 className="mb-0 mt-2">12.7</h3>
              </Card.Body>
            </Card>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-xl-width">
            <Card className="card equiheight">
              <Card.Header className="card-header-info">
                <h5 className="card-title font-weight-normal">Average FOIR</h5>
              </Card.Header>
              <Card.Body>
                <h3 className="mb-0 mt-2">43.63</h3>
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <div className="card">
              <div className="card-header card-header-icon card-header-rose">
                <div className="card-icon">
                  <i className="material-icons">chart</i>
                </div>
                <h4 className="card-title-size font-weight-normal">
                  City Statistics
                </h4>
              </div>
              <div className="card-body">
                <div className="table-responsive table-vertical-scroll ng-star-inserted">
                  <table className="table">
                    <thead className="text-dark">
                      <tr className="text-center">
                        <th className="font-weight-normal">City</th>
                        <th className="font-weight-normal">Income&nbsp;₹</th>
                        <th className="font-weight-normal">Number of Cases</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center ng-star-inserted">
                        <td className="font-weight-normal">Mumbai</td>
                        <td className="table-danger">85,220</td>
                        <td>19.49%</td>
                      </tr>
                      <tr className="text-center ng-star-inserted">
                        <td className="font-weight-normal">
                          Achari Street,Near Vrc
                        </td>
                        <td className="table-danger">236,493</td>
                        <td>11.89%</td>
                      </tr>
                      <tr className="text-center ng-star-inserted">
                        <td className="font-weight-normal">Achalpur</td>
                        <td className="table-danger">236,493</td>
                        <td>11.89%</td>
                      </tr>
                      <tr className="text-center ng-star-inserted">
                        <td className="font-weight-normal">Port Blair</td>
                        <td className="table-danger">95,863</td>
                        <td>7.77%</td>
                      </tr>
                      <tr className="text-center ng-star-inserted">
                        <td className="font-weight-normal">Other</td>
                        <td>67,498</td>
                        <td>4.44%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xs-12 col-sm-6">
            <div className="card">
              <div className="card-header card-header-icon card-header-rose">
                <div className="card-icon">
                  <i className="material-icons">chart</i>
                </div>
                <h4 className="card-title-size font-weight-normal">
                  Average Income ₹: City Split
                </h4>
              </div>
              <div className="card-body">
                <div className="ct-chart avginccitysplit-chart ng-star-inserted">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="350px"
                    className="ct-chart-bar"
                    style={{ width: "100%", height: "350px" }}
                  >
                    <g className="ct-grids">
                      <line
                        y1="315"
                        y2="315"
                        x1="50"
                        x2="531.6666870117188"
                        className="ct-grid ct-vertical"
                      ></line>
                      <line
                        y1="285"
                        y2="285"
                        x1="50"
                        x2="531.6666870117188"
                        className="ct-grid ct-vertical"
                      ></line>
                      <line
                        y1="255"
                        y2="255"
                        x1="50"
                        x2="531.6666870117188"
                        className="ct-grid ct-vertical"
                      ></line>
                      <line
                        y1="225"
                        y2="225"
                        x1="50"
                        x2="531.6666870117188"
                        className="ct-grid ct-vertical"
                      ></line>
                      <line
                        y1="195"
                        y2="195"
                        x1="50"
                        x2="531.6666870117188"
                        className="ct-grid ct-vertical"
                      ></line>
                      <line
                        y1="165"
                        y2="165"
                        x1="50"
                        x2="531.6666870117188"
                        className="ct-grid ct-vertical"
                      ></line>
                      <line
                        y1="135"
                        y2="135"
                        x1="50"
                        x2="531.6666870117188"
                        className="ct-grid ct-vertical"
                      ></line>
                      <line
                        y1="105"
                        y2="105"
                        x1="50"
                        x2="531.6666870117188"
                        className="ct-grid ct-vertical"
                      ></line>
                      <line
                        y1="75"
                        y2="75"
                        x1="50"
                        x2="531.6666870117188"
                        className="ct-grid ct-vertical"
                      ></line>
                      <line
                        y1="45"
                        y2="45"
                        x1="50"
                        x2="531.6666870117188"
                        className="ct-grid ct-vertical"
                      ></line>
                      <line
                        y1="15"
                        y2="15"
                        x1="50"
                        x2="531.6666870117188"
                        className="ct-grid ct-vertical"
                      ></line>
                    </g>
                    <g>
                      <g className="ct-series ct-series-a">
                        <line
                          x1="98.16666870117187"
                          x2="98.16666870117187"
                          y1="315"
                          y2="212.73625200000004"
                          className="ct-bar"
                          ct-value="85219.79"
                        ></line>
                        <line
                          x1="194.5000061035156"
                          x2="194.5000061035156"
                          y1="315"
                          y2="31.20816000000002"
                          className="ct-bar"
                          ct-value="236493.2"
                        ></line>
                        <line
                          x1="290.8333435058594"
                          x2="290.8333435058594"
                          y1="315"
                          y2="31.20816000000002"
                          className="ct-bar"
                          ct-value="236493.2"
                        ></line>
                        <line
                          x1="387.1666809082031"
                          x2="387.1666809082031"
                          y1="315"
                          y2="199.96422"
                          className="ct-bar"
                          ct-value="95863.15"
                        ></line>
                        <line
                          x1="483.50001831054686"
                          x2="483.50001831054686"
                          y1="315"
                          y2="234.00295200000002"
                          className="ct-bar"
                          ct-value="67497.54"
                        ></line>
                      </g>
                    </g>
                    <g className="ct-labels">
                      <foreignObject
                        style={{ overflow: "visible" }}
                        x="50"
                        y="320"
                        width="96.33333740234374"
                        height="20"
                      >
                        <span
                          className="ct-label ct-horizontal ct-end"
                          style={{ width: "96px", height: "20px" }}
                        >
                          Mumbai
                        </span>
                      </foreignObject>
                      <foreignObject
                        style={{ overflow: "visible" }}
                        x="146.33333740234374"
                        y="320"
                        width="96.33333740234374"
                        height="20"
                      >
                        <span
                          className="ct-label ct-horizontal ct-end"
                          style={{ width: "96px", height: "20px" }}
                        >
                          Achari Street, Near Vrc
                        </span>
                      </foreignObject>
                      <foreignObject
                        style={{ overflow: "visible" }}
                        x="242.6666748046875"
                        y="320"
                        width="96.33333740234372"
                        height="20"
                      >
                        <span
                          className="ct-label ct-horizontal ct-end"
                          style={{ width: "96px", height: "20px" }}
                        >
                          Achalpur
                        </span>
                      </foreignObject>
                      <foreignObject
                        style={{ overflow: "visible" }}
                        x="339.0000122070312"
                        y="320"
                        width="96.33333740234377"
                        height="20"
                      >
                        <span
                          className="ct-label ct-horizontal ct-end"
                          style={{ width: "96px", height: "20px" }}
                        >
                          Port Blair
                        </span>
                      </foreignObject>
                      <foreignObject
                        style={{ overflow: "visible" }}
                        x="435.333349609375"
                        y="320"
                        width="96.33333740234377"
                        height="20"
                      >
                        <span
                          className="ct-label ct-horizontal ct-end"
                          style={{ width: "96px", height: "20px" }}
                        >
                          Other
                        </span>
                      </foreignObject>
                      <foreignObject
                        style={{ overflow: "visible" }}
                        y="285"
                        x="10"
                        height="30"
                        width="80"
                      >
                        <span
                          className="ct-label ct-vertical ct-start"
                          style={{ height: "30px", width: "80px" }}
                        >
                          0
                        </span>
                      </foreignObject>
                      <foreignObject
                        style={{ overflow: "visible" }}
                        y="255"
                        x="10"
                        height="30"
                        width="80"
                      >
                        <span
                          className="ct-label ct-vertical ct-start"
                          style={{ height: "30px", width: "80px" }}
                        >
                          25000
                        </span>
                      </foreignObject>
                      <foreignObject
                        style={{ overflow: "visible" }}
                        y="225"
                        x="10"
                        height="30"
                        width="80"
                      >
                        <span
                          className="ct-label ct-vertical ct-start"
                          style={{ height: "30px", width: "80px" }}
                        >
                          50000
                        </span>
                      </foreignObject>
                      <foreignObject
                        style={{ overflow: "visible" }}
                        y="195"
                        x="10"
                        height="30"
                        width="80"
                      >
                        <span
                          className="ct-label ct-vertical ct-start"
                          style={{ height: "30px", width: "80px" }}
                        >
                          75000
                        </span>
                      </foreignObject>
                      <foreignObject
                        style={{ overflow: "visible" }}
                        y="165"
                        x="10"
                        height="30"
                        width="80"
                      >
                        <span
                          className="ct-label ct-vertical ct-start"
                          style={{ height: "30px", width: "80px" }}
                        >
                          100000
                        </span>
                      </foreignObject>
                      <foreignObject
                        style={{ overflow: "visible" }}
                        y="135"
                        x="10"
                        height="30"
                        width="80"
                      >
                        <span
                          className="ct-label ct-vertical ct-start"
                          style={{ height: "30px", width: "80px" }}
                        >
                          125000
                        </span>
                      </foreignObject>
                      <foreignObject
                        style={{ overflow: "visible" }}
                        y="105"
                        x="10"
                        height="30"
                        width="80"
                      >
                        <span
                          className="ct-label ct-vertical ct-start"
                          style={{ height: "30px", width: "80px" }}
                        >
                          150000
                        </span>
                      </foreignObject>
                      <foreignObject
                        style={{ overflow: "visible" }}
                        y="75"
                        x="10"
                        height="30"
                        width="80"
                      >
                        <span
                          className="ct-label ct-vertical ct-start"
                          style={{ height: "30px", width: "80px" }}
                        >
                          175000
                        </span>
                      </foreignObject>
                      <foreignObject
                        style={{ overflow: "visible" }}
                        y="45"
                        x="10"
                        height="30"
                        width="80"
                      >
                        <span
                          className="ct-label ct-vertical ct-start"
                          style={{ height: "30px", width: "80px" }}
                        >
                          200000
                        </span>
                      </foreignObject>
                      <foreignObject
                        style={{ overflow: "visible" }}
                        y="15"
                        x="10"
                        height="30"
                        width="80"
                      >
                        <span
                          className="ct-label ct-vertical ct-start"
                          style={{ height: "30px", width: "80px" }}
                        >
                          225000
                        </span>
                      </foreignObject>
                      <foreignObject
                        style={{ overflow: "visible" }}
                        y="-15"
                        x="10"
                        height="30"
                        width="80"
                      >
                        <span
                          className="ct-label ct-vertical ct-start"
                          style={{ height: "30px", width: "80px" }}
                        >
                          250
                        </span>
                      </foreignObject>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-xs-12 col-sm-12 col-md-6">
            <div className="card">
              <div className="card-header card-header-icon card-header-rose">
                <div className="card-icon">
                  <i className="material-icons">chart</i>
                </div>
                <h4 className="card-title-size font-weight-normal">
                  Customer Segregation
                </h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12"></div>
                  <div className="col-md-8 col-sm-12 col-xs-12">
                    <div className="ct-chart bold-text-pie-chart">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="300px"
                        className="ct-chart-pie"
                        style={{ width: "100%", height: "300px" }}
                      >
                        <g className="ct-series ct-series-a">
                          <path
                            d="M322.157,154.224A145,145,0,0,0,177.219,5L177.219,150Z"
                            className="ct-slice-pie"
                            ct-value="151"
                          ></path>
                        </g>
                        <g className="ct-series ct-series-b">
                          <path
                            d="M177.219,5A145,145,0,1,0,322.171,153.718L177.219,150Z"
                            className="ct-slice-pie"
                            ct-value="442"
                          ></path>
                        </g>
                        <g>
                          <text
                            dx="243.57206816221026"
                            dy="85.55244637021126"
                            textAnchor="end"
                            className="ct-label"
                          >
                            25%
                          </text>
                          <text
                            dx="110.86543183778974"
                            dy="214.44755362978876"
                            textAnchor="start"
                            className="ct-label"
                          >
                            75%
                          </text>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 col-xs-12">
                    <div>
                      <i
                        className="fa fa-circle text-series-0"
                        aria-hidden="true"
                      ></i>{" "}
                      Employed
                    </div>
                    <div>
                      <i
                        className="fa fa-circle text-series-1"
                        aria-hidden="true"
                      ></i>{" "}
                      Self-Employed
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6">
            <div className="card">
              <div className="card-header card-header-icon card-header-rose">
                <div className="card-icon">
                  <i className="material-icons">chart</i>
                </div>
                <h4 className="card-title-size font-weight-normal">
                  Employer Constitution (Salaried)
                </h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12"></div>
                  <div className="col-md-8 col-sm-12 col-xs-12">
                    <div className="ct-chart bold-text-pie-chart">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="300px"
                        className="ct-chart-pie"
                        style={{ width: "100%", height: "300px" }}
                      >
                        <g className="ct-series ct-series-a">
                          <path
                            d="M284.657,247.376A145,145,0,0,0,177.219,5L177.219,150Z"
                            className="ct-slice-pie"
                            ct-value="47"
                          ></path>
                        </g>
                        <g className="ct-series ct-series-b">
                          <path
                            d="M115.223,281.078A145,145,0,0,0,284.996,247L177.219,150Z"
                            className="ct-slice-pie"
                            ct-value="26"
                          ></path>
                        </g>
                        <g className="ct-series ct-series-c">
                          <path
                            d="M52.848,224.545A145,145,0,0,0,115.681,281.294L177.219,150Z"
                            className="ct-slice-pie"
                            ct-value="12"
                          ></path>
                        </g>
                        <g className="ct-series ct-series-d">
                          <path
                            d="M32.393,157.115A145,145,0,0,0,53.109,224.979L177.219,150Z"
                            className="ct-slice-pie"
                            ct-value="10"
                          ></path>
                        </g>
                        <g className="ct-series ct-series-e">
                          <path
                            d="M35.005,121.712A145,145,0,0,0,32.419,157.62L177.219,150Z"
                            className="ct-slice-pie"
                            ct-value="5"
                          ></path>
                        </g>
                        <g className="ct-series ct-series-f">
                          <path
                            d="M38.462,107.909A145,145,0,0,0,34.907,122.208L177.219,150Z"
                            className="ct-slice-pie"
                            ct-value="2"
                          ></path>
                        </g>
                        <g className="ct-series ct-series-g">
                          <path
                            d="M121.73,16.037A145,145,0,0,0,38.316,108.393L177.219,150Z"
                            className="ct-slice-pie"
                            ct-value="18"
                          ></path>
                        </g>
                        <g className="ct-series ct-series-h">
                          <path
                            d="M155.943,6.569A145,145,0,0,0,121.262,16.232L177.219,150Z"
                            className="ct-slice-pie"
                            ct-value="5"
                          ></path>
                        </g>
                        <g className="ct-series ct-series-i">
                          <path
                            d="M177.219,5A145,145,0,0,0,155.442,6.645L177.219,150Z"
                            className="ct-slice-pie"
                            ct-value="3"
                          ></path>
                        </g>
                        <g>
                          <text
                            dx="261.7831524025766"
                            dy="112.51517845453844"
                            textAnchor="end"
                            className="ct-label"
                          >
                            37%
                          </text>
                          <text
                            dx="195.26460478649187"
                            dy="240.72263843729883"
                            textAnchor="end"
                            className="ct-label"
                          >
                            20%
                          </text>
                          <text
                            dx="115.09954667665079"
                            dy="218.5379790953337"
                            textAnchor="start"
                            className="ct-label"
                          >
                            9%
                          </text>
                          <text
                            dx="88.70176894477068"
                            dy="176.85133264603778"
                            textAnchor="start"
                            className="ct-label"
                          >
                            8%
                          </text>
                          <text
                            dx="84.96938275722115"
                            dy="143.1952778670308"
                            textAnchor="start"
                            className="ct-label"
                          >
                            4%
                          </text>
                          <text
                            dx="87.49085907950469"
                            dy="127.5243333589481"
                            textAnchor="start"
                            className="ct-label"
                          >
                            2%
                          </text>
                          <text
                            dx="108.68077090466626"
                            dy="87.88079667665082"
                            textAnchor="start"
                            className="ct-label"
                          >
                            14%
                          </text>
                          <text
                            dx="152.54781993357187"
                            dy="60.85071391392181"
                            textAnchor="start"
                            className="ct-label"
                          >
                            4%
                          </text>
                          <text
                            dx="170.41402786703077"
                            dy="57.750632757221155"
                            textAnchor="start"
                            className="ct-label"
                          >
                            2%
                          </text>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 col-xs-12">
                    <div>
                      <div>
                        <i
                          className="fa fa-circle text-series-0"
                          aria-hidden="true"
                        ></i>{" "}
                        Government
                      </div>
                    </div>
                    <div>
                      <div>
                        <i
                          className="fa fa-circle text-series-1"
                          aria-hidden="true"
                        ></i>{" "}
                        HUF (Hindu Undivided Family)
                      </div>
                    </div>
                    <div>
                      <div>
                        <i
                          className="fa fa-circle text-series-2"
                          aria-hidden="true"
                        ></i>{" "}
                        Multi National - Global
                      </div>
                    </div>
                    <div>
                      <div>
                        <i
                          className="fa fa-circle text-series-3"
                          aria-hidden="true"
                        ></i>{" "}
                        Multi National - Indian
                      </div>
                    </div>
                    <div>
                      <div>
                        <i
                          className="fa fa-circle text-series-4"
                          aria-hidden="true"
                        ></i>{" "}
                        Nationalized / Commercial / Private Sector Bank
                      </div>
                    </div>
                    <div>
                      <div>
                        <i
                          className="fa fa-circle text-series-5"
                          aria-hidden="true"
                        ></i>{" "}
                        Partnership
                      </div>
                    </div>
                    <div>
                      <div>
                        <i
                          className="fa fa-circle text-series-6"
                          aria-hidden="true"
                        ></i>{" "}
                        Private Limited Company
                      </div>
                    </div>
                    <div>
                      <div>
                        <i
                          className="fa fa-circle text-series-7"
                          aria-hidden="true"
                        ></i>{" "}
                        Proprietorship
                      </div>
                    </div>
                    <div>
                      <div>
                        <i
                          className="fa fa-circle text-series-8"
                          aria-hidden="true"
                        ></i>{" "}
                        Public Sector Unit
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-12 col-lg-6">
            <div className="card">
              <div
                className="card-header card-header-primary"
                style={{ height: "60px" }}
              >
                <h5 className="card-title-size font-weight-normal">
                  Key Indicators
                </h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table
                    className="table borderless"
                    style={{ minWidth: "400px" }}
                  >
                    <thead className="text-dark">
                      <tr>
                        <th className="font-weight-normal">Parameter</th>
                        <th className="font-weight-normal text-right">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="py-0">Average Age</td>
                        <td className="py-0 text-right">33</td>
                      </tr>
                      <tr>
                        <td className="py-0">Average Monthly Income (INR)</td>
                        <td className="py-0 text-right">128,445</td>
                      </tr>
                      <tr>
                        <td className="py-0">Average Loan Amount (INR)</td>
                        <td className="py-0 text-right">1,772,513</td>
                      </tr>
                      <tr>
                        <td className="py-0">Loan to Value (Collateral)%</td>
                        <td className="py-0 text-right">85</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="card">
              <div
                className="card-header card-header-warning"
                style={{ height: "60px" }}
              >
                <h5 className="card-title font-weight-normal">
                  Business Types
                </h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table
                    className="table borderless"
                    style={{ minWidth: "600px" }}
                  >
                    <thead className="text-dark">
                      <tr>
                        <th className="font-weight-normal">Business Type</th>
                        <th className="font-weight-normal text-right">Count</th>
                        <th className="font-weight-normal text-right">
                          Monthly Income (INR)
                        </th>
                        <th className="font-weight-normal text-right">
                          Average RightProfile<sup>TM</sup>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="py-0">Service</td>
                        <td className="py-0 text-right">237</td>
                        <td className="py-0 text-right">172,869</td>
                        <td className="py-0 text-right">5.03</td>
                      </tr>
                      <tr>
                        <td className="py-0">Trader</td>
                        <td className="py-0 text-right">142</td>
                        <td className="py-0 text-right">121,332</td>
                        <td className="py-0 text-right">5.14</td>
                      </tr>
                      <tr>
                        <td className="py-0">Manufacturer</td>
                        <td className="py-0 text-right">42</td>
                        <td className="py-0 text-right">134,174</td>
                        <td className="py-0 text-right">5.21</td>
                      </tr>
                      <tr>
                        <td className="py-0">Professional</td>
                        <td className="py-0 text-right">21</td>
                        <td className="py-0 text-right">142,706</td>
                        <td className="py-0 text-right">5.22</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
