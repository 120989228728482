import React, { useEffect, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

import { toast, ToastContainer } from "react-toastify";
import SideMenu from './Layout/SideMenu';


const Exportpdf = () => {

  const userData = localStorage.getItem("user_data");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const BASE_URL = process.env.REACT_APP_API_URL;
  const user = JSON.parse(userData);

  const [exportApplication, setExportApplication] = useState(null);

  const fetchUsers = () => {
    // if (exportApplication === null || exportApplication.closed) {
    //   const exportBaseUrl = Auth.getExportBaseUrl();
    //   const newWindow = window.open();
    //   newWindow.location = `${}?authorization=${user.token}`;
    //   setExportApplication(newWindow);
    // } else {
    //   exportApplication.focus();
    // }\
    if (startDate == undefined) {
      toast.error("Enter Valid Start Date", {
        position: "top-right",
        autoClose: 5000, // 5 seconds
      });
      return;
    }else if(endDate == undefined){
      toast.error("Enter Valid End Date", {
        position: "top-right",
        autoClose: 5000, // 5 seconds
      });
      return;
    }
    const queryParams = new URLSearchParams({
      startDate: startDate,
      endDate: endDate,
    }).toString();
    const newWindow = window.open();
    newWindow.location = `https://3.7.159.34/api/v1/download?${queryParams}?authorization=${user.token}`
  }

  const handleStrtDteChange = (event) => {
    setStartDate(event.target.value);
  }

  const handleEndDteChange = (event) => {
    setEndDate(event.target.value);
  }


  return (
    <>
      <div>
        <SideMenu />
      </div>
      <div className="wrapper">
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </nav>

        <div className="login-container">
          <div className="login-box">
            <h3 className="mb-2 mt-3 fw-400">Download Application from RightProfileTM</h3>
            <form action="dashboard.html">
              <div className="row align-items-center">
                <div className="col-6 form-group">
                  <label htmlFor="startDate">Start Date</label>
                  <input type="date" className="form-control" id="startDate" onChange={handleStrtDteChange} />
                </div>
                <div className="col-6 form-group">
                  <label htmlFor="endDate">End Date</label>
                  <input type="date" className="form-control" id="endDate" onChange={handleEndDteChange} />
                </div>
              </div>

              <div className="d-flex justify-content-middle mt-3">
                <button type="button" className="btn btn-primary" onClick={fetchUsers}>Download</button>
              </div>
            </form>
          </div>
        </div>

        <div className="container">
          <div className="row mb-2">
            <div className="col">
              <div className="footer-section" style={{ textAlign: 'center' }}>
                &copy; RightProfile™ – Syntellect India Private Limited. All rights reserved.
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />

      </div>
    </>
  )
}

export default Exportpdf


