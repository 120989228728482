import React, { useEffect, useState } from 'react'

const UsePagination = (value:any,pageNo:any,pages:any) => {

    const [activePage, setActivePage] = useState(1);
    const [data, setData] = useState([]);
    const [paginationData, setPaginationData] = useState({
        TotalProducts: 0,
        ProductsPerPage:pages,
        TotalPages: 0,
        TotalPagesArray: []
    });

    useEffect(() => {
        if (value) {
            const totalProducts = value.length;
            const totalPages = Math.ceil(totalProducts / paginationData.ProductsPerPage);
            const totalPagesArray = Array.from(Array(totalPages).keys());
            setPaginationData((prev:any) => ({
                ...prev,
                TotalProducts: totalProducts,
                TotalPages: totalPages,
                TotalPagesArray: totalPagesArray
            }));

            setActivePage(1);
        }
    }, [value, paginationData.ProductsPerPage,pages,pageNo]);

    useEffect(() => {
        if (value) {
            setData(value.slice(paginationData.ProductsPerPage * (activePage - 1), paginationData.ProductsPerPage * activePage));
        }
    }, [activePage, value, paginationData.ProductsPerPage,pages,pageNo]);

    const start = Math.max(activePage - 3, 0);
    const end = Math.min(activePage + 2, paginationData.TotalPages);

    const Pagination:any = () => {
        return (
            <nav className="theme-pagination">
                <ul className="pagination">
                    <li className={`page-item${activePage === 1 ? " disabled" : ""}`}>
                        <a href="#" className="page-link" aria-label="First" onClick={() => activePage > 1 && setActivePage(1)}>
                            <span aria-hidden="true">&nbsp;&nbsp;&nbsp;First&nbsp;&nbsp;&nbsp;</span>
                        </a>
                    </li>
                    <li className={`page-item${activePage === 1 ? " disabled" : ""}`}>
                        <a href="#" className="page-link" aria-label="Previous" onClick={() => activePage > 1 && setActivePage(p => p - 1)}>
                            <span aria-hidden="true">Previous</span>
                        </a>
                    </li>
                    {paginationData.TotalPagesArray.slice(start, end).map((item) => (
                        <li className={`page-item${item + 1 === activePage ? " active" : ""}`} key={item} onClick={() => setActivePage(item + 1)}>
                            <a href="#" className="page-link">{item + 1}</a>
                        </li>
                    ))}
                    <li className={`page-item${activePage === paginationData.TotalPages ? " disabled" : ""}`}>
                        <a href="#" className="page-link" aria-label="Next" onClick={() => activePage < paginationData.TotalPages && setActivePage(p => p + 1)}>
                            <span>&nbsp;&nbsp;&nbsp;Next&nbsp;&nbsp;&nbsp;</span>
                        </a>
                    </li>
                    <li className={`page-item${activePage === paginationData.TotalPages ? " disabled" : ""}`}>
                        <a href="#" className="page-link" aria-label="Last" onClick={() => activePage < paginationData.TotalPages && setActivePage(paginationData.TotalPages)}>
                            <span>&nbsp;&nbsp;&nbsp;Last&nbsp;&nbsp;&nbsp;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        );
    };

    return [Pagination, data];
}

export default UsePagination