import React, { useEffect, useState } from 'react';
import SideMenu from './Layout/SideMenu';
import { Outlet, Route, Link, NavLink, useNavigate } from 'react-router-dom';
import { Tabs, Tab, Button, Modal, Accordion, Card, Toast, ToastContainer } from 'react-bootstrap';
import { Form, Col, Row, FormControl } from 'react-bootstrap';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment';
import { json } from 'stream/consumers';

interface UserData {
  token: string;
  userId: string;
  usertype: string;
  role: string;
}




const LoanCreationPhases = () => {

  const [key, setKey] = useState<string>('loanInfo');
  const [innerKey, setInnerKey] = useState<string>('applicant');

  const [subTabkey, setSubTabKey] = useState<string>('applicant');
  const [innerSubKey, setInnerSubKey] = useState<string>('applicant');

  const [loading, setLoading] = useState(false);
  const [lookup, setLookup] = useState(null);
  const [applicantView, setApplicantView] = useState(false);
  const [repaymentView, setRepaymentView] = useState(false);
  const [sitePage, setSitePage] = useState(false);
  const [addApplicantDialog, setAddApplicantDialog] = useState(false);
  const [showUploadVideo, showVideoUploadSection] = useState(false);
  const [showUploadImage, showUploadImageSection] = useState(false);
  const [showService,showServiceSection] = useState(false);

  const [bankView, setBankView] = useState(false);
  let [loanModel, setLoanModel] = useState<any>({});
  let [applicant, setApplicant] = useState<any>({ primary: true });
  let [bankModel, setBankModel] = useState<any>({});
  let [repaymentmodel, setRepaymentModel] = useState<any>({});
  let [siteModel, setSiteModel] = useState<any>({});
  let [showMedInsurancePage, setMedInsurancePage] = useState(false);
  let [showInsurancePage, setInsurancePage] = useState(false);
  let [service, setService] = useState<any>({sales:{},units:[],unitprice:[]});
  let [showCraftsmanMachineForm,setShowCraftsmanMachineForm] = useState(false);
  let [craftsmanmachineError,setcraftsmanmachineError] = useState(false);

  let visitMapDetail:any = {};
  let visitMapImge:any = {};
  let map:any ={}  
  let visitBadImages:any= [];
  let craftsman:any={};
  let [transportMachine,setTransportMachine] = useState<any>({})

  const defaultservice:any = {
    name:"", revenue:0.0, computationbasis:"", source:"", salesconsideration:"AVERAGE",
    sales:{month0:0.0, month1:0.0, month2:0.0}
  };
  

  const googleMapKey='AIzaSyBgI7ROQWWGrW2k1LgeK2na22h4LaSoPcc';
  
  const userData = localStorage.getItem("user_data");
  if (!userData) {
    throw new Error("No user data found in local storage");
  }

  const user: any = JSON.parse(userData);


  const kiranasales = [
    {id:null, name: "Branded goods", code: "BG", sales: 0,profitmargin:0 ,presentsale:0,},
    {id:null, name: "Non-branded goods / Repacked goods (E.g. Farsan, Eggs,Grains)", code: "NBG", sales: 0,profitmargin:0,presentsale:0 },
  ];
  const instruments = [{code:"PROPERTY", instrument:"Immovable Property - Market Value",amount:0.0},
    {code:"SAV", instrument:"Savings in Bank account",amount:0.0},
    {code:"LIFE_POLICY", instrument:"Life Insurance Policies - YTD Surrender Value",amount:0.0},
    {code:"FD", instrument:"Fixed Deposit - Surrender Value",amount:0.0},
    {code:"MF",instrument:"Mutual Fund - Market Value",amount:0.0},
    {code:"SHARES", instrument:"Shares - Market Value",amount:0.0},
    {code:"CHIT", instrument:"Chit Fund",amount:0.0},
    {code:"PERSONAL", instrument:"Personal Assets (TV, Car etc.) - Disposable Value",amount:0.0},
    {code:"GOLD", instrument:"Gold",amount:0.0},
    {code:"PPF",instrument:"Balance in PPF",amount:0.0},
    {code:"EPF",instrument:"Balance in EPF (Customer share)",amount:0.0},
    {code:"LOAN_RECEIVE",instrument:"Loan Receivable from Others",amount:0.0}
    ];

  const householdexpense = [
    { "code": "FOOD", "item": "Food / Kirana, Clothing and daily requirement expenses", "amount": '0', custom: false },
    { "code": "RENT", "item": "Rent of residence premise", "amount": '0', custom: false },
    { "code": "MED", "item": "Medical expenses of regular nature", "amount": '0', custom: false },
    { "code": "SCHOOL", "item": "Schooling fees", "amount": '0', custom: false },
    { "code": "TUTION", "item": "Private tuition fees", "amount": '0', custom: false },
    { "code": "ELEC", "item": "Electricity", "amount": '0', custom: false },
    { "code": "PHONE", "item": "Landline bill", "amount": '0', custom: false },
    { "code": "MOBILE", "item": "Mobile bill", "amount": '0', custom: false },
    { "code": "TRAVEL", "item": "Travel and Conveyance", "amount": '0', custom: false },
    { "code": "HOTEL", "item": "Hotel expenses", "amount": '0', custom: false },
    { "code": "ENTERTAIN", "item": "Entertainment", "amount": '0', custom: false },
    { "code": "CC", "item": "Credit card payment", "amount": '0', custom: false },
    { "code": "INS", "item": "Insurance premium", "amount": '0', custom: false },
    { "code": "FHELP", "item": "Family help", "amount": '0', custom: false }
  ];
  const contributions = [
    { "code": "PL", "contribution": "Personal Loan", "amount": '0', custom: false },
    { "code": "ASSET", "contribution": "Sale of Assets / Property", "amount": '0', custom: false },
    { "code": "JEWELLERY", "contribution": "Sale of Jewellery", "amount": '0', custom: false },
    { "code": "SAV", "contribution": "Savings", "amount": '0', custom: false },
    { "code": "INVESTMENT", "contribution": "Disposal of Investment", "amount": '0', custom: false },
    { "code": "CS", "contribution": "Credit Society", "amount": '0', custom: false },
    { "code": "CF", "contribution": "Chit Fund", "amount": '0', custom: false },
    { "code": "FRIENDS", "contribution": "Friends / Relatives / Family", "amount": '0', custom: false }
  ];
  const itrSalesModel = [
    {seq:1, name:"Gross Sales / Receipts", code:"GSR", amount:0.0},
    {seq:2, name:"Net Profit After Tax (Annual)", code:"NPAT", amount:0.0},
    {seq:3, name:"Depreciation (Annual)", code:"DPR", amount:0.0}
  ];

  const capitalinvestment=[
    {id:null,name:"Shop premise / Shop deposit",code:"SPSD",amt:'0'},
    {id:null,name:"Shop ambience / furniture",code:"SAF",amt:'0'},
    {id:null,name:"Agency / Franchise fees",code:"AFF",amt:'0'},
    {id:null,name:"Stock",code:"ST",amt:'0'}
  ];

  const bizEmployee = [
    {bizemployeeid:1, name: "Delivery boys", code: "DB", current: '0',last:'0',last_1:'0' },
    {bizemployeeid:1, name: "Un-skilled", code: "UNS", current: '0',last:'0',last_1:'0' },
    {bizemployeeid:1, name: "Skilled", code: "S",current: '0',last:'0',last_1:'0' },
    {bizemployeeid:1, name: "Semi-skilled", code: "SEMIS", current: '0',last:'0',last_1:'0'},
  ];


  const bizModel = [
    // {id:null,name:"ITR", applicability:false, verified :false, remarks:""},
     {id:null,name:"Statutory Licenses", applicability:false, verified :false, remarks:""},
     {id:null,name:"Liability Insurance*", applicability:false, verified :false, remarks:""},
     {id:null,name:"Shops and Establishment Certificate", applicability:false, verified :false, remarks:""},
     {id:null,name:"Lease Agreement", applicability:false, verified :false, remarks:""},
     //{id:null,name:"Books of Business Transactions", applicability:false, verified :false, remarks:""},
     {id:null,name:"GST Registration Certificate", applicability:false, verified :false, remarks:""},
     {id:null,name:"Association Certificate", applicability:false, verified :false, remarks:""},
     {id: null, name: "Poultry venture", applicability: false, verified: false, remarks: "" },
    // {id:null,name:"Wages Register", applicability:false, verified :false, remarks:""},
     //{id:null,name:"Electricity Bill", applicability:false, verified :false, remarks:""},
     //{id:null,name:"Telephone / Mobile Bill", applicability:false, verified :false, remarks:""},
     {id:null,name:"Attendance / Salary Register", applicability:false, verified :false, remarks:""},
     {id:null,name:"Business Agreements / Contracts", applicability:false, verified :false, remarks:""},
     {id:null,name:"Utility Bills", applicability:false, verified :false, remarks:""}
   ]
   
  const directExpenseModel = [
    {seq:1, code:"STK", name:"Stock = Opening stock - Closing stock", mamount:0, m1amount:0,m2amount:0 },
    {seq:2, code:'NEWPUR',name:"New Purchases / Production Cost",mamount:0, m1amount:0,m2amount:0 },
    {seq:3, code:'WAGES', name:"Wages" , mamount:0, m1amount:0,m2amount:0 },
    {seq:4,code:'TRANS', name:"Transportation" , mamount:0, m1amount:0,m2amount:0 }
  ];
  const indirectExpenseModel = [
    {seq:1, code:'ELEC', name:"Electricity", mamount:0, m1amount:0,m2amount:0 },
    {seq:2, code:'RENT', name:"Rent",mamount:0, m1amount:0,m2amount:0 },
    {seq:3, code:'CON', name:"Conveyance" , mamount:0, m1amount:0,m2amount:0 },
    {seq:4, code:'UTIL', name:"Utility Bills" , mamount:0, m1amount:0,m2amount:0 },
    {seq:5, code:'INT_EXP', name:"Interest Expenses" , mamount:0, m1amount:0,m2amount:0 },
    {seq:6, code:'OVERHEAD_EXP', name:"Other Overhead Expenses" , mamount:0, m1amount:0,m2amount:0 }
  ];
  const seasonalModel = [
    {season:0, 'month':'January', seasonal:false},
    {season:1,'month':'February', seasonal:false},
    {season:2,'month':'March', seasonal:false},
    {season:3,'month':'April', seasonal:false},
    {season:4,'month':'May', seasonal:false},
    {season:5,'month':'June', seasonal:false},
    {season:6,'month':'July', seasonal:false},
    {season:7,'month':'August', seasonal:false},
    {season:8,'month':'September', seasonal:false},
    {season:9,'month':'October', seasonal:false},
    {season:10,'month':'November', seasonal:false},
    {season:11,'month':'December', seasonal:false},
  ];

  const bizincomeDocumentsModel = [
    {id:null,name:"Sales Register", applicability:false, verified :false, remarks:""},
    {id:null,name:"Purchase Book", applicability:false, verified :false, remarks:""},
    {id:null,name:"Inventory Register", applicability:false, verified :false, remarks:""},
    {id:null,name:"Bills / Invoices / Bill Book", applicability:false, verified :false, remarks:""},
    {id:null,name:"Expense Bills / Vouchers", applicability:false, verified :false, remarks:""},
    {id:null,name:"Wage Register", applicability:false, verified :false, remarks:""},
    {id:null,name:"Electricity Bill", applicability:false, verified :false, remarks:""},
    {id:null,name:"Telephone Bill", applicability:false, verified :false, remarks:""},
    {id:null,name:"Lease Agreement", applicability:false, verified :false, remarks:""},
    {id:null,name:"Receipts", applicability:false, verified :false, remarks:""},
    {id:null,name:"Credit Book", applicability:false, verified :false, remarks:""},
    {id:null,name:"Bank Deposits", applicability:false, verified :false, remarks:""},
    {id:null,name:"Mandi Receipts", applicability:false, verified :false, remarks:""},      
    {id:null,name:"Purchase / Work Orders", applicability:false, verified :false, remarks:""},
    {id:null,name:"Contracts with Suppliers", applicability:false, verified :false, remarks:""},
    {id:null,name:"Contracts with Clients", applicability:false, verified :false, remarks:""},
    {id:null,name:"Dairy Income Statement", applicability:false, verified :false, remarks:""}
  ];

  const otherIncomesModel = [
    {displayOrder:1,name:"Rental Income",eligibleamount:0,percentage:null,readonly:false},
    {displayOrder:5,name:"Commission Income",eligibleamount:0,percentage:null,readonly:false},
    {displayOrder:4,name:"Agriculture Income",eligibleamount:0,percentage:null,readonly:false},
    {displayOrder:3,name:"Part-time Income",eligibleamount:0,percentage:null,readonly:false},
    {displayOrder:2,name:"Rental Income - Cash",eligibleamount:0,percentage:null,readonly:false}
  ];

  const bizotherIncomesModel = [
    {name:"Rental Income",eligibleamount:0},
    {name:"Commission Income",eligibleamount:0},
    {name:"Agriculture Income",eligibleamount:0},
    {name:"Part-time Income",eligibleamount:0},
    {name:"Other",eligibleamount:0, type:'OTHER'},
  ];
  const salaryEarningsModel = [
    {seq:1, name:"Basic_Salary", mamount:0, m1amount:0,m2amount:0 ,displayOrder:1},
    {seq:2, name:"Variable_Allowances",mamount:0, m1amount:0,m2amount:0 ,displayOrder:3},
    {seq:3, name:"Overtime_Allowances" , mamount:0, m1amount:0,m2amount:0 ,displayOrder:4},
    {seq:4, name:"Fixed_Allowances", mamount:0, m1amount:0,m2amount:0,displayOrder:2}
  ];

  const salaryDeductionsModel = [
    {seq:1, name:"Statutory Deductions", mamount:0, m1amount:0,m2amount:0 ,displayOrder:3},
    {seq:2, name:"Professional Tax",mamount:0, m1amount:0,m2amount:0 ,displayOrder:1},
    {seq:3, name:"TDS" , mamount:0, m1amount:0,m2amount:0 ,displayOrder:2}
  ];
  const incomeDocumentsModel = [
    {id:null,name:"ITR", applicability:false, verified :false, remarks:""},
    {id:null,name:"Form 16", applicability:false, verified :false, remarks:""},
    {id:null,name:"Salary Slips", applicability:false, verified :false, remarks:""},
    {id:null,name:"Salary Certificate", applicability:false, verified :false, remarks:""},
    {id:null,name:"Salary Credits in Bank Account", applicability:false, verified :false, remarks:""}
  ];
  const bizDocumentsModel = [
    {id:null,name:"Bill Book", applicability:false, verified :false, remarks:""},
    {id:null,name:"Statutory Licenses", applicability:false, verified :false, remarks:""},
    {id:null,name:"Wages Register", applicability:false, verified :false, remarks:""},
    {id:null,name:"Attendance Register", applicability:false, verified :false, remarks:""},
    //{id:null,name:"Business Card", applicability:false, verified :false, remarks:""}
  ];
  
  useEffect(() => {
    if (!lookup) {
      const headers = {
        "Authorization": "Bearer " + user.token,
        "Content-Type": "application/json"
      }
      const config: AxiosRequestConfig = {
        headers: headers
      }
      axios.get(
        process.env.REACT_APP_API_URL + "/hdfc/cd/list",
        config
      ).then((res) => {
        setLookup(res.data);
        
      }).catch((e) => {
        console.log(e);
      });
    }

  })
  
  const [errorMsg, setErrorMsg] = useState('');
  const [formData, setFormData] = useState({
    loanAmountRequested: 0,
    customerAffordableEmi: 0,
    purposeOfLoan: '',
    edbranch: '',
    applicantfor: '',
    applicantTypes: '',
    type: '',
    primary: false,
    familyHead: false,
    firstname: '',
    lastname: '',
    dob: '',
    gender: '',
    address1: '',
    address2: '',
    district: '',
    city: '',
    state: '',
    zipcode: '',
    locality: '',
    latitude: '',
    longitude: '',
    distance: '',
    qualification: '',
    dependents: false,
    standards: [],
    earningnumbers: 0,
    totalmembers: 0,
    yearsincurrentresidence: 0,
    monthincurrentresidence: 0,
    yearsincurrentcity: 0,
    monthincurrentcity: 0,
    residencetype: '',
    distancework: '',
    residenceownership: '',
    residencearea: '',
    spouseaware: '',
    isillness: '',
    illnessReason: '',
    vehiclesOwned: [],
    remarks: '',
    bankaccounts: [],
    repayments: [],
    sitevisits: [],
    networth: {},
    employer: {},
    income: {},
    spouse: 0,
    parents: 0,
    brother: 0,
    sister: 0,
    schoolchildren: 0,
    other: 0,
    accountno: 0,
    accounttype: '',
    bankname: '',
    accopendate: '',
    sinceyear: 0,
    sincemonth: 0,
    minbalance: 0,
    odfacility: '',
    loanpurpose: '',
    institution: '',
    loantype: '',
    loanamount: 0,
    roi: 0,
    originaltenure: 0,
    balancetenure: 0,
    collateral: 0,
    emi: 0,
    emimode: '',
    noofdefaults: '',
    moratoriumused: '',
    moratoriummonths: '',
    outstandingamount: 0,
    sitevisited: '',
    placevisited: '',
    area: 0,
    visitdate: '',
    nameboard: '',
    employees: '',
    customersseen: '',
    employeesseen: '',
    customers: '',
    studentsseen: '',
    activity: '',
    activitylevel: '',
    cibil: 0,
    highmark: 0,
    experian: 0,
    creditcards: 0,
    outstandingcredit: 0,
    maturitydate: '',
    sumassured: 0,
    policy: '',
  });

  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    loanAmountRequested: '',
    customerAffordableEmi: '',
    purposeOfLoan: '',
    edbranch: ''
  });
  const [isTouched, setIsTouched] = useState({
    placevisited:false,
    area: false,
    earea: false,
    carea: false,
    propplacevisited: false,
    address1: false,
    address2: false,
    city: false,
    locality: false,
    district: false,
    state: false,
    pinCode: false,
    transectionOrder: false,
    transectionArea: false,
    marketValue: false,
    residencearea: false,
    residenceownership: false,
    residencetype: false,
    monthincurrentcity: false,
    yearsincurrentcity: false,
    monthincurrentresidence: false,
    yearsincurrentresidence: false,
    applicentlocality: false,
    applicentdistrict: false,
    applicentcity: false,
    applicentstate: false,
    applicentAddress2: false,
    applicentAddress1: false,
    zipcode: false,
    lastName: false,
    firstname: false,
    qualification: false,
    type: false,
    applicantfor: false,
    loantype:false,
    institution:false,
    loanamount:false,
    roi:false,
    originaltenure:false,
    balancetenure:false,
    outstandingamount:false,
    emimode:false,
    noofdefaults:false,
    accountno:false,
    bankname:false,
    accopendate:false,
    minbalance:false,
    cibil:false,
    lastname:false,

  });

  const errorStyle=(field:any,formData:any) => {
   let styles = {
    borderColor: field && !formData ? 'red' : 'initial',
    borderWidth: field && !formData ? '1px' : '1px',
   }
    return styles;
};

  const errorFont = (field:any,formData:any) =>{
    let fontColor = {
      color: field && !formData ? 'red' : 'initial'
    }
    return fontColor;
  }

const handleBlur = (event:any) =>  {
  let field = event.target.name;
  setIsTouched({
    ...isTouched,
    [field]: true,
  });
};


  const [loanID, setLoanID] = useState(0);

  const [applicationNumber, setApplicationNumber] = useState('');

  console.log("LOAN ID:", loanID);
  console.log("Application ID:", applicationNumber);

  const handleNavigation = () => {
    setKey('loanInfo')
  }

  const handleChange = (e: any) => {
    if (e.target.type==='checkbox') {
      const checked = e.target.checked;
      setFormData({ ...formData, [e.target.name]: checked ? 'true' : '' });
    } else {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
    console.log("Form Data:", formData)
  };

  const checkValidation = () => {
    const newErrors = {
      loanAmountRequested: '',
      customerAffordableEmi: '',
      purposeOfLoan: '',
      edbranch: ''
    };
    if (!loanModel.application.loanamount) {
      newErrors.loanAmountRequested = 'Please enter Loan Amount';
    }

    if (!loanModel.application.affordableemi) {
      newErrors.customerAffordableEmi = 'Please enter Emi Payment Amount';
    }

    if (!loanModel.application.loanpurpose) {
      newErrors.purposeOfLoan = 'Please select purpose of Loan';
    }

    if (!loanModel.application.bankparticulars.location.branch) {
      newErrors.edbranch = 'Please select the branch';
    }

    setErrors(newErrors);
    return Object.values(newErrors).every(x => x === '');
  };



  const SubmitData = async () => {
    try {
      if (checkValidation()) {
        console.log("Form submitted");
        loanModel.householdexpense.expenses = householdexpense;
        loanModel.application.bankparticulars.logindate = moment(new Date()).format('dd-MM-yyyy hh:mm:ss a');
        loanModel.status = 'DRAFT';
        loanModel.userid = user.F_USERID;

        setLoading(true);
        const headers = {
          "Authorization": "Bearer " + user.token,
          "Content-Type": "application/json"
        }

        const config: AxiosRequestConfig = {
          headers: headers
        };
        const response: AxiosResponse = await axios.post(
          process.env.REACT_APP_API_URL + "/app/reach",
          loanModel,
          config
        );
        console.log("Loan api response: ", response.data)

        setLoan(response.data);
        //setLoanID(response.data.id);
        //setApplicationNumber(response.data.applicationnumber)
        setLoading(false);
        if (key === 'loanInfo') {
          setKey('personal');
        }
      }
    }
    catch (error) {
      console.log("Error response:", error);
      setErrorMsg("failed api call");
      setLoading(false);
    }
  }

  function setLoan(loan:any){
    loan = JSON.parse(JSON.stringify(loan));
    applicant = JSON.parse(JSON.stringify(applicant));
    setLoanModel(loan);
  }
  function getLookupProperty(prop: string) {
    if(prop==='applicantTypes' && (applicant.applicantfor === 'INC' || applicant.applicantfor === 'INCPRP' )){
      let applicantTypes:any = JSON.parse(JSON.stringify(lookup!==null && lookup['applicantTypes']!==null ? lookup['applicantTypes']  : [] ));
      applicantTypes = applicantTypes.filter((e:any)=>'informal_salaried'=== e['code'] || 'P' === e['code'] ||'informal_selfemployed' === e['code']);
      return applicantTypes;
    }
    return lookup!==null && lookup[prop]!==null ? lookup[prop] : [];
  }

  
  function allowAlphabetsSpacesFullstop(event:any){
    var regex = new RegExp("^[a-zA-Z\. ]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
        event.preventDefault();
        return false;
    }
    }
    
    function allowIntegers (event:any) {
      var regex = new RegExp("^[0-9]+$"); // Regular expression to allow only integers (0-9)
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
          event.preventDefault();
          return false;
      }
  }
  function allowPositiveIntegersOnly(event: any) {
    var code = (event.which) ? event.which : event.keyCode;
    if (code!==8 && !(code >= 48 && code <= 57)) {
      event.preventDefault();
    }
  }
  
  function initialize() {
    
    if(loanModel === undefined || loanModel.application===undefined || loanModel===null  || loanModel.application == null){
      
      loanModel = { applicants: [] };
      loanModel.check={};
      loanModel.check.applicant = {};
      
      loanModel.application = {repayments:[]};
      loanModel.application.loanamount=0;
      loanModel.application.bankparticulars = { location: {} };
      if (!isDefined(loanModel.householdexpense )) {
        loanModel.householdexpense = { expenses: [] };
        loanModel.householdexpense.pincodeminvalue = 0;
        loanModel.householdexpense.pincodemaxvalue = 0;
      }
      if (!isDefined(loanModel.householdexpense.expenses)|| loanModel.householdexpense.expenses.length < 1) {
        loanModel.householdexpense.expenses = JSON.parse(JSON.stringify(householdexpense));
      }
      
      if (!isDefined(loanModel.property)) {
        loanModel.property = {address:{},propertyuse:{}};
      }
      if (!isDefined(loanModel.property.contributions)|| loanModel.property.contributions.length < 1) {
        loanModel.property.contributions = JSON.parse(JSON.stringify( contributions));
      }

      calculateExpenses();
      calculateContribution();
      getNatureOfWorkFlag();
      showBusinessProfileTemplate();
      updatePropertyTypeOptionsAndCheckLapSelected();
    }
  }
  
  function calculateExpenses() {
    var total = 0.0;
    for (var i = 0; i < loanModel.householdexpense.expenses.length; i++) {
      var product = loanModel.householdexpense.expenses[i];
      if (!isNaN(product.amount) && (product.amount > 0))
        total += parseFloat(product.amount);
    }
    loanModel.totalexpenses = total;
  }
  function calculateContribution() {
    var total = 0.0;
    for (var i = 0; i < loanModel.property.contributions.length; i++) {
      var product = loanModel.property.contributions[i];
      if (!isNaN(product.amount) && (product.amount > 0))
        total += parseFloat(product.amount);
    }
    loanModel.totalcontribution = total;
  }

  function getNatureOfWorkFlag() {
    if (isDefined(loanModel.application.loanpurpose)) {
      if (loanModel.application.loanpurpose==='EXT' || loanModel.application.loanpurpose==='IMP') {
        loanModel.property.natureofworkflag = true;
      }
      else {
        loanModel.property.natureofworkflag = false;
      }
    }
  }

  function showBusinessProfileTemplate() {
    var showProfileList = JSON.parse(localStorage.getItem('showbusinessprofileslist') || '');
    var profileListFlag = localStorage.getItem('showbusinessprofileslistflag') || '';
    if (profileListFlag==="YES") {
      for (var i = 0; i < showProfileList.length; i++) {
        var profile = showProfileList[i];
        switch (profile) {
          case 'Small Kirana / Provision / Pan Store':
            loanModel.kiranaflag = true;
            break;
          case 'Tailoring / Embroidery':
            loanModel.tailorflag = true;
            break;
          case 'Vegetables / Fruits / Flowers Vendor':
            loanModel.vegetableflag = true;
            break;
          case 'Craftsman':
            loanModel.craftsmanflag = true;
            break;
          case 'Dairy Farm':
            loanModel.dairyflag = true;
            break;
          case 'Dairy Trader':
            loanModel.dairyflag = true;
            break;
          case 'Garage':
            loanModel.garageflag = true;
            break;
          case 'Beauty Parlour / Spa / Hair Saloon':
            loanModel.beautyparlourflag = true;
            break;
          case 'Tutor':
            loanModel.tutorflag = true;
            break;
          case 'Priest / Astrologer':
            loanModel.priestflag = true;
            break;
          case 'Transport':
            loanModel.transportflag = true;
            break;
          case 'Livestock - Poultry / Meat Products':
            loanModel.livestockflag = true;
            break;
          case 'Agent - Insurance':
            loanModel.agentflag = true;
            break;
          case 'Clothing - Retailer':
            loanModel.clothingflag = true;
            break;
          case 'Mobile':
            loanModel.mobileflag = true;
            break;
          case 'Street Food Stall/Cart':
            loanModel.streetfoodflag = true;
            break;
          case 'Laundry and Dry Cleaning':
            loanModel.laundryflag = true;
            break;
          case "Xerox / Stationary Shop":
            loanModel.xeroxflag = true;
            break;
          case 'Sweets / Farsan Shop':
            loanModel.sweetflag = true;
            break; //sweet
        }
      }
    }
    else {
      loanModel.kiranaflag = true;
      loanModel.tailorflag = true;
      loanModel.vegetableflag = true;
      loanModel.craftsmanflag = true;
      loanModel.dairyflag = true;
      loanModel.garageflag = true;
      loanModel.beautyparlourflag = true;
      loanModel.tutorflag = true;
      loanModel.priestflag = true;
      loanModel.transportflag = true;
      loanModel.livestockflag = true;
      loanModel.agentflag = true;
      loanModel.clothingflag = true;
      loanModel.mobileflag = true;
      loanModel.streetfoodflag = true;
      loanModel.laundryflag = true;
      loanModel.xeroxflag = true;
      loanModel.sweetflag = true;
    }
  }
  function updatePropertyTypeOptionsAndCheckLapSelected() {
    if (user.usertype!=='CAPITAL INDIA' && (loanModel.application.loanpurpose==='LAP' || loanModel.application.loanpurpose==='HLAP')) {
      loanModel.property.lapSelected = true;
    }
    //RG-995
    else if (user.usertype==='CAPITAL INDIA' && (loanModel.application.loanpurpose==='LAP' || loanModel.application.loanpurpose==='HLAP')) {
      loanModel.property.lapSelected = true;
    }
    else if (loanModel.application.loanpurpose!=='LAP' && loanModel.application.loanpurpose!=='HLAP') {
      loanModel.property.lapSelected = false;
    }
  }
  initialize();
  
  function checkApplicantPrimary() {
    var primary = 0;
    if (loanModel.applicants!==null) {
      for (var i = 0; i < loanModel.applicants.length; i++) {
        if (loanModel.applicants[i].primary) {
          primary = primary + 1;
          if (applicant.firstname!==loanModel.applicants[i].firstname) {
            loanModel.primaryApplicant = loanModel.applicants[i].firstname;
          }
        }
      }
    }
    if (primary >= 2) {
      applicant.primary = false;
      applicant.type = "";
      primary = 0;
    }
  }
  function getTypeValue(type: any, value: any) {
    var o:any = null;
    if (type==="LOAN_TYPE") {
      o = getLookupProperty('loanTypes');
    }
    else if (type==="BANK") {
      o = getLookupProperty('BANK');
    }
    else if (type==="APPLICANT_TYPE") {
      o = getLookupProperty('applicantTypes');
    }
    else if (type==="QUALIFICATION") {
      o = getLookupProperty('qualifications');
    }
    else if (type==="SITE") {
      o = getLookupProperty('sitePremises');
    }
    else if (type==="CRAFTSMANMACHINE") {
      o = getLookupProperty('paymentMade');
    }
    else if (type==="CATTLETYPE") {
      o = getLookupProperty('cattleType');
    }
    else if (type==="PROCUREMENTSOURCE") {
      o = getLookupProperty('procurementSource');
    }
    else if (type==="CATTLEAGE") {
      o = getLookupProperty('cattleAge');
    }
    else if (type==="APPLICANTFOR") {
      o = getLookupProperty('applicantsFor');
    }
    else if (type==="GARAGETYPE") {
      o = getLookupProperty('garageMachineorEquipment');
    } else if (type==="PROFILES") {
      o = getLookupProperty('PROFILES');
    }

    else if (type==="VEHICLETYPE") {
      o = getLookupProperty('vehicletype');
    }
    else if (type==="TRANSPORTTYPE") {
      o = getLookupProperty('transporttype');
    }
    else if (type==="TRANSPORTNATUREOFGOOD") {
      o = getLookupProperty('transportnatureofgood');
    }
    else if (type==="TRANSPORTADDITIONALSERVICES") {
      o = getLookupProperty('transportadditionalservices');
    }
    else if (type==="FREQUENCYOFGARAGEVISITS") {
      o = getLookupProperty('freqofgaragevisits');
    }
    else if (type==="AVGEXPOFDRIVER") {
      o = getLookupProperty('avgexpofdriver');
    }
    else if (type==="MAINTENANCEITEMS") {
      o = getLookupProperty('maintenanceitems');
    }
    else if (type==="TRANSPORTOWNERSHIP") {
      o = getLookupProperty('transportownsership');
    }
    else if (type==="TRANSPORTTRUCKAGENCY") {
      o = getLookupProperty('transporttruckingagency');
    }
    else if (type==="POSITE") {
      o = getLookupProperty('poultrysalesite');
    }
    else if (type==="POTYPE") {
      o = getLookupProperty('poultryIncomeproduct');
    }
    else if (type==="POUNIT") {
      o = getLookupProperty('poultrymeasuring');
    }
    else if (type==="POPERIOD") {
      o = getLookupProperty('poultryperiod');
    }
    else if (type==="POANIMAL") {
      o = getLookupProperty('poultryAnimalType');
    }
    else if (type==="POAGE") {
      o = getLookupProperty('poultryAge');
    }
    else if (type==="ClothingCombination") {
      o = getLookupProperty('Combination');
    }
    else if (type==="MOBTYPE") {
      o = getLookupProperty('mobileservice');
    }
    else if (type==="MOBPURPOSE") {
      o = getLookupProperty('mobileproduct');
    }
    else if (type==="MOBTIME") {
      o = getLookupProperty('mobileperiod');
    }
    else if (type==="StreetFoodVendorCategory") {
      o = getLookupProperty('StreetFoodVendorCategory');
    }
    else if (type==="SERVICESOURCE") {
      o = getLookupProperty('servicesource');
    }
    else if (type==="SERVICEOFFERED") {
      o = getLookupProperty('serviceoffered');
    }
    else if (type==="TYPEOFGARMENT") {
      o = getLookupProperty('typeofgarment');
    }
    else if (type==="PERIODTOBECONSIDERED") {
      o = getLookupProperty('periodtobeconsidered');
    }
    else if (type==="XEROXMACHINE") {
      o = getLookupProperty('xeroxmachine');
    }
    else if (type==="XEROXOWNERSHIP") {
      o = getLookupProperty('xeroxOwnership');
    }
    else if (type==="XEROXINCOMETYPE") {
      o = getLookupProperty('xeroxincometype');
    }
    else if (type==="XEROXTIMEPERIOD") {
      o = getLookupProperty('xeroxtimeperiod');
    }
    else if (type==="XEROXTIME") {
      o = getLookupProperty('xeroxTime');
    }
    else if (type==="PRODUCTSOLD") { //sweet
      o = getLookupProperty('productsold');
    }
    else if (type==="FOODPRODUCTION") { //sweet
      o = getLookupProperty('foodProduction');
    }
    else if (type==="TIMEPERIOD") { //sweet
      o = getLookupProperty('timePeriod');
    }
    else if (type==="TYPESWEET") { //sweet
      o = getLookupProperty('typesweet');
    }

    if (o!=null && value !== undefined && value !== null && value !== '') {
      return o.find((e:any)=>e.code===value).type;
    }
    return ''
  }
  function setRemarks() {
    if (!applicant.remarks) {
      applicant.remarks = "";
    }
    if(!isDefined(applicant.business)){
      applicant.business = {};
    }
    if(!isDefined(applicant.networth)){
      applicant.networth = {score:{cibil:''}};
    }
    if(!isDefined(applicant.businessincome)){
      applicant.businessincome = {};
    }
    if(!isDefined(applicant.property)){
      applicant.property = {};
    }
    if(!isDefined(applicant.employer)){
      applicant.employer = {};
    }
    if(!isDefined(applicant.income)){
      applicant.income = {};
    }
    if (!isDefined(applicant.networth.remarks)) {
      applicant.networth.remarks = "";
    }
    if (!isDefined(applicant.business.remarks)) {
      applicant.business.remarks = "";
    }
    if (!isDefined(applicant.businessincome.remarks)) {
      applicant.businessincome.remarks = "";
    }
    if(!isDefined(loanModel.householdexpense.remarks)){
      loanModel.householdexpense.remarks = "";
    }
    if(!isDefined(loanModel.property.remarks)){
      loanModel.property.remarks = "";
    }
    if(!isDefined( applicant.employer.remarks)){
      applicant.employer.remarks = "";
    }
    if(!isDefined(applicant.income.remarks)){
      applicant.income.remarks="";
    }
  };

  function refreshInstruments(){
    let instrumentList = getLookupProperty('instruments');
    if(!isDefined(applicant.networth.instruments) || applicant.networth.instruments.length < 1  ) {
      applicant.networth.instruments = [];
      instrumentList.forEach((e:any)=>{
      var instr = {code:e.code,instrument:e.type,amount:0.0};
      applicant.networth.instruments.push(instr);
      }) 
    } else {
      instrumentList.forEach((ins:any)=>{
        let idx = applicant.networth.instruments.findIndex((e:any)=>e.code===ins.code);
        if(idx===-1){
          var instr = {code:ins.code,instrument:ins.type,amount:0.0};
          applicant.networth.instruments.push(instr);
        }
      });
    }
    console.log('applicant.networth.instruments'+ applicant.networth.instruments);
  }
  function initializePriest () {
    if (!isDefined(applicant.business.priest)) {
      applicant.business.priest = {};
    }
    if (!isDefined(applicant.business.priest.specialization)) {
      applicant.business.priest.specialization = {};
    }
    if (!isDefined(applicant.business.priest.valueaddedactivities)) {
      applicant.business.priest.valueaddedactivities = {};
    }
    if (!isDefined(applicant.business.priest.customertype)) {
      applicant.business.priest.customertype = {};
    }
    if (!isDefined(applicant.business.priest.customertype)) {
      applicant.business.priest.customertype = {};
    }
    if (!isDefined(applicant.business.priest.largecustomer)) {
      applicant.business.priest.largecustomer = {};
    }
  }
  function calculateCapitalInvestment(){
    loanModel.totalCapitalInvestment=0;
    for(var i=0;i<capitalinvestment.length;i++){
      if(capitalinvestment[i].amt !== null){
        loanModel.totalCapitalInvestment+=parseFloat(capitalinvestment[i].amt);
      }
    }
  };
  function calculatekiranasales(){
    loanModel.totalSalesDeatail=0;
    for(var i=0;i<loanModel.kiranasales.length;i++){
      if(loanModel.kiranasales[i].sales>=0){
        loanModel.totalSalesDeatail=parseFloat(loanModel.totalSalesDeatail)+parseFloat(loanModel.kiranasales[i].sales);
      }
      if(isNaN(loanModel.totalSalesDeatail)){
        loanModel.totalSalesDeatail=0;
      }
     
    }
  }
  function calculateEmployee(){
    loanModel.totalCurrentEmployee=0.0;
    loanModel.totalEmployee=0;
    loanModel.totallastEmployee=0.0;
    loanModel.totallast_1Employee=0.0;
     for(var i=0;i<bizEmployee.length;i++){
      if((parseFloat(bizEmployee[i].current)>=0)&&(bizEmployee[i].current!==null)){
        loanModel.totalCurrentEmployee=parseFloat(loanModel.loanModeltotalCurrentEmployee)+parseFloat(bizEmployee[i].current);
      }
      if((parseFloat(bizEmployee[i].last)>=0) &&(bizEmployee[i].last!==null)){
        loanModel.totallastEmployee=parseFloat(loanModel.totallastEmployee)+parseFloat(bizEmployee[i].last);
      }
      if((parseFloat(bizEmployee[i].last_1)>=0)&&(bizEmployee[i].last_1!==null)){
        loanModel.totallast_1Employee=parseFloat(loanModel.totallast_1Employee)+parseFloat(bizEmployee[i].last_1);
      }
    }
    loanModel.totalEmployee=parseFloat(loanModel.totalCurrentEmployee)+parseFloat(loanModel.totallastEmployee)+parseFloat(loanModel.totallast_1Employee);
    applicant.business.totalEmployee=loanModel.totalEmployee/3;
    employeeCount();
    if(loanModel.totalEmployee === 0){
      applicant.business.avgsalary=0;
    }
    if(applicant.business!==null && applicant.business.livestock!==null){
      applicant.business.livestock.workers = loanModel.totalCurrentEmployee+loanModel.totallastEmployee ;
    }
    if(applicant.business!==null && applicant.business.mobile!==null){
      applicant.business.mobile.workers = loanModel.totalCurrentEmployee+loanModel.totallastEmployee ;
    }
  };
  function employeeCount(){
    applicant.business.employees=JSON.parse(JSON.stringify( bizEmployee));
  }
  function getGenericNetProfit(){
    if(isDefined(applicant.business.type) && isDefined(applicant.business.profile)){
        loanModel.genericnetprofit=0;
      var formData=new FormData();
      formData.append("type",applicant.business.type);
      formData.append("profile",applicant.business.profile);
      const headers = {
        "Authorization": "Bearer " + user.token,
        "Content-Type": undefined
      }
      const config: AxiosRequestConfig = {
        headers: headers
      };
      axios.post(
        process.env.REACT_APP_API_URL+"/app/margin/",
        formData,
        config
      ).then(response=>{
        if(response!=null){
          loanModel.genericnetprofit=JSON.parse(JSON.stringify( response.data.margin));
          if(loanModel.genericnetprofit==null){
            loanModel.genericnetprofit=0;
          }
        }
      });
    }else{
      loanModel.genericnetprofit=0;
    }
  }
  function getRentSqftByLocalityBusiness(){
    if (isDefined(applicant.business.address) && applicant.business.address.city!==null && applicant.business.locality!==null
        &&  applicant.business.address.city!==null){
      
      var formData=new FormData();
      formData.append("mapid",applicant.business.address.city);
      const headers = {
        "Authorization": "Bearer " + user.token,
        "Content-Type": undefined
      }
      const config: AxiosRequestConfig = {
        headers: headers
      };
      axios.post(
        process.env.REACT_APP_API_URL+"/app/rent/",
        formData,
        config
      ).then(response=>{
        if(response!=null){
          loanModel.rentSqftByLocalityBusiness=JSON.parse(JSON.stringify( response.data.rentsqft));
          if(loanModel.rentSqftByLocalityBusiness==null){
            loanModel.rentSqftByLocalityBusiness=0;
          }
        }else{
          loanModel.rentSqftByLocalityBusiness=0;
        }
      });
    }
  }
  function generateTransactionMonths(ctr:number) {
    var monthList =[];
    for(var i=0; i<ctr;i++) {
      var cDate = new Date();
      cDate.setMonth(cDate.getMonth()-(i+1));
      monthList.push(moment(cDate).format('MMMM - yyyy'));
    }
    return monthList;
  }
  function generateTransactionYear(ctr:number) {
    var yearList =[];
    for(var i=0; i<ctr;i++) {
      var cDate = new Date();
      cDate.setDate(1);
      cDate.setMonth(cDate.getMonth());
      cDate.setHours(10);
      cDate.setFullYear((cDate.getFullYear()-i));
      yearList.push(cDate);
    }
    return yearList;
  }
  function getYear(){
    applicant.business.employeePeriod = generateTransactionYear(3);
  }
  function calcTotalDirectExpenses(){
    var total0 = 0.0;
    var total1 = 0.0;
    var total2 = 0.0;

    for(var i = 0; i < applicant.businessincome.directexpenses.length; i++){
        var product = applicant.businessincome.directexpenses[i];
          if (!isNaN(product.mamount)  && (product.mamount > 0))
            total0 += parseFloat(product.mamount);
          if (!isNaN(product.m1amount) && (product.m1amount > 0))
            total1 += parseFloat(product.m1amount);
            if (!isNaN(product.m2amount)  && (product.m2amount > 0))
            total2 += parseFloat(product.m2amount);
    }

    loanModel.totaldirectexpensemamount = total0;
    loanModel.totaldirectexpensem1amount = total1;
    loanModel.totaldirectexpensem2amount = total2;
    calculateDirectExpenseMonthly();
  }
  function calculateDirectExpenseMonthly(){
    calcBizTotalRevenue();
    var consideredMonthly = 0.0;
    applicant.businessincome.directexpenseconsiderationtype = loanModel.directexpenseconsiderationtype;
    switch (applicant.businessincome.directexpenseconsiderationtype) {

      case "AVERAGE":
        consideredMonthly = (loanModel.totaldirectexpensemamount + loanModel.totaldirectexpensem1amount + loanModel.totaldirectexpensem2amount)/3;

        break;
      case "MINIMUM":
          consideredMonthly = Math.min(loanModel.totaldirectexpensemamount, loanModel.totaldirectexpensem1amount ,loanModel.totaldirectexpensem2amount);
          break;
      case "MAXIMUM":
          consideredMonthly = Math.max(loanModel.totaldirectexpensemamount, loanModel.totaldirectexpensem1amount ,loanModel.totaldirectexpensem2amount);
          break;
      case "LATEST":
          consideredMonthly = loanModel.totaldirectexpensemamount;
          break;
      case "PREVIOUS":
          consideredMonthly = loanModel.totaldirectexpensem1amount;
          break;
    }
    consideredMonthly = Math.round(consideredMonthly *100)/100;
    loanModel.totaldirectexpensemonthlyamount = consideredMonthly;
    applicant.businessincome.totaldirectexpenses = loanModel.totaldirectexpensemonthlyamount;
    loanModel.grossprofitmargin=(((loanModel.totalSales-loanModel.totaldirectexpensemonthlyamount)/loanModel.totalSales)*100).toFixed(2);
    applicant.businessincome.totalincome  = applicant.businessincome.totalrevenue - applicant.businessincome.totaldirectexpenses - applicant.businessincome.totalindirectexpenses;
    applicant.businessincome.totalincome = Math.round(applicant.businessincome.totalincome *100)/100;
    if(isNaN(loanModel.grossprofitmargin)){
      loanModel.grossprofitmargin=0;
    }
  }
  function calcBizTotalRevenue(){
    var total = 0;
    if(isDefined(applicant.businessincome.services)) {
      for(var i = 0; i < applicant.businessincome.services.length; i++){
          var product = applicant.businessincome.services[i];
          if (!isNaN(product.revenue))
          {
            total += parseFloat(product.revenue);
          }
      }
    }
     total = Math.round(total *100)/100;
      loanModel.totalrevenue = total; //DE89: saved the Total Sales/ Receipts/ Turnover value in the new field.
      applicant.businessincome.totalrevenue = total;
      loanModel.totalSales = applicant.businessincome.totalrevenue;
      applicant.businessincome.totalincome = loanModel.totalSales;
      applicant.businessincome.totalincome  = applicant.businessincome.totalrevenue - applicant.businessincome.totaldirectexpenses - applicant.businessincome.totalindirectexpenses;
      applicant.businessincome.totalincome = Math.round(applicant.businessincome.totalincome *100)/100;
  }
  function calcTotalIndirectExpenses(){
    var total0 = 0;
    var total1 = 0;
    var total2 = 0;
    
    for(var i = 0; i < applicant.businessincome.indirectexpenses.length; i++){
        var product = applicant.businessincome.indirectexpenses[i];
        if (!isNaN(product.mamount)  && (product.mamount > 0))
            total0 += parseFloat(product.mamount);
        if (!isNaN(product.m1amount) && (product.m1amount > 0))
            total1 += parseFloat(product.m1amount);
        if (!isNaN(product.m2amount)  && (product.m2amount > 0))
            total2 += parseFloat(product.m2amount);
    }

    loanModel.totalindirectexpensemamount = total0;
    loanModel.totalindirectexpensem1amount = total1;
    loanModel.totalindirectexpensem2amount = total2;
    calculateIndirectExpenseMonthly();
  }
  function calculateIndirectExpenseMonthly(){
    var consideredMonthly = 0.0;
    applicant.businessincome.indirectexpenseconsiderationtype = loanModel.indirectexpenseconsiderationtype;
    switch (loanModel.indirectexpenseconsiderationtype) {

      case "AVERAGE":
        consideredMonthly = (loanModel.totalindirectexpensemamount + loanModel.totalindirectexpensem1amount + loanModel.totalindirectexpensem2amount)/3;
        break;
      case "MINIMUM":
          consideredMonthly = Math.min(loanModel.totalindirectexpensemamount, loanModel.totalindirectexpensem1amount ,loanModel.totalindirectexpensem2amount);
          break;
      case "MAXIMUM":
          consideredMonthly = Math.max(loanModel.totalindirectexpensemamount, loanModel.totalindirectexpensem1amount ,loanModel.totalindirectexpensem2amount);
          break;
      case "LATEST":
          consideredMonthly = loanModel.totalindirectexpensemamount;
          break;
      case "PREVIOUS":
          consideredMonthly = loanModel.totalindirectexpensem1amount;
          break;
    }
    consideredMonthly = Math.round(consideredMonthly *100)/100;
    loanModel.totalindirectexpensemonthlyamount = consideredMonthly;
    applicant.businessincome.totalindirectexpenses = loanModel.totalindirectexpensemonthlyamount;
    applicant.businessincome.totalincome  = applicant.businessincome.totalrevenue - applicant.businessincome.totaldirectexpenses - applicant.businessincome.totalindirectexpenses;
    applicant.businessincome.totalincome = Math.round(applicant.businessincome.totalincome *100)/100;
    loanModel.netprofitmargin=((applicant.businessincome.totalrevenue-loanModel.totaldirectexpensemonthlyamount - loanModel.totalindirectexpensemonthlyamount)/(applicant.businessincome.totalrevenue)*100).toFixed(2);
    if(isNaN(loanModel.netprofitmargin)){
      loanModel.netprofitmargin=0;
    }
  }
  function calcBizTotalIncome(){
    var total = 0;
      for(var i = 0; i < applicant.businessincome.otherincomes.length; i++){
          var product = applicant.businessincome.otherincomes[i];
          if (!isNaN(product.eligibleamount))
          {
            total += parseFloat(product.eligibleamount);
          }
      }
    total = Math.round(total *100)/100;
    applicant.businessincome.totalotherincome = total;
    loanModel.totalbizotherincome = total;
  }
  function setITR() {
    for(var i=0;i<applicant.businessincome.itrs.length;i++){
      for(var j=0;j<applicant.businessincome.itrs[i].length;j++){ 
        for(var k=j;k<applicant.businessincome.itrs[i].length;k++){
          if(applicant.businessincome.itrs[i][j].seq>=applicant.businessincome.itrs[i][k].seq){//i=0 j=0
            var temp=JSON.parse(JSON.stringify( applicant.businessincome.itrs[i][j]));
            applicant.businessincome.itrs[i][j]= JSON.parse(JSON.stringify(applicant.businessincome.itrs[i][k]));
            applicant.businessincome.itrs[i][k]= JSON.parse(JSON.stringify( temp));
          }
        }
      }
    } 
  }
  function isDefined (val:any){
    return val !== undefined && val !== null;
  }
  function calculateITRSale() {
    loanModel.itr1 = 0.0;
    loanModel.itr2 = 0.0;
    var itrArray  = applicant.businessincome.itrs;
    if(itrArray===undefined || !isDefined(applicant.businessincome.itrs)){
      applicant.businessincome.itrs = [];
      applicant.businessincome.itrs.push(JSON.parse(JSON.stringify( itrSalesModel)));
      applicant.businessincome.itrs.push(JSON.parse(JSON.stringify( itrSalesModel)));
      itrArray  = applicant.businessincome.itrs;
    }
    if(itrArray!==undefined){
      if(itrArray[0][2].amount===null){
        itrArray[0][2].amount=0;
      }
      if((itrArray[0][1].amount!==undefined)){
        loanModel.itr1 =  (parseFloat(itrArray[0][1].amount) + parseFloat(itrArray[0][2].amount))/12;
      }
      if(itrArray[1][2].amount===null){
        itrArray[1][2].amount=0;
      }
      if((itrArray[1][1].amount!==undefined)){
        loanModel.itr2 =  (parseFloat(itrArray[1][1].amount) + parseFloat(itrArray[1][2].amount))/12;
      }
      calculateSalesITRMonthly();
      calculateByProfit();
    }
  }
  function calculateByProfit() {
    applicant.businessincome.shareincome = (parseFloat(applicant.businessincome.profitshare) * parseFloat(applicant.businessincome.itrsalesincome)) / 100;
    applicant.businessincome.shareincome = Math.round(applicant.businessincome.shareincome *100)/100;
    if(applicant.businessincome.dividends===null){
      applicant.businessincome.dividends=0;
    }
    if(applicant.businessincome.remuneration===null){
      applicant.businessincome.remuneration=0;
    }
    if(applicant.businessincome.interestoncapital===null){
      applicant.businessincome.interestoncapital=0;
    }
    applicant.businessincome.totalincome = parseFloat(applicant.businessincome.dividends) + parseFloat(applicant.businessincome.remuneration) + parseFloat(applicant.businessincome.interestoncapital)
     + parseFloat(applicant.businessincome.shareincome);
     applicant.businessincome.totalincome = Math.round(applicant.businessincome.totalincome *100)/100;
  
  }
  function calculateSalesITRMonthly(){
    var consideredMonthly = 0.0;
    switch (applicant.businessincome.itrsalesconsideration) {
      case "AVERAGE":
        consideredMonthly = (parseFloat(loanModel.itr1) + parseFloat(loanModel.itr2) )/2;
        break;
      case "MINIMUM":
          consideredMonthly = Math.min(loanModel.itr1, loanModel.itr2);
          break;
      case "MAXIMUM":
          consideredMonthly = Math.max(loanModel.itr1, loanModel.itr2);
          break;
      case "LATEST":
          consideredMonthly = loanModel.itr1;
          break;
      case "PREVIOUS":
          consideredMonthly = loanModel.itr2;
          break;
    }
    consideredMonthly = Math.round(consideredMonthly *100)/100;
    applicant.businessincome.itrsalesincome = consideredMonthly;
  }
  function checkSalaryEarningModel(){
    for(var i=0;i<salaryEarningsModel.length;i++){
      for(var j=0;j<applicant.income.earnings.length;j++){
        if(applicant.income.earnings[j].name===salaryEarningsModel[i].name){
          applicant.income.earnings[j].displayOrder=Object.assign({} ,salaryEarningsModel[i].displayOrder);
        }
      }
    }
  }
  function checkSalaryDeductionsModel(){
    for(var i=0;i<salaryDeductionsModel.length;i++){
      for(var j=0;j<applicant.income.deductions.length;j++){
        if(applicant.income.deductions[j].name===salaryDeductionsModel[i].name){
          applicant.income.deductions[j].displayOrder=JSON.parse(JSON.stringify( salaryDeductionsModel[i].displayOrder));
        }
      }
    }
  }
  function checkOtherIncome(){
    for(var i=0;i<otherIncomesModel.length;i++){
      var found=false;
      for(var j=0;j<applicant.income.otherincomes.length;j++){
        if(applicant.income.otherincomes[j].name===otherIncomesModel[i].name){
          if(otherIncomesModel[i].readonly){
            applicant.income.otherincomes[j].readonly=JSON.parse(JSON.stringify( otherIncomesModel[i].readonly));
            applicant.income.otherincomes[j].percentage=JSON.parse(JSON.stringify( otherIncomesModel[i].percentage));
          }
          found=true;
          applicant.income.otherincomes[j].displayOrder=JSON.parse(JSON.stringify( otherIncomesModel[i].displayOrder));
        }
      }
      if(!found){
        var copy=otherIncomesModel[i];
        applicant.income.otherincomes.push(copy);
      }
    }
  }
  function calcTotalIncome(){
    var total = 0;
      for(var i = 0; i < applicant.income.otherincomes.length; i++){
          var product = applicant.income.otherincomes[i];
          if (!isNaN(product.eligibleamount))
          {
            total += parseFloat(product.eligibleamount);
          }
      }
    applicant.income.totalotherincome = total;
    loanModel.totalotherincome = total;
  }
  function sortSeq(a:any, b:any) {
    return a.seq > b.seq ;
}
  function reorderIncome(inc:any) {

    inc.earnings.forEach((e:any)=>{
			var fModel = salaryEarningsModel.filter((d:any) => d.name===e.name);
      if(isDefined(fModel) && fModel.length>0){
        e.seq = fModel[0].seq;
      }
    }) 	
		inc.earnings.sort(sortSeq);

    inc.deductions.forEach((e:any)=>{
			var fModel = salaryDeductionsModel.filter((d:any) => d.name===e.name);
      if(isDefined(fModel) && fModel.length>0){
        e.seq = fModel[0].seq;
      }
    }) 
		inc.deductions.sort(sortSeq);				 
  }	  
  function recalcTotal(){
    var dTotal = [0,0,0];
    var eTotal = [0,0,0];
    applicant.income.deductions.forEach((product:any)=>{
      if (!isNaN(product.mamount)  && (product.mamount > 0))
        dTotal[0] += parseFloat(product.mamount);
      if (!isNaN(product.m1amount)  && (product.m1amount > 0))
        dTotal[1] += parseFloat(product.m1amount);
      if (!isNaN(product.m2amount)  && (product.m2amount > 0))
        dTotal[2] += parseFloat(product.m2amount);
    })
    
    applicant.income.earnings.forEach((product:any)=>{
      if (!isNaN(product.mamount)  && (product.mamount > 0))
        eTotal[0] += parseFloat(product.mamount);
      if (!isNaN(product.m1amount)  && (product.m1amount > 0))
        eTotal[1] += parseFloat(product.m1amount);
      if (!isNaN(product.m2amount)  && (product.m2amount > 0))
        eTotal[2] += parseFloat(product.m2amount);
    })
    
    loanModel.totalsalaryearningmamount =  eTotal[0];
    loanModel.totalsalaryearningm1amount = eTotal[1] ;
    loanModel.totalsalaryearningm2amount =  eTotal[2] ;
    loanModel.totalsalarydeductionmamount =  dTotal[0] ;
    loanModel.totalsalarydeductionm1amount =  dTotal[1];
    loanModel.totalsalarydeductionm2amount = dTotal[2] ;
    loanModel.totalnetsalarymamount = eTotal[0] - dTotal[0];
    loanModel.totalnetsalarym1amount = eTotal[1] - dTotal[1];
    loanModel.totalnetsalarym2amount = eTotal[2] - dTotal[2];
    calculateMonthly();
  }
  function calculateMonthly(){
    var consideredMonthly = 0.0;
    switch (loanModel.salaryconsiderationtype) {

      case "AVERAGE":
        consideredMonthly = (loanModel.totalnetsalarymamount + loanModel.totalnetsalarym1amount + loanModel.totalnetsalarym2amount)/3;
        consideredMonthly = Math.round(consideredMonthly *100)/100;
        break;
      case "MINIMUM":
          consideredMonthly = Math.min(loanModel.totalnetsalarymamount, loanModel.totalnetsalarym1amount ,loanModel.totalnetsalarym2amount);
          break;
      case "MAXIMUM":
          consideredMonthly = Math.max(loanModel.totalnetsalarymamount, loanModel.totalnetsalarym1amount ,loanModel.totalnetsalarym2amount);
          break;
      case "LATEST":
          consideredMonthly = loanModel.totalnetsalarymamount;
          break;
      case "PREVIOUS":
          consideredMonthly = loanModel.totalnetsalarym1amount;
          break;
    }
    applicant.income.monthlyamount = consideredMonthly;
    applicant.income.salaryconsiderationtype = loanModel.salaryconsiderationtype;

  }
  function calculateDependent(){
    var dependent=JSON.parse(JSON.stringify( applicant.dependent));
    var total=0;
    if(dependent!==null){
      if((dependent.sister!==null) &&(dependent.sister!==undefined)){
        total=total+dependent.sister;
      }
      if(dependent.brother!==null&&dependent.brother!==undefined){
        total=total+dependent.brother;
      }
      if(dependent.parents!==null&&dependent.parents!==undefined){
        total=total+dependent.parents;
      }
      if(dependent.schoolchildren!==null&&dependent.schoolchildren!==undefined){
        total=total+dependent.schoolchildren;
      }
      if(dependent.spouse!==null&&dependent.spouse!==undefined){
        total=total+dependent.spouse;
      }
      if(dependent.other!==null&&dependent.other!==undefined){
        total=total+dependent.other;
      }
    }
    var earningmembers =0;
    if(applicant.earningnumbers !==null && applicant.earningnumbers!==undefined){
      earningmembers = parseInt(applicant.earningnumbers);
      total=total+earningmembers;
      if(applicant.earningnumbers ==="5+"){
        total=total+1; 
      }
    }
    if(total>=6){
      applicant.totalmembers="5+";
    }else if(total===5){
      applicant.totalmembers="5";
    }else if(total===4){
      applicant.totalmembers="4";
    }else if(total===3){
      applicant.totalmembers="3";
    }else if(total===2){
      applicant.totalmembers="2";
    }
    else if(total===1){
      applicant.totalmembers="1";
    }else if(total===0){
      applicant.totalmembers="0";
    }
  };
  function calculateNetworth(){
    var total = 0.0;
    for(var i = 0; i < applicant.networth.instruments.length; i++){
        var product = applicant.networth.instruments[i];
        if (!isNaN(product.amount)  && (product.amount > 0))
          total += parseFloat(product.amount);
        }
   loanModel.totalnetworth = total;
   applicant.networth.investmentTotal=JSON.parse(JSON.stringify( loanModel.totalnetworth));
  }
  function activateTab(){
    // jQuery('#tab121').removeClass("active");
    // jQuery('#tab2110').removeClass("active");
    // jQuery('#tab221').removeClass("active");
    // jQuery('#tab321').removeClass("active");
    // jQuery('#tab62').removeClass("active");
    // jQuery('#tab72').removeClass("active");
    // jQuery('#tab02').removeClass("active");
    // jQuery('#tab002').removeClass("active");
    // jQuery('#tab003').removeClass("active");

    // jQuery('#btabs11').removeClass("active");
    // jQuery('#btabs12').removeClass("active");
    // jQuery('#btabs13').removeClass("active");
    // jQuery('#btabs14').removeClass("active");
    // jQuery('#btabs16').removeClass("active");
    // jQuery('#tabs22').children().removeClass("active");
    // jQuery('#btabs11').addClass("active");
    // jQuery('#btabs1_1').addClass("active");

    // jQuery('#tab001').addClass("active");
    // jQuery('#tabs21').children().removeClass("active");
    // jQuery('#tabs21_1').addClass("active");

    loanModel.repayMode = "";
    loanModel.repayIndex = -1;
    loanModel.bankMode = "";
    loanModel.bankIndex = -1;
    loanModel.showbank = false;
    loanModel.showRepaymentPage = false;
    loanModel.check={};
    loanModel.check.applicant = {};
    loanModel.check.applicant.totalObligation = 0.0;
    loanModel.check.applicant.eligibility = 0.0;
    loanModel.check.applicant.totalincome = 0.0;
  }
  function recommendedhouseholdrent() {
      
    if (loanModel.applicants!==null) {
      for (var i = 0; i < loanModel.applicants.length; i++) {
        if (loanModel.applicants[i].primary) {
          var pincode = 0;
          console.log("We are here before inside if")
          if (loanModel.applicants[i].address.zipcode===null || loanModel.applicants[i].address.zipcode===undefined) {
            pincode = 0;
          } else {
            pincode = loanModel.applicants[i].address.zipcode;
          }
          console.log("Step 2")
          var minmax:any = pincodeProfiling(pincode);
          console.log("Stp 3")
          if(loanModel.applicants[i].residenceownership !=null && loanModel.applicants[i].residenceownership==='RNT' && loanModel.applicants[i].residencearea > 0){
          console.log("Step 4")
          var minimum = minmax[0]===undefined ? 0 : minmax[0];
          var maximum = minmax[1]===undefined ? 0 : minmax[1];
          console.log("This is the resukt of min max " + minmax);
          loanModel.householdexpense.pincodeminvalue = minimum * loanModel.applicants[i].residencearea;
          console.log("The min value is "+minmax[0]);
          loanModel.householdexpense.pincodemaxvalue = maximum * loanModel.applicants[i].residencearea;
          console.log("The max value is "+minmax[1]);
          };
        };
      }
    }
  };
  function pincodeProfiling(pincode:any){
    
    var min = 0;
    var max = 0;

    if (pincode!==null) {
      if (pincode===452010) {
        min = 13;
        max = 22;
      } else if (pincode===421302) {
        min = 13;
        max = 24;
      } else if (pincode===421306) {
        min = 11;
        max = 17;
      } else if (pincode===411046) {
        min = 11;
        max = 16;
      } else if (pincode===421201) {
        min = 15;
        max = 20;
      } else if (pincode===400607) {
        min = 28;
        max = 41;
      } else if (pincode===421202) {
        min = 15;
        max = 20;
      } else if (pincode===400605) {
        min = 22;
        max = 32;
      } else if (pincode===400606) {
        min = 29;
        max = 41;
      } else if (pincode===411015) {
        min = 16;
        max = 25;
      } else if (pincode===411028) {
        min = 13;
        max = 20;
      } else if (pincode===410206) {
        min = 16;
        max = 29;
      } else if (pincode===411017) {
        min = 16;
        max = 23;
      } else if (pincode===110059) {
        min = 12;
        max = 18;
      } else if (pincode===140301) {
        min = 11;
        max = 15;
      } else if (pincode===411048) {
        min = 13;
        max = 17;
      } else if (pincode===110092) {
        min = 18;
        max = 28;
      } else if (pincode===400097) {
        min = 30;
        max = 66;
      } else if (pincode===411041) {
        min = 9;
        max = 12;
      } else if (pincode===110032) {
        min = 13;
        max = 19;
      } else if (pincode===401107) {
        min = 22;
        max = 30;
      } else if (pincode===421503) {
        min = 7;
        max = 9;
      } else if (pincode===248001) {
        min = 13;
        max = 20;
      } else if (pincode===421301) {
        min = 16;
        max = 22;
      } else if (pincode===410501) {
        min = 9;
        max = 14;
      } else if (pincode===411037) {
        min = 13;
        max = 24;
      } else if (pincode===412105) {
        min = 14;
        max = 18;
      } else if (pincode===401209) {
        min = 12;
        max = 19;
      } else if (pincode===400078) {
        min = 34;
        max = 60;
      } else if (pincode===302012) {
        min = 7;
        max = 12;
      } else if (pincode===452016) {
        min = 9;
        max = 17;
      } else if (pincode===132103) {
        min = 5;
        max = 10;
      } else if (pincode===411061) {
        min = 17;
        max = 25;
      } else if (pincode===401208) {
        min = 12;
        max = 16;
      } else if (pincode===453771) {
        min = 10;
        max = 19;
      } else if (pincode===411027) {
        min = 16;
        max = 28;
      } else if (pincode===360005) {
        min = 12;
        max = 20;
      } else if (pincode===452001) {
        min = 14;
        max = 21;
      } else if (pincode===422009) {
        min = 11;
        max = 21;
      } else if (pincode===452012) {
        min = 9;
        max = 16;
      } else if (pincode===141012) {
        min = 9;
        max = 12;
      } else if (pincode===141001) {
        min = 7;
        max = 14;
      } else if (pincode===411057) {
        min = 22;
        max = 29;
      } else if (pincode===452009) {
        min = 8;
        max = 13;
      } else if (pincode===141013) {
        min = 8;
        max = 19;
      } else if (pincode===411033) {
        min = 16;
        max = 25;
      } else if (pincode===422003) {
        min = 8;
        max = 14;
      } else if (pincode===360006) {
        min = 8;
        max = 14;
      } else if (pincode===141002) {
        min = 7;
        max = 11;
      } else if (pincode===360007) {
        min = 16;
        max = 21;
      }
      return [min, max];
    }
    
  }
  function deleteApplicant(appid:any) {
    if (!loanModel.applicationFinalized) { 
      loanModel.applicantToDeleteId = appid;
      setApplicantView(true);
    } else {
        displayMessage("You cannot delete the applicant after finalizing the application.");
    }
  };
  function showApplicant(appIdx:any) {
    if(!isDefined(loanModel.applicants)){
      loanModel.applicants=[];
    }
    loanModel.siteerror = false;
    loanModel.showUploadVideo = false;
    loanModel.showUploadImage = false;
    loanModel.showVideosUp = true;
    loanModel.showImagesUp = true;
    loanModel.showVisitInfoUp = true;
    //applicant = JSON.parse(JSON.stringify(loanModel.applicants[idx]);
    loanModel.applicantIndex = appIdx;
    loanModel.applicant = loanModel.applicants[appIdx];
    loanModel.tempApplicant = JSON.parse(JSON.stringify( applicant));
    applicant.typetext = getTypeValue("APPLICANT_TYPE", applicant.type);
    loanModel.selectedRow = appIdx;
    if(!isDefined(applicant.dependent))
      applicant.dependent={};
    setRemarks();
    if (!isDefined(applicant.address)) {
      applicant.address = {};
    }
    if (!isDefined(applicant.networth)) {
      applicant.networth = {score:{}};
    }
    refreshInstruments();
    if (!isDefined(applicant.employer)) {
      applicant.employer = {address:{},salarydeductions:{},receiveddoc:{},documents:[]};
    }
    if (!isDefined(applicant.employer.multiemployed)) {
      applicant.employer.multiemployed = {};
    }
    if (!isDefined(applicant.income)) {
      applicant.income = {};
    }
    if (!isDefined(applicant.business)) {
      applicant.business = {address:{},localityofbiz:{},family:{},governmentschemes:{},bankfacility:{},telephonetype:{},digitaladoption:{}};
    }
    if (!isDefined(applicant.business.tailor)) {
      applicant.business.tailor = {};
    }
    if (!isDefined(applicant.business.craftsman)) {
      applicant.business.craftsman = {};
    }
    if (!isDefined(applicant.business.craftsman.customer)) {
      applicant.business.craftsman.customer = {};
    }
    if (!isDefined(applicant.business.craftsman.eplatforms)) {
      applicant.business.craftsman.customer.eplatforms = false;
      applicant.business.craftsman.eplatforms = {};
    }
    if (!isDefined(applicant.business.vegetablefruit)) {
      applicant.business.vegetablefruit = {produce:{purchase:{},selling:{}},difficulties:{purchase:{},selling:{}},delivery:{},customertype:{}};
    }
    if (!isDefined(applicant.business.beautyparlour)) {
      applicant.business.beautyparlour = {eplatforms:{},salonequipment:{},service:{}};
    }
    if (!isDefined(applicant.business.dairy)) {
      applicant.business.dairy = {};
    }
    if (!isDefined(applicant.business.dairy.product)) {
      applicant.business.dairy.product = {};
    }
    if (!isDefined(applicant.business.garag)) {
      applicant.business.garage = {services:{},certificates:{},customer:{},valueadds:{}};
    }
   
    if (!isDefined(applicant.business.handloom)) {
      applicant.business.handloom = {registration:{},customer:{},specialisation:{},loomtype:{},activities:{}};
    }
    if (!isDefined(applicant.business.livestock)) {
      applicant.business.livestock = {additional:{}, infra:{}};
    }
    if (!isDefined(applicant.business.tutor)) {
      applicant.business.tutor = {};
    }
    if (!isDefined(applicant.business.tutor.educationboard)) {
      applicant.business.tutor.educationboard = {};
    }
    if (!isDefined(applicant.business.tutor.standards)) {
      applicant.business.tutor.standards = {};
    }
    if (!isDefined(applicant.business.tutor.standards)) {
      applicant.business.tutor.subject = {};
    }
    if (!isDefined(applicant.business.tutor.subject)) {
      applicant.business.tutor.subject = {};
    }
    if (!isDefined(applicant.business.tutor.student)) {
      applicant.business.tutor.student = {};
    }
    if (!isDefined(applicant.business.tutor.valueaddedactivities)) {
      applicant.business.tutor.valueaddedactivities = {};
    }
    if (!isDefined(applicant.business.tutor.schoolstandardstaught)) {
      applicant.business.tutor.schoolstandardstaught = {};
    }
    if (!isDefined(applicant.business.transport)) {
      applicant.business.transport = {};
    }
    if (!isDefined(applicant.business.transport.vehicle)) {
      applicant.business.transport.transportvehicle = {};
    }
    if (!isDefined(applicant.business.clothing)) {
      applicant.business.clothing = {type:{},price:{}};
    }
    //agent
    if (!isDefined(applicant.business.agent)) {
      applicant.business.agent = {dmstrategies:{},leads:{},part:{},website:{}};
    }
    if (!isDefined(applicant.business.mobile)) {
      applicant.business.mobile = {mobilelocalityofbiz:{}, service:{},bank:{},activities:{}, product:{},source:{},mobaccessories:{},repair:{},authorized:{},benefit:{},promotional:{},accessories:{},inventory:{},adhar:{},equipment:{}};
    }
    if (!isDefined(applicant.business.streetfood)) {
      applicant.business.streetfood = {documentation:{}, streetfoodvendorcategory:{},foodcategory:{},type:{},howisfoodtransported:{},sourceoffood:{},power:{},season:{},location:{},streetfoodequipment:{},customertype:{},largecustomer:{},foodapptype:{},largecustomertype:{}};
    }
    if (!isDefined(applicant.business.laundry)) {
      applicant.business.laundry = {service:{},market:{},serviceoffered:{},irontype:{},typoffabrics:{},dryingarea:{},deliveryvehicle:{},equipments:{}};
    }
    if (!isDefined(applicant.business.xerox)) {
      applicant.business.xerox = {valueservicesadded:{}};
    }
    //sweet
    if (!isDefined(applicant.business.sweet)) {
      applicant.business.sweet = {};
    }

    initializePriest();
    if (!isDefined(applicant.businessincome) ) {
      applicant.businessincome = {};
      //itr
      applicant.businessincome.itrs = [];
      applicant.businessincome.itrs.push(JSON.parse(JSON.stringify(itrSalesModel)));
      applicant.businessincome.itrs.push(JSON.parse(JSON.stringify(itrSalesModel)));
      applicant.businessincome.itrsalesconsideration = "AVERAGE";
      applicant.businessincome.totalincome = 0.0;
      applicant.businessincome.itrsalesincome = 0.0;
      applicant.businessincome.shareincome = 0.0;
      applicant.businessincome.profitshare = 0.0;
      applicant.businessincome.remuneration = 0.0;
      applicant.businessincome.interestoncapital = 0.0;
      loanModel.itrsalesconsiderationtype = "AVERAGE";
    }
    else {
      if (!isDefined(applicant.businessincome.itrs)) {
        applicant.businessincome.itrs = [];
        applicant.businessincome.itrs.push(JSON.parse(JSON.stringify(itrSalesModel)));
        applicant.businessincome.itrs.push(JSON.parse(JSON.stringify(itrSalesModel)));
      }
      else if (applicant.businessincome.itrs!==null && applicant.businessincome.itrs.length > 0) {
        if (applicant.businessincome.itrs[0].length===0) {
          applicant.businessincome.itrs = [];
          applicant.businessincome.itrs.push(JSON.parse(JSON.stringify(itrSalesModel)));
          applicant.businessincome.itrs.push(JSON.parse(JSON.stringify(itrSalesModel)));
        }
      }
      if (applicant.businessincome.salesconsideration!==null) {
        loanModel.dailySalesConsideration = applicant.businessincome.salesconsideration;
        applicant.businessincome.salesconsideration = loanModel.dailySalesConsideration;

      }
      else {
        // applicant.businessincome.salesconsideration=""
      }
      if (isDefined(applicant.businessincome.monthlysales) && applicant.businessincome.monthlysales.length >= 1) {
        loanModel.monthlysales = JSON.parse(JSON.stringify(applicant.businessincome.monthlysales));
        for (var i = 0; i < applicant.businessincome.monthlysales.length; i++) {
          loanModel.monthlySale[i] = true;
        }

      } else {
        applicant.businessincome.monthlysales = [];
        applicant.businessincome.consideredmonthlysales = 0;
      }
    }

    if (!isDefined(applicant.sitevisits))  {
      applicant.sitevisits = [];
    }
    else {
     // loanModel.sitePage = false;
    }

    if (applicant.type==='informal_selfemployed') {
      //if(applicant.business.address.state!=null){
      //bizfields[2].fieldGroup[5].templateOptions.options = eval( 'lookuplist.'+applicant.business.address.state);
      //  }
      if (!isDefined(applicant.business)) {
        applicant.business = {address:{},localityofbiz:{},family:{},governmentschemes:{},bankfacility:{},telephonetype:{},digitaladoption:{}};
      }
      else {

        if (applicant.businessincome.directexpenseconsiderationtype) {
          loanModel.directexpenseconsiderationtype = applicant.businessincome.directexpenseconsiderationtype;
        } else {
          loanModel.directexpenseconsiderationtype = "AVERAGE";
        }
        if (applicant.businessincome.indirectexpenseconsiderationtype) {
          loanModel.indirectexpenseconsiderationtype = applicant.businessincome.indirectexpenseconsiderationtype;
        } else {
          loanModel.indirectexpenseconsiderationtype = "AVERAGE";
        }
      }
      if (!isDefined(applicant.business.kiranastore)) {
        // console.log("null at kirana store")
        applicant.business.kiranastore = {capitalinvestment:{}};
      }
      else {
        if(!isDefined(applicant.business.kiranastore.capitalinvestment)){
          applicant.business.kiranastore.capitalinvestment = {};
        }
        // console.log("kiranastore is not null setting other details");
        if ((applicant.business.kiranastore.capitalinvestment!==null) &&
          (applicant.business.kiranastore.capitalinvestment.length >= 1)) {
          // console.log("length of kirana store "+applicant.business.kiranastore.capitalinvestment.length);
          loanModel.capitalinvestment = JSON.parse(JSON.stringify( applicant.business.kiranastore.capitalinvestment));
          calculateCapitalInvestment();
        }
        // else{
        //   applicant.business.kiranastore.capitalinvestment={};
        // }
        if (isDefined(applicant.business.kiranastore.kiranasales) && (applicant.business.kiranastore.kiranasales.length >= 1)) {
            loanModel.kiranasales = JSON.parse(JSON.stringify( applicant.business.kiranastore.kiranasales));
          calculatekiranasales();
        }
      }
      if (!isDefined(applicant.business.tailor)) {
        applicant.business.tailor = {};
      }
      if (!isDefined(applicant.business.vegetablefruit)) {
        applicant.business.vegetablefruit = {produce:{purchase:{},selling:{}},difficulties:{purchase:{},selling:{}},delivery:{},customertype:{}};
      }
      if (!isDefined(applicant.business.beautyparlour)) {
        applicant.business.beautyparlour = {eplatforms:{},salonequipment:{},service:{}};
      }
      if (!isDefined(applicant.business.livestock)) {
        applicant.business.livestock = {};
      }
      if (!isDefined(applicant.business.mobile)) {
        applicant.business.mobile = {mobilelocalityofbiz:{}, service:{},bank:{},activities:{}, product:{},source:{},mobaccessories:{},repair:{},authorized:{},benefit:{},promotional:{},accessories:{},inventory:{},adhar:{},equipment:{}};
      }
      if (!isDefined(applicant.business.transport)) {
        applicant.business.transport = {};
      }
      if (!isDefined(applicant.business.streetfood)) {
        applicant.business.streetfood = {documentation:{}, streetfoodvendorcategory:{},foodcategory:{},type:{},howisfoodtransported:{},sourceoffood:{},power:{},season:{},location:{},streetfoodequipment:{},customertype:{},largecustomer:{},foodapptype:{},largecustomertype:{}};
      }
      if (!isDefined(applicant.business.sweet)) {
        applicant.business.sweet = {};
      }
      // else {
      //   if (applicant.business.transport.maintainence!==null && applicant.business.transport.maintainence.length >= 1) {
      //     transmaintainence = JSON.parse(JSON.stringify(applicant.business.transport.maintainence);
      //     calculateTransMaintainence();
      //   } else {
      //     applicant.business.transport.maintainence = {};
      //   }

      // }
      if (!isDefined(applicant.business.agent)) {
        applicant.business.agent = {dmstrategies:{},leads:{},part:{},website:{}};
      }
      if (!isDefined(applicant.business.clothing)) {
        applicant.business.clothing = {type:{},price:{}};
      }
      if (!isDefined(applicant.business.laundry)) {
        applicant.business.laundry = {service:{},market:{},serviceoffered:{},irontype:{},typoffabrics:{},dryingarea:{},deliveryvehicle:{},equipments:{}};
      }
      if (!isDefined(applicant.business.xerox)) {
        applicant.business.xerox = {valueservicesadded:{}};
      }
      if (!isDefined(applicant.business.employees)) {
        loanModel.totalEmployee = 0;
        applicant.business.employees = [];
        loanModel.totalCurrentEmployee = 0;
        loanModel.totallastEmployee = 0;
        loanModel.totallast_1Employee = 0;
        for (var i = 0; i < bizEmployee.length; i++) {
          bizEmployee[i].current = '0';
          bizEmployee[i].last = '0';
          bizEmployee[i].last_1 = '0';
        }
        calculateEmployee();
      }
      else {
        for (var i = 0; i < bizEmployee.length; i++) {
          for (var j = 0; j < applicant.business.employees.length; j++) {
            if (bizEmployee[i].code===applicant.business.employees[j].code) {
              bizEmployee[i].bizemployeeid = applicant.business.employees[j].bizemployeeid;
              bizEmployee[i].current = applicant.business.employees[j].current;
              bizEmployee[i].last = applicant.business.employees[j].last;
              bizEmployee[i].last_1 = applicant.business.employees[j].last_1;
            }
          }
        }
        calculateEmployee();
      }
      getGenericNetProfit();
      getRentSqftByLocalityBusiness();


      if (!isDefined(applicant.business.documents) || applicant.business.documents.length < 1) {
        applicant.business.documents = JSON.parse(JSON.stringify( bizModel));
      }
     
      if (!isDefined(applicant.business.peakperiod)) {
        applicant.business.peakperiod = JSON.parse(JSON.stringify(seasonalModel));
      }
      if (!isDefined(applicant.business.offperiod)) {
        applicant.business.offperiod = JSON.parse(JSON.stringify(seasonalModel));
      }
      if (!isDefined(applicant.businessincome.transactionperiod) || applicant.businessincome.transactionperiod.length == 0) {
        applicant.businessincome.transactionperiod = generateTransactionMonths(3);
      }
      getYear();
     
      if (!isDefined(applicant.businessincome.directexpenses) || applicant.businessincome.directexpenses.length < 1) {
        applicant.businessincome.directexpenses = JSON.parse(JSON.stringify(directExpenseModel));
      }
      else {
        calcTotalDirectExpenses();
      }
      if (!isDefined(applicant.businessincome.indirectexpenses) || applicant.businessincome.indirectexpenses.length < 1) {
        applicant.businessincome.indirectexpenses = JSON.parse(JSON.stringify(indirectExpenseModel));
      }
      else {
        calcTotalIndirectExpenses();
      }

      if (!isDefined(applicant.businessincome.otherincomes) || applicant.businessincome.otherincomes.length < 1) {
        applicant.businessincome.otherincomes = JSON.parse(JSON.stringify(bizotherIncomesModel));
      }
      else {
        calcBizTotalIncome();
      }
      if (!isDefined(applicant.businessincome.documents) || applicant.businessincome.documents.length < 1) {
        applicant.businessincome.documents = JSON.parse(JSON.stringify(bizincomeDocumentsModel));
      }
      if (applicant.business.constitution!==null && applicant.business.constitution==="Proprietorship") {
        applicant.business.profitshare = 100;
        applicant.businessincome.profitshare = 100;
      }
      if ((applicant.businessincome!==null) && (applicant.businessincome.itrs!==null)) {
        setITR();
      }
      if (applicant.businessincome.consideritrincome) {
        calculateITRSale();
      }
      else {
        calcBizTotalRevenue();
      }
      calcBizTotalIncome();
    }
    else if (applicant.type==='informal_salaried') {
      if (applicant.income.salaryconsiderationtype) {
        loanModel.salaryconsiderationtype = applicant.income.salaryconsiderationtype;
      } else {
        loanModel.salaryconsiderationtype = "AVERAGE";
      }
      if (!isDefined(applicant.employer)) {
        applicant.employer = {};
      }
      else {
        //if (applicant.employer.type!==null)
          //loanModel.employerfields[1].fieldGroup[7].templateOptions.options = eval('lookuplist.' + applicant.employer.type);
      }
      if (!isDefined(applicant.income.earnings) || applicant.income.earnings.length < 1) {
        applicant.income.earnings = JSON.parse(JSON.stringify(salaryEarningsModel));
      }
      else {
        checkSalaryEarningModel();
      }
      if (!isDefined(applicant.income.deductions) || applicant.income.deductions.length < 1) {
        applicant.income.deductions = JSON.parse(JSON.stringify(salaryDeductionsModel));
      }
      else {
        checkSalaryDeductionsModel();
      }
      if (!isDefined(applicant.income.otherincomes) || applicant.income.otherincomes.length < 1) {
        applicant.income.otherincomes = JSON.parse(JSON.stringify(otherIncomesModel));
      } else {
        checkOtherIncome();
      }
      if (!isDefined(applicant.employer.multiemployed)) {
        applicant.employer.multiemployed = {};
      }

      if (!isDefined(applicant.income.documents) || applicant.income.documents.length < 1) {
        applicant.income.documents = JSON.parse(JSON.stringify(incomeDocumentsModel));
      }
      if (!isDefined(applicant.employer.documents) || applicant.employer.documents.length < 1) {
        applicant.employer.documents = JSON.parse(JSON.stringify(bizDocumentsModel));
      }
      if (!isDefined(applicant.employer.multiemployed.employment)) {
        applicant.employer.multiemployed.employment = {};
      }
      if(!isDefined(service.sales)){
        service.sales={}
      }
      calcTotalIncome();
      reorderIncome(applicant.income);
      recalcTotal();
      calculateMonthly();
      if (isDefined(applicant.income.transactionperiod) && applicant.income.transactionperiod.length > 0) {

      }
      else {
        var mList = generateTransactionMonths(3);
        applicant.income.transactionperiod = mList;
      }
    }
    calculateDependent();

    if (!isDefined(applicant.eligibility)) {
      applicant.eligibility = {};
    }

    //getApplicantPhoto(applicant.applicantphoto ? applicant.applicantphoto : undefined);
    calculateNetworth();
    loanModel.showApplicantTab = true;
    //vm.applicantMode = "EDIT";
    loanModel.applicantIndex = appIdx;
    activateTab();
    recommendedhouseholdrent();
    setLoan(loanModel);
    //jQuery('#btabs1').children().removeClass("active"); // Setting all the sub child tabs inactive
    //jQuery('#btabs11').addClass("active"); //Business tab1 active
    //jQuery('#btabs1_1').addClass("active");
  }

  function changeApplicantType(){
    showApplicant(loanModel.applicants.length-1);
  }
  function getpincodelocation() {
    if(applicant.address.zipcode && applicant.address.zipcode.length===6){
      axios.get('https://api.postalpincode.in/pincode/'+applicant.address.zipcode).then((data:any)=>{
        applicant.address.state = data[0].PostOffice[0].State;
        applicant.address.city = data[0].PostOffice[0].Block;
        applicant.address.district = data[0].PostOffice[0].District;
        applicant.address.locality = data[0].PostOffice[0].Name;
        setLoan(loanModel);
      })
    }
  };
  function getProppincodelocation() {
    if(loanModel.property.zipcode.length===6){
      axios.get('https://api.postalpincode.in/pincode/'+loanModel.property.zipcode).then((data:any)=>{
        loanModel.property.address.state = data[0].PostOffice[0].State;
        loanModel.property.address.city = data[0].PostOffice[0].Block;
        loanModel.property.address.district = data[0].PostOffice[0].District;
        loanModel.property.address.locality = data[0].PostOffice[0].Name;
        setLoan(loanModel);
      })
    }
  };
  function getEmppincodelocation() {
    axios.get('https://api.postalpincode.in/pincode/'+applicant.employer.address.zipcode).then((data:any)=>{
      
    });
  };
  function getbizpincodelocation () {
    
      if(applicant.business.address.zipcode && applicant.business.address.zipcode===6){
      axios.get('https://api.postalpincode.in/pincode/'+applicant.business.address.zipcode).then((data:any)=>{

        applicant.business.address.state =data[0].PostOffice[0].State;
        applicant.business.address.city = data[0].PostOffice[0].Block;
        applicant.business.address.district = data[0].PostOffice[0].District;
        applicant.business.locality = data[0].PostOffice[0].Name;
        
      });
    }
  };
  function addAccount(){
    loanModel.bankMode = "ADD";
    bankModel = {};
    bankModel.transactions = [];
    generateBankModel(6);
  }
// for CraftsMan
  function  resetcraftsmanMachine(){
    applicant.business.craftsman.equipment=!applicant.business.craftsman.equipment;
    if(applicant.business!=null && applicant.business.craftsman!=null 
      && (!applicant.business.craftsman.equipment)){
        if(applicant.business.craftsman.machines!=null && applicant.business.craftsman.machines.length>=1){
          applicant.business.craftsman.equipment=true;
          // $("#craftsman").modal('show');
        }
      }
  }

  function addcraftsmanMachine (){
    if(applicant.business.craftsman.machines==null){
      applicant.business.craftsman.machines=[];
      setShowCraftsmanMachineForm(true);
      // craftsman={};
      setcraftsmanmachineError(false);
    }
    else if((applicant.business.craftsman.machines!=null)&&(applicant.business.craftsman.machines.length<=4)){
      setShowCraftsmanMachineForm(true);
      // craftsman={};
      setcraftsmanmachineError(false);
    }else{
      setcraftsmanmachineError(true);
    }
  }
  function saveMachine(){
    if(isDefined(applicant.business.craftsman.machines)){
      applicant.business.craftsman.machines=[];
    }
    var copy=JSON.parse(JSON.stringify(craftsman));
    if(!isDefined(copy.id)){
      applicant.business.craftsman.machines.push(copy);
    }
    else if(isDefined(copy.id)){
      let fIdx = applicant.business.garage.craftsman.findIndex((e:any)=>e.id === copy.id);
      applicant.business.garage.craftsman[fIdx] = copy
    }
    craftsman={};
    setShowCraftsmanMachineForm(false);
  }
  function showCraftsmanMachine(index:any){
    const copy = { ...applicant.business.craftsman.machines[index] }
    craftsman=copy;
    setShowCraftsmanMachineForm(true);
    // craftsmanMachineIndex=angular.copy(index);
  }

  function removeCraftsmanMachine (index:any){
    if(applicant.business.craftsman.machines[index].id==craftsman.id){
      setShowCraftsmanMachineForm(false)
    }
    applicant.business.craftsman.machines.splice(index,1);
  }

  
  // Craftmans

  function removeBanking(idx:any) {
    applicant.bankaccounts.splice(idx,1);
    setBankView(false);
  }
  function showBanking(idx:any) {
    bankModel = Object.assign({},applicant.bankaccounts[idx]);
    setBankView(true);
    loanModel.bankMode = "EDIT";
    loanModel.bankIndex = idx;
    if(!isDefined(bankModel.transactions))
    {
      bankModel.transactions = [];
    }
    if(bankModel.transactions.length===0)
    {
      generateBankModel(6);
    }
    if (!bankModel.remarks) {
      bankModel.remarks = "";
    }
    bankModel.transactions.sort(sortarrayWithDate);

  }
  function sortarrayWithDate(a:any, b:any) {
    return  new Date(b.name).getTime() - new Date(a.name).getTime() ;
  }
  function generateBankModel(ctr:number) {
    bankModel.transactions = [];
      for (let i = 1; i <= ctr; i++) {
        let date = new Date();
        date.setMonth(date.getMonth() - i);
        bankModel.transactions.push({
            name: moment(date).format('MMM-yyyy'), 
            credits:null, 
            creditamount:null, 
            debits:null, 
            debitamount:null,  
            outchequebounces:null, 
            minbalancecharges:null, 
            balance:null, 
            avgbalance:null }
        );
      }
      setBankModel(bankModel);
  }
  function getGeoLocation(){
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position){
        if(position!=null){
            loanModel.locationallowed=true;
            loanModel.position = position;
            loanModel.lat=loanModel.position.coords.latitude;
            loanModel.lang=loanModel.position.coords.longitude;
          }
        },function(error:any) {
        if (error.code===error.PERMISSION_DENIED)
          console.log("Allow location to track");
        loanModel.locationallowed=false;
      });
    }
  }
  function showSitePage() {
    getGeoLocation();
    setSitePage(true);
    siteModel={};
    siteModel.sitevisited="EMP";
    siteModel.visitdate = new Date();  
    siteModel.appearances = getLookupProperty('appearances');
    siteModel.images=[];
    siteModel.videos=[];
    setLoan(loanModel);
    
    if(siteModel.lat!==undefined && siteModel.lang!==undefined && siteModel.lat===null && siteModel.lang===null){
      siteModel.lat=JSON.parse(JSON.stringify(loanModel.lat));
      siteModel.lang=JSON.parse(JSON.stringify(loanModel.lang));
    }
    
  }
  function validateText(value:any) {
    return /[a-zA-Z0-9\_\.\,\-\'\s\[\]\(\)]$/.test(value);
  }
  function removeInstrument(i:number) {
    applicant.networth.instruments.splice(i,1);
    calculateNetworth();
    setLoan(loanModel);
  }
  function removeContribution(i:number) {
    loanModel.property.contributions.splice(i,1);
    calculateContribution();
    setLoan(loanModel);
  }
  function removeBizOtherIncome(i:number) {
    //console.log("removing index " + i);
    try{
      applicant.businessincome.otherincomes.splice(i,1);
      setLoan(loanModel);
    }
    catch(exception) {
      console.log(exception);
    }
  }
  function removeOtherIncome(i:number) {
    try{
      applicant.income.otherincomes.splice(i,1);
      setLoan(loanModel);
    }
    catch(exception) {
      console.log(exception);
    }
  }
  function addOtherIncome(){
    var oI = {name:"Other", type:"OTHER"};
    applicant.income.otherincomes.push(oI);
    setLoan(loanModel);
  }

  function addBizOtherIncome(){
    var oI = {name:"Other", type:"OTHER"};
    applicant.businessincome.otherincomes.push(oI);
    setLoan(loanModel);
  }

  function addInstrument(){
    var oI = {instrument:"Other",amount:0.0, type:"OTHER"};
    applicant.networth.instruments.push(oI);
    setLoan(loanModel);
  }

  function addContribution(){
    var oI = {contribution:"Other Contribution",amount:0.0, type:"OTHER"};
    loanModel.property.contributions.push(oI);
    setLoan(loanModel);
  }

  function removeEarning(i:any) {
    try{
      applicant.income.earnings.splice(i,1);
      recalcTotal();
      setLoan(loanModel);
    }
    catch(exception) {
      console.log(exception);
    }
    setLoan(loanModel);
  }

  function addGarageMachine(){
    if(!isDefined(applicant.business.garage.machines)){
      applicant.business.garage.machines=[];
      loanModel.showGarageMachineForm=true;
      loanModel.garageMachine={};
      loanModel.garageMachineError=false;
    }
    else if(isDefined(applicant.business.garage.machines) && applicant.business.garage.machines.length<=1000){
      loanModel.showGarageMachineForm=true;
      loanModel.garageMachine={};
      loanModel.garageMachineError=false;
    }else{
      loanModel.garageMachineError=true;
    }
    setLoan(loanModel);
  }

  function saveGarageMachine(){
    if(!isDefined(applicant.business.garage.machines)){
      applicant.business.garage.machines=[];
    }
    var copy=JSON.parse(JSON.stringify(loanModel.garageMachine));
    if(!isDefined(copy.id)){
      applicant.business.garage.machines.push(copy);
    }else if(isDefined(copy.id)){
      let fIdx = applicant.business.garage.machines.findIndex((e:any)=>e.id === copy.id);
      applicant.business.garage.machines[fIdx] = copy
    }
    loanModel.garageMachine={};
    loanModel.garageMachineIndex=null;
    loanModel.showGarageMachineForm=false;
    setLoan(loanModel);
  }
  
  function showGarageMachine(index:any){
    var copy=JSON.parse(JSON.stringify(applicant.business.garage.machines[index]));
    loanModel.garageMachine=copy;
    loanModel.showGarageMachineForm=true;
    loanModel.garageMachineIndex=index;
    setLoan(loanModel);
  }
  function removeGarageMachine(index:any){
    if(applicant.business.garage.machines[index].id==loanModel.garageMachine.id){
      loanModel.showGarageMachineForm=false;
    }
    loanModel.applicant.business.garage.machines.splice(index,1);
    setLoan(loanModel);
  }

  function cancelGarageMachine (){
    loanModel.showGarageMachineForm=false;
    loanModel.garageMachine={};
    setLoan(loanModel);
  }

  // Transport Starts

  let [showTransportMachineForm,setShowTransportMachineForm] = useState(false)

 function addTransportVehicle(){
  if(applicant.business.transport.machines==null){
    applicant.business.transport.machines=[];
    setShowTransportMachineForm(true);
    applicant.business.transport.TransportMachine={};
    // TransportMachineError=false;
  }
  else if((applicant.business.transport.machines!=null)&&(applicant.business.transport.machines.length<=4)){
    setShowTransportMachineForm(true);
    applicant.business.transport.TransportMachine={};
    // TransportMachineError=false;
  }
 }

 function showTransportVehicle(index:any){
  var copy=applicant.business.transport.machines[index];
  applicant.business.transport.TransportMachine=copy;
  transportMachine.index = index;
  setShowTransportMachineForm(true);
  applicant.business.transport.transportMachineIndex=applicant.business.transport.machines[index];
}

function removeTransportVehicle(index:any){
  if(applicant.business.transport.machines[index].id==applicant.business.transport.TransportMachine.id){
    setShowTransportMachineForm(false);
  }
  applicant.business.transport.machines.splice(index,1);
}

function saveTransportVehicle(){
  if((applicant.business.transport==null)
  ||(applicant.business.transport.machines==null)){
    applicant.business.transport.machines=[];
  }
  var copy=JSON.parse(JSON.stringify(transportMachine));
  // var transportMachineIndex = 
  // var copy=angular.copy(TransportMachine);/
  if((copy.id==null)&&(transportMachine.index==null)){
    applicant.business.transport.machines.push(copy);
  }
  else if(copy.id!=null){
    for(var i=0;i<applicant.business.transport.machines.length;i++){
      if(copy.id==applicant.business.transport.machines[i].id){
        applicant.business.transport.machines[i]=copy;
      }
    }
  }
  else if((transportMachine.index!=null)&&(showTransportMachineForm)&&(copy.id==null)){
    for(var i=0;i<applicant.business.transport.machines.length;i++){
      if(transportMachine.index==i){
        applicant.business.transport.machines[i]=copy;
      }
    }
  }
  transportMachine={};
  transportMachine.index=null;
  setShowTransportMachineForm(false);
}

function cancelTransportVehicle(){
  setShowTransportMachineForm(false);
  loanModel.TransportMachine={};
}

  //Transport Ends

  function addCattle(){

    if(!isDefined(applicant.business.dairy.cattles)){
      applicant.business.dairy.cattles = [];
    }

    if(applicant.business.dairy.cattles.length<30){
      loanModel.cattleMode="ADD";
      loanModel.cattleForm=true;
    }else{
      loanModel.cattleError=true;
    }
    loanModel.cattle={};
    setLoan(loanModel);
  }
  function saveCattle(){
    var copy=JSON.parse(JSON.stringify(loanModel.cattle));
    if(!isDefined(applicant.business.dairy.cattles)){
      applicant.business.dairy.cattles=[];
    }
    switch(loanModel.cattleMode){
      case 'ADD':
        applicant.business.dairy.cattles.push(copy);
        break;
      case 'EDIT':
        applicant.business.dairy.cattles[loanModel.cattleIndex]=copy;
        break;
    }
    loanModel.cattle={};
    loanModel.cattleForm=false;
    loanModel.cattleError=false;
    loanModel.cattleIndex=null;
    setLoan(loanModel);
  }
  function cancleCattle(){
    loanModel.cattleForm=false;
    loanModel.cattle={};
    loanModel.cattleError=false;
    loanModel.cattleIndex=null;
    setLoan(loanModel);
  }
  function showDairyCattle(index:any){
    loanModel.cattleMode='EDIT';
    loanModel.cattleIndex=index;
    var copy=JSON.parse(JSON.stringify(applicant.business.dairy.cattles[index]));
    loanModel.cattle=copy;
    loanModel.cattleForm=true;
    loanModel.cattleError=false;
    setLoan(loanModel);
  }
  function removeCattle(index:any){
    loanModel.cattleError=false;
    applicant.business.dairy.cattles.splice(index,1);
    setLoan(loanModel);
  }
  function addLaundryIncome(){
    if(!isDefined(applicant.business.laundry.laundryincome)){
      applicant.business.laundry.laundryincome = [];
    }
    var l = applicant.business.laundry.laundryincome.length;
    if(l<30){
      loanModel.laundryflagMode="ADD";
      loanModel.laundryincomeForm=true;
    }else{
      loanModel.laundryincomeError=true;
    }
    loanModel.laundryincome={};
  }
  
  function saveLaundryIncome(){
    var copy=JSON.parse(JSON.stringify(loanModel.laundryincome));
    
    switch(loanModel.laundryflagMode){
      case 'ADD':
        applicant.business.laundry.laundryincome.push(copy);
        break;
      case 'EDIT':
        applicant.business.laundry.laundryincome[loanModel.laundryincomeIndex]=copy;
        break;
    }
    loanModel.laundryincome={};
    loanModel.laundryincomeForm=false;
    loanModel.laundryincomeError=false;
    loanModel.laundryincomeIndex=null;
  }
  
  function cancelLaundryIncome(){
    loanModel.laundryincomeForm=false;
    loanModel.laundryincome={};
    loanModel.laundryincomeError=false;
    loanModel.laundryincomeIndex=null;
  }
  
  function showLaundryIncome(index:any){
    loanModel.laundryflagMode='EDIT';
    loanModel.laundryincomeIndex=index;
    
    loanModel.laundryincome=JSON.parse(JSON.stringify(applicant.business.laundry.laundryincome[index]));
    loanModel.laundryincomeForm=true;
    loanModel.laundryincomeError=false;
  }
  
  function removeLaundryIncome(index:any){
    loanModel.laundryincomeError=false;
    applicant.business.laundry.laundryincome.splice(index,1);
  }

  

  function addSalaryEarnings() {
    var oI = {name:"Other Earnings", type:"OTHER"};
    applicant.income.earnings.push(oI);
    setLoan(loanModel);
  }
  function removeDeduction(i:any){
    try{
      applicant.income.deductions.splice(i,1);
      recalcTotal();
      setLoan(loanModel);
    }
    catch(exception) {
      console.log(exception);
    }
  }
  function addSalaryDeductions() {
    var oI = {name:"Other Deductions", type:"OTHER"};
    applicant.income.deductions.push(oI);
    setLoan(loanModel);
  }
  function addInsurance(){
    console.log('before'+ showInsurancePage )
    if(!showInsurancePage){
      
      if(!isDefined(applicant.networth.lifeinsurances))
        applicant.networth.lifeinsurances = []
      applicant.networth.lifeinsurances.push({policy:'',sumassured:'',maturitydate:''});
      setInsurancePage(true);
    }
  }

  function addLifeInsurance() {
    setInsurancePage(false);
  }
  function lifeinsurancecancel(i:number){
    applicant.networth.lifeinsurances.splice(i,1);
    setInsurancePage(false);
  }

  function addMedication(){
    console.log('before'+ showMedInsurancePage )
    if(!isDefined(showMedInsurancePage) || showMedInsurancePage === false){
      if(applicant.networth.medinsurances === undefined || applicant.networth.medinsurances === null)
        applicant.networth.medinsurances = []
      applicant.networth.medinsurances.push({policy:'',sumassured:'',coverage:''});
      setMedInsurancePage(true);
    }
  }

  function addMedLifeInsurance() {
    setMedInsurancePage(false)
  }
  function medicalpoliciescancel(i:number){
    applicant.networth.medinsurances.splice(i,1);
    setMedInsurancePage(false)
  }
  function getHideFlagBasedOnEmployerConstitution() {
    if(!isDefined(applicant.employer) || !isDefined(applicant.employer.constitution)){
      return false;
    } else if(applicant.employer.constitution==="GOV" || applicant.employer.constitution==="MNG" || applicant.employer.constitution==="MNI" || applicant.employer.constitution==="NB" || applicant.employer.constitution==="PSU" || applicant.employer.constitution==="" || applicant.employer.constitution===undefined){
      return true;
    }else{
      return false;
    }
  }
  function validateExtension(section:any,extension:any){
    var valid=false;
    if(section=="document"){
      let list = getLookupProperty('documentExtension')
      for(var i=0;i<list.length;i++){
        if(extension.toUpperCase()===list[i].code.toUpperCase()){
          valid=true;
        }
      }
    }
    if(section=="video"){
      let list = getLookupProperty('videoExtension')
      for(var i=0;i<list.length;i++){
        if(extension.toUpperCase()===list[i].code.toUpperCase()){
          valid=true;
        }
      }
    }
    return valid;
  }
  function uploadDocuments(params:any,docid:any) {
    const headers = {
      "Authorization": "Bearer " + user.token,
      "Content-Type": undefined
    }
    const config: AxiosRequestConfig = {
      headers: headers
    }
    return axios.post(
      process.env.REACT_APP_API_URL + "/app/upload/doc/",
      params,
      config
    );
  }

  function setEmployerDocument(response:any){
    if(applicant.employer.documents!==null){
      let i = applicant.employer.documents.findIndex((e:any)=>e.id===response.body.id);
      if(!isDefined(applicant.employer.documents[i].files)){
        applicant.employer.documents[i].files=[];
      }
      for(var j=0;j<response.body.files.length;j++){
        applicant.employer.documents[i].files.push(JSON.parse(JSON.stringify(response.body.files[j])));
      }
    }
  }

  function setBusinessDocuments(response:any){
    if(applicant.business.documents!==null){
      let i = applicant.business.documents.findIndex((e:any)=>e.id===response.body.id);
      if(!isDefined(applicant.business.documents[i].files)){
        applicant.business.documents[i].files=[];
      }
      for(var j=0;j<response.body.files.length;j++){
        applicant.business.documents[i].files.push(JSON.parse(JSON.stringify(response.body.files[j])));
      }
    }
  }

  function setBusinessincomeDocuments(response:any){
    if(applicant.businessincome.documents!==null){
      let i = applicant.businessincome.documents.findIndex((e:any)=>e.id===response.body.id);
      if(!isDefined(applicant.businessincome.documents[i].files)){
        applicant.businessincome.documents[i].files=[];
      }
      for(var j=0;j<response.body.files.length;j++){
        applicant.businessincome.documents[i].files.push(JSON.parse(JSON.stringify(response.body.files[j])));
      }
    }
  }

  function setIncomeDocuments(response:any){
    if(applicant.income.documents!==null){
      let i = applicant.income.documents.findIndex((e:any)=>e.id===response.body.id);
      if(!isDefined(applicant.income.documents[i].files)){
        applicant.income.documents[i].files=[];
      }
      for(var j=0;j<response.body.files.length;j++){
        applicant.income.documents[i].files.push(JSON.parse(JSON.stringify(response.body.files[j])));
      }
    }
  }
  function changeVisitInfo(){
    var s = siteModel.sitevisited;
    var p = siteModel.placevisited;
    if ((s==="BIZ" || s==="EMP") && (p==="EDU") ){
      siteModel.observations = JSON.parse(JSON.stringify(getLookupProperty('observation_education')));
    }
    else if(s==="BIZ" && p!=="EDU") {
      siteModel.observations = JSON.parse(JSON.stringify(getLookupProperty('observation_business')));

    } else if(s==="RES"){
      siteModel.observations = JSON.parse(JSON.stringify(getLookupProperty('observation_residence'))); 
    } else {
      siteModel.observations = JSON.parse(JSON.stringify(getLookupProperty('observation_employer')));
    }
    setLoan(loanModel);
  }
  function uploadDocument(element:any,section:any,docid:any,docname:any){
    loanModel.error=[];
    loanModel.businessError=[];
    loanModel.businessIncomeError=[];
    loanModel.incomeError=[];
    const fileUpload:any = document.getElementById(element);
    var totalfilesize=0;
    var formData=new FormData();
    getGeoLocation();
    fileUpload.onchange=function(){
      var totalImg=0;
      var validextension=true;
      for (var index = 0; index < fileUpload.files.length; index++) {
        const localfile = fileUpload.files[index];
        var kilobytes = (fileUpload.files[index].size / 1024);
        var megabytes = (kilobytes / 1024);
        totalfilesize = totalfilesize + megabytes;
        formData.append("files",localfile);
        var list=fileUpload.files[index].name.split(".");
        var extension=list[list.length-1];
        if(!validateExtension('document',extension)){
          validextension=false;
          break;
        }
        
      }
      if(section=="employer"){
        if(!validextension){
          loanModel.employerError=[];
          loanModel.employerError[docid]=true;
        }
        else{
          loanModel.employerError=[];
          loanModel.employerError[docid]=false;
          formData.append("sectionid",applicant.employer.id);
        }
      }else if(section=="business"){
        if(!validextension){
          loanModel.businessError=[];
          loanModel.businessError[docid]=true;
        }
        else{
          loanModel.businessError=[];
          loanModel.businessError[docid]=false;
          formData.append("sectionid",applicant.business.id);
        }
      }else if(section=="businessincome"){
        if(!validextension){
          loanModel.businessIncomeError=[];
          loanModel.businessIncomeError[docid]=true;
        }
        else{
          loanModel.businessIncomeError=[];
          loanModel.businessIncomeError[docid]=false;
          formData.append("sectionid",applicant.businessincome.id);
        }
      }else if(section=="income"){
        if(!validextension){
          loanModel.incomeError=[];
          loanModel.incomeError[docid]=true;
        }
        else{
          loanModel.incomeError=[];
          loanModel.incomeError[docid]=false;
          formData.append("sectionid",loanModel.applicant.income.id);
        }
      }
      if(validextension){
        formData.append("section",section);
        formData.append("docname",docname);
        formData.append("lat",loanModel.lat);
        formData.append("lang",loanModel.lang);
        uploadDocuments(formData,docid).then((response:any)=>{
          if(response.body!=null){
            if(section=="employer"){
              setEmployerDocument(response);
            }
            else if(section=="business"){
              setBusinessDocuments(response);
            }
            else if(section=="businessincome"){
              setBusinessincomeDocuments(response);
            }
            else if(section=="income"){
              setIncomeDocuments(response);
            }
          }
        })
        setLoan(loanModel);
      }
      else{
        loanModel.uploadError="The format is not supported. The supported formats include JPEG, JPG, PNG, GIF, TIFF, PDF, DOC, DOCX, XLS, XLSX and TXT.";
        setLoan(loanModel);
      }
    }
    fileUpload.click();
  }
  function uploadSiteVisit(formData:any){
    const headers = {
      "Authorization": "Bearer " + user.token,
      "Content-Type": undefined
    }
    const config: AxiosRequestConfig = {
      headers: headers
    }
    return axios.post(
      process.env.REACT_APP_API_URL + "/app/sitevisit/upload",
      formData,
      config
    );
  }
  function imageUpload() {
    showUploadImageSection(false);
    getGeoLocation();
    visitBadImages = [];
    siteModel.errorMessage = "";
    const fileUpload:any = document.getElementById('svupload');
    var totalfilesize = 0;
    var formData = new FormData();
    fileUpload.onchange = function () {
      var totalImg = 0;
      totalImg = fileUpload.files.length + (siteModel.images!==null ? siteModel.images.length : 0);
      for (var index = 0; index < fileUpload.files.length; index++) {
        const localfile = fileUpload.files[index];
        var kilobytes = (fileUpload.files[index].size / 1024);
        var megabytes = (kilobytes / 1024);
        totalfilesize = totalfilesize + megabytes;
  
        // Check if the file is an image
        if (localfile.type.startsWith('image/')) {
          formData.append("files", localfile);
        } else {
          // Display an error message or handle the invalid file type as needed
          siteModel.errorMessage = "The format is not supported. The supported formats include jpg, jpeg & png";
          this.setLoan(loanModel);
          continue; // Skip this file and continue with the next one
        }
      }
      //uncomment when need to immplement size restrication
      //console.log(JSON.stringify(vm.siteModel));
      // if((vm.siteModel.images!=null)&&(vm.siteModel.images.length>=1)){
      //   for(var img=0;img<vm.siteModel.images.length;img++){
      //     totalfilesize=totalfilesize+vm.siteModel.images[img].imagesize;
      //   }
      // }
     // console.log("imagesize "+totalfilesize);
      // vm.siteModel.errorMessage="";
      
      if((totalImg<=3)&&(fileUpload.files.length>=1)){
        //console.log("lat "+vm.lat+" lang "+vm.lang);
        formData.append("lat",loanModel.lat);
        formData.append("lang",loanModel.lang);
        formData.append("offlineMode", "false");
        
        uploadSiteVisit(formData).then(function(response){
          var data=response.data;
          for (var j = 0; j < data.images.length; j++) {
            let siteImage:any = {};
            siteImage.id = j
            siteImage.name = data.images[j].filename.name;
            siteImage.code = data.images[j].filename.code;
            siteImage.totalprocced = data.images[j].totalprocced;
            siteImage.objectidentified = [];
            siteImage.totalseen = parseInt(data.images[j].totalseen);
            siteImage.imagesize = data.images[j].documentsize;
            siteImage.lat = data.images[j].lat;
            siteImage.lang = data.images[j].lang;
            siteImage.date = data.images[j].date;
            for (var obj = 0; obj < data.images[j].objectidentified.length; obj++) {
              let siteObservation:any = {};
              siteObservation.id = obj;
              siteObservation.seen = parseInt(data.images[j].objectidentified[obj].seen);
              siteObservation.objectname = data.images[j].objectidentified[obj].objectname.substring(0, 1).toUpperCase() +
                data.images[j].objectidentified[obj].objectname.substring(1);
              siteObservation.procced = data.images[j].objectidentified[obj].procced;
              
              siteImage.objectidentified.push(siteObservation);
            }
            siteModel.images.push(siteImage);

            getSiteVisitImage(data.images[j].image.code, "good");
          
          }
          if (data.badimages.length >= 1) {
            for (var bad = 0; bad < data.badimages.length; bad++) {
              for (var k = 0; k < data.images.length; k++) {
                if (data.images[k].filename.code===data.badimages[bad]) {
                  getSiteVisitImage(data.badimages[bad], "bad", data.images[k].filename.name);
                }
              }
            }
          }
          fileUpload.file = null;
    
    }).catch(function(error){
      console.log(JSON.stringify(error));
    });
    }
    else{
      if(totalImg>=4){
        siteModel.errorMessage="Uploaded images exceed threshold. Please upload a maximum of 3 images per location.";
      }
    }
      
    }
    fileUpload.click();
  }
  function getSiteVisitImage(imageCode:any,type:any,name:any=""){
    const headers = {
      "Authorization": "Bearer " + user.token,
      "Content-Type": undefined
    }
    const config: AxiosRequestConfig = {
      headers: headers
    }
    return axios.get(
      process.env.REACT_APP_API_URL + "app/sitevisit/image/"+imageCode,
      config
    ).then(function(response){
      var file = response.data;
      var fileURL = window.URL.createObjectURL(file);
      if(type=="good"){
        for(var i=0;i<siteModel.images.length;i++){
          if((siteModel.images[i].code==imageCode)&&(type=="good")){
            siteModel.images[i].url=fileURL;
          } 
        }
      }
      if((imageCode!=null)&&(type=="bad")){
        var copy={"code":imageCode,"name":name,"url":fileURL};
        visitBadImages.push(copy);
        if(fileURL!=null){
          return axios.get(
            process.env.REACT_APP_API_URL + "app/sitevisit/badimage/"+imageCode,
            config
          ).then(function(response){
           
            })
        }
        imageCode=null;
      }
    }).catch(function(error){
      console.log(JSON.stringify(error));
    })
  }

  // Xerox shop start
  let [showXeroxmanPeakForm, setshowXeroxmanPeakForm] = useState(false);
  let [xeroxPeakError, setxeroxPeakError] = useState(false)
  let xeroxPeak: any = {}
  let xeroxPeakIndex: any


  function addxeroxPeakHour() {
    if (applicant.business.xerox.peak == null) {
      applicant.business.xerox.peak = [];
      setshowXeroxmanPeakForm(true);
      xeroxPeak = {};
      setxeroxPeakError(false);
    } else if (applicant.business.xerox.peak != null && applicant.business.xerox.peak.length <= 4) {
      setshowXeroxmanPeakForm(true);
      xeroxPeak = {};
      setxeroxPeakError(false);
    }
    else {
      setxeroxPeakError(true);
    }
  };

  function showXeroxPeakHour(index: any) {

    var copy = JSON.stringify(applicant.business.xerox.peak[index]);
    xeroxPeak = copy;
    setshowXeroxmanPeakForm(true);
    xeroxPeakIndex = index;
  };

  function removeXeroxPeakHour(index: any) {
    if ((applicant.business.xerox.peak[index].id = xeroxPeak.id)) {
      setshowXeroxmanPeakForm(false);
    }
    applicant.business.xerox.peak.splice(index, 1);
  };

  function saveXeroxPeakHour() {
    if (applicant.business.xerox == null || applicant.business.xerox.peak == null) {
      applicant.business.xerox.peak = [];
    }
    var copy: any = JSON.stringify(xeroxPeak);
    if (copy.id == null && xeroxPeakIndex == null) {
      applicant.business.xerox.peak.push(copy);
    } else if (copy.id != null) {
      for (var i = 0; i < applicant.business.xerox.peak.length; i++) {
        if (copy.id == applicant.business.xerox.peak[i].id) {
          applicant.business.xerox.peak[i] = copy;
        }
      }
    } else if (xeroxPeakIndex != null && showXeroxmanPeakForm && copy.id == null) {
      for (var i = 0; i < applicant.business.xerox.peak.length; i++) {
        if (xeroxPeakIndex == i) {
          applicant.business.xerox.peak[i] = copy;
        }
      }
    }
    xeroxPeak = {};
    xeroxPeakIndex = null;
    setshowXeroxmanPeakForm(false);
  };

  function cancelxeroxPeakHour() {
    setshowXeroxmanPeakForm(false);
    xeroxPeak = {};
  };

  function resetxeroxmanMachine() {
    if (applicant.business != null && applicant.business.xerox != null && !applicant.business.xerox.equipment) {
      if (applicant.business.xerox.machines != null && applicant.business.xerox.machines.length >= 1) {
        applicant.business.xerox.equipment = true;
        // $("#xeroxMachine").modal("show");
      }
    }
  };

  let [showXeroxmanMachineForm, setshowXeroxmanMachineForm] = useState(false)
  let xeroxMachine: any = {}
  let xeroxMachineIndex: any

  function addxeroxMachine() {
    if (applicant.business.xerox.machines == null) {
      applicant.business.xerox.machines = [];
      setshowXeroxmanMachineForm(true);
      xeroxMachine = {};
      // xeroxmachineError = false;
    } else if (applicant.business.xerox.machines != null) {
      setshowXeroxmanMachineForm(true);
      xeroxMachine = {};
      // xeroxmachineError = false;
    }
    //  else {
    //   xeroxmachineError = true;
    // }
  };

  function showXeroxMachine(index: any) {
    var copy = JSON.stringify(applicant.business.xerox.machines[index]);
    xeroxMachine = copy;
    setshowXeroxmanMachineForm(true);
    xeroxMachineIndex = index;
  };

  function removeXeroxMachine(index: any) {
    if ((applicant.business.xerox.machines[index].id = xeroxMachine.id)) {
      setshowXeroxmanMachineForm(false);
    }
    applicant.business.xerox.machines.splice(index, 1);
  };

  function saveXeroxMachine(){
    if (applicant.business.xerox == null || applicant.business.xerox.machines == null) {
      applicant.business.xerox.machines = [];
    }
    var copy:any = JSON.stringify(xeroxMachine);
    if (copy.id == null && xeroxMachineIndex == null) {
      applicant.business.xerox.machines.push(copy);
    } else if (copy.id != null) {
      for (var i = 0; i < applicant.business.xerox.machines.length; i++) {
        if (copy.id == applicant.business.xerox.machines[i].id) {
          applicant.business.xerox.machines[i] = copy;
        }
      }
    } else if (xeroxMachineIndex != null && showXeroxmanMachineForm && copy.id == null) {
      for (var i = 0; i < applicant.business.xerox.machines.length; i++) {
        if (xeroxMachineIndex == i) {
          applicant.business.xerox.machines[i] = copy;
        }
      }
    }
    xeroxMachine = {};
    xeroxMachineIndex = null;
    setshowXeroxmanMachineForm(false);
  };

  function cancelxeroxMachine() {
    setshowXeroxmanMachineForm(false);
    xeroxMachine = {};
  };


  //xerox invoice

  let [showXeroxIncomeForm, setshowXeroxIncomeForm] = useState(false)
  let [xeroxIncomeError, setxeroxIncomeError] = useState(false)
  let xeroxIncome: any = {}
  let xeroxIncomeIndex: any

  function addxeroxincome() {
    if (applicant.business.xerox.income == null) {
      applicant.business.xerox.income = [];
      setshowXeroxIncomeForm(true);
      xeroxIncome = {};
      setxeroxIncomeError(false);
    } else if (applicant.business.xerox.income != null) {
      setshowXeroxIncomeForm(true);
      xeroxIncome = {};
      setxeroxIncomeError(false);
      // } else {
      //   vm.xeroxIncomeError = true;
    }
  };

  function showXeroxIncome(index: any) {
    var copy = JSON.stringify(applicant.business.xerox.income[index]);
    xeroxIncome = copy;
    setshowXeroxIncomeForm(true);
    xeroxIncomeIndex = index;
  };

  function removeXeroxIncome(index: any) {
    if ((applicant.business.xerox.income[index].id = xeroxIncome.id)) {
      setshowXeroxIncomeForm(false);
    }
    applicant.business.xerox.income.splice(index, 1);
  };

  function saveXeroxIncome() {
    if (applicant.business.xerox == null || applicant.business.xerox.income == null) {
      applicant.business.xerox.income = [];
    }
    var copy: any = JSON.stringify(xeroxIncome);
    if (copy.id == null && xeroxIncomeIndex == null) {
      applicant.business.xerox.income.push(copy);
    } else if (copy.id != null) {
      for (var i = 0; i < applicant.business.xerox.income.length; i++) {
        if (copy.id == applicant.business.xerox.income[i].id) {
          applicant.business.xerox.income[i] = copy;
        }
      }
    } else if (xeroxIncomeIndex != null && showXeroxIncomeForm && copy.id == null) {
      for (var i = 0; i < applicant.business.xerox.income.length; i++) {
        if (xeroxIncomeIndex == i) {
          applicant.business.xerox.income[i] = copy;
        }
      }
    }
    xeroxIncome = {};
    xeroxIncomeIndex = null;
    setshowXeroxIncomeForm(false);
  };

  function cancelxeroxIncome() {
    setshowXeroxIncomeForm(false);
    xeroxIncome = {};
  };

  // Xerox shop end
  function checkApplicantRecommendation() {
    applicant.eligibility.applicableEMI =
     pmt(applicant.eligibility.roi/(100*12),applicant.eligibility.tenure*12,-1*applicant.eligibility.loanrecommended);
     if(isNaN(applicant.eligibility.applicableEMI))
      applicant.eligibility.applicableEMI = 0.0;
     applicant.eligibility.actualfoir = Math.round(((( loanModel.check.applicant.totalObligation + applicant.eligibility.applicableEMI )/loanModel.check.applicant.totalincome ) * 100)*100)/100;
     if(isNaN(applicant.eligibility.actualfoir)){
      applicant.eligibility.actualfoir = 0.0;
     }
    if(loanModel.application.loanpurpose!='LAP' && loanModel.application.loanpurpose!=='HLAP'){
    loanModel.property.value = loanModel.property.transactioncost;
       applicant.eligibility.lcr = (applicant.eligibility.loanrecommended / loanModel.property.transactioncost)*100;
      
     }
     if(loanModel.application.loanpurpose=='LAP' || loanModel.application.loanpurpose==='HLAP'){
       applicant.eligibility.lcr = (applicant.eligibility.loanrecommended / loanModel.property.marketvalue)*100;
     
     }

     loanModel.check.totalcontribution = getTotalContribution();
     applicant.eligibility.lcr = Math.round(applicant.eligibility.lcr *100)/100;
     if(isNaN(applicant.eligibility.lcr)){
       applicant.eligibility.lcr = 0.0;
     }
     applicant.eligibility.applicableEMI = Math.round(applicant.eligibility.applicableEMI*100)/100;
  }
  function getTotalContribution(){
    var total = 0.0;
    var p = loanModel.property.contributions;
    for(var i=0; i < p.length ; i++) {
      total += parseFloat(p[i].amount);
    }
    return total;
  }
  function refreshApplicantObligation(){
    calApplicantObligation();
    calLoanApplicantEligibility();
    checkApplicantRecommendation();
  }

  function calApplicantObligation() {
    let obs = applicant.repayments.filter((e:any)=> e.consider===true);
    loanModel.check.applicant.totalObligation = 0.0;
    if(obs!==null && obs.length > 0) {
      for(var o=0; o<obs.length; o++) {
        loanModel.check.applicant.totalObligation += obs[o].emi;
      }
    }
  }
  function pmt(rate:any, nper:any, pv:any, fv:any=0, type:any=0) {
    if (!fv) fv = 0;
    if (!type) type = 0;

    if (rate===0) return -(pv + fv)/nper;

    var pvif = Math.pow(1 + rate, nper);
    var pmt = rate / (pvif - 1) * -(pv * pvif + fv);

    if (type===1) {
      pmt /= (1 + rate);
    };

    return pmt;
  };
  function calLoanApplicantEligibility() {
    var a = loanModel.check.applicant.totalincome * applicant.eligibility.foir/100 - parseFloat(loanModel.check.applicant.totalObligation);
    var b = pmt(applicant.eligibility.roi/(100*12),applicant.eligibility.tenure*12,-100000);
    var c = a/b*100000;
    applicant.eligibility.loaneligibility = c;
    applicant.eligibility.loaneligibility = Math.round(applicant.eligibility.loaneligibility*100)/100;
    var e =  pmt(applicant.eligibility.roi/(100*12),applicant.eligibility.tenure*12,-c);
    if(isNaN(e))
    {
      e = 0.0;
    }
    applicant.eligibility.totalEMI = e + loanModel.check.applicant.totalObligation;
    applicant.eligibility.totalEMI = Math.round(applicant.eligibility.totalEMI*100)/100;
    //return c;
  }
  function removeRepayment(idx:any) {
    applicant.repayments.splice(idx,1);
    repaymentmodel = {};
    setRepaymentView(false);
  }
  function showRepayment(idx:any) {
    repaymentmodel = JSON.parse(JSON.stringify( applicant.repayments[idx]));
    loanModel.repayIndex = idx;
    setRepaymentView(true);
  }
  function addRepayment() {
    repaymentmodel = {noofdefaults: '0'};
    setRepaymentView(true);
  }
  function viewMap(idx:any){
    visitMapDetail={name :"",images:[]};
    var images=applicant.sitevisits[idx].images;
    var videos=applicant.sitevisits[idx].videos;
    var  sitePremises = getLookupProperty('sitePremises');
    for(var i=0;i<sitePremises.length;i++){
      if(applicant.sitevisits[idx].sitevisited==sitePremises[i].code){
        visitMapDetail.name=sitePremises[i].type;
      }
    }
    if((images!=null)&&(images.length>=1)){
      for(var i=0;i<images.length;i++){
        if(images[i].locationdetail.address1!=null){
          visitMapImge.name=images[i].name;
          visitMapImge.id=i+1;
          visitMapImge.address=images[i].locationdetail.address1;
          visitMapImge.lat=parseFloat(images[i].lat);
          visitMapImge.lang=parseFloat(images[i].lang);
          visitMapDetail.images.push(JSON.parse(JSON.stringify( visitMapImge)));
        }
      }
    }
    var totalimages=images!=null?images.length:0;
    for(var j=0;j<videos.length;j++){
      if((videos[j].locationdetail!=null)&&(videos[j].locationdetail.address1!=null)){
        visitMapImge.name=videos[j].name;
      	visitMapImge.id=totalimages+1+j;
      	visitMapImge.address=videos[j].locationdetail.address1;
      	visitMapImge.lat=parseFloat(videos[j].lat);
      	visitMapImge.lang=parseFloat(videos[j].lang);
      	visitMapDetail.images.push(JSON.parse(JSON.stringify( visitMapImge)));
      }
    }

    //initMap();
    setSitePage(true);
  }
  // function initMap(){
  //   var mapOptions = {
  //    zoom: 8,
  //    fullscreenControl:false,
  //    center: new google.maps.LatLng(visitMapDetail.images[0].lat,visitMapDetail.images[0].lang)
  //    //mapTypeId: google.maps.MapTypeId.SATELLITE
  //  }
  //  map = new google.maps.Map(document.getElementById('map'), mapOptions);
  //  for (var i = 0; i < visitMapDetail.images.length; i++){
  //    createMarker(visitMapDetail.images[i]);
  //  }
  // function createMarker (info:any){
  //   var marker = new google.maps.Marker({
  //       map: map,
  //       position: new google.maps.LatLng(info.lat, info.lang),
  //       label:""+info.id,
  //       title: info.city
  //   });
  //   marker.content = '<div className="infoWindowContent">' + info.desc + '</div>';
  //   google.maps.event.addListener(marker, 'click', function(){
  //       infoWindow.setContent('<h2>' + marker.title + '</h2>' + marker.content);
  //       infoWindow.open(map, marker);
  //   });
    
  //   markers.push(marker);
  // }
 //}
  function videoDialog(id:any){
    let visitVideoDetail=siteModel.videos.filter((v:any)=> v.id === id)[0];
    //$("#videoDialog").modal('show');
  }
  function removeVideoDialog (id:any, name:any){
    // vm.deleteConfMessage={};
    // vm.deleteConfMessage.id=id;
    // vm.deleteConfMessage.section="video";
    // vm.deleteConfMessage.message=name;
    // $("#visitdelete").modal('show');
  }
  function calculateShareIncome(){
    if(applicant.businessincome!==null){
      applicant.businessincome.shareincome = (parseFloat(applicant.businessincome.profitshare) * parseFloat(applicant.businessincome.itrsalesincome)) / 100;
    applicant.businessincome.shareincome = Math.round(applicant.businessincome.shareincome * 100) / 100;
    applicant.businessincome.totalincome = Math.round(applicant.businessincome.shareincome * 100) / 100;
    }
  }
  function videoUpload (){
    showVideoUploadSection(false);
    getGeoLocation();
    var formData=new FormData();
    const fileUpload:any=document.getElementById('svuploadvideo');
    var validextension=true;
    fileUpload.onchange=function(){
      siteModel.videoErrorMessage="";
      // var totalImg=0;
      // totalImg=fileUpload.files.length+(vm.siteModel.images.length!=null?vm.siteModel.images.length:0);
      for (var index = 0; index < fileUpload.files.length; index++) {
        const localfile = fileUpload.files[index];
        // var kilobytes = (fileUpload.files[index].size / 1024);
        // var megabytes = (kilobytes / 1024);
        // totalfilesize = totalfilesize + megabytes;
        formData.append("files",localfile);
        var list=fileUpload.files[index].name.split(".");
        var extension=list[list.length-1];
        if(!validateExtension("video",extension)){
          validextension=false;
          break;
        }
      }
      if((validextension)&&(fileUpload.files.length>=1)){
        //console.log("lat "+vm.lat+" lang "+vm.lang);
        
        formData.append("lat",loanModel.lat);
        formData.append("lang",loanModel.lang);

        const headers = {
          "Authorization": "Bearer " + user.token,
          "Content-Type": undefined
        }

        const config: AxiosRequestConfig = {
          headers: headers
        }
        
        axios.post(
          process.env.REACT_APP_API_URL + "app/sitevisit/video/upload",
          formData,
          config
        ).then((response:any)=>{
          if(response!=null){
            //console.log(JSON.stringify(response));
            for(var i=0;i<response.data.length;i++){
              var video:any={};
              video.date=response.data[i].date;
              video.name=response.data[i].name;
              video.code=response.data[i].code;
              video.id=response.data[i].id;
              video.lat=response.data[i].lat;
              video.lang=response.data[i].lang;
              video.type=response.data[i].type;
              video.path=response.data[i].path;
              var copy=video;
              siteModel.videos.push(copy);
            }
          }
        });
        setLoan(loanModel);
      }else{
        if(!validextension){
          siteModel.videoErrorMessage="The format is not supported. The supported formats include  mp4, avi, 3gp, wmv, flv, mpeg and wav.";
        }
        setLoan(loanModel);
      }
    }
    fileUpload.click();
    siteModel.videoErrorMessage="";
  }
  function addService() {
    if (!isDefined(applicant.businessincome.services)) {
      applicant.businessincome.services = [];
    }
    service = defaultservice;
    loanModel.serviceMode = 'ADD'
    loanModel.serviceIndex = applicant.businessincome.services.length;
    showServiceSection(true);
    if (!isDefined(service.workingdays)) {
      service.workingdays = [];
      service.workingdays[0] = 0;
      service.workingdays[1] = 0;
      service.workingdays[2] = 0;
    }
    if (!isDefined(service.units)) {
      service.units = [];
      service.units[0] = 0;
      service.units[1] = 0;
      service.units[2] = 0;
    }
    if (!isDefined(service.unitprice)) {
      service.unitprice = [];
      service.unitprice[0] = 0;
      service.unitprice[1] = 0;
      service.unitprice[2] = 0;
    }
    if (!isDefined(service.sales)) {
      service.sales = {month0:0,month1:0,month2:0};
    }
    
  }
  function saveService(){
    if(loanModel.serviceMode === 'ADD') {
      var s = JSON.parse(JSON.stringify( service));
      applicant.businessincome.services.push(s);
    }
    else
    {
      applicant.businessincome.services[loanModel.serviceIndex] = service;
    }
    showServiceSection(false);
    calcBizTotalRevenue();
    calculateDirectExpenseMonthly();
    calculateIndirectExpenseMonthly()
  }
  function serviceproduct(){
    showServiceSection(false);
  }
  function calculateSalesMonthly(){
    var consideredMonthly = 0.0;
    switch (service.salesconsideration) {
  
      case "AVERAGE":
        consideredMonthly = (parseFloat(service.sales.month0) + parseFloat(service.sales.month1) + parseFloat(service.sales.month2))/3;
        consideredMonthly = Math.round(consideredMonthly *100)/100;
        break;
      case "MINIMUM":
          consideredMonthly = Math.min(service.sales.month0, service.sales.month1, service.sales.month2);
          break;
      case "MAXIMUM":
          consideredMonthly = Math.max(service.sales.month0, service.sales.month1, service.sales.month2);
          break;
      case "LATEST":
          consideredMonthly = service.sales.month0;
          break;
      case "PREVIOUS":
          consideredMonthly = service.sales.month1;
          break;
    }
    service.revenue = consideredMonthly;
  }
  function editService(idx:any) {
    showServiceSection(true);
    
    loanModel.serviceIndex = idx;
    service = JSON.parse(JSON.stringify( applicant.businessincome.services[idx]));
    calculateSalesMonthly();
    if (!isDefined(service.workingdays)) {
      service.workingdays = [];
      service.workingdays[0] = 0;
      service.workingdays[1] = 0;
      service.workingdays[2] = 0;
    }
    if (!isDefined(service.units)) {
      service.units = [];
      service.units[0] = 0;
      service.units[1] = 0;
      service.units[2] = 0;
    }
    if (!isDefined(service.unitprice )) {
      service.unitprice = [];
      service.unitprice[0] = 0;
      service.unitprice[1] = 0;
      service.unitprice[2] = 0;
    }
    if (!isDefined(service.sales)) {
      service.sales = {month0:0,month1:0,month2:0};
    }
  }
  function removeService(idx:any) {
    applicant.businessincome.services.splice(idx,1);
    showServiceSection(false);
    calcBizTotalRevenue();
  }
  function calculateSalesUnits(){  
    var factor = 1;
    var workingdays0 = 1;
    var workingdays1 = 1;
    var workingdays2 = 1;  
    switch (service.workingperiod) {
      case "Daily":
        factor = 1;
        workingdays0 = service.workingdays[0];
        workingdays1 = service.workingdays[1];
        workingdays2 = service.workingdays[2];
      break;
      case "Weekly":
        factor = 4;
      
        break;
      case "Fortnightly":
        factor = 2;
        break;
      case "Monthly":
        factor = 1;
        break;
  
    }
  }
  function calculateWorkingDays() {
    loanModel.workingdaysone = new Date(service.workingdays[0]+"-"+applicant.businessincome.transactionperiod[0]);  
    loanModel.workingdaystwo = new Date(service.workingdays[1]+"-"+applicant.businessincome.transactionperiod[1]);  
    loanModel.workingdaysthree = new Date(service.workingdays[2]+"-"+applicant.businessincome.transactionperiod[2]);  
  }

  function getSelfEmployedApplicantSubtype (consideritrincome:any) {
    if (consideritrincome) {
      return 'Formal';
    }
    else {
      return 'Informal';
    }
  }
  function validateEmployerSubType(constitution:any,employmenttype:any,salarymode:any,salarydeductions:any) {
    if (constitution==="GOV" || constitution==="MNG" || constitution==="MNI" || constitution==="NB" || constitution==="PSU") {
      if (employmenttype==="Temporary / Contract" && salarymode==="Cash" && (salarydeductions===undefined || salarydeductions===null)) {
        return 'Informal';
      }
      else if (employmenttype==="Temporary / Contract" && salarymode==="Cash" && (salarydeductions!==undefined || salarydeductions!==null)) {
        if (!(salarydeductions.epf===true || salarydeductions.esi===true)) {
          return 'Informal';
        }
        else {
          return 'Formal';
        }
      }
      else {
        return 'Formal';
      }
    }
    else if (constitution==="PRLC") {
      if (employmenttype==="Permanent" && salarymode==="BC" && salarydeductions!==undefined && (salarydeductions.epf===true || salarydeductions.esi===true)) {
        return 'Formal';
      }
      else {
        return 'Informal';
      }
    }
    else {
      return 'Informal';
    }
  }

  function resetTutor(i:any, req:any) {
    if (req.applicants[i].business.profile==='Tutors' && req.applicants[i].business.tutor!==null) {
      if (req.applicants[i].business.tutor.standards!==null) {
        if (!(req.applicants[i].business.tutor.standards.nursery || req.applicants[i].business.tutor.standards.class10 ||
          req.applicants[i].business.tutor.standards.kindergarten || req.applicants[i].business.tutor.standards.class1 || req.applicants[i].business.tutor.standards.class3 ||
          req.applicants[i].business.tutor.standards.class6 || req.applicants[i].business.tutor.standards.class9)) {
          req.applicants[i].business.tutor.subject.hindi = false;
          req.applicants[i].business.tutor.subject.english = false;
          req.applicants[i].business.tutor.subject.science = false;
          req.applicants[i].business.tutor.subject.maths = false;
          req.applicants[i].business.tutor.subject.history = false;
          req.applicants[i].business.tutor.subject.geography = false;
          req.applicants[i].business.tutor.subject.ca = false;
          req.applicants[i].business.tutor.subject.computer = false;
          req.applicants[i].business.tutor.subject.sanskrit = false;
          req.applicants[i].business.tutor.subject.rl = false;
          req.applicants[i].business.tutor.subject.il = false;
        }
        if (!(req.applicants[i].business.tutor.standards.class11 || req.applicants[i].business.tutor.standards.ug ||
          req.applicants[i].business.tutor.standards.graduate || req.applicants[i].business.tutor.standards.pg)) {
          req.applicants[i].business.tutor.collegesubject = null;
        }
      }
      if ((req.applicants[i].business.tutor.skillacquired!==null) && (req.applicants[i].business.tutor.skillacquired!=='PTES')) {
        req.applicants[i].business.tutor.schoolname = null;
        req.applicants[i].business.tutor.schoolboard = null;
        req.applicants[i].business.tutor.schoolemployment = null;
        req.applicants[i].business.tutor.schoolstandardstaught.nursery = false;
        req.applicants[i].business.tutor.schoolstandardstaught.class10 = false;
        req.applicants[i].business.tutor.schoolstandardstaught.kindergarten = false;
        req.applicants[i].business.tutor.schoolstandardstaught.class11 = false;
        req.applicants[i].business.tutor.schoolstandardstaught.class1 = false;
        req.applicants[i].business.tutor.schoolstandardstaught.ug = false;
        req.applicants[i].business.tutor.schoolstandardstaught.class3 = false;
        req.applicants[i].business.tutor.schoolstandardstaught.graduate = false;
        req.applicants[i].business.tutor.schoolstandardstaught.class6 = false;
        req.applicants[i].business.tutor.schoolstandardstaught.pg = false;
        req.applicants[i].business.tutor.schoolstandardstaught.class9 = false;
      }
    }
  }

  function resetPriest(i:any, req:any) {
    if (req.applicants[i].business.profile==='Priest / Astrologer' && req.applicants[i].business.priest!==null) {
      if (req.applicants[i].business.priest.customertype!==null) {
        if (!req.applicants[i].business.priest.customertype.if) {
          req.applicants[i].business.priest.nocustomer = null;
          req.applicants[i].business.priest.norepeat = null;
          req.applicants[i].business.priest.avgbilling = null;
        }
      }
      if (!req.applicants[i].business.priest.customertype.ni) {
        req.applicants[i].business.priest.largecustomer.temples = false;
        req.applicants[i].business.priest.largecustomer.wh = false;
        req.applicants[i].business.priest.largecustomer.bh = false;
        req.applicants[i].business.priest.largecustomer.hospitals = false;
        req.applicants[i].business.priest.largecustomer.corporates = false;
        req.applicants[i].business.priest.largecustomer.schools = false;
        req.applicants[i].business.priest.largecustomer.sg = false;
        req.applicants[i].business.priest.nolargecustomer = null;
        req.applicants[i].business.priest.avglargebilling = null;
      }
    }
  }
  
  //RG-675
  function resetKirana(i:any,req:any){
    if (req.applicants[i].business.profile!=='Small Kirana / Provision / Pan Store' && req.applicants[i].business.kiranastore!==null) {
      if(req.applicants[i].business.kiranastore.capitalinvestment!==null){
        req.applicants[i].business.kiranastore = null;
      }
    }
  }
  function displayMessage(message:any) {
    return (
      <ToastContainer
      className="p-3"
      position='bottom-end'
      style={{ zIndex: 1 }}
    >
      <Toast>
        
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
    );
  }
  function orderApplicants(){
    var sortApplicant=JSON.parse(JSON.stringify(loanModel.applicants));
    var doid=1;
    for(var i=0;i<sortApplicant.length;i++){
      if(sortApplicant[i].primary){
        sortApplicant[i].displayOrder=0;
      }else if((sortApplicant[i].applicantfor==='INC')||(sortApplicant[i].applicantfor==='INCPRP')){
        sortApplicant[i].displayOrder=JSON.parse(JSON.stringify(doid));
        doid=doid+1;
      }
      else {
        sortApplicant[i].displayOrder=sortApplicant.length-1;
      }
    }
    for(var i=0;i<sortApplicant.length;i++){
      for(var j=i;j<sortApplicant.length;j++){
        if(sortApplicant[j].displayOrder<sortApplicant[i].displayOrder){
          var temp=JSON.parse(JSON.stringify(sortApplicant[j]));
          sortApplicant[j]=JSON.parse(JSON.stringify(sortApplicant[i]));
          sortApplicant[i]=JSON.parse(JSON.stringify(temp));
        }
      }
    }
    loanModel.applicants=JSON.parse(JSON.stringify(sortApplicant));
  }
  function setSelectedRow(selectedApplicantId:any,oldApplicantsId:any){
    if((selectedApplicantId!=null) && (selectedApplicantId!=0)){
      for(var i=0;i<loanModel.applicants.length;i++){
        if(loanModel.applicants[i].id===selectedApplicantId){
          loanModel.selectedRow=i;
          loanModel.applicantIndex=i;
        }
      }
    }
    else if(selectedApplicantId==0){
      for(var i=0;i<loanModel.applicants.length;i++){
        var f=false;
        for(var j=0;j<oldApplicantsId.length;j++){
          if(oldApplicantsId[j]===loanModel.applicants[i].id){
            f=true;
          }
        }
        if(!f){
          loanModel.selectedRow=i;
          loanModel.applicantIndex=i;
        }
      }
      
    }
  }
  function showDob(applicant:any) {
    if(applicant.dob!=null){
        applicant.dob=Date.parse(applicant.dob);
      }
      return applicant;
  }

  //Multi-Employed Start
  function addMultiEmployed() {
    if (applicant.employer.multiemployed.employment === null) {
      applicant.employer.multiemployed.employment = [];
      loanModel.showMultiEmployedForm = true;
      loanModel.multiemployedEmployment = {};
      loanModel.multiEmployedFormError = false;
    } else if (applicant.employer.multiemployed.employment !== null && applicant.employer.multiemployed.employment.length <= 3) {
      loanModel.showMultiEmployedForm = true;
      loanModel.multiemployedEmployment = {};
      loanModel.multiEmployedFormError = false;
    } else {
      loanModel.multiEmployedFormError = true;
    }
  };

  function  saveMultiEmployed () {
    if (applicant.employer.multiemployed === null || applicant.employer.multiemployed.employment === null) {
      applicant.employer.multiemployed.employment = [];
    }
    var copy = JSON.parse(JSON.stringify(loanModel.multiemployedEmployment));
    if (copy.id===null && loanModel.multiEmployedIndex===null) {
      applicant.employer.multiemployed.employment.push(copy);
    } else if (copy.id!==null) {
      for (var i = 0; i < applicant.employer.multiemployed.employment.length; i++) {
        if (copy.id===applicant.employer.multiemployed.employment[i].id) {
          applicant.employer.multiemployed.employment[i] = copy;
        }
      }
    } else if (loanModel.multiEmployedIndex !== null && loanModel.showMultiEmployedForm && copy.id === null) {
      for (var i = 0; i < applicant.employer.multiemployed.employment.length; i++) {
        if (loanModel.multiEmployedIndex===i) {
          applicant.employer.multiemployed.employment[i] = copy;
        }
      }
    }
    loanModel.multiemployedEmployment = {};
    loanModel.multiEmployedIndex = null;
    loanModel.showMultiEmployedForm = false;
  };

  function showMultiEmployed(index:any) {
    var copy = JSON.parse(JSON.stringify(applicant.employer.multiemployed.employment[index]));
    loanModel.multiemployedEmployment = copy;
    loanModel.showMultiEmployedForm = true;
    loanModel.multiEmployedIndex = JSON.parse(JSON.stringify(index));
  };
  function removeMultiEmployed (index:any) {
    if (applicant.employer.multiemployed.employment[index].id === loanModel.multiemployedEmployment.id) {
      loanModel.showMultiEmployedForm = false;
    }
    applicant.employer.multiemployed.employment.splice(index, 1);
  };

  function cancelMultiEmployed() {
    loanModel.showMultiEmployedForm = false;
    loanModel.multiemployedEmployment = {};
  };
  function publishDraft() {

    if (!checkValidation()) {
      return;
    }
    loanModel.application.bankparticulars.logindate = moment(new Date()).format('dd-MM-yyyy hh:mm:ss a');
    loanModel.status = 'DRAFT';
    loanModel.userid = user.F_USERID;
    if (loanModel.application.loanamount > 15000 && loanModel.application.affordableemi > 800) {
      var oldApplicantsId: any = [];
      var oldApplicantId = 0;
      if (applicant.id!==null) {
        oldApplicantId = applicant.id;
      }
      applicant.business.employees = JSON.parse(JSON.stringify(bizEmployee));
      if (!isDefined(applicant.business.kiranastore)) {
        applicant.business.kiranastore = {};
      } else {
        applicant.business.kiranastore.capitalinvestment = JSON.parse(JSON.stringify(capitalinvestment));
        applicant.business.kiranastore.kiranasales = JSON.parse(JSON.stringify(kiranasales));
      }
      //agent
      if (!isDefined(applicant.business.agent)) {
        applicant.business.agent = {dmstrategies:{},leads:{},part:{},website:{}};
      }
      if (!isDefined(applicant.business.laundry)) {
        applicant.business.laundry = {service:{},market:{},serviceoffered:{},irontype:{},typoffabrics:{},dryingarea:{},deliveryvehicle:{},equipments:{}};
      }
      var req = JSON.parse(JSON.stringify(loanModel));
      console.log("clothing income req = " + JSON.stringify(req));
      if ((req.applicants!==null) && (req.applicants.length >= 1)) {
        for (var i = 0; i < req.applicants.length; i++) {
          if (req.applicants[i].id!==null) {
            oldApplicantsId.push(req.applicants[i].id);
          }
          if (req.applicants[i].dob!==null) {
            var dob = moment(Date.parse(req.applicants[i].dob)).format('dd/MM/yyyy');
            req.applicants[i].dob = JSON.parse(JSON.stringify(dob));
          }
          if ((req.applicants[i].type==='informal_selfemployed') && (req.applicants[i].businessincome !== null)) {
            req.applicants[i].subtype = getSelfEmployedApplicantSubtype(req.applicants[i].businessincome.consideritrincome);
          }
          if (req.applicants[i].type==='informal_salaried') {
            req.applicants[i].subtype = validateEmployerSubType(req.applicants[i].employer.constitution, req.applicants[i].employer.employmenttype, req.applicants[i].employer.salarymode, req.applicants[i].employer.salarydeductions);
          }
          if (req.applicants[i].id === applicant.id) {
            if (req.applicants[i].business !== null && req.applicants[i].business.profile !== null) {
              if (req.applicants[i].business.profile==='Beauty Parlour / Spa / Hair Saloon' && req.applicants[i].business.beautyparlour!==null) {
                if (!req.applicants[i].business.beautyparlour.isfranchise) {
                  req.applicants[i].business.beautyparlour.franchisename = null
                }
                if (req.applicants[i].business.beautyparlour.salonsetup!=='Shop' && req.applicants[i].business.beautyparlour.salonsetup!=='Working from Home' && req.applicants[i].business.beautyparlour.salonsetup!=='Door Step Service / Home Visit only') {
                  req.applicants[i].business.beautyparlour.noofchairs = null
                  req.applicants[i].business.beautyparlour.airconditionshop = false
                }
                if (!req.applicants[i].business.beautyparlour.isattachedplatform) {
                  req.applicants[i].business.beautyparlour.eplatforms.housejoy = false
                  req.applicants[i].business.beautyparlour.eplatforms.justdail = false
                  req.applicants[i].business.beautyparlour.eplatforms.quikreasy = false
                  req.applicants[i].business.beautyparlour.eplatforms.sulekha = false
                  req.applicants[i].business.beautyparlour.eplatforms.urbancompany = false
                  req.applicants[i].business.beautyparlour.eplatforms.zimmber = false
                }
                if (req.applicants[i].business.profile === 'Agent  - Insurance' && req.applicants[i].business.agent !== null) {
                  // if(!req.applicants[i].business.agent.nature){
                  //   req.applicants[i].business.agent.nature.excl=false
                  //   req.applicants[i].business.agent.nature.nonexcl=false
                  //   // req.applicants[i].business.agent.agentnature.quikreasy=false
                  //   // req.applicants[i].business.agent.agentnature.sulekha=false
                  //   // req.applicants[i].business.agent.agentnature.urbancompany=false
                  //   // req.applicants[i].business.agent.agentnature.zimmber=false
                  //  }
                }
              }
            }
            if (req.applicants[i].business !== null && req.applicants[i].business.profile !==null) {
              resetTutor(i, req);
              resetPriest(i, req);
              resetKirana(i, req);
            }
            req.applicants[i].eligibility.lcr = applicant.eligibility.lcr;
          }
        }
      }
      //  req.userid="";
      // console.log("we are here in which userid is "+req.userid)
      if (req.userid === null || req.userid === '') {
        req.userid = user && user.userId ? user.userId : '';
        // console.log("This is new s:et userid "+req.userid);
      }
      // console.log("sending data:"+JSON.stringify(req));
      const headers = {
        "Authorization": "Bearer " + user.token,
        "Content-Type": "application/json"
      }

      const config: AxiosRequestConfig = {
        headers: headers
      };
      axios.post(
        process.env.REACT_APP_API_URL + "/app/reach",
        loanModel,
        config
      ).then((response: any) => {
        //  console.log("Got Response: " + JSON.stringify(response.data)) ;
        displayMessage("Your application has been saved.");
        //save();
        loanModel = response.data;
        orderApplicants();
        if (loanModel.applicantIndex !== -1) {
          setSelectedRow(oldApplicantId, oldApplicantsId);
          setApplicantView(true);
          var applicant = showDob(loanModel.applicants[loanModel.applicantIndex]);
          loanModel.applicants[loanModel.applicantIndex] = applicant;
          applicant = loanModel.applicants[loanModel.applicantIndex];
          if (applicant.business === null) {
            applicant.business = {};
          }
          if (!isDefined(applicant.business.kiranastore) && (applicant.business.kiranastore.capitalinvestment.length >= 1)) {
            loanModel.capitalinvestment = applicant.business.kiranastore.capitalinvestment;
            loanModel.kiranasales = JSON.parse(JSON.stringify(applicant.business.kiranastore.kiranasales));
            calculatekiranasales();
          }
          if (!isDefined(applicant.business.tailor)) {
            applicant.business.tailor = {};
          }
          if (!isDefined(applicant.business.vegetablefruit)) {
            applicant.business.vegetablefruit = {produce:{purchase:{},selling:{}},difficulties:{purchase:{},selling:{}},delivery:{},customertype:{}};
          }
          if (!isDefined(applicant.business.beautyparlour)) {
            applicant.business.beautyparlour = {eplatforms:{},salonequipment:{},service:{}};
          }
          if (!isDefined(applicant.business.craftsman)) {
            applicant.business.craftsman = {}
          }
          if (!isDefined(applicant.business.dairy)) {
            applicant.business.dairy = {};
          }
          if (!isDefined(applicant.business.dairy.product)) {
            applicant.business.dairy.product = {};
          }
          if (!isDefined(applicant.business.garage)) {
            applicant.business.garage = {services:{},certificates:{},customer:{},valueadds:{}};
          }
          if (!isDefined(applicant.business.handloom)) {
            applicant.business.handloom = {registration:{},customer:{},specialisation:{},loomtype:{},activities:{}};
          }
          if (!isDefined(applicant.business.tutor)) {
            applicant.business.tutor = {};
          }
          if (!isDefined(applicant.business.priest)) {
            applicant.business.priest = {};
          }
          if (!isDefined(applicant.business.transport)) {
            applicant.business.transport = {};
          }
          if (!isDefined(loanModel.transportVehicle)) {
            loanModel.transportVehicle = {};
          }
          if (!isDefined(loanModel.TransportMachine)) {
            loanModel.TransportMachine = {};
          }
          if (!isDefined(applicant.business.mobile)) {
            applicant.business.mobile = {mobilelocalityofbiz:{}, service:{},bank:{},activities:{}, product:{},source:{},mobaccessories:{},repair:{},authorized:{},benefit:{},promotional:{},accessories:{},inventory:{},adhar:{},equipment:{}};
          }
          // if (applicant.business!==null && applicant.business.transport.maintainence!==null && applicant.business.transport.maintainence.length >= 1) {
          //   transmaintainence = JSON.parse(JSON.stringify(applicant.business.transport.maintainence);
          // }
          // if(applicant.business.transport!==null && applicant.business.transport.insuranceDocuments!==null){
          //   checkTransportBusinessModel();
          //  }
          //  if(applicant.business.transport!==null && applicant.business.transport.licenseDocuments!==null){
          //   checkTransportLicenseModel();
          //  }
          if (!isDefined(applicant.business.livestock)) {
            applicant.business.livestock = {};
          }
          if (!isDefined(applicant.business.xerox)) {
            applicant.business.xerox = {valueservicesadded:{}};
          }
          if (!isDefined(applicant.business.agent)) {
            applicant.business.agent = {dmstrategies:{},leads:{},part:{},website:{}};
          }
          if (!isDefined(applicant.business.clothing)) {
            applicant.business.clothing = {type:{},price:{}};
          }
          if (!isDefined(applicant.business.streetfood)) {
            applicant.business.streetfood = {documentation:{}, streetfoodvendorcategory:{},foodcategory:{},type:{},howisfoodtransported:{},sourceoffood:{},power:{},season:{},location:{},streetfoodequipment:{},customertype:{},largecustomer:{},foodapptype:{},largecustomertype:{}};
          }
          if (!isDefined(applicant.business.laundry)) {
            applicant.business.laundry = {service:{},market:{},serviceoffered:{},irontype:{},typoffabrics:{},dryingarea:{},deliveryvehicle:{},equipments:{}};
          }
          //sweet
          if (!isDefined(applicant.business.sweet)) {
            applicant.business.sweet = {};
          }//sweet
          if (!isDefined(applicant.employer)) {
            applicant.employer = {};
          }
          if (!isDefined(applicant.employer.multiemployed)) {
            applicant.employer.multiemployed = {};
          }
          if (!isDefined(loanModel.multiemployedEmployment)) {
            loanModel.multiemployedEmployment = {};
          }
          if (!isDefined(applicant.income.otherincomes)) {
            checkOtherIncome();
          }
          if ((applicant.businessincome!==null) && (applicant.businessincome.consideritrincome || applicant.businessincome.itrs!==null)) {
            setITR();
          }
          getYear();
          getNatureOfWorkFlag();
          updatePropertyTypeOptionsAndCheckLapSelected();
          recommendedhouseholdrent();
          //getApplicantPhoto(applicant.applicantphoto ? applicant.applicantphoto : undefined);
        }
        else {
          initialize();
        }
        setRemarks();

      })
        .catch(function (error) {
          displayMessage("Your application has not been saved. Please retry.");
          console.log("Call to save Draft Failed. Please retry.");
        });
    } if (loanModel.application.loanamount <= 15000 || loanModel.application.loanamount===null) {
      displayMessage('The Loan amount must be greater than 15000');
    } if (loanModel.application.affordableemi <= 800 || loanModel.application.affordableemi===null) {
      displayMessage('The affordable EMI must be greater than 800');
    }
  }
  function checkApplicantEligibility() {
    loanModel.check.applicant.monthlyamount = 0.0;
    loanModel.check.applicant.totalotherincome = 0.0;

    if (applicant.type==='informal_selfemployed') {
      if (applicant.businessincome!==null) {
        loanModel.check.applicant.totalotherincome = loanModel.totalbizotherincome;
        if (applicant.businessincome.consideritrincome) {
          loanModel.check.applicant.monthlyamount = applicant.businessincome.totalincome;
          loanModel.check.applicant.totalincome = loanModel.check.applicant.monthlyamount + loanModel.check.applicant.totalotherincome;
        }
        else {
          loanModel.check.applicant.monthlyamount = applicant.businessincome.totalincome;
          loanModel.check.applicant.totalincome = loanModel.check.applicant.monthlyamount + loanModel.totalbizotherincome;
        }
        applicant.eligibility.totalotherincome = loanModel.check.applicant.totalotherincome;
        applicant.eligibility.monthlyincome = loanModel.check.applicant.monthlyamount;
        applicant.eligibility.monthlytotalincome = JSON.parse(JSON.stringify(loanModel.check.applicant.totalincome));
      }

    }
    else  if(applicant.type==='informal_salaried'){
      loanModel.check.applicant.totalotherincome = loanModel.totalotherincome;
      loanModel.check.applicant.monthlyamount  =  applicant.income.monthlyamount;
      loanModel.check.applicant.totalincome = applicant.income.monthlyamount + loanModel.check.applicant.totalotherincome;
      applicant.eligibility.totalotherincome=loanModel.check.applicant.totalotherincome;
      applicant.eligibility.monthlyincome=loanModel.check.applicant.monthlyamount;
      applicant.eligibility.monthlytotalincome=JSON.parse(JSON.stringify(loanModel.check.applicant.totalincome));
    }
      calApplicantObligation();
      calLoanApplicantEligibility();
      checkApplicantRecommendation();
  }
  function publish() {
    var appliacantlength = loanModel.applicants.length;
    for (var k = 0; k < appliacantlength; k++) {
      showApplicant(k);
      calApplicantObligation();
      checkApplicantEligibility();
      calLoanApplicantEligibility();
      // checkApplicantRecommendation();
    }
    var req = JSON.parse(JSON.stringify(loanModel));
    if (req.applicants.length >= 1) {
      for (var i = 0; i < req.applicants.length; i++) {
        if (req.applicants[i].dob!==null) {
          req.applicants[i].dob = moment(Date.parse(req.applicants[i].dob)).format('dd/MM/yyyy');
        }
        if (req.applicants[i].type==='informal_selfemployed') {
          req.applicants[i].subtype = getSelfEmployedApplicantSubtype(req.applicants[i].businessincome.consideritrincome);
          loanModel.applicants[i].subtype = req.applicants[i].subtype;
          if (req.applicants[i].business.profile!=='Small Kirana / Provision / Pan Store') {
            req.applicants[i].business.kiranastore = null;
          }
        }
        if (req.applicants[i].type==='informal_salaried') {
          req.applicants[i].subtype = validateEmployerSubType(req.applicants[i].employer.constitution, req.applicants[i].employer.employmenttype, req.applicants[i].employer.salarymode, req.applicants[i].employer.salarydeductions);
          loanModel.applicants[i].subtype = req.applicants[i].subtype;
        }
      }
    }
    //var req = loanModel;
    validateProfiling();
    if (!loanModel.error) {
      const headers = {
        "Authorization": "Bearer " + user.token,
        "Content-Type": "application/json"
      }

      const config: AxiosRequestConfig = {
        headers: headers
      };
      axios.post(
        process.env.REACT_APP_API_URL + "/app/reach/finalize",
        loanModel,
        config
      ).then((response: any) => {
        ///	console.log("Got Response: " + JSON.stringify(response.data));
        displayMessage("Your application has been finalized and submitted for Profiling.");

      })
        .catch(function (error) {
          displayMessage("Your application has not been finalized. Please retry.");
          console.log("Call to save Failed. Please retry.");
        });
    }
    else {
      loanModel.showErrorTab();
    }
  }
  function validateProfiling() {
    if (!loanModel.error) {
      const headers = {
        "Authorization": "Bearer " + user.token,
        "Content-Type": "application/json"
      }

      const config: AxiosRequestConfig = {
        headers: headers
      };
      axios.post(
        process.env.REACT_APP_API_URL + "/app/reach/finalize",
        loanModel,
        config
      ).then((response: any) => {
        ///	console.log("Got Response: " + JSON.stringify(response.data));
        displayMessage("Your application has been finalized and submitted for Profiling.");

      })
        .catch(function (error) {
          displayMessage("Your application has not been finalized. Please retry.");
          console.log("Call to save Failed. Please retry.");
        });
    }
    else {
      //showErrorTab();
    }
  }
  function addExpense() {
    var oI = {item:"Other Expense", amount:0, type:"OTHER"};
    loanModel.householdexpense.expenses.push(oI);
  }
  function removeExpense(idx:any) {
    loanModel.householdexpense.expenses.splice(idx, 1);
    calculateExpenses();
  }
  function locateAddress(){
    setTimeout(function(){ 
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position){
          if(position!=null){
           
            loanModel.locationallowed=true;
            loanModel.position = position;
            loanModel.lat=loanModel.position.coords.latitude;
            loanModel.lang=loanModel.position.coords.longitude;
            if(loanModel.locationallowed){
              var formdata=new FormData();
              formdata.append("lat",loanModel.lat);
              formdata.append("lang",loanModel.lang);
              const headers = {
                "Authorization": "Bearer " + user.token,
                "Content-Type": "application/json"
              }
        
              const config: AxiosRequestConfig = {
                headers: headers
              };
              axios.post(
                process.env.REACT_APP_API_URL + "/app/location/",
                loanModel,
                config
              ).then((response: any) => {
              
                loanModel.lat="";
                loanModel.lang="";
                if(response!=null){
                  if(loanModel.loanModel.property.address==undefined){
                    loanModel.property.address={};
                  }
                  loanModel.property.address.city=response.data.city;
                  loanModel.property.address.district=response.data.district;
                  loanModel.property.address.address1=response.data.address1;
                  loanModel.property.address.address2=response.data.address2;
                  loanModel.property.address.taluka=response.data.taluka;
                  loanModel.property.address.zipcode=response.data.zipcode;
                  let states = getLookupProperty('state')
                  for(var i=0;i<states.length;i++){
                    if(response.data.state==states[i].type){
                      loanModel.property.address.state=states[i].code;
                    }
                  }
                }
              });
            }
            
          }
        },function(error) {
          if (error.code===error.PERMISSION_DENIED)
            console.log("Allow location to track");
            window.alert("Failed to detect the location, kindly allow permission for your browser to locate the device's location or check your internet connectivity.");
            loanModel.locationallowed=false;
        });
      }  
    }, 500);
  
  }
  function getValue(obj:any, prop:any){
    if(obj!=undefined && obj != null && obj[prop] !=undefined )
      return obj[prop];
    return '';
  }

  function addLivestockMachine () {
    if (!isDefined(applicant.business.livestock.machines)) {
      applicant.business.livestock.machines = [];
      loanModel.showLivestockMachineForm = true;
      loanModel.LivestockMachine = {};
    }
    else if ((applicant.business.livestock.machines != null)) {
      loanModel.showLivestockMachineForm = true;
      loanModel.LivestockMachine = {};
    } 
  }
  
  function saveLivestockMachine() {
    if (!isDefined(applicant.business.livestock.machines)) {
      applicant.business.livestock.machines = [];
    }
    if(!isDefined(loanModel.LivestockMachine.id)){
      applicant.business.livestock.machines.push(JSON.parse(JSON.stringify(loanModel.LivestockMachine)));
    }else if(isDefined(loanModel.LivestockMachine.id)){
      let idx = applicant.business.livestock.machines.findIndex((e:any)=>e.id == loanModel.LivestockMachine.id);
      applicant.business.livestock.machines[idx] = JSON.parse(JSON.stringify(loanModel.LivestockMachine));
    }
    loanModel.LivestockMachine = {};
    loanModel.LivestockMachineIndex = null;
    loanModel.showLivestockMachineForm = false;
  }
  
  function showLivestockMachine(index:any) {
    loanModel.LivestockMachine = JSON.parse(JSON.stringify(applicant.business.livestock.machines[index]));
    loanModel.showLivestockMachineForm = true;
    loanModel.LivestockMachineIndex = index;
  }
  function removeLivestockMachine(index:any) {
    if (applicant.business.livestock.machines[index].id == loanModel.LivestockMachine.id) {
      loanModel.showLivestockMachineForm = false;
    }
    applicant.business.livestock.machines.splice(index, 1);
  }
  
  function cancelLivestockMachine () {
    loanModel.showLivestockMachineForm = false;
    loanModel.LivestockMachine = {};
  }
  
  function livestockMachineConfirmation () {
    if (isDefined(applicant.business.livestock.machines) && applicant.business.livestock.machines.length >= 1) {
      applicant.business.livestock.machines = [];
      applicant.business.livestock.machinecheck = false;
    }
  }
  // LiveStock Machines End 
  
  // LiveStock Animal Start
  function addLivestockAnimal() {
    if (!isDefined(applicant.business.livestock.animal)) {
      applicant.business.livestock.animal = [];
      loanModel.showLivestockAnimalForm = true;
      loanModel.LivestockAnimal = {};
    }
    else {
      loanModel.showLivestockAnimalForm = true;
      loanModel.LivestockAnimal = {};
    }
  }
  
  function saveLivestockAnimal () {
    if (!isDefined(applicant.business.livestock.animal)) {
      applicant.business.livestock.animal = [];
    }
    var copy = JSON.parse(JSON.stringify(loanModel.LivestockAnimal));
    if(!isDefined(copy.id)){
      applicant.business.livestock.animal.push(copy);
    }else{
      let idx = applicant.business.livestock.animal.findIndex((e:any)=>e.id == loanModel.LivestockAnimal.id);
      applicant.business.livestock.animal[idx] = copy;
    }
    loanModel.LivestockAnimal = {};
    loanModel.LivestockAnimalIndex = null;
    loanModel.showLivestockAnimalForm = false;
  }
  
  function showLivestockAnimal(index:any) {
    
    loanModel.LivestockAnimal = JSON.parse(JSON.stringify(applicant.business.livestock.animal[index]));
    loanModel.showLivestockAnimalForm = true;
    loanModel.LivestockAnimalIndex = index;
  }
  function removeLivestockAnimal(index:any) {
    if (applicant.business.livestock.animal[index].id == loanModel.LivestockAnimal.id) {
      loanModel.showLivestockAnimalForm = false;
    }
    loanModel.applicant.business.livestock.animal.splice(index, 1);
  }
  
  function cancelLivestockAnimal() {
    loanModel.showLivestockAnimalForm = false;
    loanModel.LivestockAnimal = {};
  }
  
  function LivestockAnimalConfirmation() {
    if (isDefined(applicant.business.livestock.animal) && applicant.business.livestock.animal.length >= 1) {
      applicant.business.livestock.animal = [];
      applicant.business.livestock.animalcheck = false;
    }
  }
  
  function calculatelivestockincomeTotal(rate:any,cost:any){
    var total =0;
    if(rate>=0 && cost>=0){
      total =  rate * cost; 
    }
    return total;
  
  }
  function parseVal(val:any){
    if(!isDefined(val)){
      return 0
    }
    return parseFloat(val+"");
  }
  function calculateLivestockInvestmenttable(){
    applicant.business.livestock.capitalInvestment = parseVal(applicant.business.livestock.investmentamount1) + parseVal(applicant.business.livestock.investmentamount2) +parseVal(applicant.business.livestock.investmentamount3)
  }

  function addClothingIncome(){
    if(!isDefined(applicant.business.clothing.income)){
      applicant.business.clothing.income = [];
    }
    var l=applicant.business.clothing.income.length;
    
    if(l<30){
      loanModel.clothingincomeMode="ADD";
      loanModel.clothingincomeForm=true;
    }else{
      loanModel.clothingincomeError=true;
    }
    loanModel.clothingincome={};
    setLoan(loanModel);
  }
  function saveClothingIncome(){
    var copy=JSON.parse(JSON.stringify(loanModel.clothingincome));
    
    if(!isDefined(applicant.business.clothing.income)){
      applicant.business.clothing.income=[];
    }
    
    switch(loanModel.clothingincomeMode){
      case 'ADD':
        applicant.business.clothing.income.push(copy);
        break;
      case 'EDIT':
        applicant.business.clothing.income[loanModel.clothingIndex]=copy;
        break;
    }
    loanModel.clothingincome={};
    loanModel.clothingincomeForm=false;
    loanModel.clothingincomeError=false;
    loanModel.clothingIndex=null;
    setLoan(loanModel);
  }
  function cancleClothingIncome(){
    loanModel.clothingincomeForm=false;
    loanModel.clothingincome={};
    loanModel.clothingincomeError=false;
    loanModel.clothingIndex=null;
    setLoan(loanModel);
  }
  function showClothingIncome(index:any){
    loanModel.clothingincomeMode='EDIT';
    loanModel.clothingIndex=index;
    var copy=JSON.parse(JSON.stringify(applicant.business.clothing.income[index]));
    loanModel.clothingincome=copy;
    loanModel.clothingincomeForm=true;
    loanModel.clothingincomeError=false;
    setLoan(loanModel);
  }
  function removeClothing(index:any){
    loanModel.clothingincomeError=false;
    applicant.business.clothing.income.income.splice(index,1);
    setLoan(loanModel);
  }

  // Mobile start
// Mobile Machine Start
  function addMobileMachine(){  
    if(!isDefined(applicant.business.mobile.machines)){
      applicant.business.mobile.machines=[];
    }
    loanModel.showMobileMachineForm=true;
    loanModel.mobileMachine={};
  }

  function saveMobileMachine(){
    if(!isDefined(applicant.business.mobile.machines)){
      applicant.business.mobile.machines=[];
    }
    var copy=JSON.parse(JSON.stringify(loanModel.mobileMachine));
    if(!isDefined(copy.id)){
      applicant.business.mobile.machines.push(copy);
    } else {
      let idx = applicant.business.mobile.machines.findIndex((e:any)=>e.id === copy.id);
      if(idx !=-1){
        applicant.business.mobile.machines[idx]=copy;
      }
    }
    loanModel.mobileMachine={};
    loanModel.mobileMachineIndex=null;
    loanModel.showMobileMachineForm=false;
  }

  function showMobileMachine(index:any){
    var copy=JSON.parse(JSON.stringify(applicant.business.mobile.machines[index]));
    loanModel.mobileMachine=copy;
    loanModel.showMobileMachineForm=true;
    loanModel.mobileMachineIndex = index;
  }
  function removeMobileMachine(index:any){
    if(applicant.business.mobile.machines[index].id==loanModel.mobileMachine.id){
      loanModel.showMobileMachineForm=false;
    }
    applicant.business.mobile.machines.splice(index,1);
  }

  function cancelMobileMachine(){
    loanModel.showMobileMachineForm=false;
    loanModel.mobileMachine={};
  }

  function mobileMachineConfirmation(){
    if(isDefined(applicant.business.mobile.machines) && applicant.business.mobile.machines.length>=1){
        applicant.business.mobile.machines=[];
        applicant.business.mobile.machinecheck=false;
      }
  }

  //mobile machine end
  //mobile functions start
  function calculateMobileTotal(time:any, days:any, average:any, cost:any){
    var total =0;
    if(time=='DAILY'){
      total = days * average * cost; 
    }else if(time=='WEEKLY'){
      total = 4 * average * cost; 
    }else {
      total = average * cost;
    }
    return total;
  }

  function setmobiledays(time:any, days:any ){
    var day='';
    if(time=='DAILY'){
    day=days;
    }else {
      day =''
    }
    return day;
  }
//mobile functions end
//Mobile End

  return (
    <>
      <SideMenu />
      <div className="content-wrapper-user-tab">
        <div className="col-md-4 col-lg-6 mb-2 mt-1">
          <div className="title-header">
            <h2>Loan</h2>
          </div>
        </div>


        <Tabs
          id="main-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k || 'loanInfo')}
          className="custom-tabs"
        >
          <Tab eventKey="loanInfo" title="Loan Information" tabClassName="outerTabs">
            <div className="tab-content">

              <img style={{ display: loading ? 'block' : 'none' }} className="loading-img" src={`${process.env.PUBLIC_URL}/loading-wheel.gif`} alt="" width="90" height="90" />

              <div className="container mt-5">
                <h3>Loan Summary</h3>
                <hr></hr>
                <Form>
                  <Row className="mb-3">
                    <Col md={3}>
                      <Form.Group controlId="loanAmountRequested">
                        <Form.Label className="custom-form-label">Loan Amount Requested *</Form.Label>
                        <Form.Control name="loanAmountRequested"  onChange={(event) => { 
                          loanModel.application.loanamount = event.target.value ;
                          setLoan(loanModel);
                          }} style={{ fontSize: '16px', height: '40px' }} min={15000} type="number"  defaultValue={getValue(loanModel.application,'loanamount')}/>
                        {errors.loanAmountRequested && <p style={{ color: 'red' }}>{errors.loanAmountRequested}</p>}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group controlId="customerAffordableEmi">
                        <Form.Label className="custom-form-label">Customer Affordable EMI *</Form.Label>
                        <Form.Control name="customerAffordableEmi" onChange={(event) => { 
                          loanModel.application.affordableemi = event.target.value;
                          setLoan(loanModel); 
                          }} style={{ fontSize: '16px', height: '40px' }} type="number" min="800" defaultValue={getValue(loanModel.application,'affordableemi')} />
                        {errors.customerAffordableEmi && <p style={{ color: 'red' }}>{errors.customerAffordableEmi}</p>}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group controlId="purposeOfLoan">
                        <Form.Label className="custom-form-label">Purpose of Loan *</Form.Label>
                        <Form.Select name="purposeOfLoan" defaultValue={getValue(loanModel.application,'loanpurpose')} onChange={(event) => {
                          loanModel.application.loanpurpose = event.target.value;
                          loanModel.property.natureofworkflag = loanModel.application.loanpurpose === 'EXT' || loanModel.application.loanpurpose === 'IMP';
                          loanModel.property.propertyselected = loanModel.property.natureofworkflag || loanModel.application.loanpurpose === 'BALT';
                          updatePropertyTypeOptionsAndCheckLapSelected();
                          setLoan(loanModel);
                        }} size="lg" style={{ fontSize: '16px', height: '40px' }} aria-label="Select Purpose of loan">
                          {
                            getLookupProperty(user.usertype === 'CAPITAL INDIA' ? 'capitalloanPurposes' : 'loanPurposes').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                          }
                        </Form.Select>
                        {errors.purposeOfLoan && <p style={{ color: 'red' }}>{errors.purposeOfLoan}</p>}

                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group controlId="edbranch">
                        <Form.Label className="custom-form-label">Branch *</Form.Label>
                        <Form.Select name="edbranch" defaultValue={getValue(loanModel.application.bankparticulars.location,'branch')} onChange={(event) => { loanModel.application.bankparticulars.location.branch = event.target.value }} style={{ fontSize: '16px', height: '40px' }} size="lg" aria-label="Select the branch">
                          <option value="">Select Branch</option>
                          {
                            getLookupProperty(user.usertype === 'CAPITAL INDIA' ? 'branch' : 'edbranch').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                          }
                        </Form.Select>
                        {errors.edbranch && <p style={{ color: 'red' }}>{errors.edbranch}</p>}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Form.Group controlId="earningmembers">
                      <Form.Label className="custom-form-label" >Remarks</Form.Label>
                      <FormControl onChange={(e)=>{applicant.remarks = e.target.value;setLoan(loanModel)}} as="textarea" rows={6}
                        style={{ width: '100%', height: '120px' }} defaultValue={applicant.remarks}
                      />
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            </div>
            <div style={{ marginTop: '100px' }}>
              <span style={{ marginTop: '30px' }}>Applicant Number</span> | <span style={{ marginTop: '30px' }}>Login Date</span>
            </div>
          </Tab>

          <Tab eventKey="personal" title="Personal" tabClassName="outerTabs">
            <div className="tab-content">
              <div>
                <div className="col-md-4 col-lg-6 mb-2 mt-3">
                  <div className="title-header" style={{ marginBottom: '2px' }}>
                    <strong>Applicants
                      {!applicantView && <a style={{ cursor: "pointer", textDecoration: 'none', color: 'blue  !important' }} >
                        <i className="fa fa-plus fa-lg" aria-hidden="true" onClick={() => setAddApplicantDialog(true)}></i>
                      </a>}
                    </strong>
                    <Modal show={addApplicantDialog} onHide={() => setAddApplicantDialog(false)}>
                      <Modal.Header>
                        <Modal.Title>Add Applicant</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>Do you want to add a new Co-applicant?</Modal.Body>
                      <Modal.Footer>
                        <Button variant="success" onClick={() => {
                          setAddApplicantDialog(false);
                          setApplicantView(true);
                          loanModel.applicants.push(applicant);
                          showApplicant(loanModel.applicants.length-1);
                        }}>
                          Yes
                        </Button>
                        <Button variant="danger" onClick={() => { setAddApplicantDialog(false); }}>
                          No
                        </Button>

                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
                <div className="card-box mh-50">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col"><span style={{ fontSize: "200%", color: "yellow" }}>★</span></th>
                          <th scope="col">NAME</th>
                          <th scope="col">TYPE</th>
                          <th scope="col">DATE OF BIRTH</th>
                          <th scope="col">APPLICANT FOR</th>
                          <th scope="col">QUALIFICATION</th>
                        </tr>
                      </thead>
                      <tr ng-show="vm.loanModel.applicants.length < 1">
                        <td colSpan={6} className="align-center"><span className="col-lg-4 col-sm-6 col-xs-8 col-lg-offset-5 col-sm-offset-5 col-xs-offset-4">No Applicants Registered</span></td>
                      </tr>
                      {loanModel.applicants && loanModel.applicants.length>0 && loanModel.applicants.map((ele:any, i:any)=>(
                        <tr className={i===loanModel.selectedRow ? 'bg-primary':''}  onClick={()=>{showApplicant(i);loanModel.applicantMode='EDIT';loanModel.NewCount=0;}} >
                            <td><span hidden={ele.primary !== 1} className="starred"><i className="fa fa-star"></i></span></td>
                            <td className="name">{ele.firstname} {ele.lastname}</td>
                            <td>{getTypeValue('APPLICANT_TYPE',ele.type)}</td>
                            <td>{ele.dob}</td>
                            <td className="hidden">{ele.customerno}</td>
                            <td>{getTypeValue('APPLICANTFOR',ele.applicantfor)}</td>
                            <td>{getTypeValue('QUALIFICATION',ele.qualification)}</td>
                            <td hidden={ele.reportstatus !== 1}>
                              <button hidden={ele.primary===1} onClick={()=>deleteApplicant(ele.id)} className="btn btn-black">
                                  <i className="fa fa-trash"></i> Delete
                              </button>
                            </td>
                          </tr>
                      ))}
                    </table>
                  </div>
                </div>

                {applicantView &&
                  <Tabs
                    activeKey={subTabkey}
                    onSelect={(k) => setSubTabKey(k || 'applicant')}
                    className="custom-tabs"
                  >
                    <Tab eventKey="applicant" title="Applicant">
                      <div className="tab-content">
                        <div className="tab-content custom-tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="tab5" role="tabpanel" aria-labelledby="tab5-tab">
                            <Form className="page-section">
                              <Row className="mt-3">
                                <Col md={3}>
                                  <Form.Check inline label="Primary Applicant" name="primary" type='checkbox' id='primary' onChange={(e) => {
                                    applicant.primary = e.target.checked;
                                    if(!applicant.primary){
                                      applicant.relationship='';
                                    }
                                    setLoan(loanModel);
                                  }} checked={applicant.primary} />
                                </Col>
                                { !applicant.primary && <Col md={4}>
                                  <Form.Group controlId="">
                                    <Form.Label className="custom-form-label">Relationship between applicants:</Form.Label>
                                    <Form.Select className="form-control" defaultChecked={applicant.relationship} onChange={(e) => {
                                      applicant.relationship = e.target.value;

                                      if (e.target.value) {
                                        checkApplicantPrimary();
                                      }
                                      setLoan(loanModel);
                                    }} name="applicantsRelationship" value={applicant.relationship}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('applicantsRelationship').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col> }
                                { applicant.relationship==='Others' && <Col md={4}>
                                  <Form.Group controlId="otherrelationship">
                                    <Form.Label className="custom-form-label">Please Specify:</Form.Label>
                                    <FormControl type="text" name="otherrelationship" defaultValue={applicant.otherrelationship} onChange={(e) => { applicant.otherrelationship = e.target.value; }
                                    } />

                                  </Form.Group>
                                </Col> }
                              </Row>
                              <Row className="mt-3">
                                <Col md={3}>
                                  <Form.Group controlId="" style = {{}}>
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.applicantfor,applicant.applicantfor)}>On Application For*:</Form.Label>

                                    <Form.Select defaultValue={applicant.applicantfor} style={errorStyle(isTouched.applicantfor,applicant.applicantfor)}  className="form-control" name="applicantfor"  onChange={(e) => {
                                      applicant.applicantfor = e.target.value;
                                      
                                      setLoan(loanModel);
                                    }} onBlur={()=>console.log((!isDefined(applicant.applicantfor) || applicant.applicantfor ===''))}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('applicantsFor').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" style={errorFont(isTouched.type,applicant.type)} >Applicant Type*:</Form.Label>
                                  <Form.Select defaultValue={applicant.tye} className="form-control" style={errorStyle(isTouched.type,applicant.type)}   disabled={!isDefined(applicant.applicantfor) || applicant.applicantfor ===''} name="type" onChange={(e) => {
                                    if (user.usertype = 'CAPITAL INDIA' && !applicant.primary) {
                                      applicant.type = null;
                                    } else {
                                      applicant.type = e.target.value;
                                    }
                                    changeApplicantType();
                                    
                                    setLoan(loanModel);
                                  }}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('applicantTypes').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Col>
                                <Col md={3}>
                                  <Form.Group >
                                  <Form.Check inline label="Head Of Family" name="familyHead" id="familyHead" onChange={(e)=>{ 
                                      applicant.familyHead = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={applicant.familyHead}/>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="qualification">
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.qualification,applicant.qualification)}>Qualification *</Form.Label>
                                    <Form.Select className="form-control" defaultValue={applicant.qualification} style={errorStyle(isTouched.qualification,applicant.qualification)} onChange={(e) => {
                                      applicant.qualification = e.target.value;setLoan(loanModel)}} name="qualification" value={applicant.qualification}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('qualifications').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>

                              <Row className="mt-3">
                                <Col md={3}>
                                  <Form.Group controlId="firstname">
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.firstname,applicant.firstname)}>First Name</Form.Label>
                                    <FormControl style={errorStyle(isTouched.firstname,applicant.firstname)}  type="text" onKeyDown={allowAlphabetsSpacesFullstop} onChange={(e)=>{ applicant.firstname = e?.target.value;setLoan(loanModel)}} name="firstname" defaultValue={applicant.firstname}/>
                                  </Form.Group>
                                </Col>

                                <Col md={3}>
                                  <Form.Group controlId="lastName">
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.lastname,applicant.lastname)} >Last Name</Form.Label>
                                    <FormControl style={errorStyle(isTouched.lastname,applicant.lastname)}  type="text" name="lastname" onKeyDown={allowAlphabetsSpacesFullstop} onChange={(e)=> {applicant.lastName = e?.target.value;setLoan(loanModel)}} defaultValue={applicant.lastname} />
                                  </Form.Group>
                                </Col>

                                <Col md={3}>
                                  <Form.Group controlId="dob">
                                    <Form.Label className="custom-form-label" >Date Of Birth:</Form.Label>
                                    <FormControl type="date" className="form-control" name="dob" onChange={(e)=> {
                                      applicant.dob = e?.target.value;
                                      setLoan(loanModel);
                                    }} defaultValue={applicant.dob} />
                                  </Form.Group>
                                </Col>

                                <Col md={3}>
                                  <Form.Group controlId="lastName">
                                    <Form.Label className="custom-form-label" >Gender</Form.Label>
                                    <Form.Select className="form-control" onChange={(e)=> {applicant.gender = e?.target.value;setLoan(loanModel)}} name="gender" defaultValue={applicant.gender}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('Gender').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>

                              <Row className="mt-3">
                                <Col md={4}>
                                  <Form.Group controlId="zipcode">
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.zipcode,applicant.address.zipcode)} >Pin Code</Form.Label>
                                    <FormControl style={errorStyle(isTouched.zipcode,applicant.address.zipcode)}   type="number" min="0" max="9999" className="form-control" onChange={(e)=> {
                                      applicant.address.zipcode = e?.target.value;
                                      getpincodelocation();
                                      applicant.address.distance=false;
                                    }} name="zipcode" defaultValue={applicant.address.zipcode} />
                                  </Form.Group>
                                </Col>
                                <Col md={2} >
                                  <Form.Group controlId="fetch">
                                    <Button className="form-control" style={{ color: "#fff", backgroundColor: "#ed1d25", borderColor: "#df121a", marginTop: "33px", marginLeft: "18px", width: "100px" }} onClick={()=>{
                                      getpincodelocation();
                                      applicant.address.distance=false;
                                      
                                    }}>Fetch</Button>
                                  </Form.Group>
                                </Col>
                              </Row>

                              <Row className="mt-3">
                                <Col md={4}>
                                  <Form.Group controlId="address1">
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.address1,applicant.address.address1)} >Address Line 1 *</Form.Label>
                                    <FormControl style={errorStyle(isTouched.address1,applicant.address.address1)}  type="text" name="address1" onKeyDown={validateText} onChange={(e)=> {applicant.address.address1 = e?.target.value;setLoan(loanModel)}}
                                     defaultValue={applicant.address.address1} />
                                  </Form.Group>
                                </Col>
                                <Col md={4}>
                                  <Form.Group controlId="address2">
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.address2,applicant.address.address2)} >Address Line 2 *</Form.Label>
                                    <FormControl style={errorStyle(isTouched.address2,applicant.address.address2)}  type="text" name="address2" onKeyDown={validateText} onChange={(e)=> {applicant.address.address2 = e?.target.value;setLoan(loanModel)}}
                                     defaultValue={applicant.address.address2} />
                                  </Form.Group>
                                </Col>
                                <Col md={4}>
                                  <Form.Group controlId="state">
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.state,applicant.address.state)}>State / Union Territory *</Form.Label>
                                    <FormControl style={errorStyle(isTouched.state,applicant.address.state)}  type="text" name="state" onKeyDown={validateText} onChange={(e)=> {applicant.address.state = e?.target.value;setLoan(loanModel)}}
                                     defaultValue={applicant.address.state} />
                                  </Form.Group>
                                </Col>

                              </Row>

                              <Row className="mt-3">
                                <Col md={4}>
                                  <Form.Group controlId="city">
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.city,applicant.address.city)}>City / Village *</Form.Label>
                                    <FormControl style={errorStyle(isTouched.city,applicant.address.city)}  type="text" name="city" onKeyDown={validateText} onChange={(e)=> {applicant.address.city = e?.target.value;setLoan(loanModel)}}
                                     defaultValue={applicant.address.city} />
                                  </Form.Group>
                                </Col>
                                <Col md={4}>
                                  <Form.Group controlId="district">
                                    <Form.Label className="custom-form-label">District *</Form.Label>
                                    <FormControl type="text" name="district" onKeyDown={validateText} onChange={(e)=> {applicant.address.district = e?.target.value;setLoan(loanModel)}} defaultValue={applicant.address.district} />
                                  </Form.Group>
                                </Col>
                                <Col md={4}>
                                  <Form.Group controlId="locality">
                                    <Form.Label className="custom-form-label " style={errorFont(isTouched.locality,applicant.address.locality)} >Locality *</Form.Label>
                                    <FormControl style={errorStyle(isTouched.locality,applicant.address.locality)}  type="text" name="locality" onKeyDown={validateText} onChange={(e)=> {applicant.address.locality = e?.target.value;setLoan(loanModel)}}
                                     defaultValue={applicant.address.locality} />
                                  </Form.Group>
                                </Col>

                              </Row>

                              <Row className="mt-3">
                                <Col md={6}>
                                  
                                    <Form.Check inline label="Select to calculate the distance between the proposed property and the current residence." name="distance" onChange={(e)=>{ 
                                        applicant.address.distance = e?.target.value;
                                        if(applicant.address.distance===true){
                                          applicant.address.address1 = applicant.address.address1!==null ? applicant.address.address1 :'';
                                          applicant.address.address2 = applicant.address.address2!==null ? applicant.address.address2 :'';
                                          applicant.address.city = applicant.address.city!==null ? applicant.address.city :'';
                                          applicant.address.state = applicant.address.state!==null ? applicant.address.state :'';
                                          applicant.address.locality = applicant.address.locality!==null ? applicant.address.locality :'';
                                          applicant.address.district = applicant.address.district!==null ? applicant.address.district :'';
                                          applicant.address.zipcode = applicant.address.zipcode!==null ? applicant.address.zipcode :'';
                                          var apiKey = 'AIzaSyC3F2Dwsz-mYj46nLoUTo34Zh8GaP6yWSc';

                                          var addressComponents = [
                                            applicant.address.address1 ,
                                            applicant.address.address2,
                                            applicant.address.city,
                                            applicant.address.locality,
                                            applicant.address.zipcode,
                                            applicant.address.district,
                                            applicant.address.state
                                          ];
                                          var filteredAddressComponents = addressComponents.filter(function(component) {
                                            return component;
                                          });
                                          var formattedAddress = filteredAddressComponents.join('+');
                                          var geocodingUrl = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURIComponent(formattedAddress) + '&key=' + googleMapKey;
                                          
                                          const config: AxiosRequestConfig = {
                                            headers: {
                                              "Content-Type": "application/json"
                                            }
                                          }
                                          axios.get(geocodingUrl,  config).then((data:any) => {
                                            if (data.status === 'OK') {
                                              applicant.address.latitude=data.results[0].geometry.location.lat;
                                              applicant.address.longitude=data.results[0].geometry.location.lng;

                                            }
                                          })
                                        }
                                      }}  type="checkbox" checked={applicant.address.distance} />
                                  
                                </Col>
                              </Row>

                              <Row className="mt-3">
                                <Col md={2}>
                                  
                                    <Form.Check inline label="Dependents?" name="dependents" type='checkbox' id='dependents' onChange={(e)=>{
                                      
                                      if (applicant.dependent === undefined || applicant.dependent.spouse===undefined) {
                                        applicant.dependent = {};
                                        applicant.dependent.spouse = 0;
                                        applicant.dependent.parents = 0;
                                        applicant.dependent.brother = 0;
                                        applicant.dependent.sister = 0;
                                        applicant.dependent.schoolchildren = 0;
                                        applicant.dependent.other = 0;
                                        applicant.standards = null;
                                      }
                                      applicant.dependents = e.target.checked;
                                      calculateDependent();
                                      setLoan(loanModel);
                                    }}  checked={applicant.dependents} />

                                </Col>
                                { applicant.dependents && <Col md={1}>
                                  <Form.Group controlId="spouse">
                                    <Form.Label className="custom-form-label">Spouse (Number)</Form.Label>
                                    <FormControl type="number" onChange={(e)=>{ applicant.dependent.spouse = e.target.value;setLoan(loanModel)}} min="0" name="spouse" defaultValue={applicant.dependent.spouse} />
                                  </Form.Group>
                                </Col>}
                                { applicant.dependents && <Col md={1}>
                                  <Form.Group controlId="parent">
                                    <Form.Label className="custom-form-label">Parents (Number)</Form.Label>
                                    <FormControl type="number" onChange={(e)=> {applicant.dependent.parent = e.target.value;setLoan(loanModel)}} min="0" name="parent" defaultValue={applicant.dependent.parent} />
                                  </Form.Group>
                                </Col>}
                                { applicant.dependents && <Col md={1}>
                                  <Form.Group controlId="brother">
                                    <Form.Label className="custom-form-label">Brother (Number)</Form.Label>
                                    <FormControl type="number" onChange={(e)=> {applicant.dependent.brother = e.target.value;setLoan(loanModel)}} min="0" name="brother" defaultValue={applicant.dependent.brother} />
                                  </Form.Group>
                                </Col>}
                                { applicant.dependents && <Col md={1}>
                                  <Form.Group controlId="sister">
                                    <Form.Label className="custom-form-label">Sister (Number)</Form.Label>
                                    <FormControl type="number" onChange={(e)=> {applicant.dependent.sister = e.target.value;setLoan(loanModel)}} min="0" name="sister" defaultValue={applicant.dependent.sister} />
                                  </Form.Group>
                                </Col>}
                                { applicant.dependents && <Col md={1}>
                                  <Form.Group controlId="children">
                                    <Form.Label className="custom-form-label">Brother (Number)</Form.Label>
                                    <FormControl type="number" onChange={(e)=> {applicant.dependent.schoolchildren = e.target.value;setLoan(loanModel)}} min="0" name="children" defaultValue={applicant.dependent.schoolchildren} />
                                  </Form.Group>
                                </Col>}
                                { applicant.dependents && <Col md={1}>
                                  <Form.Group controlId="others">
                                    <Form.Label className="custom-form-label">Others (Number)</Form.Label>
                                    <FormControl type="number" onChange={(e)=> {applicant.dependent.other = e.target.value;setLoan(loanModel)}} min="0" name="others" defaultValue={applicant.dependent.other} />
                                  </Form.Group>
                                </Col>}
                              </Row>

                              <Row className="mt-3">
                                <Col md={3}>
                                  <Form.Group controlId="earningnumbers">
                                    <Form.Label className="custom-form-label" >Earning Members</Form.Label>
                                    <Form.Select className="form-control" defaultValue={applicant.earningnumbers} onChange={(e)=> {
                                        applicant.earningnumbers = e.target.value;
                                        calculateDependent();
                                      }} name="earningnumbers" value={applicant.earningnumbers}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('members').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="totalmembers">
                                    <Form.Label className="custom-form-label" >Total Members</Form.Label>
                                    <Form.Select className="form-control" onChange={(e)=> {applicant.totalmembers = e.target.value;setLoan(loanModel)}} name="totalmembers" defaultValue={applicant.totalmembers}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('members').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>

                              <Row className="mt-3">
                                <Col md={3}>
                                  <Form.Group controlId="yearsincurrentresidence">
                                    <Form.Label className="custom-form-label"style={errorFont(isTouched.yearsincurrentresidence,applicant.yearsincurrentresidence)}>Current Residence (Yrs) *</Form.Label>
                                    <Form.Select style={errorStyle(isTouched.yearsincurrentresidence,applicant.yearsincurrentresidence)}  className="form-control" onChange={(e)=> {applicant.yearsincurrentresidence = e.target.value;setLoan(loanModel)}}  name="yearsincurrentresidence" 
                                    defaultValue={applicant.yearsincurrentresidence}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('yearsresidence').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="monthincurrentresidence">
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.monthincurrentresidence,applicant.monthincurrentresidence)}>Current Residence (Mths) *</Form.Label>
                                    <Form.Select style={errorStyle(isTouched.monthincurrentresidence,applicant.monthincurrentresidence)}    className="form-control" onChange={(e)=> {applicant.monthincurrentresidence = e.target.value;setLoan(loanModel)}}  name="monthincurrentresidence" 
                                    defaultValue={applicant.monthincurrentresidence}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('monthsresidence').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="yearsincurrentcity">
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.yearsincurrentcity,applicant.yearsincurrentcity)}>Residence in Current City (Yrs) *</Form.Label>
                                    <Form.Select style={errorStyle(isTouched.yearsincurrentcity,applicant.yearsincurrentcity)}  className="form-control" onChange={(e)=> {applicant.yearsincurrentcity = e.target.value;setLoan(loanModel)}}  name="yearsincurrentcity" defaultValue={applicant.yearsincurrentcity}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('yearsresidence').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="monthincurrentcity">
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.monthincurrentcity,applicant.monthincurrentcity)}  >Residence in Current City (Mths) *</Form.Label>
                                    <Form.Select style={errorStyle(isTouched.monthincurrentcity,applicant.monthincurrentcity)}   className="form-control" onChange={(e)=> {applicant.monthincurrentcity = e.target.value;setLoan(loanModel)}}  name="monthincurrentcity" defaultValue={applicant.monthincurrentcity}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('monthsresidence').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>

                              <Row className="mt-3">
                                <Col md={3}>
                                  <Form.Group controlId="residencetype">
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.residencetype,applicant.residencetype)}>Residence Type *</Form.Label>
                                    <Form.Select style={errorStyle(isTouched.residencetype,applicant.residencetype)}  className="form-control" onChange={(e)=> {applicant.residencetype = e.target.value;setLoan(loanModel)}} name="residencetype"
                                     defaultValue={applicant.residencetype}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('residenceTypes').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="distancework">
                                    <Form.Label className="custom-form-label" >Distance from Work Place</Form.Label>
                                    <Form.Select className="form-control" onChange={(e)=> {applicant.distancework = e.target.value;setLoan(loanModel)}} name="distancework" defaultValue={applicant.distancework}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('distanceRange').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="residenceownership">
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.residenceownership,applicant.residenceownership)} >Residence Ownership *</Form.Label>
                                    <Form.Select style={errorStyle(isTouched.residenceownership,applicant.residenceownership)}  className="form-control" onChange={(e)=> {applicant.residenceownership = e.target.value;setLoan(loanModel)}} name="residenceownership" defaultValue={applicant.residenceownership}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('ownership').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="residencearea">
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.residencearea,applicant.residencearea)}  >Residence Premise Area (sq ft) *</Form.Label>
                                    <FormControl style={errorStyle(isTouched.residencearea,applicant.residencearea)}  type="number" onChange={(e)=> {applicant.residencearea = e.target.value;setLoan(loanModel)}} min="0" name="residencearea" defaultValue={applicant.residencearea} />
                                  </Form.Group>
                                </Col>
                              </Row>


                              <Row className="mt-3">
                                <Col md={3}>
                                  <Form.Group controlId="vehiclesOwned">
                                    <Form.Label className="custom-form-label" >Vehicle Owned</Form.Label>
                                    <Form.Select onChange={(e)=> {applicant.vehiclesOwned = e.target.value;setLoan(loanModel)}} name="vehiclesOwned" defaultValue={applicant.vehiclesOwned}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('vehicleOwned').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="spouseaware" >
                                    <Form.Check inline label="Spouse Aware of the Transaction?" name="spouseaware" type='checkbox' id='spouseaware' onChange={(e)=> {
                                      applicant.spouseaware = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={applicant.spouseaware} />
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="isillness" >
                                    <Form.Check inline label="Major illnesses during last 3 years?" name="isillness" type='checkbox' id='isillness' onChange={(e)=> {
                                      applicant.isillness = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={applicant.isillness} />
                                  </Form.Group>
                                </Col>
                                {applicant.isillness && <Col md={3}>
                                  <Form.Group controlId="illnessReason">
                                    <Form.Label className="custom-form-label" >Please Specify *</Form.Label>
                                    <FormControl type="text" name="illnessReason" onKeyDown={allowAlphabetsSpacesFullstop} onChange={(e)=> {applicant.illnessReason = e.target.value;setLoan(loanModel)}} defaultValue={applicant.illnessReason} />
                                  </Form.Group>
                                </Col>}
                              </Row>
                            </Form>
                          </div>
                          <div className="tab-pane fade" id="tab6" role="tabpanel" aria-labelledby="tab6-tab">

                          </div>
                          <div className="tab-pane fade" id="tab7" role="tabpanel" aria-labelledby="tab7-tab"></div>
                          <div className="tab-pane fade" id="tab8" role="tabpanel" aria-labelledby="tab8-tab"></div>
                          <div className="tab-pane fade" id="tab9" role="tabpanel" aria-labelledby="tab9-tab"></div>
                        </div>
                        <Row className="mt-3">
                          <Form.Group controlId="earningmembers">
                            <Form.Label className="custom-form-label" >Remarks</Form.Label>
                            <FormControl onChange={(e)=>{applicant.remarks = e.target.value;setLoan(loanModel)}} as="textarea" rows={6}
                              style={{ width: '100%', height: '120px' }} defaultValue={applicant.remarks}
                            />
                          </Form.Group>
                        </Row>
                      </div>
                    </Tab>
                    <Tab eventKey="bank" title="Banking"  >
                      <div className="tab-content">
                        <div className="title-header" style={{ marginBottom: '2px' }}>
                          <strong>Banking
                            <a style={{ cursor: "pointer", textDecoration: 'none', color: 'blue  !important' }} onClick={()=>{
                                addAccount();
                                setBankView(true);
                              }}>
                              <i className="fa fa-plus fa-lg" aria-hidden="true"></i>
                            </a>
                          </strong>
                        </div>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">ACCOUNT NO.</th>
                                <th scope="col">NAME OF BANK</th>
                                <th scope="col">ACCOUNT TYPE</th>
                                <th scope="col">ACCOUNT OPENING SINCE (MONTHS)</th>
                                <th scope="col">MINIMUM BALANCE</th>
                                <th scope="col">OD/CC FACILITY</th>
                              </tr>
                            </thead>
                            {(applicant.bankaccounts== null || applicant.bankaccounts.length===0) && <tr>
                                    <td colSpan={6}><span className="col-lg-4 col-sm-6 col-xs-8 col-lg-offset-5 col-sm-offset-5 col-xs-offset-4">No Accounts</span></td>
                              </tr>}
                              {applicant.bankaccounts && applicant.bankaccounts.map((account:any,i:any)=> 
                                (<tr>
                                <td><a href="#"onClick={()=>showBanking(i)}>{account.accountno}</a></td>
                                <td>{getTypeValue('BANK',account.bankname)}</td>
                                <td>{account.accounttype}</td>
                                <td>{account.sinceyear*12 + account.sincemonth}</td>
                                <td>{account.minbalance}</td>
                                <td> <div className="w-15 checkbox checkbox-primary " style={{'marginTop':'-5px','marginLeft': 'auto','marginRight': 'auto'}}>
                                  <input id="odfacility" type="checkbox" disabled checked={account.odfacility}/>
                                </div>
                                </td>
                                <td><a data-widgster="close" onClick={()=>removeBanking(i)}><i className="fa fa-times fa-lg"></i></a></td>
                              </tr>))}
                          </table>
                          { bankView &&
                          <div className='title-header'>
                            <strong>Bank Details</strong>
                          
                            <hr />
                            <Form className="page-section">
                              <Row className="mt-3">
                                <Col md={3}>
                                  <Form.Group controlId="accountno">
                                    <Form.Label className="custom-form-label"style={errorFont(isTouched.accountno,bankModel.accountno)} >Bank A/C *:</Form.Label>
                                    <FormControl style={errorStyle(isTouched.accountno,bankModel.accountno)}  type="number" min="0" max="25" name="accountno" onChange={(e)=>{bankModel.accountno = e.target.value;setLoan(loanModel);}} defaultValue={bankModel.accountno} />
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" style={errorFont(isTouched.type,bankModel.type)} >Account Type *:</Form.Label>
                                  <Form.Select style={errorStyle(isTouched.type,bankModel.type)}  className="form-control" onChange={(e)=>{bankModel.accounttype = e.target.value;setLoan(loanModel);}} name="type" defaultValue={bankModel.accounttype}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('accounttypes').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="bankname">
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.bankname,bankModel.bankname)}  >Bank *</Form.Label>
                                    <Form.Select style={errorStyle(isTouched.bankname,bankModel.bankname)}  className="form-control" onChange={(e)=>{bankModel.bankname = e.target.value;setLoan(loanModel)}} name="bankname" defaultValue={bankModel.bankname}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('BANK').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="accopendate">
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.accopendate,bankModel.accopendate)} >A/C Open Date *:</Form.Label>
                                    <FormControl style={errorStyle(isTouched.accopendate,bankModel.accopendate)}  type="date" className="form-control" min={applicant.dob} max= {new Date().toISOString().split('T')[0]} name="accopendate" onChange={(e)=>
                                      {

                                        bankModel.accopendate = e.target.value;
                                        var d1 = new Date(bankModel.accopendate);
                                        var d2 = new Date();
                                        const oneDay = 24 * 60 * 60 * 1000;
                                        const diffDays = Math.round(Math.abs((d2.getTime() - d1.getTime()) / oneDay));
                                        var d3 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
                                        if(d2.getMonth()==0){
                                          d3.setFullYear(d2.getFullYear()-1);
                                          d3.setMonth(11);
                                        }
                                        else{
                                          d3.setFullYear(d2.getFullYear());
                                          if(d3.getDate()>new Date(d2.getFullYear(), d2.getMonth(), 0).getDate()){
                                            d3.setDate(new Date(d2.getFullYear(), d2.getMonth(), 0).getDate());
                                          }
                                          d3.setMonth(d2.getMonth()-1);
                                        }
                                        const daysDifferenceBetweenCurrentMonthAndPreviousMonth = Math.round(Math.abs((d2.getTime() - d3.getTime()) / oneDay));
                                        var months;
                                        if (diffDays < 31) {
                                          if(diffDays>=15){
                                            months = 1;
                                          }
                                          else{
                                            months=0;
                                          }
                                        } else {
                                          months = (d2.getFullYear() - d1.getFullYear()) * 12;
                                          months -= d1.getMonth();
                                          months += d2.getMonth();
                                          if(daysDifferenceBetweenCurrentMonthAndPreviousMonth<15){
                                            months--;
                                          }
                                        }
                                        var yearsSinceBankAccOpenDate = Math.floor(months / 12);
                                        var monthsSinceBankAccOpenDate = months % 12;

                                        bankModel.sinceyear = yearsSinceBankAccOpenDate;
                                        bankModel.sincemonth = monthsSinceBankAccOpenDate;
                                        setLoan(loanModel);
                                      }} defaultValue={bankModel.accopendate} />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col md={3}>
                                  <Form.Group controlId="sinceyear">
                                    <Form.Label className="custom-form-label">A/C Since(Years):</Form.Label>
                                    <FormControl type="number" name="sinceyear"  disabled={true} defaultValue={bankModel.sinceyear} />
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="sincemonth">
                                    <Form.Label className="custom-form-label" >A/C Since(Months):</Form.Label>
                                    <FormControl type="number" name="sincemonth"  disabled={true} defaultValue={bankModel.sincemonth} />
                                  </Form.Group>
                                </Col>

                                <Col md={3}>
                                  <Form.Group controlId="minbalance">
                                    <Form.Label className="custom-form-label" style={errorFont(isTouched.minbalance,bankModel.minbalance)}  >Min. Balance *</Form.Label>
                                    <FormControl style={errorStyle(isTouched.minbalance,bankModel.minbalance)}  type="number" name="minbalance" onChange={(e)=>{bankModel.minbalance = e.target.value;setLoan(loanModel);}}  defaultValue={bankModel.minbalance} />
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="odfacility">
                                    <Form.Check inline label="OD/CC Facility?" name="odfacility" onChange={(e)=>bankModel.odfacility = e.target.value} type='checkbox' id='odfacility' checked={bankModel.odfacility} />
                                  </Form.Group>
                                </Col>
                              </Row>
                              {bankModel.transactions && bankModel.transactions.length>0 && bankModel.transactions.map((bt:any, i:any) =>
                              (
                                <div>
                                  <div className='label label-danger'>{bt.name}</div>
                                  <hr />
                                  <Row className="mt-3">
                                    <Col md={3}>
                                      <Form.Group controlId="credits_{i}">
                                        <Form.Label className="custom-form-label">Credits (Count)</Form.Label>
                                        <FormControl type="number" placeholder='0.0' name="credits_{i}" onChange={(event) => {bt.credits = event.target.value;setLoan(loanModel);}} defaultValue={bt.credits} />
                                      </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                      <Form.Group controlId="debits_{i}">
                                        <Form.Label className="custom-form-label" >Debits (Count)</Form.Label>
                                        <FormControl type="number" placeholder='0.0' name="debits_{i}" onChange={(event) => {bt.debits = event.target.value;setLoan(loanModel);}} defaultValue={bt.debits} />
                                      </Form.Group>
                                    </Col>

                                    <Col md={3}>
                                      <Form.Group controlId="outchequebounces_{i}">
                                        <Form.Label className="custom-form-label" >Outward Cheque Bounces</Form.Label>
                                        <FormControl type="number" placeholder='0.0' name="outchequebounces_{i}" onChange={(event) => {bt.outchequebounces = event.target.value;setLoan(loanModel);}} defaultValue={bt.outchequebounces} />
                                      </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                      <Form.Group controlId="creditamount_{i}">
                                        <Form.Label className="custom-form-label" >Credit Amt</Form.Label>
                                        <FormControl type="number" placeholder='0.0' name="creditamount_{i}" onChange={(event) => {bt.creditamount = event.target.value;setLoan(loanModel);}} defaultValue={bt.creditamount} />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row className="mt-3">
                                    <Col md={3}>
                                      <Form.Group controlId="debitamount_{i}">
                                        <Form.Label className="custom-form-label">Debit Amt</Form.Label>
                                        <FormControl type="number" placeholder='0.0' name="debitamount_{i}" onChange={(event) => {bt.debitamount = event.target.value;setLoan(loanModel);}} defaultValue={bt.debitamount} />
                                      </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                      <Form.Group controlId="minbalancecharges_{i}">
                                        <Form.Label className="custom-form-label" >Min. Bal Charges</Form.Label>
                                        <FormControl type="number" placeholder='0.0' name="minbalancecharges_{i}" onChange={(event) => {bt.minbalancecharges = event.target.value;setLoan(loanModel);}} defaultValue={bt.minbalancecharges} />
                                      </Form.Group>
                                    </Col>

                                    <Col md={3}>
                                      <Form.Group controlId="balance_{i}">
                                        <Form.Label className="custom-form-label" >Closing Balance</Form.Label>
                                        <FormControl type="number" placeholder='0.0' name="balance_{i}" onChange={(event) =>{ bt.balance = event.target.value;setLoan(loanModel);}} defaultValue={bt.balance} />
                                      </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                      <Form.Group controlId="avgbalance_{i}">
                                        <Form.Label className="custom-form-label" >Avg. Balance</Form.Label>
                                        <FormControl type="number" placeholder='0.0' name="avgbalance_{i}" onChange={(event) =>{ bt.avgbalance = event.target.value;setLoan(loanModel);}} defaultValue={bt.avgbalance} />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              )
                              )}
                              <Row className="mt-3">
                                <Form.Group controlId="earningmembers">
                                  <Form.Label className="custom-form-label" >Remarks</Form.Label>
                                  <FormControl onChange={(e)=>{applicant.remarks = e.target.value;setLoan(loanModel)}} as="textarea" rows={6}
                                    style={{ width: '100%', height: '120px' }} defaultValue={applicant.remarks}
                                  />
                                </Form.Group>
                              </Row>
                            </Form>
                        </div>}
                      </div>
                      </div>
                    </Tab>
                    <Tab eventKey="repayment" title="Repayment" >
                      <div className="tab-content">
                        <div className="title-header" style={{ marginBottom: '2px' }}>
                          <strong>Repayments
                            <a style={{ cursor: "pointer", textDecoration: 'none', color: 'blue  !important' }} onClick={()=>{ addRepayment()}} >
                              <i className="fa fa-plus fa-lg" aria-hidden="true"></i>
                            </a>
                          </strong>
                        </div>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">LOAN TYPE</th>
                                <th scope="col">INSTITUTION</th>
                                <th scope="col">EMI</th>
                                <th scope="col">ROI</th>
                                <th scope="col">LOAN AMOUNT</th>
                                <th scope="col">OUTSTANDING AMOUNT</th>
                                <th scope="col">MORATORIUM USED</th>
                                <th></th>
                              </tr>
                            </thead>
                            { (applicant.repayments===undefined || applicant.repayments === null || applicant.repayments.length === 0) && (
                              <tr>
                                <td colSpan={8}><span>No Repayments</span></td>
                              </tr>
                            )}
                            <tbody>
                              {applicant.repayments && applicant.repayments.length > 0 && applicant.repayments.map((repayment:any, i:any)=> (<tr className="table-row" onClick={()=>showRepayment(i)}>
                                <td>{getTypeValue("LOAN_TYPE", repayment.loantype)}</td>
                                <td>{repayment.institution}</td>
                                <td>{repayment.emi}</td>
                                <td>{repayment.roi}</td>
                                <td>{repayment.loanamount}</td>
                                <td>{repayment.outstandingamount}</td>
                                <td>
                                  <Form.Check inline label="" name={"moratoriumused"+i} type='checkbox' id={'moratoriumused'+i} onChange={(e)=> {repaymentmodel.moratoriumused = e.target.value;setLoan(loanModel)}} disabled={true} />
                                </td>
                                <td><a data-widgster="close" onClick={()=>removeRepayment(i)}><i className="fa fa-times fa-lg"></i></a></td>
                              </tr>))}
                            </tbody> 
                          </table>
                          {repaymentView && 
                          <Form className="page-section">
                            <Row className="mt-3">
                              <Col md={3}>
                                <Form.Group controlId="loantype">
                                  <Form.Label className="custom-form-label" style={errorFont(isTouched.loantype,repaymentmodel.loantype)}>Loan Type *</Form.Label>
                                  <Form.Select style={errorStyle(isTouched.loantype,repaymentmodel.loantype)}  className="form-control" onChange={(e)=> {repaymentmodel.loantype = e.target.value;setLoan(loanModel)}} name="loantype" defaultValue={repaymentmodel.loantype}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('loanTypes').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>

                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" style={errorFont(isTouched.institution,repaymentmodel.institution)}>Institution Name *</Form.Label>
                                <FormControl  style={errorStyle(isTouched.institution,repaymentmodel.institution)}  type="text" name="institution" onKeyDown={validateText} onChange={(e)=> {
                                  repaymentmodel.institution = e.target.value;
                                  setLoan(loanModel);
                                }} defaultValue={repaymentmodel.institution} />
                              </Col>
                              <Col md={3}>
                                <Form.Group controlId="loanamount">
                                  <Form.Label className="custom-form-label" style={errorFont(isTouched.loanamount,repaymentmodel.loanamount)} >Disbursed Amount *</Form.Label>
                                  <FormControl  style={errorStyle(isTouched.loanamount,repaymentmodel.loanamount)}  type="number" name="loanamount" onKeyDown={allowPositiveIntegersOnly}  onChange={(e)=> {
                                    repaymentmodel.loanamount = e.target.value;
                                    setLoan(loanModel);
                                  }}defaultValue={repaymentmodel.loanamount} />
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Group controlId="roi">
                                  <Form.Label className="custom-form-label" style={errorFont(isTouched.roi,repaymentmodel.roi)} >Rate of Interest *</Form.Label>
                                  <FormControl style={errorStyle(isTouched.roi,repaymentmodel.roi)}  type="date" className="form-control" name="roi" onChange={(event)=> {
                                    repaymentmodel.roi = event.target.value;
                                    var repayments = JSON.parse(JSON.stringify( repaymentmodel));
                                    var total = 0;
                                    var balance = 0;
                                    var roi = 0;
                                    var emi = 0;
                                    var i = 0;
                                    var e = 0;
                                    var f = 0;
                                    var g = 0;
                                    if (repayments.balancetenure!==null || repayments.balancetenure!==undefined) {
                                      balance = parseInt(repayments.balancetenure);
                                      console.log("Balance tenure" + repayments.balancetenure)
                                    }
                                    if (repayments.roi!==null || repayments.roi!==undefined) {
                                      roi = parseInt(repayments.roi);
                                      console.log("ROI tenure" + repayments.roi)
                                    }
                                    if (repayments.emi!==null || repayments.emi!==undefined) {
                                      emi = repayments.emi;
                                      console.log("EMI tenure" + repayments.emi)
                
                                    }
                                    i = roi / 1200;
                                    e = Math.pow((1 + i), balance);
                                    f = 1 / e;
                                    g = (emi * (1 - f));
                                    total = g / i;
                                    if (total > 0) {
                                      repaymentmodel.outstandingamount = Math.round(total);
                                      console.log("Outstanding ammount is" + total);
                                    } else if (balance===0) {
                                      repaymentmodel.outstandingamount = 0;
                                    }
                                    setLoan(loanModel);
                                  }} defaultValue={repaymentmodel.roi} />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col md={3}>
                                <Form.Group controlId="originaltenure">
                                  <Form.Label className="custom-form-label" style={errorFont(isTouched.originaltenure,repaymentmodel.originaltenure)} >Original Tenure (in months) *</Form.Label>
                                  <FormControl style={errorStyle(isTouched.originaltenure,repaymentmodel.originaltenure)}  type="number" name="originaltenure" onChange={(e)=> {
                                    repaymentmodel.originaltenure = e.target.value;
                                    repaymentmodel.balancetenure = null;
                                    setLoan(loanModel);
                                  }}defaultValue={repaymentmodel.originaltenure} />
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Group controlId="balancetenure">
                                  <Form.Label className="custom-form-label" style={errorFont(isTouched.balancetenure,repaymentmodel.balancetenure)} >Balance Tenure (in months) *</Form.Label>
                                  <FormControl style={errorStyle(isTouched.balancetenure,repaymentmodel.balancetenure)}  type="number" name="balancetenure" onChange={(event)=>{ 
                                    repaymentmodel.balancetenure = event.target.value;
                                    var repayments = JSON.parse(JSON.stringify( repaymentmodel));
                                    var total = 0;
                                    var balance = 0;
                                    var roi = 0;
                                    var emi = 0;
                                    var i = 0;
                                    var e = 0;
                                    var f = 0;
                                    var g = 0;
                                    if (repayments.balancetenure!==null || repayments.balancetenure!==undefined) {
                                      balance = parseInt(repayments.balancetenure);
                                      console.log("Balance tenure" + repayments.balancetenure)
                                    }
                                    if (repayments.roi!==null || repayments.roi!==undefined) {
                                      roi = parseInt(repayments.roi);
                                      console.log("ROI tenure" + repayments.roi)
                                    }
                                    if (repayments.emi!==null || repayments.emi!==undefined) {
                                      emi = repayments.emi;
                                      console.log("EMI tenure" + repayments.emi)
                                    }
                                    i = roi / 1200;
                                    e = Math.pow((1 + i), balance);
                                    f = 1 / e;
                                    g = (emi * (1 - f));
                                    total = g / i;
                                    if (total > 0) {
                                      repaymentmodel.outstandingamount = Math.round(total);
                                      console.log("Outstanding amount is" + total);
                                    } else if (balance===0) {
                                      repaymentmodel.outstandingamount = 0;
                                    }
                                    setLoan(loanModel);
                                  }} defaultValue={repaymentmodel.balancetenure} />
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Group controlId="collateral">
                                  <Form.Label className="custom-form-label" style={errorFont(isTouched.type,repaymentmodel.type)} >Collateral *</Form.Label>
                                  <Form.Select style={errorStyle(isTouched.type,repaymentmodel.type)}  className="form-control" onChange={(e)=> repaymentmodel.collateral = e.target.value} name="type" defaultValue={repaymentmodel.collateral}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('COLLATERAL').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                <Button variant="danger" onClick={()=>{
                                  var tenure = 0;
                                  var amount = 0;
                                  var totalroi = 0.0;
                                  var maincal = 0.0;
                                  if (repaymentmodel.originaltenure!==null) {
                                    tenure = parseInt(repaymentmodel.originaltenure);
                                  }
                                  if (repaymentmodel.roi!==null) {
                                    totalroi = repaymentmodel.roi;
                                  } if (repaymentmodel.loanamount!==null) {
                                    amount = repaymentmodel.loanamount;
                                  }
                                  var montlyroi = totalroi / 1200;
                                  var cal0 = montlyroi + 1;
                                  var cal1 = Math.pow(cal0, tenure);
                                  var cal2 = cal1 * montlyroi;
                                  var cal3 = cal1 - 1;
                                  var cal4 = cal2 / cal3;
                                  var maincal = cal4 * amount;
                                  if (maincal!==null) {
                                    repaymentmodel.emi = maincal;
                                  }
                                  var repayments = JSON.parse(JSON.stringify( repaymentmodel));
                                  var total = 0;
                                  var balance = 0;
                                  var roi = 0;
                                  var emi = 0;
                                  var i = 0;
                                  var e = 0;
                                  var f = 0;
                                  var g = 0;
                                  if (repayments.balancetenure!==null || repayments.balancetenure!==undefined) {
                                    balance = parseInt(repayments.balancetenure);
                                    console.log("Balance tenure" + repayments.balancetenure)
                                  }
                                  if (repayments.roi!==null || repayments.roi!==undefined) {
                                    roi = parseInt(repayments.roi);
                                    console.log("ROI tenure" + repayments.roi)
                                  }
                                  if (repayments.emi!==null || repayments.emi!==undefined) {
                                    emi = repayments.emi;
                                    console.log("EMI tenure" + repayments.emi)
                
                                  }
                                  i = roi / 1200;
                                  e = Math.pow((1 + i), balance);
                                  f = 1 / e;
                                  g = (emi * (1 - f));
                                  total = g / i;
                                  if (total > 0) {
                                    repaymentmodel.outstandingamount = Math.round(total);
                                    console.log("Outstanding ammount is" + total);
                                  } else if (balance===0) {
                                    repaymentmodel.outstandingamount = 0;
                                  }
                                  setLoan(loanModel);
                                }}><span style={{ borderRadius: '50%', backgroundColor: '#fff', display: 'inline-block', width: '23px', height: '23px' }} ><i className="fa fa-arrow-left text-danger"></i></span> Calculate EMI</Button>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col md={3}>
                                <Form.Group controlId="emi">
                                  <Form.Label className="custom-form-label">EMI</Form.Label>
                                  <FormControl type="number" name="emi" onChange={(event)=> {
                                    repaymentmodel.emi = event.target.value;
                                    var repayments = JSON.parse(JSON.stringify( repaymentmodel));
                                    var total = 0;
                                    var balance = 0;
                                    var roi = 0;
                                    var emi = 0;
                                    var i = 0;
                                    var e = 0;
                                    var f = 0;
                                    var g = 0;
                                    if (repayments.balancetenure!==null || repayments.balancetenure!==undefined) {
                                      balance = parseInt(repayments.balancetenure);
                                      console.log("Balance tenure" + repayments.balancetenure)
                                    }
                                    if (repayments.roi!==null || repayments.roi!==undefined) {
                                      roi = parseInt(repayments.roi);
                                      console.log("ROI tenure" + repayments.roi)
                                    }
                                    if (repayments.emi!==null || repayments.emi!==undefined) {
                                      emi = repayments.emi;
                                      console.log("EMI tenure" + repayments.emi)

                                    }
                                    i = roi / 1200;
                                    e = Math.pow((1 + i), balance);
                                    f = 1 / e;
                                    g = (emi * (1 - f));
                                    total = g / i;
                                    if (total > 0) {
                                      repaymentmodel.outstandingamount = Math.round(total);
                                      console.log("Outstanding ammount is" + total);
                                    } else if (balance===0) {
                                      repaymentmodel.outstandingamount = 0;
                                    }
                                    setLoan(loanModel);
                                  }} defaultValue={repaymentmodel.emi} />
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" style={errorFont(isTouched.outstandingamount,repaymentmodel.outstandingamount)}  >Outstanding Amount *</Form.Label>
                                <FormControl style={errorStyle(isTouched.outstandingamount,repaymentmodel.outstandingamount)}  type="number" name="outstandingamount" onKeyDown={allowPositiveIntegersOnly} onChange={(e)=> {
                                  repaymentmodel.outstandingamount = e.target.value;
                                  setLoan(loanModel);
                                }} defaultValue={repaymentmodel.outstandingamount} />
                              </Col>
                              <Col md={2}>
                                <Form.Group controlId="emimode">
                                  <Form.Label className="custom-form-label"style={errorFont(isTouched.emimode,repaymentmodel.emimode)}  >EMI Payment mode *</Form.Label>
                                  <Form.Select className="form-control" style={errorStyle(isTouched.emimode,repaymentmodel.emimode)}  onChange={(e)=> repaymentmodel.emimode = e.target.value} name="emimode" defaultValue={repaymentmodel.emimode}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('emiPaymentModes').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group controlId="noofdefaults">
                                  <Form.Label className="custom-form-label" style={errorFont(isTouched.noofdefaults,repaymentmodel.noofdefaults)}  >Number of defaults / cheque bounces in last 18 months *</Form.Label>
                                  <Form.Select className="form-control" style={errorStyle(isTouched.noofdefaults,repaymentmodel.noofdefaults)}  onChange={(e)=> repaymentmodel.noofdefaults = e.target.value} name="noofdefaults" defaultValue={repaymentmodel.noofdefaults}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('noOfDefaults').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                <Form.Group controlId="moratoriumused">
                                  <Form.Check inline label="Moratorium used in 2020 ?" name="moratoriumused" type='checkbox' id='moratoriumused' onChange={(e)=> {
                                    repaymentmodel.moratoriumused = e.target.value;
                                    setLoan(loanModel);
                                  }} defaultValue={repaymentmodel.moratoriumused} />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Form.Group controlId="earningmembers">
                                <Form.Label className="custom-form-label" >Remarks</Form.Label>
                                <FormControl onChange={(e)=>{applicant.remarks = e.target.value;setLoan(loanModel)}} as="textarea" rows={6}
                                  style={{ width: '100%', height: '120px' }} defaultValue={applicant.remarks}
                                />
                              </Form.Group>
                            </Row>
                          </Form>}
                        </div>
                      </div>
                    </Tab>

                    <Tab eventKey="sitevisit" title="Site Visit">

                      <div className="tab-content" >
                        <div className="title-header" style={{ marginBottom: '2px' }}>
                          <strong>Site Visits
                            <a style={{ cursor: "pointer", textDecoration: 'none', color: 'blue  !important' }} onClick={()=>{
                              showSitePage();
                            }}>
                              <i className="fa fa-plus fa-lg" aria-hidden="true"></i>
                            </a>
                          </strong>
                        </div>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">SITE VISITED</th>
                                <th scope="col">PLACE VISITED</th>
                                <th scope="col">PLACE VISITED</th>
                                <th scope="col">SIGNAGE</th>
                                <th scope="col">AREA</th>
                                <th scope="col">VIEW ON MAP</th>
                                <th scope="col">ACTION</th>
                              </tr>
                            </thead>
                            {(applicant.sitevisits=== undefined || applicant.sitevisits===null || applicant.sitevisits.length===0) && (
                              <tr>
                                <td colSpan={6}>No Site Visits</td>
                              </tr>
                            )}
                            {applicant.sitevisits && applicant.sitevisits.length > 0 && applicant.sitevisits.map((visit:any, i:any)=>(
                              <tr>
                                <td>{getTypeValue('SITE',visit.sitevisited)}</td>
                                <td>
                                  {visit.sitevisited==='Sales' && (<i className="fas fa-store-alt fa-lg "></i>)}
                                  {visit.sitevisited==='Shop' && (<i className="fa fa-store fa-lg "></i>)}
                                  {visit.sitevisited==='RES' && (<i className="fa fa-home fa-lg  fa-prim "></i>)}
                                  {visit.sitevisited==='Office' && (<i className="fas fa-calendar-alt fa-lg fa-prim "></i>)}
                                  {visit.sitevisited==='Godown' && (<i className="fas fa-warehouse fa-lg fa-prim"></i>)}
                                  {visit.sitevisited==='Factory' && (<i className="fas fa-industry fa-lg fa-prim"></i>)}
                                  {visit.sitevisited==='EDU' && (<i className="fa fa-school fa-lg fa-prim "></i>)}

                                </td>
                                <td>{visit.locationdetail.address1}</td>
                                <td>{visit.nameboard && (<i className="fas fa-sign fa-lg fa-prim"></i>)}</td>
                                <td>{visit.area}</td>
                                <td>{(visit.imageavailabel || visit.videosavailabel) && <a onClick={()=>viewMap(i)}><i data-toggle="modal"  className="fa fa-map-marker-alt  fa-alt fa-lg visit-list-icon"></i> </a>}</td>
                              </tr>
                            ))}
                          </table>
                        </div>
                        {sitePage && 
                        <Form className="page-section">
                          <Accordion defaultActiveKey="0" >
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>Site Visited</Accordion.Header>
                              <Accordion.Body>
                                <Row className="mt-3">
                                  <Col md={2}>
                                    <Form.Group controlId="sitevisited">
                                      <Form.Label className="custom-form-label">Site Visited *</Form.Label>
                                      <Form.Select className="form-control" onChange={(e)=> {
                                          siteModel.sitevisited = e.target.value;
                                          changeVisitInfo();
                                          
                                        }} name="sitevisited" defaultValue={siteModel.sitevisited}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('sitePremises').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>

                                    </Form.Group>
                                  </Col>
                                  <Col md={2} hidden={siteModel.sitevisited && siteModel.sitevisited !== 'RES'}>
                                    <Form.Group controlId="placevisited">
                                      <Form.Label className="custom-form-label" >Place Visited</Form.Label>
                                      <Form.Select className="form-control" onChange={(e)=> {
                                          siteModel.placevisited = e.target.value;
                                          changeVisitInfo();
                                        }} name="placevisited" defaultValue={siteModel.placevisited}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('placeVisited').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Group controlId="area">
                                      <Form.Label className="custom-form-label" >Area seen(sq ft)</Form.Label>
                                      <FormControl type="number" name="area" onChange={(e)=> siteModel.area = e.target.value} defaultValue={siteModel.area} />
                                    </Form.Group>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Group controlId="visitdate">
                                      <Form.Label className="custom-form-label" >Visit Date</Form.Label>
                                      <FormControl type="date" className="form-control" name="visitdate" onChange={(e)=> siteModel.visitdate = e.target.value} defaultValue={siteModel.visitdate} />
                                    </Form.Group>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Group controlId="nameboard">
                                      <Form.Label className="custom-form-label">Visit Date</Form.Label>
                                      <Form.Check className="form-control"  onChange={(e)=> siteModel.nameboard = e.target.checked} name="sitevisited" defaultValue={siteModel.nameboard}/>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>
                              <i className="fa fa-file-video-o fa-lg " data-toggle="collapse" data-target="#visitvideos"></i> &nbsp;&nbsp;
                              <label className="visist-headings" data-toggle="collapse" data-target="#visitvideos"><strong> Site Visit Videos</strong></label>&nbsp;&nbsp;
                              <button className="btn btn-lg btn-link sitevisit-remove-padding" onClick={()=>showVideoUploadSection(true)} ><i className="fas fa-cloud-upload-alt fa-lg fa-alt"></i></button>
                                             
                              </Accordion.Header>
                              <Accordion.Body>
                                <div hidden={siteModel.videos && siteModel.videos.length > 0}>
                                  No Videos
                                </div>
                                { siteModel.videos && siteModel.videos.length > 0 && siteModel.videos.map((video:any,i:any)=>(
                                   <Row>
                                      <Col md={2}>
                                          <strong>{video.name}</strong>
                                            <a className="float-right" onClick={()=>removeVideoDialog(video.id,video.name)}><i className="fa fa-times fa-lg fa-alt"></i></a>
                                      </Col>
                                      <Col md={6}>
                                        <video width="100%" height="200" controls>
                                          <source src={video.path} type={video.type}>
                                            Your browser does not support the video tag.
                                          </source>
                                        </video>
                                        <i className="fa fa-play fa-lg play-on-img" onClick={()=>videoDialog(video.id)}></i>
                                      </Col>
                                      <Col md={2}> {video.date} </Col>
                                   </Row>
                                  ))
                                }
                                {
                                  showUploadVideo && (
                                    <Row>
                                      <input id="svuploadvideo" type="file" className="sitevisitfilechooser" multiple/>
                                      <div onClick={()=>videoUpload()} className="image-upload-wrap">
                                        
                                        <button className="btn btn-lg btn-link sitevisit-remove-padding" ><i className="fas fa-cloud-upload-alt fa-alt upload"></i></button>
                                        <h4>Drag & drop your video here</h4>
                                        <div>Upload videos in format {getLookupProperty('videoExtension').map((extension:any,i:any)=>(
                                            <span>.{extension.code.toLowerCase()}</span>))}</div>
                                      </div>
                                    </Row>
                                  )
                                }
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                              <Accordion.Header>
                                <i className="fa fa-file-photo-o fa-lg " data-toggle="collapse" data-target="#visitimages"></i> 
                                <label className="visist-headings" data-toggle="collapse" data-target="#visitimages">&nbsp;&nbsp;<strong>Site Visit Photos</strong></label>&nbsp;&nbsp;
                                <button hidden={!showUploadImage} className="btn btn-lg btn-link sitevisit-remove-padding" onClick={()=>showUploadImageSection(true)} ><i className="fas fa-cloud-upload-alt fa-lg fa-alt"></i></button>
                              </Accordion.Header>
                              <Accordion.Body>
                                {
                                  siteModel.images && siteModel.images.map((image:any, i:any)=>(
                                    <Row>
                                      
                                    </Row>
                                  ))
                                }
                                <div className="col-md-12" hidden={!showUploadImage}>
                                    <input id="svupload" type="file" className="sitevisitfilechooser" multiple accept=".jpg, .jpeg, .png" />
                                    <div onClick={()=>imageUpload()} className="image-upload-wrap">
                                      <button className="btn btn-lg btn-link sitevisit-remove-padding"><i className="fas fa-cloud-upload-alt fa-lg fa-alt"></i></button>
                                      <h4>Drag & drop your Photos here</h4>
                                      <div><span>Upload Photos in format .jpg, .jpeg, .png</span>
                                      </div>
                                    </div>
                                  </div>
                                No Photos
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                          <Card>
                            <Card.Body>
                              <Row className="mt-3">
                                <Col md={2}>
                                  <Form.Group controlId="employeesseen">
                                    <Form.Check inline label="Employees Seen?" name="employeesseen" type='checkbox' id='employeesseen' onChange={(e)=> {
                                      siteModel.employeesseen = e.target.value;
                                    }} checked={siteModel.employeesseen} />
                                  </Form.Group>
                                </Col>
                                {(siteModel.sitevisited !== 'RES' && siteModel.placevisited !== 'EDU') && <Col md={2}>
                                  <Form.Group controlId="employees">
                                    <Form.Label className="custom-form-label">No of employees *</Form.Label>
                                    <FormControl type="text" disabled name="employees" onKeyDown={allowPositiveIntegersOnly} onChange={(e)=> {
                                      siteModel.employees = e.target.value;
                                      
                                    }} defaultValue={siteModel.employees} />
                                  </Form.Group>
                                </Col>}
                                {(siteModel.sitevisited !== 'RES' && siteModel.placevisited !== 'EDU') && 
                                <Col md={2}>
                                  <Form.Group controlId="customersseen">
                                    <Form.Check inline label="Customers Seen?" name="customersseen" type='checkbox' id='customersseen' onChange={(e)=> {
                                      siteModel.customersseen = e.target.value;
                                      if(siteModel.customersseen)
                                        siteModel.customers = 0;
                                    }} defaultValue={siteModel.customersseen }/>
                                  </Form.Group>
                                </Col>}
                                <Col md={2}>
                                  <Form.Group controlId="customers">
                                    <Form.Label className="custom-form-label" >No of customers *</Form.Label>
                                    <FormControl type="number" name="customers" onKeyDown={allowPositiveIntegersOnly} onChange={(e)=> {
                                      siteModel.customers = e.target.value;
                                    }} defaultValue={formData.customers} />
                                  </Form.Group>
                                </Col>
                                <Col md={2}>
                                  <Form.Group controlId="activity">
                                    <Form.Label className="custom-form-label">Activity Level</Form.Label>
                                    <Form.Select className="form-control" onChange={(e)=> siteModel.activity = e.target.value} name="activity" defaultValue={siteModel.activity}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('activitylevel').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>

                            </Card.Body>
                          </Card>
                          <Row className="mt-3">
                            <Form.Group controlId="earningmembers">
                              <Form.Label className="custom-form-label" >Remarks</Form.Label>
                              <FormControl onChange={(e)=>{applicant.remarks = e.target.value;setLoan(loanModel)}} as="textarea" rows={6}
                                style={{ width: '100%', height: '120px' }} defaultValue={applicant.remarks}
                              />
                            </Form.Group>
                          </Row>
                        </Form> }
                      </div>
                    </Tab>

                    <Tab eventKey="networth" title="Net Worth" >
                      <div className="tab-content">
                        <div className="title-header" style={{ marginBottom: '2px' }}>
                          <strong>Instruments
                            <a style={{ cursor: "pointer", textDecoration: 'none', color: 'blue  !important' }} onClick={addInstrument}>
                              <i className="fa fa-plus fa-lg" aria-hidden="true"></i>
                            </a>
                          </strong>
                        </div>
                        
                        <Form className="page-section">
                          {
                            applicant.networth && applicant.networth.instruments && applicant.networth.instruments.length>0 && applicant.networth.instruments.map((item:any, i:any) => (
                              <Row className="mt-3">
                                  <Col md={3}>
                                    <Form.Label className="custom-form-label">{item['instrument']}</Form.Label>
                                  </Col>
                                  <Col md={2}>
                                    <FormControl type="number" name="instrument{i}" onChange={(e)=>{item.amount = e.target.value}} defaultValue={item.amount} onBlur={calculateNetworth} />
                                  </Col>
                                  <Col md={2}>
                                  {item['type']==='OTHER' && <a style={{ cursor: "pointer", textDecoration: 'none', color: 'blue  !important' }} onClick={()=>removeInstrument(i)}>
                                    <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                                  </a>}
                                  </Col>
                              </Row>
                            ))
                          }
                          <Row className="mt-3">
                            <Col md={3}>Total Networth *</Col>
                            <Col md={2}>{loanModel.totalnetworth}</Col>
                          </Row>

                          <Row className="mt-3">
                            <Col md={3}>
                              <Form.Group controlId="cibil">
                                <Form.Label className="custom-form-label" style={errorFont(isTouched.cibil,applicant.networth.score.cibil)} >CIBIL *</Form.Label>
                                <FormControl style={errorStyle(isTouched.cibil,applicant.networth.score.cibil)}  type="number" name="cibil" onChange={(e)=> {
                                  var maxlength=3;
                                  var copy=""+JSON.parse(JSON.stringify( applicant.networth.score.cibil));
                                  if(copy.length>=maxlength){
                                    applicant.networth.score.cibil=parseInt(copy.substring(0,maxlength));
                                  }
                                }} defaultValue={applicant.networth.score.cibil} />
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Group controlId="highmark">
                                <Form.Label className="custom-form-label">CRIF High Mark</Form.Label>
                                <FormControl type="number" name="highmark" onChange={(e)=> applicant.networth.score.highmark = e.target.value } defaultValue={applicant.networth.score.highmark} />
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Group controlId="experian">
                                <Form.Label className="custom-form-label">Experian</Form.Label>
                                <FormControl type="number" name="experian" onChange={(e)=> applicant.networth.score.experian = e.target.value } defaultValue={applicant.networth.score.experian} />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md={3}>
                              <Form.Group controlId="creditcards">
                                <Form.Label className="custom-form-label">No. of Credit Cards</Form.Label>
                                <FormControl type="number" name="creditcards" onChange={(e)=> {
                                  applicant.networth.score.creditcards = e.target.value; 
                                  if(applicant.networth.creditcards===0 || applicant.networth.creditcards===null){
                                    applicant.networth.outstandingcredit = 0;
                                  }
                                }}  defaultValue={applicant.networth.score.creditcards} />
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Group controlId="outstandingcredit">
                                <Form.Label className="custom-form-label">Outstanding Amount (Credit Card)</Form.Label>
                                <FormControl type="number" onKeyDown={allowPositiveIntegersOnly} name="outstandingcredit" disabled={true} onChange={(e)=> applicant.networth.score.outstandingcredit = e.target.value}  defaultValue={applicant.networth.score.outstandingcredit}  />
                              </Form.Group>
                            </Col>
                          </Row>
                          <div className="title-header" style={{ marginBottom: '2px' }}>
                            <strong>Life Insurance Policies
                              <a style={{ cursor: "pointer", textDecoration: 'none', color: 'blue  !important' }} onClick={addInsurance}>
                                <i className="fa fa-plus fa-lg" aria-hidden="true"></i>
                              </a>
                            </strong>
                          </div>
                          <Row className="mt-3">
                            <Col md={3}>
                              <Form.Label className="custom-form-label"><strong>POLICY</strong></Form.Label>
                            </Col>
                            <Col md={3}>
                              <Form.Label className="custom-form-label"><strong>SUM ASSURED</strong></Form.Label>
                            </Col>
                            <Col md={3}>
                              <Form.Label className="custom-form-label"><strong>MATURITY DATE</strong></Form.Label>
                            </Col>
                          </Row>
                          <hr/>
                          {showInsurancePage && applicant.networth.lifeinsurances && applicant.networth.lifeinsurances.length>0 && applicant.networth.lifeinsurances.map((item:any,i:any)=>
                            (<Row className="mt-3">
                            <Col md={3}>
                              <Form.Group controlId="cibil_{i}">
                                
                                <FormControl type="text" name="cibil_{i}" onChange={(e)=> item.policy = e.target.value} defaultValue={item.policy} />
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Group controlId="highmark_{i}">
                                <FormControl type="number" name="highmark_{i}" onChange={(e)=> item.sumassured = e.target.value} defaultValue={item.sumassured} />
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Group controlId="maturitydate_{i}">
                                <FormControl type="date" name="maturitydate" onChange={(e)=> item.maturitydate = e.target.value} defaultValue={item.maturitydate} />
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Button variant="danger" onClick={addLifeInsurance}> Save</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                              <Button variant="dark" onClick={(e)=>lifeinsurancecancel(i)}> Cancel</Button>
                            </Col>
                          </Row>)
                          ) }

                          <div className="title-header" style={{ marginBottom: '2px' }}>
                            <strong>Medical Policies
                              <a style={{ cursor: "pointer", textDecoration: 'none', color: 'blue  !important' }} onClick={addMedication}>
                                <i className="fa fa-plus fa-lg" aria-hidden="true"></i>
                              </a>
                            </strong>
                          </div>
                          <Row className="mt-3">
                            <Col md={3}>
                              <Form.Label className="custom-form-label"><strong>POLICY</strong></Form.Label>
                            </Col>
                            <Col md={3}>
                              <Form.Label className="custom-form-label"><strong>SUM ASSURED</strong></Form.Label>
                            </Col>
                            <Col md={3}>
                              <Form.Label className="custom-form-label"><strong>WHO ARE COVERED?</strong></Form.Label>
                            </Col>
                          </Row>
                          <hr/>
                          { showMedInsurancePage && applicant.networth.medinsurances && applicant.networth.medinsurances.length>0 && applicant.networth.medinsurances.map((item:any,i:any)=>
                            (<Row className="mt-3">
                              <Col md={3}>
                                <Form.Group controlId="policy_{i}">
                                  <FormControl type="text" name="policy_{i}" onChange={(e)=> item.policy = e.target.value} defaultValue={item.policy} />
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Group controlId="sumassured_{i}">
                                  <FormControl type="number" name="sumassured_{i}" onChange={(e)=> item.sumassured = e.target.value} defaultValue={item.sumassured} />
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Group controlId="coverage_{i}">
                                  <Form.Select className="form-control" onChange={(e)=> item.coverage = e.target.value} name="coverage_{i}" defaultValue={item.coverage}>
                                    <option value=""></option>
                                    <option value="Self">Self</option>
                                    <option value="Spouse">Spouse</option>
                                    <option value="Parents">Parents</option>
                                    <option value="Children">Children</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Button variant="danger" onClick={addMedLifeInsurance}> Save</Button> &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button variant="dark" onClick={()=>medicalpoliciescancel(i)}> Cancel</Button>
                              </Col>
                            </Row>)
                            )
                          }
                          <Row className="mt-3">
                            <Form.Group controlId="earningmembers">
                              <Form.Label className="custom-form-label" >Remarks</Form.Label>
                              <FormControl onChange={(e)=>{applicant.remarks = e.target.value;setLoan(loanModel)}} as="textarea" rows={6}
                                style={{ width: '100%', height: '120px' }} defaultValue={applicant.remarks}
                              />
                            </Form.Group>
                          </Row>
                        </Form>
                      </div>
                    </Tab>

                    {applicant.type ==='informal_salaried' && <Tab eventKey="Employer" title="Employer">
                      <div className="tab-content">
                        <div className="title-header" style={{ marginBottom: '2px' }}><strong>Employer</strong></div>
                        <hr/>
                        <Row className="mt-3">
                          <Col md={4}>
                            <Form.Group controlId="employername">
                              <Form.Label className="custom-form-label" >Employer Name *</Form.Label>
                              <FormControl type="text" name="employername" onChange={(e)=> applicant.employer.name = e.target.value} defaultValue={applicant.employer.name} />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group controlId="employeraddress1">
                              <Form.Label className="custom-form-label" >Address1 *</Form.Label>
                              <FormControl type="text" name="employeraddress1" onChange={(e)=> applicant.employer.address.address1 = e.target.value} defaultValue={applicant.employer.address1} />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group controlId="employeraddress2">
                              <Form.Label className="custom-form-label" >Address2 *</Form.Label>
                              <FormControl type="text" name="employeraddress2" onChange={(e)=> applicant.employer.address.address2 = e.target.value} defaultValue={applicant.employer.address2} />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={4}>
                            <Form.Group controlId="employerzipcode">
                              <Form.Label className="custom-form-label">Pin Code</Form.Label>
                              <FormControl type="number" min="0" max="9999" name="employerzipcode" className="form-control" onChange={(e)=> {
                                applicant.employer.address.zipcode = e?.target.value;
                                var maxlength = 6;
                                var copy = "" + applicant.employer.address.zipcode ;
                                if (copy.length===maxlength) {
                                  getEmppincodelocation();
                                }
                                
                              }}  defaultValue={applicant.address.zipcode} />
                            </Form.Group>
                          </Col>
                          <Col md={2} >
                            <Form.Group controlId="fetch">
                              <Button className="form-control" style={{ color: "#fff", backgroundColor: "#ed1d25", borderColor: "#df121a", marginTop: "33px", marginLeft: "18px", width: "100px" }} onClick={()=>{
                                getEmppincodelocation();
                              }}>Fetch</Button>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={4}>
                            <Form.Group controlId="employerstate">
                              <Form.Label className="custom-form-label" >State / Union Territory *</Form.Label>
                              <FormControl type="text" name="employerstate" onChange={(e)=> applicant.employer.address.state = e.target.value} defaultValue={getValue(applicant.employer.address,'state')} />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group controlId="employercity">
                              <Form.Label className="custom-form-label" >City / Village *</Form.Label>
                              <FormControl type="text" name="employercity" onChange={(e)=> applicant.employer.address.city = e.target.value} defaultValue={getValue(applicant.employer.address,'city')} />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group controlId="employerdistrict">
                              <Form.Label className="custom-form-label" >District</Form.Label>
                              <FormControl type="text" name="employerdistrict" onChange={(e)=> applicant.employer.address.district = e.target.value} defaultValue={getValue(applicant.employer.address,'district')} />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={4}>
                            <Form.Group controlId="employerlocality">
                              <Form.Label className="custom-form-label" >Locality *</Form.Label>
                              <FormControl type="text" name="employerlocality" onChange={(e)=> applicant.employer.locality = e.target.value} defaultValue={applicant.employer.locality} />
                            </Form.Group>
                          </Col>
                        </Row>
                        {!applicant.primary && (<Row className="mt-3">
                          <Col md={6}>
                            <Form.Group controlId="employerdistance" >
                              <Form.Check inline label="Property Select to calculate the distance between the proposed property and the employer premise." name="employerdistance" type='checkbox' id='employerdistance' onChange={(e)=> {
                                applicant.employer.address.distance = e.target.value;
                                if(applicant.employer.address.distance==true ){
                                  var addressComponents = [
                                    applicant.employer.address.address1 !== null ? applicant.employer.address.address1 : '',
                                    applicant.employer.address.address2 !== null ? applicant.employer.address.address2 : '',
                                    applicant.employer.address.city !== null ? applicant.employer.address.city : '',
                                    applicant.employer.address.locality !== null ? applicant.employer.address.locality : '',
                                    applicant.employer.address.zipcode !== null ? applicant.employer.address.zipcode : '',
                                    applicant.employer.address.district !== null ? applicant.employer.address.district : '',
                                    applicant.employer.address.state !== null ? applicant.employer.address.state : '',
                                  ];
                  
                                  var filteredAddressComponents = addressComponents.filter(function(component) {
                                    return component;
                                  });
                                  
                                  // Combine the filtered components with a '+' separator
                                  var formattedAddress = filteredAddressComponents.join('+');
                                  var geocodingUrl = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURIComponent(formattedAddress) + '&key=' + googleMapKey;
                                        
                                  const config: AxiosRequestConfig = {
                                    headers: {
                                      "Content-Type": "application/json"
                                    }
                                  }
                                  axios.get(geocodingUrl,  config).then((data:any) => {
                                    if (data.status === 'OK') {
                                      applicant.employer.address.latitude=data.results[0].geometry.location.lat;
                                      applicant.employer.address.longitude=data.results[0].geometry.location.lng;
                                      setLoan(loanModel);
                                    }
                                  })
                                }
                              }} checked={applicant.employer.address.distance}/>
                            </Form.Group>
                          </Col>
                        </Row>)}
                        <Row className="mt-3">
                          <Col md={6}>
                            <Form.Group controlId="constitution">
                              <Form.Label className="custom-form-label" >Constitution *</Form.Label>
                              <Form.Select className="form-control" onChange={(e)=> {
                                applicant.employer.constitution = e.target.value;
                                setLoan(loanModel);
                                }} name="constitution" defaultValue={applicant.employer.constitution}>
                                <option value=""></option>
                                {
                                  getLookupProperty('constitutions').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                }
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group controlId="employmenttype">
                              <Form.Label className="custom-form-label" >Employment Type *</Form.Label>
                              <Form.Select className="form-control" onChange={(e)=> {applicant.employer.employmenttype = e.target.value;setLoan(loanModel)}} name="employmenttype" defaultValue={applicant.employer.employmenttype}>
                                <option value=""></option>
                                {
                                  getLookupProperty('employmenttypes').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                }
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={3}>
                            <Form.Group controlId="employeeassociation">
                              <Form.Label className="custom-form-label" >Current Employment Tenure (Months) *</Form.Label>
                              <FormControl type="number" onKeyDown={allowPositiveIntegersOnly} name="employeeassociation" onChange={(e)=> {applicant.employer.employeeassociation = e.target.value;setLoan(loanModel)}} defaultValue={applicant.employer.employeeassociation} />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group controlId="employeeworkingpremise">
                              <Form.Label className="custom-form-label" >Working from this employer premise (Months) *</Form.Label>
                              <FormControl type="number" name="employeeworkingpremise" onChange={(e)=> {applicant.employer.employeeworkingpremise = e.target.value;setLoan(loanModel)}} defaultValue={applicant.employer.employeeworkingpremise} />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group controlId="employeeskillset">
                              <Form.Label className="custom-form-label" >Employee Skillset *</Form.Label>
                              <Form.Select className="form-control" onChange={(e)=> {applicant.employer.employeeskillset = e.target.value;setLoan(loanModel)}} name="employeeskillset" defaultValue={applicant.employer.employeeskillset}>
                                <option value=""></option>
                                {
                                  getLookupProperty('employeeskills').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                }
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group controlId="salarymode">
                              <Form.Label className="custom-form-label" >Salary Mode *</Form.Label>
                              <Form.Select className="form-control" onChange={(e)=> {applicant.employer.salarymode = e.target.value;setLoan(loanModel)}} name="salarymode" defaultValue={applicant.employer.salarymode}>
                                <option value=""></option>
                                {
                                  getLookupProperty('salarymodes').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                }
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>
                        {!getHideFlagBasedOnEmployerConstitution() && (
                        <Row className="mt-3">
                          <Col md={3}>
                            <Form.Group controlId="profileremarks">
                              <Form.Label className="custom-form-label" >Profile Remarks</Form.Label>
                              <FormControl type="text" name="profileremarks" onChange={(e)=> {applicant.employer.profileremarks = e.target.value;setLoan(loanModel)}} defaultValue={applicant.employer.profileremarks} />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group controlId="employeeworkingpremise">
                              <Form.Label className="custom-form-label" >Employer Existence (Months) *</Form.Label>
                              <FormControl type="number" onKeyDown={allowPositiveIntegersOnly}  name="employeeworkingpremise" onChange={(e)=> {applicant.employer.employeeworkingpremise = e.target.value;setLoan(loanModel)}} defaultValue={applicant.employer.employeeworkingpremise} />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group controlId="employeestype">
                              <Form.Label className="custom-form-label" >Employer Type *</Form.Label>
                              <Form.Select className="form-control" onChange={(e)=> {applicant.employer.type = e.target.value;setLoan(loanModel)}} name="employeestype" defaultValue={applicant.employer.type}>
                                <option value=""></option>
                                {
                                  getLookupProperty('employertypes').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                }
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group controlId="employeesprofile">
                              <Form.Label className="custom-form-label" >Employer Profile *</Form.Label>
                              <Form.Select className="form-control" onChange={(e)=> {applicant.employer.profile = e.target.value;setLoan(loanModel)}} name="employeesprofile" defaultValue={applicant.employer.profile}>
                                <option value=""></option>
                                {
                                  getLookupProperty('profiles').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                }
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>
                        )}
                        <div>Salary Deductions *</div>
                        <Row className="mt-3">
                          <Col md={2}>
                            <Form.Group controlId="salarydeductionsnone" >
                              <Form.Check inline label="None" name="salarydeductionsnone" type='checkbox' id='salarydeductionsnone' onChange={(e)=>{
                                applicant.employer.salarydeductions.none = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.employer.salarydeductions,'none')} />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group controlId="salarydeductionsepf" >
                              <Form.Check inline label="Employees' Provident Fund (EPF)" name="salarydeductionsepf" type='checkbox' disabled={applicant.employer.salarydeductions && applicant.employer.salarydeductions.none } id='salarydeductionsepf' onChange={(e)=> applicant.employer.salarydeductions.epf = e.target.checked} checked={getValue(applicant.employer.salarydeductions,'epf')} />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group controlId="salarydeductionsesi" >
                              <Form.Check inline label="Employees' State Insurance (ESI)" name="salarydeductionsesi" type='checkbox' disabled={applicant.employer.salarydeductions && applicant.employer.salarydeductions.none } id='salarydeductionsesi' onChange={(e)=> applicant.employer.salarydeductions.esi = e.target.checked} checked={getValue(applicant.employer.salarydeductions,'esi')} />
                            </Form.Group>
                          </Col>
                          <Col md={2}>
                            <Form.Group controlId="salarydeductionsemployerloanemi" >
                              <Form.Check inline label="Employer Loan EMI" name="salarydeductionsemployerloanemi" type='checkbox' disabled={applicant.employer.salarydeductions && applicant.employer.salarydeductions.none } id='salarydeductionsemployerloanemi' onChange={(e)=> applicant.employer.salarydeductions.employerloanemi = e.target.checked} checked={getValue(applicant.employer.salarydeductions,'employerloanemi')} />
                            </Form.Group>
                          </Col>
                          <Col md={2}>
                            <Form.Group controlId="tds" >
                              <Form.Check inline label="TDS" name="tds" disabled={applicant.employer.salarydeductions && applicant.employer.salarydeductions.none } type='checkbox' id='tds' onChange={(e)=> applicant.employer.salarydeductions.tds = e.target.checked} checked={getValue(applicant.employer.salarydeductions,'tds')} />
                            </Form.Group>
                          </Col>
                        </Row>
                        {!getHideFlagBasedOnEmployerConstitution() && (
                        <Row className="mt-3">
                          <Col md={3}>
                            <Form.Group controlId="ownership">
                              <Form.Label className="custom-form-label" >Premise Ownership *</Form.Label>
                              <Form.Select className="form-control" onChange={(e)=> {applicant.employer.ownership = e.target.value;setLoan(loanModel)}} name="ownership" defaultValue={applicant.employer.ownership}>
                                <option value=""></option>
                                {
                                  getLookupProperty('ownership').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                }
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group controlId="structure">
                              <Form.Label className="custom-form-label" >Premise Structure *</Form.Label>
                              <Form.Select className="form-control" onChange={(e)=> {applicant.employer.structure = e.target.value;setLoan(loanModel)}} name="structure" defaultValue={applicant.employer.structure}>
                                <option value=""></option>
                                {
                                  getLookupProperty('structures').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                }
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group controlId="permanent">
                              <Form.Label className="custom-form-label" >Permanent Employees *</Form.Label>
                              <FormControl type="number" onKeyDown={allowPositiveIntegersOnly} name="permanent" onChange={(e)=> {applicant.employer.permanent = e.target.value;setLoan(loanModel)}} defaultValue={applicant.employer.permanent} />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group controlId="contract">
                              <Form.Label className="custom-form-label" >Contract Employees *</Form.Label>
                              <FormControl type="number" onKeyDown={allowPositiveIntegersOnly}  name="contract" onChange={(e)=> {applicant.employer.contract = e.target.value;setLoan(loanModel)}} defaultValue={applicant.employer.contract} />
                            </Form.Group>
                          </Col>
                          
                        </Row>
                        )}
                        <div className="title-header" style={{ marginBottom: '2px' }}><strong>Documents Received from Employer</strong></div>
                        <hr/>
                        <Row className="mt-3">
                          <Col md={3}>
                            <Form.Group controlId="empletter" >
                              <Form.Check inline label="Employment Letter" name="empletter" type='checkbox' id='empletter' onChange={(e)=> {applicant.employer.receiveddoc.empletter = e.target.checked;setLoan(loanModel)}} checked={getValue(applicant.employer.receiveddoc,'empletter')} />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group controlId="form16" >
                              <Form.Check inline label="Form 16" name="form16" type='checkbox' id='form16' onChange={(e)=> {applicant.employer.receiveddoc.form16 = e.target.checked;setLoan(loanModel)}} checked={getValue(applicant.employer.receiveddoc,'form16')} />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group controlId="monsalaryslip" >
                              <Form.Check inline label="Monthly Salary Slip" name="monsalaryslip" type='checkbox'  id='monsalaryslip' onChange={(e)=> {applicant.employer.receiveddoc.monsalaryslip = e.target.checked;setLoan(loanModel)}} checked={getValue(applicant.employer.receiveddoc,'monsalaryslip')} />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group controlId="salarycertificate" >
                              <Form.Check inline label="Salary Certificate" name="salarycertificate" type='checkbox' id='tds' onChange={(e)=> {applicant.employer.receiveddoc.salarycertificate = e.target.checked;setLoan(loanModel)}} checked={getValue(applicant.employer.receiveddoc,'salarycertificate')} />
                            </Form.Group>
                          </Col>
                        </Row>
                        {!getHideFlagBasedOnEmployerConstitution() && (<div>
                          <div className="title-header" style={{ marginBottom: '2px' }}><strong>Business Documents</strong></div>
                          <hr/>
                          <Row className="mt-3">
                            <Col md={2}><strong>Document</strong></Col>
                            <Col md={2}><strong>Applicability</strong></Col>
                            <Col md={2}><strong>Verified</strong></Col>
                            <Col md={2}><strong>Remarks</strong></Col>
                            <Col md={2}></Col>
                          </Row>
                          {applicant.employer.documents && applicant.employer.documents.map((item:any,i:any)=>(
                            <Row className="mt-3">
                              <Col md={2}>{item.name}</Col>
                              <Col md={2}>
                                <Form.Check inline label="" name={"applicability"+i} type='checkbox' id={"applicability"+i} onChange={(e)=>{ 
                                  item.applicability = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={item.applicability} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label=""  name={"verified_"+i} type='checkbox' onChange={(e)=> {
                                  item.verified = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={item.verified} />
                              </Col>
                              <Col md={2}>
                                <Form.Group controlId="remarks">
                                  <FormControl type="text"  name={"remarks"+i} onChange={(e)=> {
                                    item.remarks = e.target.value;
                                    setLoan(loanModel);
                                  }} defaultValue={item.remarks} />
                                </Form.Group>
                              </Col>
                              <Col md={1}>
                                <input id={"employer"+item.name+item.id} type="file" className="sitevisitfilechooser" multiple/>
                                
                              </Col>
                              <Col md={2}>
                              <a className="btn btn-lg sitevisit-remove-padding" onClick={()=>uploadDocument('employer'+item.name+item.id,'employer',item.id,item.name)} ><i className="fa fa-cloud-upload-alt fa-lg"></i></a>  
                              </Col>
                            </Row>)
                          )}
                          
                        </div>
                      )}  
                      <Row className="mt-3">
                        <Form.Group controlId="earningmembers">
                          <Form.Label className="custom-form-label" >Remarks</Form.Label>
                          <FormControl onChange={(e)=>{applicant.remarks = e.target.value;setLoan(loanModel)}} as="textarea" rows={6}
                            style={{ width: '100%', height: '120px' }} defaultValue={applicant.remarks}
                          />
                        </Form.Group>
                      </Row>
                      </div>
                    </Tab>}
                    
                    {applicant.type ==='informal_salaried' && <Tab eventKey="Income" title="Income" >
                      <div className="tab-content">
                        <div className="title-header" style={{ marginBottom: '2px' }}><strong>Salary</strong></div>
                        <hr/>
                        <Row className="mt-3">
                          <Col md={2}><strong>Earnings</strong><a onClick={()=>addSalaryEarnings()} className="butn"><i className="fa fa-plus fa-lg"></i></a></Col>
                          {applicant.income && applicant.income.transactionperiod && applicant.income.transactionperiod.map((item:any,i:any)=>(
                            <Col md={2} ><label className='label label-danger'>{item}</label></Col>  
                          ))
                          }
                        </Row>
                        {applicant.income && applicant.income.earnings && applicant.income.earnings.map((earning:any,i:any)=>(
                            <Row className="mt-3">
                              <Col md={2}>{earning.name}</Col>
                              <Col md={2}>
                                <FormControl type="number" onKeyDown={allowPositiveIntegersOnly}  name={'mamount'+i} onChange={(e)=> {earning.mamount = e.target.value;setLoan(loanModel)}} defaultValue={earning.mamount} onBlur={recalcTotal}/>
                              </Col>
                              <Col md={2}>
                                <FormControl type="number" onKeyDown={allowPositiveIntegersOnly}  name={'m1amount'+i} onChange={(e)=> {earning.m1amount = e.target.value;setLoan(loanModel)}} defaultValue={earning.m1amount} onBlur={recalcTotal}/>
                              </Col>
                              <Col md={2}>
                                <FormControl type="number" onKeyDown={allowPositiveIntegersOnly}  name={'m2amount'+i} onChange={(e)=> {earning.m2amount = e.target.value;setLoan(loanModel)}} defaultValue={earning.m2amount} onBlur={recalcTotal}/>
                              </Col>
                              <Col md={2}>
                                {earning.type === 'OTHER' && <a data-widgster="close"  onClick={()=>removeEarning(i)}><i className="fa fa-times fa-lg"></i></a>}
                              </Col>
                            </Row>
                          ))  
                        }
                        <Row className="mt-3">
                          <Col md={2}><strong>Total Gross Salary</strong></Col>
                          <Col md={2}><label className='label label-danger'>{loanModel.totalsalaryearningmamount}</label></Col>
                          <Col md={2}><label className='label label-danger'>{loanModel.totalsalaryearningm1amount}</label></Col>
                          <Col md={2}><label className='label label-danger'>{loanModel.totalsalaryearningm2amount}</label></Col>
                        </Row>
                        
                        <Row className="mt-3">
                          <Col md={2}><strong>Deductions</strong><a onClick={()=>addSalaryDeductions()} className="butn"><i className="fa fa-plus fa-lg"></i></a></Col>
                          {applicant.income && applicant.income.transactionperiod && applicant.income.transactionperiod.map((item:any,i:any)=>(
                            <Col md={2} ><label className='label label-danger'>{item}</label></Col>  
                          ))
                          }
                        </Row>
                        {applicant.income && applicant.income.deductions && applicant.income.deductions.map((deduction:any,i:any)=>(
                            <Row className="mt-3">
                              <Col md={2}>{deduction.name}</Col>
                              <Col md={2}>
                                <FormControl type="number" onKeyDown={allowPositiveIntegersOnly}  name={'deductionmamount'+i} onChange={(e)=> {deduction.mamount = e.target.value;setLoan(loanModel)}} defaultValue={deduction.mamount} onBlur={recalcTotal}/>
                              </Col>
                              <Col md={2}>
                                <FormControl type="number" onKeyDown={allowPositiveIntegersOnly}  name={'deductionm1amount'+i} onChange={(e)=> {deduction.m1amount = e.target.value;setLoan(loanModel)}} defaultValue={deduction.m1amount} onBlur={recalcTotal}/>
                              </Col>
                              <Col md={2}>
                                <FormControl type="number" onKeyDown={allowPositiveIntegersOnly}  name={'deductionm2amount'+i} onChange={(e)=> {deduction.m2amount = e.target.value;setLoan(loanModel)}} defaultValue={deduction.m2amount} onBlur={recalcTotal}/>
                              </Col>
                              <Col md={2}>
                                {deduction.type === 'OTHER' && <a data-widgster="close"  onClick={()=>removeDeduction(i)}><i className="fa fa-times fa-lg"></i></a>}
                              </Col>
                            </Row>
                          ))  
                        }
                        <Row className="mt-3">
                          <Col md={2}><strong>Total Deductions</strong></Col>
                          <Col md={2}><label className='label label-danger'>{loanModel.totalsalarydeductionmamount}</label></Col>
                          <Col md={2}><label className='label label-danger'>{loanModel.totalsalarydeductionm1amount}</label></Col>
                          <Col md={2}><label className='label label-danger'>{loanModel.totalsalarydeductionm2amount}</label></Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={2}><strong>Total Net Salary</strong></Col>
                          <Col md={2}><label className='label label-success'>{loanModel.totalnetsalarymamount}</label></Col>
                          <Col md={2}><label className='label label-success'>{loanModel.totalnetsalarym1amount}</label></Col>
                          <Col md={2}><label className='label label-success'>{loanModel.totalnetsalarym2amount}</label></Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={2}>Monthly Regular Salary to be considered (AVERAGE)</Col>
                          <Col md={4}>
                            <Form.Select className="form-control" onChange={(e)=> {
                                loanModel.salaryconsiderationtype = e.target.value;
                                calculateMonthly();
                                setLoan(loanModel)
                              }} name="structure" defaultValue={loanModel.salaryconsiderationtype}>
                              <option value=""></option>
                              <option value="LATEST">Latest Month</option>
                              <option value="PREVIOUS">Previous Month</option>
                              <option value="AVERAGE">Average</option>
                              <option value="MAXIMUM">Maximum</option>
                              <option value="MINIMUM">Minimum</option>
                            </Form.Select>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={2}>Considered Monthly Net Salary</Col>
                          <Col md={2}><label className='label label-warning'>{applicant.income.monthlyamount}</label></Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={2}><strong>Other Income</strong><a onClick={()=>addOtherIncome()} className="butn"><i className="fa fa-plus fa-lg"></i></a></Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={2}><strong>Other Income</strong></Col>
                          <Col md={2}><strong>Monthly Amount</strong></Col>
                          <Col md={2}><strong>% to be considered</strong></Col>
                          <Col md={2}><strong>Eligible Amount</strong></Col>
                        </Row>
                        {applicant.income && applicant.income.otherincomes && applicant.income.otherincomes.map((income:any,i:any)=>(
                            <Row className="mt-3">
                              <Col md={2}>{income.name}</Col>
                              <Col md={2}>
                                <FormControl type="number" onKeyDown={allowPositiveIntegersOnly}  name={'monthlyamount'+i} onChange={(e)=> {income.monthlyamount = e.target.value;setLoan(loanModel)}} defaultValue={income.mamount} onBlur={()=>{
                                  income.eligibleamount=income.monthlyamount*income.percentage/100;
                                  calcTotalIncome();
                                }}/>
                              </Col>
                              <Col md={2}>
                                <FormControl type="number" onKeyDown={allowPositiveIntegersOnly}  name={'percentage'+i} onChange={(e)=> {income.percentage = e.target.value;setLoan(loanModel)}} defaultValue={income.percentage} onBlur={()=>{
                                  income.eligibleamount=income.monthlyamount*income.percentage/100;
                                  calcTotalIncome()
                                }}/>
                              </Col>
                              <Col md={2}>
                                <FormControl type="number" onKeyDown={allowPositiveIntegersOnly} disabled={true} name={'deductionm2amount'+i} defaultValue={income.eligibleamount}/>
                              </Col>
                              <Col md={2}>
                                {income.type === 'OTHER' && <a data-widgster="close"  onClick={()=>removeOtherIncome(i)}><i className="fa fa-times fa-lg"></i></a>}
                              </Col>
                            </Row>
                          ))  
                        }
                        <Row className="mt-3">
                          <Col md={6}></Col>
                          <Col md={2}><label className='label label-warning'>{loanModel.totalotherincome}</label></Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={2}><strong>Income Proof Documents</strong></Col>
                        </Row>
                        <hr/>
                        <Row className="mt-3">
                          <Col md={2}><strong>Document</strong></Col>
                          <Col md={2}><strong>Applicability</strong></Col>
                          <Col md={2}><strong>Verified</strong></Col>
                          <Col md={2}><strong>Remarks</strong></Col>
                          <Col md={2}></Col>
                        </Row>
                        {applicant.income.documents && applicant.income.documents.map((item:any,i:any)=>(
                          <Row className="mt-3">
                            <Col md={2}>{item.name}</Col>
                            <Col md={2}>
                              <Form.Check inline label="" name={"applicability"+i} type='checkbox' id={"applicability"+i} onChange={(e)=>{ 
                                item.applicability = e.target.checked;
                                setLoan(loanModel);
                              }} checked={item.applicability} />
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label=""  name={"verified_"+i} type='checkbox' onChange={(e)=> {
                                item.verified = e.target.checked;
                                setLoan(loanModel);
                              }} checked={item.verified} />
                            </Col>
                            <Col md={2}>
                              <Form.Group controlId="remarks">
                                <FormControl type="text"  name={"remarks"+i} onChange={(e)=> {
                                  item.remarks = e.target.value;
                                  setLoan(loanModel);
                                }} defaultValue={item.remarks} />
                              </Form.Group>
                            </Col>
                            <Col md={1}>
                              <input id={"income"+item.name+item.id} type="file" className="sitevisitfilechooser" multiple/>
                              
                            </Col>
                            <Col md={2}>
                                <a className="btn btn-lg sitevisit-remove-padding" onClick={()=>uploadDocument('income'+item.name+item.id,'income',item.id,item.name)} ><i className="fa fa-cloud-upload-alt fa-lg"></i></a>  
                            </Col>
                          </Row>)
                        )}
                        <Row className="mt-3">
                          <Form.Group controlId="earningmembers">
                            <Form.Label className="custom-form-label" >Remarks</Form.Label>
                            <FormControl onChange={(e)=>{applicant.remarks = e.target.value;setLoan(loanModel)}} as="textarea" rows={6}
                              style={{ width: '100%', height: '120px' }} defaultValue={applicant.remarks}
                            />
                          </Form.Group>
                        </Row>
                      </div>
                    </Tab>}
                    
                    {applicant.type ==='informal_selfemployed' && <Tab eventKey="Business" title="Business"  >
                      <label>&nbsp;</label>
                      <Tabs className="custom-tabs">
                        <Tab eventKey="Business" title="Business" >
                          <Row className="mt-3">
                            <Col md={4}>
                              <Form.Group controlId="name">
                                <Form.Label className="custom-form-label" >Business / Shop Name *</Form.Label>
                                <FormControl type="text" className="form-control" name="visitdate" onChange={(e)=> {
                                  applicant.business.name = e.target.value;
                                  setLoan(loanModel);
                                }} defaultValue={applicant.business.name} />
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group controlId="type">
                                <Form.Label className="custom-form-label">Business Type *</Form.Label>
                                <FormControl type="text" className="form-control" disabled={true} name="type" onChange={(e:any)=>{
                                  applicant.business.type = e.target.value;
                                }}  defaultValue={applicant.business.type} />
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group controlId="profile">
                                <Form.Label className="custom-form-label" >Business Profile</Form.Label>
                                <Form.Select className="form-control"  onChange={(e)=> {
                                    applicant.business.profile = e.target.value;
                                    var p = e.target.value;
                                    console.log('applicant.business.profile==>',applicant.business.profile)
                                    if (
                                      p==="Agri products" ||
                                      p==="Aluminium / Iron Fabrication Work" ||
                                      p==="Auto ancillary" ||
                                      p==="Bicycle" ||
                                      p==="Bricks / Brick Kiln / Cement / Tiles" ||
                                      p==="Chemicals / Paints / Industrial Gas / Polymers" ||
                                      p==="Computer / Electronics components" ||
                                      p==="Construction Equipment" ||
                                      // p==="Dairy Farm" ||
                                      p==="Engineering Goods / Durable Parts / Electronics" ||
                                      p==="Fishery" ||
                                      p==="Food / Beverage Production" ||
                                      p==="Food Processing" ||
                                      p==="Glass / Mirror" ||
                                      p==="Household Items / Broom" ||
                                      p==="Leather Apparels / Leather Shoes / Leather Belts / Tannery" ||
                                      p==="Handloom" ||
                                      p==="Medical / Surgical Material & Equipment" ||
                                      p==="Paper & Stationery" ||
                                      p==="Photo Frames / Gift Articles" ||
                                      p==="Plastics" ||
                                      p==="Poultry  Farm / Fishery" ||
                                      p==="Property Developer / Builder" ||
                                      p==="Security Equipment/ Fire Extinguishers / Fire Alarms" ||
                                      p==="Steel / Brass / Iron / Copper Parts" ||
                                      p==="Sweets / Farsan" ||
                                      p==="Textile / Dye" ||
                                      p==="Tyres" ||
                                      p==="Wood Furniture / Carpentry / Timber" ||
                                      p==="Stationery (Manufacturer)" ||
                                      p==="Power Looms"
                                    ) {
                                      applicant.business.type = "MFR";
                                    } else if (
                                      p==="Accounting & Tax" ||
                                      p==="Agent - Auto" ||
                                      p==="Agent - Clearing and Forwarding" ||
                                      p==="Agent - Insurance" ||
                                      p==="Agent - KYC" ||
                                      p==="Agent - Real Estate" ||
                                      p==="Agent - Referral / DSA" ||
                                      p==="Agent - Tour and Travel" ||
                                      p==="Auto wheel alignment" ||
                                      p==="Babysitter" ||
                                      p==="Bar" ||
                                      p==="Beauty Parlour / Spa / Hair Saloon" ||
                                      p==="Broker - Financial securities / Shares" ||
                                      p==="Cable Operator" ||
                                      p==="Caterers" ||
                                      p==="Chit Fund" ||
                                      p==="Coaching Classes" ||
                                      p==="Consultants" ||
                                      p==="Contractor - Housekeeping Service" ||
                                      p==="Contractor - Plumber, Electrician, Carpenter" ||
                                      p==="Courier" ||
                                      p==="Craftsman" ||
                                      p==="Driving  School" ||
                                      p==="Flour Mill" ||
                                      p==="Food / Tea Stall" ||
                                      p==="Food Court / Food Outlet / Snacks & Juice Centre" ||
                                      p==="Gaming Parlour" ||
                                      p==="Garage" ||
                                      p==="Gym Owner / Health / Fitness" ||
                                      p==="Hostel / Lodging & Boarding" ||
                                      p==="Interior Decorator / Designer" ||
                                      p==="IT Services / Software Development" ||
                                      p==="Jewellery - Goldsmith / Silversmith" ||
                                      p==="Laundry / Dry Cleaners" ||
                                      p==="Library / Books / Toys Rental" ||
                                      p==="Mandap Decorator / Light & Sound" ||
                                      p==="Man-Power Supply / Staffing" ||
                                      p==="Moneylender" ||
                                      p==="Mover & Packers" ||
                                      p==="Newspaper Vendor" ||
                                      p==="Pathology / Diagnostics / Lab" ||
                                      p==="Photography / Video Services / Studio" ||
                                      p==="Priest / Astrologer" ||
                                      p==="Printing Press / Xerox & Printing Services" ||
                                      p==="Rentals / Leasing" ||
                                      p==="Repairs & AMC - Computer / Mobile / AC / Electronics" ||
                                      p==="Tailoring / Embroidery" ||
                                      p==="Transport" ||
                                      p==="Travel" ||
                                      p==="Tutors" ||
                                      p==="Wood Work / Furniture / Upholstery / Timber / Plywood" ||
                                      p==="Laundry and Dry Cleaning" ||
                                      p==="Xerox / Stationary Shop" ||
                                      p==="Stationery Shop (Service)" ||
                                      p==="Bakeries & Food Products" ||
                                      p==="Restaurant & Food Service Industry"
                                    ) {
                                      applicant.business.type = "SVC";
                                    } else if (
                                      p==="Automobile parts / Tyre / Lubricants - Retailer" ||
                                      p==="Automobile parts / Tyre / Lubricants - Wholesaler" ||
                                      p==="Bicycle" ||
                                      p==="Books & Stationery" ||
                                      p==="Chemicals / Paints / Industrial Gas / Polymers" ||
                                      p==="Clothing - Retailer" ||
                                      p==="Clothing - Wholesaler" ||
                                      p==="Computer / Accessories / Repairing" ||
                                      p==="Construction raw materials - Brick, Cement, Sand" ||
                                      p==="Construction raw materials - Cement, Asbestos, Brick, Sand" ||
                                      p==="Consumer durables - Retailer" ||
                                      p==="Consumer durables - Wholesaler" ||
                                      p==="Container / Packaging Related Products" ||
                                      p==="Cosmetics / Perfumes / Beauty Products" ||
                                      p==="Dairy - Trader" ||
                                      p==="Electrical Equipment / Hardware - Retailer" ||
                                      p==="Electrical Equipment / Hardware - Wholesaler" ||
                                      p==="Electronic Equipment - Retailer" ||
                                      p==="Electronic Equipment - Wholesaler" ||
                                      p==="Hardware - Retailer" ||
                                      p==="Hardware - Wholesaler" ||
                                      p==="Electrical & Electronic - Retailer" ||
                                      p==="Electrical & Electronic - Wholesaler" ||
                                      p==="Fertilizer / Pesticides" ||
                                      p==="FMCG - Retailer" ||
                                      p==="FMCG - Wholesaler" ||
                                      p==="Food Related Products" ||
                                      p==="Footwear / Bags" ||
                                      p==="Furniture / Wood Work" ||
                                      p==="General Merchandising Stores / Fashion Accessories" ||
                                      p==="Gift / Fancy Items Store" ||
                                      p==="Healthcare / Surgical Equipment" ||
                                      p==="Ice-cream Parlour" ||
                                      p==="Industrial Equipment" ||
                                      p==="Jewellery Shop / Jewellery Commission" ||
                                      p==="Marble / Granite / Tiles" ||
                                      p==="Metal / Steel / Brass / Copper / Glass" ||
                                      p==="Metal / Steel / Brass / Copper / Glass (Trader)" ||
                                      p==="Mobile / Accessories / Repairing / Recharge" ||
                                      p==="Pet Animals / Pet Related Products" ||
                                      p==="Poultry / Fish / Meat Products" ||
                                      p==="Scrap Dealer" ||
                                      p==="Small Kirana / Provision / Pan Store" ||
                                      p==="Small Medical Owner / Drug & Pharmaceutical / Medicine Vendors" ||
                                      p==="Spectacles / Opticals / Sun Glasses / Contact Lenses" ||
                                      p==="Sweets / Farsan Shop" ||
                                      p==="Tobacco / Alcohol" ||
                                      p==="Two / Four Wheeler Showroom" ||
                                      p==="Vegetables / Fruits / Flowers Vendor" ||
                                      p==="Wood Work / Furniture / Upholstery / Timber / Plywood" ||
                                      p==="Dairy Farm" ||
                                      p==="Dairy Trader" ||
                                      p==="Livestock - Poultry / Meat Products" ||
                                      p==="Mobile / Recharge / Repairing / Accessories" ||
                                      p==="Street Food Stall/Cart" ||
                                      p==="Stationery Shop (Trader)" ||
                                      p==="Leather / Shoes Stores" ||
                                      p==="Paints & Varnish Stores" ||
                                      p==="Ceramic Tiles & Sanitary Ware" ||
                                      p==="Papers & Printing Products / Stores" ||
                                      p==="Non-Alcoholic Beverages" ||
                                      p==="Battery" ||
                                      p==="Animal Feed / Pashu Aahar" 
                                    ) {
                                      applicant.business.type = "TRD";
                                    } else if (
                                      p==="Actor / Actress" ||
                                      (p==="Architect") ||
                                      (p==="Ayuervedic / Unani / Homeopathy Doctor") ||
                                      (p==="Chartered Accountant") ||
                                      (p==="Company Secretary") ||
                                      (p==="Cost And Work Accountant") ||
                                      (p==="Dancer") ||
                                      (p==="Freelancer") ||
                                      (p==="Journalist") ||
                                      (p==="Lawyer") ||
                                      (p==="Music Artist") ||
                                      (p==="Painter") ||
                                      (p==="Physiotherapist") ||
                                      (p==="Singer") ||
                                      (p==="Surveyor")
                                    ) {
                                      applicant.business.type = "PRF";
                                    } else {
                                      applicant.business.type = "";
                                    }
                                    setLoan(loanModel);
                                  }} name="placevisited" defaultValue={applicant.business.profile}>
                                  <option value=""></option>
                                  {
                                    getLookupProperty('PROFILES').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                  }
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          </Row>
                          <strong>Business / Shop Address</strong>
                          <hr/>
                          <Row className="mt-3">
                            <Col md={4}>
                              <Form.Group controlId="zipcode">
                                <Form.Label className="custom-form-label">Pin Code</Form.Label>
                                <FormControl type="number" min="0" max="9999" className="form-control" onChange={(e)=> {
                                  applicant.business.address.zipcode = e?.target.value;
                                  getbizpincodelocation();
                                  
                                }} name="zipcode" defaultValue={getValue(applicant.business.address,'zipcode')} />
                              </Form.Group>
                            </Col>
                            <Col md={2} >
                              <Form.Group controlId="fetch">
                                <Button className="form-control" style={{ color: "#fff", backgroundColor: "#ed1d25", borderColor: "#df121a", marginTop: "33px", marginLeft: "18px", width: "100px" }} onClick={()=>{
                                  getbizpincodelocation();
                                }}>Fetch</Button>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md={4}>
                              <Form.Group controlId="address1">
                                <Form.Label className="custom-form-label">Address Line 1 *</Form.Label>
                                <FormControl type="text" name="address1" onKeyDown={validateText} onChange={(e)=> {
                                  applicant.business.address.address1 = e?.target.value;setLoan(loanModel)}
                                  } defaultValue={getValue(applicant.business.address,'address1')} />
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group controlId="address2">
                                <Form.Label className="custom-form-label">Address Line 2 *</Form.Label>
                                <FormControl type="text" name="address2" onKeyDown={validateText} onChange={(e)=> {applicant.business.address.address2 = e?.target.value;setLoan(loanModel)}} defaultValue={getValue(applicant.business.address,'address2')} />
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group controlId="state">
                                <Form.Label className="custom-form-label">State / Union Territory *</Form.Label>
                                <FormControl type="text" name="state" onKeyDown={validateText} onChange={(e)=> {applicant.business.address.state = e?.target.value;setLoan(loanModel)}} defaultValue={getValue(applicant.business.address,'state')} />
                              </Form.Group>
                            </Col>

                          </Row>

                          <Row className="mt-3">
                            <Col md={4}>
                              <Form.Group controlId="city">
                                <Form.Label className="custom-form-label">City / Village *</Form.Label>
                                <FormControl type="text" name="city" onKeyDown={validateText} onChange={(e)=> {applicant.business.address.city = e?.target.value;setLoan(loanModel)}} defaultValue={getValue(applicant.business.address,'city')} />
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group controlId="district">
                                <Form.Label className="custom-form-label">District *</Form.Label>
                                <FormControl type="text" name="district" onKeyDown={validateText} onChange={(e)=> {applicant.business.address.district = e?.target.value;setLoan(loanModel)}} defaultValue={getValue(applicant.business.address,'district')} />
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group controlId="locality">
                                <Form.Label className="custom-form-label">Locality *</Form.Label>
                                <FormControl type="text" name="locality" onKeyDown={validateText} onChange={(e)=> {applicant.business.address.locality = e?.target.value;setLoan(loanModel)}} defaultValue={getValue(applicant.business.address,'locality')} />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md={6}>
                              
                                <Form.Check inline label="Select to calculate the distance between the proposed property and the current residence." name="distance" onChange={(e)=>{ 
                                    applicant.business.address.distance = e?.target.value;
                                    if(applicant.business.address.distance==true){
                                      applicant.business.address.address1 = applicant.business.address.address1!=null ? applicant.business.address.address1 :'';
                                      applicant.business.address.address2 = applicant.business.address.address2!=null ? applicant.business.address.address2 :'';
                                      applicant.businessaddress.city = applicant.business.address.city!=null ? applicant.business.address.city :'';
                                      applicant.business.address.state = applicant.business.address.state!=null ? applicant.business.address.state :'';
                                      applicant.business.address.locality = applicant.business.address.locality!=null ? applicant.business.address.locality :'';
                                      applicant.business.address.district = applicant.business.address.district!=null ? applicant.business.address.district :'';
                                      applicant.business.address.zipcode = applicant.business.address.zipcode!=null ? applicant.business.address.zipcode :'';
                                      var apiKey = 'AIzaSyC3F2Dwsz-mYj46nLoUTo34Zh8GaP6yWSc';

                                      var addressComponents = [
                                        applicant.business.address.address1 ,
                                        applicant.business.address.address2,
                                        applicant.business.address.city,
                                        applicant.business.address.locality,
                                        applicant.business.address.zipcode,
                                        applicant.business.address.district,
                                        applicant.business.address.state
                                      ];
                                      var filteredAddressComponents = addressComponents.filter(function(component) {
                                        return component;
                                      });
                                      var formattedAddress = filteredAddressComponents.join('+');
                                      var geocodingUrl = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURIComponent(formattedAddress) + '&key=' + googleMapKey;
                                      
                                      const config: AxiosRequestConfig = {
                                        headers: {
                                          "Content-Type": "application/json"
                                        }
                                      }
                                      axios.get(geocodingUrl,  config).then((data:any) => {
                                        if (data.status === 'OK') {
                                          applicant.business.address.latitude=data.results[0].geometry.location.lat;
                                          applicant.business.address.longitude=data.results[0].geometry.location.lng;

                                        }
                                      })
                                    }
                                    setLoan(loanModel);
                                  }}  type="checkbox" defaultValue={getValue(applicant.business.address,'distance')} />
                              
                            </Col>
                          </Row>
                          <label>Business / Shop Locality *</label>
                          <Row className="mt-3">
                            <Col md={2}>
                                <Form.Check inline label="In market" name="localityofbiz.market" type='checkbox' id='localityofbiz.market' onChange={(e)=>{
                                  applicant.business.localityofbiz.market = e.target.checked;
                                  setLoan(loanModel);
                                }}  checked={getValue(applicant.business.localityofbiz,'market')} />

                            </Col>
                            <Col md={2}>
                                <Form.Check inline label="At highway" name="localityofbiz.highway" type='checkbox' id='localityofbiz.highway' onChange={(e)=>{
                                  applicant.business.localityofbiz.highway = e.target.checked;
                                  setLoan(loanModel);
                                }}  checked={getValue(applicant.business.localityofbiz,'highway')} />

                            </Col>
                            <Col md={1}>
                                <Form.Check inline label="Near temple" name="localityofbiz.temple" type='checkbox' id='localityofbiz.temple' onChange={(e)=>{
                                  applicant.business.localityofbiz.temple = e.target.checked;
                                  setLoan(loanModel);
                                }}  checked={getValue(applicant.business.localityofbiz,'temple')} />

                            </Col>
                            <Col md={1}>
                                <Form.Check inline label="Near offices" name="localityofbiz.offices" type='checkbox' id='localityofbiz.offices' onChange={(e)=>{
                                  applicant.business.localityofbiz.offices = e.target.checked;
                                  setLoan(loanModel);
                                }}  checked={getValue(applicant.business.localityofbiz,'offices')} />

                            </Col>
                            <Col md={1}>
                                <Form.Check inline label="Industrial" name="localityofbiz.industrial" type='checkbox' id='localityofbiz.industrial' onChange={(e)=>{
                                  applicant.business.localityofbiz.industrial = e.target.checked;
                                  setLoan(loanModel);
                                }}  checked={getValue(applicant.business.localityofbiz,'industrial')} />

                            </Col>
                            <Col md={1}>
                                <Form.Check inline label="Residential area" name="localityofbiz.area" type='checkbox' id='localityofbiz.area' onChange={(e)=>{
                                  applicant.business.localityofbiz.area = e.target.checked;
                                  setLoan(loanModel);
                                }}  checked={getValue(applicant.business.localityofbiz,'area')} />

                            </Col>

                            <Col md={1}>
                                <Form.Check inline label="Near railway / bus station" name="localityofbiz.busyroad" type='checkbox' id='localityofbiz.busyroad' onChange={(e)=>{
                                  applicant.business.localityofbiz.busyroad = e.target.checked;
                                  setLoan(loanModel);
                                }}  checked={getValue(applicant.business.localityofbiz,'busyroad')} />

                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Form.Group controlId="earningmembers">
                              <Form.Label className="custom-form-label" >Remarks</Form.Label>
                              <FormControl onChange={(e)=>{applicant.remarks = e.target.value;setLoan(loanModel)}} as="textarea" rows={6}
                                style={{ width: '100%', height: '120px' }} defaultValue={applicant.remarks}
                              />
                            </Form.Group>
                          </Row>
                        </Tab>
                        <Tab eventKey="BusinessShopManagement" title="Business / Shop Management">
                          <Row className="mt-3">
                            <Col md={2}>
                              <Form.Group controlId="constitution">
                                <Form.Label className="custom-form-label">Entity Type *</Form.Label>
                                <Form.Select className="form-control" onChange={(e)=> {
                                    applicant.business.constitution = e.target.value;
                                    if (applicant.business.constitution==='Proprietorship') {
                                      applicant.businessincome.profitshare = 100;
                                      calculateShareIncome()
                                      
                                    }
                                    if (applicant.business.constitution!=='Proprietorship') {
                                      applicant.business.profitshare = 0;
                                      applicant.businessincome.profitshare = 0;
                                      calculateShareIncome()
                                    }
                                  }} name="constitution" defaultValue={applicant.business.constitution}>
                                  <option value=""></option>
                                  {
                                    getLookupProperty('SEconstitutions').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                  }
                                </Form.Select>

                              </Form.Group>
                            </Col>
                            <Col md={2}>
                            <Form.Group controlId="profitshare">
                                <Form.Label className="custom-form-label" >% Share of Profit *</Form.Label>
                                <FormControl type="number" min={0} max={100} onKeyDown={allowPositiveIntegersOnly} name="profitshare" onChange={(e)=> {
                                  applicant.businessincome.profitshare = e.target.value;
                                  var maxlength = 2;
                                  var copy = "" + e.target.value;
                                  if (applicant.business.profitshare!==null) {
                                    if (copy.length >= maxlength) {
                                      applicant.business.profitshare = parseInt(copy.substring(0, maxlength));
                                      applicant.businessincome.profitshare = applicant.business.profitshare;
                                      calculateShareIncome();
                                    }
                                  }
                                  setLoan(loanModel);
                                }} defaultValue={applicant.business.profitshare} />
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group controlId="establishment">
                                <Form.Label className="custom-form-label" >Year of establishment (YYYY) *</Form.Label>
                                <FormControl type="date" name="establishment" onChange={(e)=> {
                                  applicant.business.establishment = e.target.value;
                                  var maxlength = 4;
                                  var copy = "" + e.target.value;
                                  if (applicant.business.establishment!==null) {
                                    if (copy.length >= maxlength) {
                                      applicant.business.establishment = parseInt(copy.substring(0, maxlength));
                                    }
                                  }
                                  setLoan(loanModel);
                                }} onBlur={()=>{
                                  var currentdate = new Date();	
                                  var month = currentdate.getMonth();
                                  var year = currentdate.getFullYear();	
                                  if (applicant.business.establishment!==null) {
                                    var newyear = year - applicant.business.establishment;
                                    if (applicant.business.establishment > year) {	
                                      applicant.business.establishment = null;	
                                    }	
                                    if (applicant.business.establishment < 1600) {	
                                      applicant.business.establishment = null;	
                                    }	
                                    if(applicant.business.establishment <= year && applicant.business.establishment > 1600 ){
                                      applicant.business.currentbusinessexperience = (newyear *12)+month;
                                    } 
                                    if(applicant.business.establishment===null ){
                                      applicant.business.currentbusinessexperience = 0;
                                    }
                                  }
                                }} defaultValue={applicant.business.establishment} />
                              </Form.Group>
                            </Col>
                            <Col md={1}>
                              <Form.Check inline label="First generation Business?" name="applicant.business.generation" type='checkbox' id='applicant.business.generation' onChange={(e)=>{
                                    applicant.business.generation = e.target.checked;
                                    setLoan(loanModel);
                                  }}  checked={applicant.business.generation} />
                            </Col>
                            <Col md={1}>
                              <Form.Check inline label="Is Business in Residence?" name="applicant.business.inresidence" type='checkbox' id='applicant.business.inresidence' onChange={(e)=>{
                                    applicant.business.inresidence = e.target.checked;
                                    setLoan(loanModel);
                                  }}  checked={applicant.business.inresidence} />
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md={1}>
                              <Form.Group controlId="family.none" >
                                <Form.Check inline label="None" name="family.none" type='checkbox' id='family.none' onChange={(e)=>{
                                  applicant.business.family.none = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.family,'none')} />
                              </Form.Group>
                            </Col>
                            <Col md={1}>
                              <Form.Group controlId="family.spouse" >
                                <Form.Check inline label="Spouse" name="family.spouse" type='checkbox' disabled={applicant.business.family && applicant.business.family.none } id='family.spouse' onChange={(e)=> {applicant.business.family.spouse = e.target.checked;setLoan(loanModel);}} checked={getValue(applicant.business.family,'spouse')} />
                              </Form.Group>
                            </Col>
                            <Col md={1}>
                              <Form.Group controlId="family.parents" >
                                <Form.Check inline label="Parents" name="family.parents" type='checkbox' disabled={applicant.business.family && applicant.business.family.none } id='family.parents' onChange={(e)=> {applicant.business.family.parents = e.target.checked;setLoan(loanModel);}} checked={getValue(applicant.business.family,'parents')} />
                              </Form.Group>
                            </Col>
                            <Col md={1}>
                              <Form.Group controlId="family.children" >
                                <Form.Check inline label="Children" name="family.children" type='checkbox' disabled={applicant.business.family && applicant.business.family.none } id='family.children' onChange={(e)=> {applicant.business.family.children = e.target.checked;setLoan(loanModel);}} checked={getValue(applicant.business.family,'children')} />
                              </Form.Group>
                            </Col>
                            <Col md={1}>
                              <Form.Group controlId="family.brother" >
                                <Form.Check inline label="Brother" name="tdsfamily.brother" disabled={applicant.business.family && applicant.business.family.none } type='checkbox' id='tds' onChange={(e)=> {applicant.business.family.brother = e.target.checked;setLoan(loanModel);}} checked={getValue(applicant.business.family,'brother')} />
                              </Form.Group>
                            </Col>
                            <Col md={1}>
                              <Form.Group controlId="family.sister" >
                                <Form.Check inline label="Sister" name="tdsfamily.sister" disabled={applicant.business.family && applicant.business.family.none } type='checkbox' id='tds' onChange={(e)=> {applicant.business.family.sister = e.target.checked;setLoan(loanModel);}} checked={getValue(applicant.business.family,'sister')} />
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group controlId="family.other" >
                                <Form.Check inline label="Any Other Relative" name="family.other" disabled={applicant.business.family && applicant.business.family.none } type='checkbox' id='tds' onChange={(e)=> {applicant.business.family.other = e.target.checked;setLoan(loanModel);}} checked={getValue(applicant.business.family,'other')} />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row hidden={!getValue(applicant.business.family,'other')}>
                            <Col md={4}>
                              <Form.Group controlId="constitution">
                                <Form.Label className="custom-form-label">After applicant who will manage business?</Form.Label>
                                <Form.Select multiple className="form-control" onChange={(e)=> {
                                    applicant.business.constitution = e.target.value;
                                    if(applicant.business.ownernext !=='anyother'){
                                      applicant.business.otherowner = "";
                                    }
                                    setLoan(loanModel);
                                  }} name="constitution" defaultValue={applicant.business.constitution}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('owners').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group controlId="otherowner">
                                <Form.Label className="custom-form-label" >Other relative - Please specify</Form.Label>
                                <FormControl type="text" name="otherowner" onChange={(e)=> {
                                  applicant.business.otherowner = e.target.value;
                                  setLoan(loanModel);
                                }} hidden={applicant.business.ownernext && applicant.business.ownernext ==='anyother'} defaultValue={applicant.business.otherowner} />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row hidden={!getValue(applicant.business.family,'other')}>
                            <Col md={4}>
                              <Form.Group controlId="currentbusinessexperience">
                                <Form.Label className="custom-form-label" >Current Business Experience (Months) *</Form.Label>
                                <FormControl type="number" min={0} name="currentbusinessexperience" onChange={(e)=> {
                                  applicant.business.currentbusinessexperience = e.target.value;
                                  setLoan(loanModel);
                                }} defaultValue={applicant.business.currentbusinessexperience} />
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group controlId="totalexperience">
                                <Form.Label className="custom-form-label" >Total Business Work Experience (Months) *</Form.Label>
                                <FormControl type="number" min={0} name="totalexperience" onChange={(e)=> {
                                  applicant.business.totalexperience = e.target.value;
                                  setLoan(loanModel);
                                }} defaultValue={applicant.business.totalexperience} />
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group controlId="yearsinsamepremise">
                                <Form.Label className="custom-form-label" >Working from this business premise (Months) *</Form.Label>
                                <FormControl type="number" min={0} name="yearsinsamepremise" onChange={(e)=> {
                                  applicant.business.yearsinsamepremise = e.target.value;
                                  setLoan(loanModel);
                                }} defaultValue={applicant.business.yearsinsamepremise} />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Form.Group controlId="earningmembers">
                              <Form.Label className="custom-form-label" >Remarks</Form.Label>
                              <FormControl onChange={(e)=>{applicant.remarks = e.target.value;setLoan(loanModel)}} as="textarea" rows={6}
                                style={{ width: '100%', height: '120px' }} defaultValue={applicant.remarks}
                              />
                            </Form.Group>
                          </Row>
                          <Row className="mt-3">
                            <Col md={4}>
                              <Form.Group controlId="ownership">
                                <Form.Label className="custom-form-label">Business Premise Ownership *</Form.Label>
                                <Form.Select className="form-control" onChange={(e)=> {
                                    applicant.business.ownership = e.target.value;
                                    getRentSqftByLocalityBusiness();
                                    setLoan(loanModel);
                                  }} name="ownership" defaultValue={applicant.business.ownership}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('ownership').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group controlId="structure">
                                <Form.Label className="custom-form-label">Premise Structure *</Form.Label>
                                <Form.Select className="form-control" onChange={(e)=> {
                                    applicant.business.structure = e.target.value;
                                    if(applicant.business.structure!=="Permanent structure"|| applicant.business.structure!=="Temporary structure"){
                                      applicant.business.businessarea=null;
                                      }
                                    setLoan(loanModel);
                                  }} name="structure" defaultValue={applicant.business.structure}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('structures').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group controlId="businessarea">
                                <Form.Label className="custom-form-label" >Business Premise Area (sq ft) *</Form.Label>
                                <FormControl type="number" min={0} name="businessarea" onChange={(e)=> {
                                  applicant.business.businessarea = e.target.value;
                                  setLoan(loanModel);
                                }} hidden={!((applicant.business.structure=="Permanent structure") || (applicant.business.structure=="Temporary structure"))} defaultValue={applicant.business.businessarea} />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md={4}>
                              <Form.Group controlId="emergencyfund">
                                <Form.Label className="custom-form-label">Source of fund for Business fund emergency *</Form.Label>
                                <Form.Select className="form-control" onChange={(e)=> {
                                    applicant.business.emergencyfund = e.target.value;
                                    if (applicant.business.emergencyfund !== "LI") {
                                      applicant.business.specify = null;
                                    }
                                    setLoan(loanModel);
                                  }} name="emergencyfund" defaultValue={applicant.business.emergencyfund}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('emergencyFundSource').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group controlId="specify" >
                                <Form.Check inline label="Please Specify" name="specify" type='checkbox' id='specify' onChange={(e)=> {
                                  applicant.business.specify = e.target.checked;
                                  setLoan(loanModel);
                                  
                                }} checked={applicant.business.specify} />
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group controlId="availedschemes" >
                                <Form.Check inline label="Have you availed any Government Schemes?" name="availedschemes" type='checkbox' id='availedschemes' onChange={(e)=> {
                                  applicant.business.availedschemes = e.target.checked;
                                  if(!applicant.business.availedschemes){
                                    applicant.business.governmentschemes.pmmys=false;
                                    applicant.business.governmentschemes.pmmyk=false;
                                    applicant.business.governmentschemes.pmmyt=false;
                                    applicant.business.governmentschemes.mcs=false;
                                  }
                                  setLoan(loanModel);
                                }} checked={applicant.business.availedschemes} />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>Government Schemes *</Row>
                          <hr/>
                          <Row hidden={applicant.business && applicant.business.availedschemes}>
                            <Col md={2}>
                              <Form.Group controlId="governmentschemes.pmmys" >
                                <Form.Check inline label="Pradhan Mantri MUDRA Yojana - Shishu" name="governmentschemes.pmmys" type='checkbox' id='governmentschemes.pmmys' onChange={(e)=> {applicant.business.governmentschemes.pmmys = e.target.checked;setLoan(loanModel);}} checked={getValue(applicant.business.governmentschemes,'pmmys')} />
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group controlId="governmentschemes.pmmyk" >
                                <Form.Check inline label="Pradhan Mantri MUDRA Yojana - Kishor" name="governmentschemes.pmmyk" type='checkbox' id='governmentschemes.pmmyk' onChange={(e)=> {applicant.business.governmentschemes.pmmyk = e.target.checked;setLoan(loanModel);}} checked={getValue(applicant.business.governmentschemes,'pmmyk')} />
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group controlId="family.pmmyt" >
                                <Form.Check inline label="Pradhan Mantri MUDRA Yojana - Tarun" name="governmentschemes.pmmyt" type='checkbox' id='governmentschemes.pmmyt' onChange={(e)=> {applicant.business.governmentschemes.pmmyt = e.target.checked;setLoan(loanModel);}} checked={getValue(applicant.business.governmentschemes,'pmmyt')} />
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group controlId="family.mcs" >
                                <Form.Check inline label="Micro Credit Scheme" name="governmentschemes.mcs"  type='checkbox' id='governmentschemes' onChange={(e)=> {applicant.business.governmentschemes.mcs = e.target.checked;setLoan(loanModel);}} checked={getValue(applicant.business.governmentschemes,'mcs')} />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Tab>
                        <Tab eventKey="BusinessShopOperation  " title="Business / Shop Operation  ">
                          <label>Separate banking facility availed by Business *</label>
                          <Row>
                            <Col md={2}>
                              <Form.Group controlId="bankfacility.none" >
                                <Form.Check inline label="None" name="bankfacility" type='checkbox' id='bankfacility.none' onChange={(e)=> {applicant.business.bankfacility.none = e.target.checked;setLoan(loanModel);}} checked={getValue(applicant.business.bankfacility,'none')} />
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group controlId="bankfacility.savingsaccount" >
                                <Form.Check inline label="Savings Account" name="bankfacility" type='checkbox' id='bankfacility.savingsaccount' onChange={(e)=> {applicant.business.bankfacility.savingsaccount = e.target.checked;setLoan(loanModel);}} checked={getValue(applicant.business.bankfacility,'savingsaccount')} disabled={applicant.business.bankfacility&& applicant.business.bankfacility.none} />
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group controlId="bankfacility.currentaccount" >
                                <Form.Check inline label="Current  Account" name="bankfacility" type='checkbox' id='bankfacility.currentaccount' onChange={(e)=> {applicant.business.bankfacility.currentaccount = e.target.checked;setLoan(loanModel);}} checked={getValue(applicant.business.bankfacility,'currentaccount')} disabled={applicant.business.bankfacility&& applicant.business.bankfacility.none} />
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group controlId="bankfacility.od" >
                                <Form.Check inline label="OD" name="bankfacility" type='checkbox' id='bankfacility.od' onChange={(e)=> {applicant.business.bankfacility.od = e.target.checked;setLoan(loanModel);}} checked={getValue(applicant.business.bankfacility,'od')} disabled={applicant.business.bankfacility&& applicant.business.bankfacility.none} />
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group controlId="bankfacility.loan" >
                                <Form.Check inline label="Loan" name="bankfacility" type='checkbox' id='bankfacility.loan' onChange={(e)=> {applicant.business.bankfacility.loan = e.target.checked;setLoan(loanModel);}} checked={getValue(applicant.business.bankfacility,'loan')} disabled={applicant.business.bankfacility&& applicant.business.bankfacility.none} />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md={3}>
                              <Form.Group controlId="compensate">
                                
                                <Form.Select className="form-control" onChange={(e)=> {
                                    applicant.business.compensate = e.target.value;
                                    changeVisitInfo();
                                    
                                  }} name="compensate" defaultValue={applicant.business.compensate}>
                                  <option value=""></option>
                                  {
                                    getLookupProperty('sitePremises').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                  }
                                </Form.Select>

                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Label className="custom-form-label" >Telephone Type</Form.Label>
                              <Form.Group controlId="telephonetype.landline" >
                                <Form.Check inline label="Landline" name="telephonetype" type='checkbox' id='telephonetype.landline' checked={getValue(applicant.business.telephonetype,'landline')} onChange={(e)=> {applicant.business.telephonetype.landline = e.target.checked;setLoan(loanModel);}}
                                  disabled={applicant.business.telephonetype && applicant.business.telephonetype.both}/>
                                <Form.Check inline label="Mobile" name="telephonetype" type='checkbox' id='telephonetype.mobile' checked={getValue(applicant.business.telephonetype,'mobile')} onChange={(e)=> {applicant.business.telephonetype.mobile = e.target.checked;setLoan(loanModel);}}
                                  disabled={applicant.business.telephonetype && applicant.business.telephonetype.both}/>
                                <Form.Check inline label="Both" name="telephonetype" type='checkbox' id='telephonetype.both' checked={getValue(applicant.business.telephonetype,'both')} onChange={(e)=> {applicant.business.telephonetype.both = e.target.checked;setLoan(loanModel);}}
                                  disabled={applicant.business.telephonetype && applicant.business.telephonetype.both}/>
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group controlId="recordkeeping">
                                <Form.Label className="custom-form-label" >Records Keeping</Form.Label>
                                <Form.Select className="form-control" onChange={(e)=> {
                                    applicant.business.recordkeeping = e.target.value;
                                    changeVisitInfo();
                                  }} name="recordkeeping" defaultValue={applicant.business.recordkeeping}>
                                  <option value=""></option>
                                  {
                                    getLookupProperty('recordkeeping').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                  }
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Use of internet for Business" name="useinternet" type='checkbox' id='useinternet' checked={getValue(applicant.business.telephonetype,'useinternet')} onChange={(e)=> {applicant.business.telephonetype.useinternet = e.target.checked;setLoan(loanModel);}}/>
                            </Col>
                          </Row>
                          <label>Digital Adoption</label>
                          <Row>
                            <Col md={1}>
                              <Form.Group controlId="digitaladoption.aadhaarpay" >
                                <Form.Check inline label="Aadhaar Pay" name="digitaladoption" type='checkbox' id='digitaladoption.aadhaarpay' onChange={(e)=> {applicant.business.digitaladoption.aadhaarpay = e.target.checked;setLoan(loanModel);}} 
                                  checked={getValue(applicant.business.digitaladoption,'aadhaarpay')} />
                              </Form.Group>
                            </Col>
                            <Col md={1}>
                              <Form.Group controlId="digitaladoption.etailer" >
                                <Form.Check inline label="E-tailer" name="digitaladoption" type='checkbox' id='digitaladoption.etailer' onChange={(e)=> {applicant.business.digitaladoption.etailer = e.target.checked;setLoan(loanModel);}} 
                                  checked={getValue(applicant.business.digitaladoption,'etailer')} />
                              </Form.Group>
                            </Col>
                            <Col md={1}>
                              <Form.Group controlId="digitaladoption.googlepay" >
                                <Form.Check inline label="Google Pay" name="digitaladoption" type='checkbox' id='digitaladoption.googlepay' onChange={(e)=> {applicant.business.digitaladoption.googlepay = e.target.checked;setLoan(loanModel);}} 
                                  checked={getValue(applicant.business.digitaladoption,'googlepay')} />
                              </Form.Group>
                            </Col>
                            <Col md={1}>
                              <Form.Group controlId="digitaladoption.mpesa" >
                                <Form.Check inline label="M-Pesa" name="digitaladoption" type='checkbox' id='digitaladoption.mpesa' onChange={(e)=> {applicant.business.digitaladoption.mpesa = e.target.checked;setLoan(loanModel);}} 
                                  checked={getValue(applicant.business.digitaladoption,'mpesa')} />
                              </Form.Group>
                            </Col>
                            <Col md={1}>
                              <Form.Group controlId="digitaladoption.paytm" >
                                <Form.Check inline label="Paytm" name="digitaladoption" type='checkbox' id='digitaladoption.paytm' onChange={(e)=> {applicant.business.digitaladoption.paytm = e.target.checked;setLoan(loanModel);}} 
                                  checked={getValue(applicant.business.digitaladoption,'aadhaarpay')} />
                              </Form.Group>
                            </Col>
                            <Col md={1}>
                              <Form.Group controlId="digitaladoption.phonepe" >
                                <Form.Check inline label="PhonePe" name="digitaladoption" type='checkbox' id='digitaladoption.phonepe' onChange={(e)=> {applicant.business.digitaladoption.phonepe = e.target.checked;setLoan(loanModel);}} 
                                  checked={getValue(applicant.business.digitaladoption,'phonepe')} />
                              </Form.Group>
                            </Col>
                            <Col md={1}>
                              <Form.Group controlId="digitaladoption.pos" >
                                <Form.Check inline label="POS" name="digitaladoption" type='checkbox' id='digitaladoption.pos' onChange={(e)=> {applicant.business.digitaladoption.pos = e.target.checked;setLoan(loanModel);}} 
                                  checked={getValue(applicant.business.digitaladoption,'pos')} />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={3}><strong>Employees</strong></Col>
                            <Col md={3}><strong>Permanent</strong></Col>
                            <Col md={3}><strong>Temporary</strong></Col>
                          </Row>
                          { bizEmployee.map((e:any,i:any)=>(
                            <Row>
                              <Col md={3}>{e.name}</Col>
                              <Col md={3}>
                                <FormControl type="number" name={'current'+i} onChange={(event)=> {
                                  e.current = event.target.value;
                                  setLoan(loanModel);
                                  calculateEmployee();
                                }} defaultValue={e.current} />
                              </Col>
                              <Col md={3}>
                                <FormControl type="number" name={'current'+i} onChange={(event)=> {
                                  e.last = event.target.value;
                                  setLoan(loanModel);
                                  calculateEmployee();
                                }} defaultValue={e.last} />
                              </Col>
                            </Row>
                          ))}
                          <Row>
                            <Col md={3}>Total</Col>
                            <Col md={3}>
                              <label className='label label-danger'>{loanModel.totalCurrentEmployee}</label>
                            </Col>
                            <Col md={3}>
                              <label className='label label-danger'>{loanModel.totallastEmployee}</label>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md={3}>
                              <Form.Label className="custom-form-label" >Working Hours*</Form.Label>
                              <Form.Group controlId="workinghours">
                                <Form.Select className="form-control" onChange={(e)=> {
                                    applicant.business.workinghours = e.target.value;
                                    if(e.target.value!=null){
                                      applicant.business.vegetablefruit.workinghours=e.target.value;
                                    }
                                    if(applicant.business.type=='TRD'
                                    && applicant.business.profile==='Clothing - Retailer'){
                                      applicant.business.clothing.workinghours= applicant.business.workinghours;
                                    }
                                    
                                  }} name="workinghours" defaultValue={applicant.business.workinghours}>
                                  <option value=""></option>
                                  {
                                    getLookupProperty('workingdays').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                  }
                                </Form.Select>

                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Label className="custom-form-label" >Working days in a week *</Form.Label>
                              <Form.Group controlId="workingdays">
                                <Form.Select className="form-control" onChange={(e)=> {
                                    applicant.business.workingdays = e.target.value;
                                    if(e.target.value!=null){
                                      applicant.business.vegetablefruit.workingdays=e.target.value;
                                    }
                                    if(applicant.business.type=='TRD'
                                    && applicant.business.profile==='Clothing - Retailer'){
                                      applicant.business.clothing.workingdays= applicant.business.workingdays;
                                    }
                                    
                                  }} name="workinghours" defaultValue={applicant.business.workingdays}>
                                  <option value=""></option>
                                  {
                                    getLookupProperty('workingdays').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                  }
                                </Form.Select>

                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group controlId="avgsalary">
                                <Form.Label className="custom-form-label">Monthly Salaries</Form.Label>
                                <FormControl type="number" name="avgsalary" onKeyDown={allowPositiveIntegersOnly} onChange={(e)=> {applicant.business.avgsalary = e?.target.value;setLoan(loanModel)}} value={applicant.business.avgsalary} hidden={!(loanModel.totalEmployee>=1)}/>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={3}>
                              <Form.Check inline label="Membership of Association?" name="memberassociation" type='checkbox' id='memberassociation' onChange={(e)=> {applicant.business.memberassociation = e.target.checked;setLoan(loanModel);}} 
                                checked={applicant.business.memberassociation} />      
                            </Col>
                            <Col md={3} hidden={!applicant.business.memberassociation}>
                              <FormControl type="text" name="associationname" onChange={(event)=> {
                                  applicant.business.associationname = event.target.value;
                                  setLoan(loanModel);
                                }} defaultValue={applicant.business.associationname} />    
                            </Col>
                          </Row>
                          <Row>
                            <Col md={3}>
                              <Form.Check inline label="Is the business seasonal?" name="isseasonal" type='checkbox' id='isseasonal' onChange={(e)=> {applicant.business.isseasonal = e.target.checked;setLoan(loanModel);}} 
                                checked={applicant.business.isseasonal} />      
                            </Col>
                          </Row>
                          <Row>
                            <Col md={3}><strong>Moonth</strong></Col>
                            <Col md={3}><strong>Peak Period</strong></Col>
                            <Col md={3}><strong>Off Period</strong></Col>
                          </Row>
                          { applicant.business.isseasonal && seasonalModel.map((e:any,i:any)=>(
                            <Row>
                              <Col md={3}>{e.month}</Col>
                              <Col md={3}>
                                <Form.Check inline label="" name={"checkboxpp"+i} type='checkbox' id={"checkboxpp"+i} onChange={(e)=> {applicant.business.peakperiod[i].seasonal = e.target.checked;setLoan(loanModel);}} 
                                checked={getValue(applicant.business.peakperiod[i],'seasonal')} disabled={applicant.business.offperiod[i].seasonal} /> 
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="" name={"checkboxpp"+i} type='checkbox' id={"checkboxpp"+i} onChange={(e)=> {applicant.business.offperiod[i].seasonal = e.target.checked;setLoan(loanModel);}} 
                                checked={getValue(applicant.business.offperiod[i],'seasonal')} disabled={applicant.business.peakperiod[i].seasonal}/> 
                              </Col>
                            </Row>
                          ))}
                        </Tab>
                        
                        {applicant !== null && applicant.business !== null && applicant.business.type=='SVC' && applicant.business.profile == 'Small Kirana / Provision / Pan Store' && loanModel.kiranaflag && 
                        <Tab eventKey="btabs14" title="Kirana">
                          <strong>Kirana</strong>
                          <hr />
                          <label>Initial Capital Investment by Proprietor *</label>
                          {capitalinvestment && capitalinvestment
                            .map((map: any, i: any) => (
                              <Row className="mt-3">
                                <Col md={3}>
                                  {map.name}
                                </Col>
                                <Col md={3}>
                                  <Form.Control type='number' name={'kiranaInvAmount' + i} onChange={((event) => {
                                    map.amount = event.target.value;
                                    setLoan(loanModel);
                                  })} value={map.amount} onBlur={calculateCapitalInvestment} />
                                </Col>
                              </Row>
                            ))}
                          <Row>
                            <Col md={3}>Total</Col>
                            <Col md={3}>
                              <label className='label label-danger'>0</label>
                            </Col>
                          </Row>
                          <strong className='mt-2'>Purchase Cycle</strong>
                          <hr />
                          <table className="table table-borderless">
                            <thead>
                              <tr>
                                <th scope="col">Product Group</th>
                                <th scope="col">Sales in INR</th>
                                <th scope="col">Net Profit Margin (in %)</th>
                                <th scope="col">% of Total Sales</th>
                              </tr>
                            </thead>
                            <tbody>
                              {applicant.business.kiranasales && applicant.business.kiranasales
                                .map((map: any, i: any) => (
                                  <tr>
                                    <td scope="row">{map.name}</td>
                                    <td><Form.Control type='number' name={'kirana.sales' + i} onChange={((event) => {
                                      map.sales = event.target.value;
                                      setLoan(loanModel);
                                    })} value={map.sales} onBlur={calculatekiranasales} /></td>
                                    <td><Form.Control type='number' name={'kirana.sales' + i} onChange={((event) => {
                                      map.profitmargin = event.target.value;
                                      calculateExpenses();
                                      setLoan(loanModel);
                                    })} value={map.profitmargin} onBlur={calculatekiranasales}/></td>
                                    <td><Col md={3}>
                                      <label className='label label-success'>{map.presentsale}%</label>
                                    </Col></td>
                                      
                                  </tr>
                                ))}
                                <tr>
                                  <td>Total Sales</td>
                                  <td>{loanModel.totalSalesDeatail}</td>
                                  <td>{loanModel.totalSalesDeatailPresent}</td>
                                </tr>
                            </tbody>
                          </table>
                          <div className='row d-flex'>
                            <label className='col-6'>
                              Most Profitable Goods *
                              <textarea name="postContent" rows={5} cols={60} onChange={(event) => { 
                               if(event.target.value!=null){
                                var maxlength=1000;
                                if(event.target.value.length>=maxlength){
                                  applicant.business.kiranastore.profitablegoods=applicant.business.kiranastore.profitablegoods.substring(0,maxlength);
                                }
                              }
                              }}/>
                            </label>
                            <label className='col-6'>
                              Most Profitable Goods *
                              <textarea name="postContent" rows={5} cols={60} onChange={(event) => { 
                                if(event.target.value!=null){
                                  var maxlength=1000;
                                  if(event.target.value.length>=maxlength){
                                    applicant.business.kiranastore.populargoods=applicant.business.kiranastore.populargoods.substring(0,maxlength);
                                  }
                                }
                              }}/>
                            </label>
                          </div>

                          <div className='row d-flex'>
                            <div className='col-2'>
                              <label className='mt-2'>Stock Value *</label>
                            </div>
                            <div className='col-2'>
                              <label>Minimum</label>
                              <input  type="number" className='form-control' onChange={(event) => { 
                                var value = event.target.value;
                                var maxlength = 15;
                                var data = value.split(".");
                                if (data.length <= 2) {
                                  value = data[0];
                                }
                                if (value.length >= maxlength) {
                                  value = value.substring(0, maxlength);
                                 applicant.business.kiranastore.minimum = parseInt(value);
                                }
                                if ((applicant.business.kiranastore.minimumstock != null) && (applicant.business.kiranastore.minimumstock > 0)) {
                                  if ((applicant.business.kiranastore.maximumstock != null) && (applicant.business.kiranastore.maximumstock > 0)) {
                                    applicant.business.kiranastore.avgstock = (applicant.business.kiranastore.maximumstock + applicant.business.kiranastore.minimumstock) / 2;
                                  }
                                }
                                if (applicant.business.kiranastore.minimumstock == null && applicant.business.kiranastore.minimumstock == 0) {
                                  applicant.business.kiranastore.minimumstock = null;
                                }
                               }}
                               />
                            </div>
                            <div className='col-2'>
                              <label>Maximum</label>
                              <input type="number" className='form-control'  onChange={(event) => { 
                                var value = event.target.value;
                                var maxlength = 15;
                                var data = value.split(".");
                                if (data.length <= 2) {
                                  value = data[0];
                                }
                                if (value.length >= maxlength) {
                                  value = value.substring(0, maxlength);
                                  applicant.business.kiranastore.maximum = parseInt(value);
                                }
                                if ((applicant.business.kiranastore.maximumstock != null) && (applicant.business.kiranastore.maximumstock > 0)) {
                                  if ((applicant.business.kiranastore.minimumstock != null) && (applicant.business.kiranastore.minimumstock > 0)) {
                                    applicant.business.kiranastore.avgstock = (applicant.business.kiranastore.maximumstock + applicant.business.kiranastore.minimumstock) / 2;
                                  }
                                }
                                if (applicant.business.kiranastore.maximumstock == null && applicant.business.kiranastore.maximumstock == 0) {
                                  applicant.business.kiranastore.maximumstock = null;
                                }
                               }}/>
                            </div>
                            <div className='col-2'>
                              <label>Average</label>
                              <input type="number" className='form-control'
                              onChange={(event) => {
                                let value = event.target.value;
                                const maxlength = 15;
                                const data = value.split(".");
                      
                                if (data.length <= 2) {
                                  value = data[0];
                                }
                      
                                if (value.length >= maxlength) {
                                  value = value.substring(0, maxlength);
                                }
                      
                                if (applicant.business.type === 'TRD' && applicant.business.profile === 'Small Kirana / Provision / Pan Store') {
                                  if (applicant.business.kiranastore) {
                                    if (applicant.business.kiranastore.maximumstock != null && applicant.business.kiranastore.maximumstock > 0 &&
                                      applicant.business.kiranastore.minimumstock != null && applicant.business.kiranastore.minimumstock > 0) {
                                        applicant.business.kiranastore.avgstock = (applicant.business.kiranastore.maximumstock + applicant.business.kiranastore.minimumstock) / 2;
                                    } else {
                                      applicant.business.kiranastore.avgstock = '';
                                    }
                                  }
                                }
                              }}
                              onBlur={() => {
                                if ((applicant.business.kiranastore.maximumstock != null && applicant.business.kiranastore.maximumstock > 0) && (applicant.business.kiranastore.minimumstock != null && applicant.business.kiranastore.minimumstock > 0)) {
                                  if (applicant.business.kiranastore.minimumstock > applicant.business.kiranastore.maximumstock) {
                                    window.alert('Maximum stock should be greater than Minimum stock');
                                  }
                                }
                              }} />
                            </div>
                            <div className='col-2'>
                              <label>Current</label>
                              <input type="number" className='form-control' name='currentstock' onChange={(e) => {
                                let value = e.target.value;
                                const maxlength = 15;
                                const data = value.split(".");

                                if (data.length <= 2) {
                                  value = data[0];
                                }
                                if (value.length >= maxlength) {
                                  value = value.substring(0, maxlength);
                                  applicant.business.kiranastore.currentstock =  parseInt(value);
                                }
                              }}
                              onBlur={() => {
                                if (applicant.business.kiranastore.currentstock != null && applicant.business.kiranastore.currentstock > 0 && applicant.business.kiranastore.maximumstock != null && applicant.business.kiranastore.maximumstock > 0) {
                                  if (applicant.business.kiranastore.currentstock > applicant.business.kiranastore.maximumstock) {
                                    window.alert('Current stock should be lesser than Maximum stock');
                                  }
                                }
                      
                                if (applicant.business.kiranastore.currentstock != null && applicant.business.kiranastore.currentstock > 0 && applicant.business.kiranastore.minimumstock != null && applicant.business.kiranastore.minimumstock > 0) {
                                  if (applicant.business.kiranastore.currentstock < applicant.business.kiranastore.minimumstock) {
                                    window.alert('Current stock should be greater than Minimum stock');
                                  }
                                }
                              }} />
                            </div>
                          </div>
                          <strong>Customer Landscape</strong>
                          <hr />
                          <label >Customers *</label>
                          <div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" onChange={(e) => {
                                applicant.business.kiranastore.households = e.target.value;
                                setLoan(loanModel);
                              }} />
                              <label className="form-check-label" >Retail - walk -in</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value={applicant.business.kiranastore.retail} onChange={(e) => {
                                applicant.business.kiranastore.retail = e.target.value;
                                setLoan(loanModel);
                              }} />
                              <label className="form-check-label" >Households</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value={applicant.business.kiranastore.offices} onChange={(e) => {
                                applicant.business.kiranastore.offices = e.target.value;
                                setLoan(loanModel);
                              }}/>
                              <label className="form-check-label" >Offices / Hotels</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value={applicant.business.kiranastore.others} onChange={(e) => {
                                applicant.business.kiranastore.others = e.target.value;
                                setLoan(loanModel);
                              }} />
                              <label className="form-check-label" >Others</label>
                            </div>
                          </div>
                          <label className='mt-3'>Regular Repeat Customers / Households *</label>
                          <div className='row d-flex mt-2'>
                            <div className='col-2'>
                              <label >Number</label>
                              <input className="form-control" type="text" name='noofcust' id="inlineCheckbox3" value={applicant.business.kiranastore.noofcust}
                                onChange={(e) => {
                                  let newValue = e.target.value;
                                  const maxlength = 15;
                                  const data = newValue.split('.');

                                  if (data.length <= 2) {
                                    newValue = data[0];
                                  }

                                  if (newValue.length >= maxlength) {
                                    newValue = newValue.substring(0, maxlength);
                                  }
                                  applicant.business.kiranastore.noofcust = parseInt(newValue)
                                }} />
                            </div>
                            <div className='col-3'>
                              <label>Average Monthly Billing</label>
                              <input className="form-control" type="text" name='avgbilling' id="inlineCheckbox3" value={applicant.business.kiranastore.avgbilling} onChange={(e) => {
                                let newValue = e.target.value;
                                const maxlength = 15;
                                const data = newValue.split('.');

                                if (data.length <= 2) {
                                  newValue = data[0];
                                }

                                if (newValue.length >= maxlength) {
                                  newValue = newValue.substring(0, maxlength);
                                }

                                applicant.business.kiranastore.avgbilling = newValue;
                              }} />
                            </div>
                            <div className='col-3'>
                              <label>Average Footfall (Perday)</label>
                              <input className="form-control" type="number" id="inlineCheckbox3" value="" />
                            </div>
                          </div>
                        </Tab>}
                          {applicant!==null && applicant.business!==null && applicant.business.type=='SVC' && applicant.business.profile==='Tailoring / Embroidery' && loanModel.tailorflag && <Tab eventKey="btabs16  " title="Tailor">
                            <span><strong>Business</strong></span>
                            <hr/>
                            <Row>
                              <Col md={6}>
                                <Form.Check inline label="Are you member of National Association of Street Vendors of India (NASVI)?" name="contractedjobs" type='checkbox' id="contractedjobs" onChange={(e)=>{ 
                                  applicant.business.tailor.contractedjobs = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor,'contractedjobs')} />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={2}>Tailor type *</Col>
                              <Col md={2}>
                                <Form.Check inline label="Sewing" name="type.sewing" type='checkbox' id="type.sewing" onChange={(e)=>{ 
                                  applicant.business.tailor.type.sewing = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.type,'sewing')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Alteration" name="type.alteration" type='checkbox' id="type.alteration" onChange={(e)=>{ 
                                  applicant.business.tailor.type.alteration = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.type,'alteration')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Embroidery" name="type.embroidery" type='checkbox' id="type.embroidery" onChange={(e)=>{ 
                                  applicant.business.tailor.type.embroidery = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.type,'embroidery')} />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={2}>Value added business activities *</Col>
                              <Col md={2}>
                                <Form.Check inline label="None" name="activities.none" type='checkbox' id="activities.none" onChange={(e)=>{ 
                                  applicant.business.tailor.activities.none = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.activities,'none')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Saree / Dress Embroidery" name="activities.saree" type='checkbox' id="activities.saree" onChange={(e)=>{ 
                                  applicant.business.tailor.activities.saree = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.activities,'saree')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Selling fabrics" name="activities.sellingfabrics" type='checkbox' id="activities.sellingfabrics" onChange={(e)=>{ 
                                  applicant.business.tailor.activities.sellingfabrics = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.activities,'sellingfabrics')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Sub - contracted jobs" name="activities.contractedjobs" type='checkbox' id="activities.contractedjobs" onChange={(e)=>{ 
                                  applicant.business.tailor.activities.contractedjobs = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.activities,'contractedjobs')} />
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Number of sewing machines *</Form.Label>
                                <Form.Group controlId="machinesno">
                                  <Form.Select className="form-control" onChange={(e)=> {
                                      applicant.business.tailor.machinesno = e.target.value;
                                    }} name="workinghours" defaultValue={getValue(applicant.business.tailor,'machinesno')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('sewingmachines').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Total cost of machines *</Form.Label>
                                <FormControl type="number" name="totalcost" onChange={(event)=> {
                                  applicant.business.tailor.totalcost = event.target.value;
                                  setLoan(loanModel);
                                }} defaultValue={getValue(applicant.business.tailor,'totalcost')} />    
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Area / Market assessment *</Form.Label>
                                <Form.Group controlId="assessment">
                                  <Form.Select className="form-control" onChange={(e)=> {
                                      applicant.business.tailor.assessment = e.target.value;
                                    }} name="assessment" defaultValue={getValue(applicant.business.tailor,'assessment')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('assessment').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Tailoring skill set acquired from *</Form.Label>
                                <Form.Group controlId="skillset">
                                  <Form.Select className="form-control" onChange={(e)=> {
                                      applicant.business.tailor.skillset = e.target.value;
                                    }} name="skillset" defaultValue={getValue(applicant.business.tailor,'skillset')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('skillset').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>Machine Type *</Row>
                            <Row>
                              <Col md={2}>
                                <Form.Check inline label="Electronic Sewing Machine" name="machine.electronic" type='checkbox' id="machine.electronic" onChange={(e)=>{ 
                                  applicant.business.tailor.machine.electronic = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.machine,'electronic')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Automated Sewing Machine" name="machine.automated" type='checkbox' id="machine.automated" onChange={(e)=>{ 
                                  applicant.business.tailor.machine.automated = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.machine,'automated')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Embroidery Machine" name="machine.embroidery" type='checkbox' id="machine.embroidery" onChange={(e)=>{ 
                                  applicant.business.tailor.machine.embroidery = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.machine,'embroidery')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Button Hole Machine" name="machine.buttonhole" type='checkbox' id="machine.buttonhole" onChange={(e)=>{ 
                                  applicant.business.tailor.machine.buttonhole = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.machine,'buttonhole')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Button Attachment Machine" name="machine.buttonattachment" type='checkbox' id="machine.buttonattachment" onChange={(e)=>{ 
                                  applicant.business.tailor.machine.buttonattachment = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.machine,'buttonattachment')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Mechanical Sewing Machine (Foot Operated)" name="machine.footoperated" type='checkbox' id="machine.footoperated" onChange={(e)=>{ 
                                  applicant.business.tailor.machine.footoperated = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.machine,'footoperated')} />
                              </Col>
                            </Row>
                            <span><strong>Customer Landscape *</strong></span>
                            <hr/>
                            <Row>
                              <Col md={2}>Customer</Col>
                              <Col md={2}>
                                <Form.Check inline label="Gents" name="customers.gents" type='checkbox' id="customers.gents" onChange={(e)=>{ 
                                  applicant.business.tailor.customers.gents = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.customers,'gents')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Ladies" name="customers.ladies" type='checkbox' id="customers.ladies" onChange={(e)=>{ 
                                  applicant.business.tailor.customers.ladies = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.customers,'ladies')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Kids" name="customers.kids" type='checkbox' id="customers.kids" onChange={(e)=>{ 
                                  applicant.business.tailor.customers.kids = e.target.checked;
                                  if(applicant.business.tailor.customers.kids == false) {
                                    applicant.business.tailor.specialisation.uniform = false;
                                  }
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.customers,'kids')} />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={2}>Specialisation</Col>
                              <Col md={2}>
                                <Form.Check inline label="Indian wear" name="specialisation.indianwear" type='checkbox' id="specialisation.indianwear" onChange={(e)=>{ 
                                  applicant.business.tailor.specialisation.indianwear = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.specialisation,'indianwear')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Western wear" name="specialisation.westernwear" type='checkbox' id="specialisation.westernwear" onChange={(e)=>{ 
                                  applicant.business.tailor.specialisation.westernwear = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.specialisation,'westernwear')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check hidden={!(applicant.business.tailor.customers && applicant.business.tailor.customers.kids)} inline label="Uniform" name="specialisation.uniform" type='checkbox' id="specialisation.uniform" onChange={(e)=>{ 
                                  applicant.business.tailor.specialisation.uniform = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor.specialisation,'uniform')} />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Form.Check inline label="Are you associated with any Dresser / Employer / School for Dress / Uniform Tailoring?" name="anyassociation" type='checkbox' id="anyassociation" onChange={(e)=>{ 
                                  applicant.business.tailor.anyassociation = e.target.checked;
                                  if(!applicant.business.tailor.anyassociation){
                                    applicant.business.tailor.specify = "";
                                  }
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.tailor,'anyassociation')} />
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Please specify *</Form.Label>
                                <FormControl type="text" name="applicant.business.tailor.specify" onChange={(event)=> {
                                  applicant.business.tailor.specify = event.target.value;
                                }} defaultValue={getValue(applicant.business.tailor,'specify')} />    
                              </Col>
                            </Row>
                          </Tab>}
                           {applicant!==null && applicant.business!==null && applicant.business.type=='TRD' && applicant.business.profile==='Vegetables / Fruits / Flowers Vendor'  && loanModel.tailorflag && <Tab eventKey="btabs17  " title="Vegetables / Fruits / Flowers Vendor">
                            <span><strong>Street vendor Documentation *</strong></span>
                            <hr/>
                            <Row>
                              <Col md={3}>
                                <Form.Check inline label="No Documentation" name="documentation.no" type='checkbox' id="documentation.no" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.documentation.no = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.documentation,'no')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Certificate of Vending (CoV)" name="documentation.cov" type='checkbox' id="documentation.cov" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.documentation.no = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.documentation,'cov')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Identity Card issued by Urban Local Bodies (ULB's)" name="documentation.ulb" type='checkbox' id="documentation.ulb" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.documentation.ulb = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.documentation,'ulb')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Letter of Recommendation (LoR) by ULB or Town Vending Committee (TVC)" name="documentation.tvc" type='checkbox' id="documentation.tvc" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.documentation.tvc = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.documentation,'tvc')} />
                              </Col>
                            </Row>
                            <span><strong>Business</strong></span>
                            <hr/>
                            <Row>
                              <Col md={2}>Produce Sold *</Col>
                              <Col md={2}>
                                <Form.Check inline label="Vegetables" name="produce.sold.vegetables" type='checkbox' id="produce.sold.vegetables" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.produce.sold.vegetables = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.produce.sold,'vegetables')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Fruits" name="produce.sold.fruits" type='checkbox' id="produce.sold.fruits" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.produce.sold.fruits = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.produce.sold,'fruits')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Flowers" name="produce.sold.flowers" type='checkbox' id="produce.sold.flowers" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.produce.sold.flowers = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.produce.sold,'flowers')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Sprouts" name="produce.sold.sprouts" type='checkbox' id="produce.sold.sprouts" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.produce.sold.sprouts = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.produce.sold,'sprouts')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="All" name="produce.sold.all" type='checkbox' id="produce.sold.all" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.produce.sold.all = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.produce.sold,'all')} />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Working days in a week *</Form.Label>
                                <Form.Group controlId="vegetablefruit_workingdays">
                                  <Form.Select className="form-control" disabled={true} onChange={(e)=> {
                                      applicant.business.vegetablefruit.workingdays = e.target.value;
                                    }} name="vegetablefruit_workingdays" defaultValue={getValue(applicant.business.vegetablefruit,'workingdays')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('workingdays').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    Select Working Days in a week from Business / Shop Operation module
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >How many Varieties do you sell? *</Form.Label>
                                <Form.Group controlId="vegetablefruit_varietiess">
                                  <Form.Select className="form-control" onChange={(e)=> {
                                      applicant.business.vegetablefruit.varietiess = e.target.value;
                                    }} name="vegetablefruit_varietiess" defaultValue={getValue(applicant.business.vegetablefruit,'varietiess')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('varietiessold').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Who looks after the stall when you are ill / away? *</Form.Label>
                                <FormControl type="text" name="vegetablefruit_away" onChange={(event)=> {
                                  var maxlength=150;
                                  if((event.target.value!=null)&&(event.target.value.length>=maxlength)){
                                    applicant.business.vegetablefruit.away = event.target.value;
                                  }    
                                }} defaultValue={getValue(applicant.business.vegetablefruit,'away')} />    
                              </Col>
                            </Row>
                            <label>How do you maintain the freshness and fragrance of your items? *</label>
                            <Row>
                              <Col md={2}>Produce Sold *</Col>
                              <Col md={2}>
                                <Form.Check inline label="Cloth Cover" name="maintain_clothcover" type='checkbox' id="maintain_clothcover" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.maintain.clothcover = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.maintain,'clothcover')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Sprinkle Water" name="maintain_sprinklewater" type='checkbox' id="maintain_sprinklewater" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.maintain.sprinklewater = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.maintain,'sprinklewater')} />
                              </Col>
                            </Row>
                            <span><strong>Purchase Cycle</strong></span>
                            <hr/>
                            <Row>
                              <Col md={2}>Produce Purchase *</Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                <Form.Check inline label="Purchase Directly from Farmer" name="produce_purchase_fromfarmer" type='checkbox' id="produce_purchase_fromfarmer" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.produce.purchase.fromfarmer = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.produce.purchase,'fromfarmer')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Own Farm Produce" name="produce_purchase_ownfarm" type='checkbox' id="produce_purchase_ownfarm" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.produce.purchase.ownfarm = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.produce.purchase,'ownfarm')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="From Near Market" name="produce_purchase_nearmarket" type='checkbox' id="produce_purchase_nearmarket" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.produce.purchase.nearmarket = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.produce.purchase,'nearmarket')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="From APMC / Wholesale market" name="produce_purchase_apmc" type='checkbox' id="produce_purchase_apmc" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.produce.purchase.apmc = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.produce.purchase,'apmc')} />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                Difficulties faced while purchasing *
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Regular Supply" name="produce_difficulties_supply" type='checkbox' id="produce_difficulties_supply" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.difficulties.purchase.supply = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.difficulties.purchase,'supply')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Transportation" name="produce_difficulties_Transportation" type='checkbox' id="produce_difficulties_Transportation" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.difficulties.purchase.Transportation = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.difficulties.purchase,'Transportation')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Price Determination" name="produce_difficulties_price" type='checkbox' id="produce_difficulties_price" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.difficulties.purchase.price = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.difficulties.purchase,'price')} />
                              </Col>
                              
                            </Row>
                            <Row>
                              <Col md={3}>
                                Difficulties faced while selling *
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Price Determination" name="selling_pricedetermination" type='checkbox' id="selling_pricedetermination" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.difficulties.selling.pricedetermination = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.difficulties.selling,'pricedetermination')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Bargaining" name="selling_bargaining" type='checkbox' id="selling_bargaining" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.difficulties.selling.bargaining = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.difficulties.selling,'bargaining')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Quality" name="selling_quality" type='checkbox' id="selling_quality" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.difficulties.selling.quality = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.difficulties.selling,'quality')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Storage" name="selling_storage" type='checkbox' id="selling_storage" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.difficulties.selling.storage = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.difficulties.selling,'storage')} />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>Produce Delivery *</Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                <Form.Check inline label="Doorstep Delivery by Supplier" name="delivery_doorstep" type='checkbox' id="delivery_doorstep" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.produce.delivery.doorstep = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.produce.delivery,'doorstep')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Shared Delivery with Other Vendors" name="delivery_sharedvendors" type='checkbox' id="delivery_sharedvendors" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.produce.delivery.sharedvendors = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.produce.delivery,'sharedvendors')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Use of Hired Tempo / Vehicle" name="delivery_hiredvehicle" type='checkbox' id="delivery_hiredvehicle" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.produce.delivery.hiredvehicle = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.produce.delivery,'hiredvehicle')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Use of Own Tempo / Vehicle" name="delivery_ownvehicle" type='checkbox' id="delivery_ownvehicle" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.produce.delivery.ownvehicle = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.produce.delivery,'ownvehicle')} />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >No of times purchases are made *</Form.Label>
                                <Form.Group controlId="vegetablefruit_purchaseoccurence">
                                  <Form.Select className="form-control" onChange={(e)=> {
                                      applicant.business.vegetablefruit.purchaseoccurence = e.target.value;
                                    }} name="vegetablefruit_purchaseoccurence" defaultValue={getValue(applicant.business.vegetablefruit,'purchaseoccurence')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('purchaseoccurence').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Amount spent of each purchase *</Form.Label>
                                <FormControl type="number" name="vegetablefruit_amtpurchase" onChange={(event)=> {
                                  applicant.business.vegetablefruit.amtpurchase = event.target.value;
                                }} defaultValue={getValue(applicant.business.vegetablefruit,'amtpurchase')} />    
                              </Col>
                              <Col md={6}>
                                <Form.Check inline label="Can you purchase your vegetables / flowers / fruits / sprouts on credit?" name="vegetablefruit_oncredit" type='checkbox' id="vegetablefruit_oncredit" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.oncredit = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit,'oncredit')} />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={2} style={{fontWeight:'bold'}}>Customer Landscape</Col>
                            </Row>
                            <hr/>
                            <Row>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Which are your good seasons *</Form.Label>
                                <FormControl type="text" maxLength={150} name="vegetablefruit_goodseasons" onChange={(event)=> {
                                  applicant.business.vegetablefruit.goodseasons = event.target.value;
                                }} defaultValue={getValue(applicant.business.vegetablefruit,'goodseasons')} />    
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Area / Market assessment *</Form.Label>
                                <Form.Group controlId="vegetablefruit_assessment">
                                  <Form.Select className="form-control" onChange={(e)=> {
                                      applicant.business.vegetablefruit.assessment = e.target.value;
                                    }} name="vegetablefruit_assessment" defaultValue={getValue(applicant.business.vegetablefruit,'assessment')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('areaassessment').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              
                              <Col md={6}>
                                <Form.Check inline label="Are you member of National Association of Street Vendors of India (NASVI)?" name="vegetablefruit_association" type='checkbox' id="vegetablefruit_association" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.association = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit,'association')} />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Average Customers per day *</Form.Label>
                                <FormControl type="number" maxLength={150} name="vegetablefruit_customers" onChange={(event)=> {
                                  var value=event.target.value;
                                  applicant.business.vegetablefruit.customers = value;
                                  var maxlength=10;
                                  var data=value.split(".");
                                  if(data.length<=2){
                                    value=data[0];
                                  }
                                  if(value.length>=maxlength){
                                    value=value.substring(0,maxlength);
                                    applicant.business.vegetablefruit.customers=parseInt(value);
                                  }
                                  if(isDefined(applicant.business.vegetablefruit.customers) && applicant.business.vegetablefruit.customers<0){
                                      applicant.business.vegetablefruit.billing = null;
                                  }
                                }} defaultValue={getValue(applicant.business.vegetablefruit,'customers')} />    
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Regular repeat customers / Households *</Form.Label>
                                <FormControl type="number" maxLength={150} name="vegetablefruit_repeatcustomer" onChange={(event)=> {
                                  var value=event.target.value;
                                  applicant.business.vegetablefruit.repeatcustomer = value;
                                  var maxlength=10;
                                  var data=value.split(".");
                                  if(data.length<=2){
                                    value=data[0];
                                  }
                                  if(value.length>=maxlength){
                                    value=value.substring(0,maxlength);
                                    applicant.business.vegetablefruit.repeatcustomer=parseInt(value);
                                  }
                                }} defaultValue={getValue(applicant.business.vegetablefruit,'repeatcustomer')} />    
                              </Col>
                              <Col md={3} hidden={!isDefined(applicant.business.vegetablefruit.customers) 
                                  || parseInt(applicant.business.vegetablefruit.customers)<0 }>
                                <Form.Label className="custom-form-label" >Average Monthly Billing *</Form.Label>
                                <FormControl type="number" maxLength={150} name="vegetablefruit_billing" onChange={(event)=> {
                                  applicant.business.vegetablefruit.billing=event.target.value;
                                }} defaultValue={getValue(applicant.business.vegetablefruit,'billing')} />    
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                <Form.Check inline label="Large Customers" name="delivery_largecustomer" type='checkbox' id="delivery_largecustomer" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.largecustomer = e.target.checked;
                                  if(!applicant.business.vegetablefruit.largecustomer){
                                    if(!isDefined(applicant.business.vegetablefruit.customertype)){
                                      applicant.business.vegetablefruit.customertype = {};
                                    }
                                    applicant.business.vegetablefruit.customertype.hotels=false;
                                    applicant.business.vegetablefruit.customertype.restaurants=false;
                                    applicant.business.vegetablefruit.customertype.marriagehalls=false;
                                    applicant.business.vegetablefruit.customertype.housingsocieties=false;
                                    applicant.business.vegetablefruit.customertype.catering=false;
                                    applicant.business.vegetablefruit.customertype.mess=false;
                                    applicant.business.vegetablefruit.customertype.canteens=false;
                                    applicant.business.vegetablefruit.customertype.hostels=false;
                                    applicant.business.vegetablefruit.avgbilling=null;
                                    applicant.business.vegetablefruit.avgcustomers=null;
                                  }
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit,'largecustomer')} />
                              </Col>
                            </Row>
                            <label>Large Customer Type *</label>
                            <Row hidden={applicant.business.vegetablefruit && !applicant.business.vegetablefruit.largecustomer}>
                              <Col md={2}>
                                <Form.Check inline label="Hotels" name="customertype_hotels" type='checkbox' id="customertype_hotels" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.customertype.hotels = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.customertype,'hotels')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Restaurants" name="customertype_restaurants" type='checkbox' id="customertype_restaurants" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.customertype.restaurants = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.customertype,'restaurants')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Marriage Halls" name="customertype_marriagehalls" type='checkbox' id="customertype_marriagehalls" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.customertype.marriagehalls = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.customertype,'marriagehalls')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Housing Societies" name="customertype_housingsocieties" type='checkbox' id="customertype_housingsocieties" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.customertype.housingsocieties = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.customertype,'housingsocieties')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Catering Services" name="customertype_catering" type='checkbox' id="customertype_catering" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.customertype.catering = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.customertype,'catering')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Mess" name="customertype_mess" type='checkbox' id="customertype_mess" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.customertype.mess = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.customertype,'mess')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Canteens" name="customertype_canteens" type='checkbox' id="customertype_canteens" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.customertype.canteens = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.customertype,'canteens')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Hostels" name="customertype_hostels" type='checkbox' id="customertype_hostels" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.customertype.hostels = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.customertype,'hostels')} />
                              </Col>
                            </Row>
                            <Row hidden={applicant.business.vegetablefruit && !applicant.business.vegetablefruit.largecustomer}>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Average Customers per day *</Form.Label>
                                <FormControl type="number" maxLength={150} name="vegetablefruit_avgcustomers" onChange={(event)=> {
                                  applicant.business.vegetablefruit.avgcustomers = event.target.value;
                                }} defaultValue={getValue(applicant.business.vegetablefruit,'avgcustomers')} />    
                              </Col>
                              <Col md={3} hidden={!isDefined(applicant.business.vegetablefruit) || !isDefined(applicant.business.vegetablefruit.avgcustomers) || parseInt(applicant.business.vegetablefruit.avgcustomers) < 0 }>
                                <Form.Label className="custom-form-label" >Average Monthly Billing *</Form.Label>
                                <FormControl type="number" maxLength={150} name="vegetablefruit_avgbilling" onChange={(event)=> {
                                  applicant.business.vegetablefruit.avgbilling=event.target.value;
                                }} defaultValue={getValue(applicant.business.vegetablefruit,'avgbilling')} />    
                              </Col>
                            </Row>
                            <strong>Surrounding Landscape</strong>
                            <hr/>
                            <Row>
                              <Col md={6}>
                                <Form.Check inline label="Do you pay any charge or fees/ tax on purchase or sales of trade to local authorities?" name="chargeontrade" type='checkbox' id="chargeontrade" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.chargeontrade = e.target.checked;
                                  if(!applicant.business.vegetablefruit.chargeontrade ){
                                    applicant.business.vegetablefruit.amount= null;
                                  }
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit,'chargeontrade')} />
                              </Col>
                              <Col md={3} hidden={!isDefined(applicant.business.vegetablefruit) || !applicant.business.vegetablefruit.chargeontrade}>
                                <Form.Label className="custom-form-label" >Amount Paid *</Form.Label>
                                <FormControl type="number" maxLength={150} name="vegetablefruit_amount" onChange={(event)=> {
                                  applicant.business.vegetablefruit.amount=event.target.value;
                                }} defaultValue={getValue(applicant.business.vegetablefruit,'amount')} />    
                              </Col>
                            </Row>
                            <label>How do you face competitors in business? *</label>
                            <Row>
                              <Col md={2}>
                                <Form.Check inline label="Keep only excellent quality of goods" name="competitors_quality" type='checkbox' id="competitors_quality" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.competitors.quality = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.competitors,'quality')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Bargain and reduce prices" name="competitors_bargain" type='checkbox' id="competitors_bargain" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.competitors.bargain = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.competitors,'bargain')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Tie up with large buyers" name="competitors_largebuyers" type='checkbox' id="competitors_largebuyers" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.competitors.largebuyers = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.competitors,'largebuyers')} />
                              </Col>
                              <Col md={1}>
                                <Form.Check inline label="Location" name="competitors_location" type='checkbox' id="competitors_location" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.competitors.location = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.competitors,'location')} />
                              </Col>
                              <Col md={1}>
                                <Form.Check inline label="Home Delivery" name="competitors_homedelivery" type='checkbox' id="competitors_homedelivery" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.competitors.homedelivery = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.competitors,'homedelivery')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Non Cash Options" name="competitors_noncash" type='checkbox' id="competitors_noncash" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.competitors.noncash = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.competitors,'noncash')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Extend Credit" name="competitors_credit" type='checkbox' id="competitors_credit" onChange={(e)=>{ 
                                  applicant.business.vegetablefruit.competitors.credit = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.vegetablefruit.competitors,'credit')} />
                              </Col>
                            </Row>
                          </Tab>}
                          {applicant!==null && applicant.business!==null && applicant.business.type=='SVC' && applicant.business.profile==='Craftsman' && loanModel.craftsmanflag && 
                           <Tab eventKey="btabs18  " title="Craftsman">
                            <div className="row overflow padding-top">
                            <legend><label><strong>Business</strong></label></legend>
                            <hr />
                            <div className="col-md-3">
                              <Form.Group controlId="craftsmanType">
                                <Form.Select className="form-control" onChange={(e) => {
                                  applicant.craftsman1 = e.target.value;
                                }} name="craftsmanType" defaultValue={getValue(applicant.business.craftman1, 'craftsmanType')}>
                                  <option value=""></option>
                                  {
                                    getLookupProperty('craftsmanType').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                  }
                                </Form.Select>
                              </Form.Group>
                            </div>
                            <div className="col-md-3 col-xs-12 padding-top">
                              <div className="col-md-12 checkbox checkbox-primary padding-top"  style={{marginTop:'-5px'}}>
                                <input type="checkbox" onChange={((event) => {
                                    resetcraftsmanMachine()
                                      map.sales = event.target.value;
                                      setLoan(loanModel);
                                    })} value={map.sales}/>
                                  <label >Equipment / Machinery Owned</label></div>
                            </div>
                            {applicant.business.craftsman.equipment && (
                              <div>
                            <div style={{marginTop:'10px'}}> 
                              <legend><label><strong>Machine&nbsp;</strong><a className="" onClick={addcraftsmanMachine}><i className="fa fa-plus fa-lg"></i></a></label>
                                    &nbsp;
                                    {craftsmanmachineError && (
                                      <label className="error-msg">
                                        Machine entries exceed threshold. Please add a maximum of 5 Machines.
                                      </label>
                                    )}
                                  </legend>
                                  <hr />
                            </div>
                            <div className="col-md-12">
                              <table className="table table-striped table-emails table-hover tablemobileview" id="folder-view" >
                                <thead>
                                  <tr>
                                    <th id="folder-actions">
                                      <strong>TYPE OF MACHINE / EQUIPMENT</strong>
                                    </th>
                                    <th><strong>PURPOSE FOR WHICH REQUIRED</strong></th>
                                    <th><strong>TOTAL COST OF MACHINE</strong></th>
                                    <th><strong>HOW WAS PAYMENT MADE</strong></th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(!applicant.business.craftsman.machines || applicant.business.craftsman.machines.length === 0) && (
                                    <tr>
                                      <td colSpan={6}>
                                        <span className="col-lg-4 col-sm-6 col-xs-8 col-lg-offset-5 col-sm-offset-5 col-xs-offset-4">No Machines</span>
                                      </td>
                                    </tr>
                                  )}
                                  {applicant.business.craftsman.machines && applicant.business.craftsman.machines.map((machine:any, index:any) => (
                                    <tr key={index}>
                                      <td>
                                        <a href="#" onClick={() => showCraftsmanMachine(index)}>{machine.type}</a>
                                      </td>
                                      <td>{machine.purpose}</td>
                                      <td>{machine.cost}</td>
                                      <td>{getTypeValue('CRAFTSMANMACHINE', machine.payment)}</td>
                                      <td>
                                        <a href="#" onClick={() => removeCraftsmanMachine(index)}>
                                          <i className="glyphicon glyphicon-remove"></i>
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                                {showCraftsmanMachineForm && (
                                  
                                    <div className="row">
                                      <div className='col-3'>
                                        <label>Type of Machine / Equipment</label>
                                        <input type="text" className='form-control' onChange={(event) => {applicant.craftsman2 = event.target.value}} onKeyDown={validateText}
                                        />
                                      </div>
                                      <div className='col-3'>
                                        <label>Purpose for which required</label>
                                        <input type="text" className='form-control' onChange={(event) => {applicant.craftsman2 = event.target.value }} onKeyDown={validateText}
                                        />
                                      </div>
                                      <div className='col-3'>
                                        <label>Total cost of Machine</label>
                                        <input type="text" className='form-control' onChange={(event) => {applicant.craftsman2 = event.target.value }} onKeyDown={validateText}
                                        />
                                    </div>
                                    <div className='col-3'>
                                      <label>How was payment made</label>
                                      <Form.Group controlId="paymentMade">
                                        <Form.Select className="form-control" onChange={(e) => {
                                          applicant.craftsman1 = e.target.value;
                                        }} name="paymentMade" defaultValue={getValue(applicant.business.tailor, 'craftsmanType')}>
                                          <option value=""></option>
                                          {
                                            getLookupProperty('paymentMade').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                          }
                                        </Form.Select>
                                      </Form.Group>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                      <button className="btn btn-primary submit-button pull-right" style={{ marginLeft: '5px' }}
                                        onClick={saveMachine}
                                      >
                                        Save
                                      </button>
                                      <button className="btn btn-inverse submit-button pull-right"
                                         onClick={()=>setShowCraftsmanMachineForm(false)}
                                        >
                                        Cancel
                                      </button>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                    </div>
                                )}
                              </div>
                            )}
                            <div style={{marginTop:'10px'}}><legend className="businessAddress padding-top"><strong>Customer Landscape</strong></legend><br/></div>
                            <hr />
                            <label className='col-md-12 unset-padding' style={{fontSize:'20px'}}>Customer *</label>
                            <div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="" onChange={(event) => {applicant.customer.individuals = event.target.value }}/>
                                <label className="form-check-label">Individuals</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="" onChange={(event) => {applicant.customer.builder = event.target.value }}/>
                                <label className="form-check-label">Developer / Builder</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value=""  onChange={(event) => {applicant.customer.contractor = event.target.value }}/>
                                <label className="form-check-label">Contractor</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="" onChange={(event) => {applicant.customer.societies = event.target.value }} />
                                <label className="form-check-label">Companies / Societies</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value=""  onChange={(event) => {applicant.customer.eplatforms = event.target.value }}/>
                                <label className="form-check-label">E Platforms</label>
                            </div>
                            </div>

                            <div style={{ marginTop: '10px' }}><legend className="businessAddress padding-top"><strong>Vocational Certificates *</strong></legend><br /></div>

                            <hr />
                            <div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value={applicant.business.craftsman.certificates} onChange={(event) => {
                                  if (applicant.business.craftsman.certificates.jobtraining == true) {
                                    applicant.business.craftsman.certificates = { "jobtraining": true };
                                  }
                                }} />
                                <label className="form-check-label">No Vocational Course / On job training</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value={craftsman} onChange={(event)=>{
                                  applicant.business.craftsman.certificates.ats = event.target.value
                                }} />
                                <label className="form-check-label">Apprentiship Training Scheme (ATS)</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value={applicant.business.craftsman.certificates} onChange={(event)=>{
                                  applicant.business.craftsman.certificates.cts = event.target.value
                                }}/>
                                <label className="form-check-label">Craftsman Training Scheme (CTS)</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="" onChange={(event)=>{
                                  applicant.business.craftsman.certificates.ncvt = event.target.value
                                }}/>
                                <label className="form-check-label">National Council of Vocational Training (NCVT)</label>
                              </div>
                            </div>

                          </div>
                          </Tab>}
                           {applicant!==null && applicant.business!==null && applicant.business.type=='TRD' && (applicant.business.profile==='Dairy Trader' || applicant.business.profile == 'Dairy Farm') && loanModel.dairyflag && <Tab eventKey="btabs19  " title="Dairy">
                            <strong>Business</strong>
                            <hr/>
                            <Row>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Total Experience in Dairy Business</Form.Label>
                                <Form.Group controlId="dariy_experience">
                                  <Form.Select className="form-control" onChange={(e)=> {
                                      applicant.business.dairy.experience = e.target.value;
                                    }} name="dariy_experience" defaultValue={getValue(applicant.business.dairy,'experience')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('dairyExperience').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Water Source</Form.Label>
                                <Form.Group controlId="dariy_watersource">
                                  <Form.Select className="form-control" onChange={(e)=> {
                                      applicant.business.dairy.watersource = e.target.value;
                                    }} name="dariy_watersource" defaultValue={getValue(applicant.business.dairy,'watersource')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('waterSource').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Closest Veterinary Service</Form.Label>
                                <FormControl type="text"  name="dariy_veterinaryservice" onChange={(event)=> {
                                  applicant.business.dairy.veterinaryservice=event.target.value;
                                }} defaultValue={getValue(applicant.business.dairy,'veterinaryservice')} />    
                              </Col>
                            </Row>
                            <Row>
                             <strong style={{paddingTop:'20px'}}>Cattle<a onClick={addCattle}><i className="fa fa-plus fa-lg"></i></a></strong>
                             <hr/>
                            </Row>
                            <table className="table table-striped table-emails table-hover tablemobileview" id="folder-view" >
                              <thead>
                              <tr>
                                <th id="folder-actions">
                                  <strong>CATTLE #</strong>
                                </th>
                                <th><strong>CATTLE TYPE</strong></th>
                                <th><strong>PROCUREMENT SOURCE</strong></th>
                                <th><strong>CATTLE AGE (YEARS)</strong></th>
                                <th><strong>MILK YIELD (LITRS PER DAY)</strong></th>
                                <th><strong>RATE PER LITRE</strong></th>
                                <th></th>
                              </tr>
                              </thead>
                              {(!isDefined(applicant.business.dairy.cattles) || applicant.business.dairy.cattles.length === 0) && 
                                <tr >
                                <td colSpan={7}><span className="col-lg-4 col-sm-6 col-xs-8 col-lg-offset-5 col-sm-offset-5 col-xs-offset-4">No Cattles</span></td>
                                </tr>
                              }
                              {isDefined(applicant.business.dairy.cattles) && applicant.business.dairy.cattles.length > 0 && applicant.business.dairy.cattles.map((cattle:any,i:any)=>(
                                <tr>
                                  <td>{i+1}</td>
                                  <td><a  onClick={()=>showDairyCattle(i)}>{getTypeValue('CATTLETYPE',cattle.cattletype)}</a></td>
                                  <td>{getTypeValue('PROCUREMENTSOURCE',cattle.procurementsource)}</td>
                                  <td>{getTypeValue('CATTLEAGE',cattle.cattleage)}</td>
                                  <td>{cattle.milkyield}</td>
                                  <td>{cattle.rateperliter}</td>
                                  <td><a hidden={isDefined(loanModel.cattleIndex) && loanModel.cattleIndex==i} data-widgster="close" ng-click={removeCattle(i)}><i className="fa fa-times fa-lg"></i></a></td>
                                </tr>
                              ))} 
                            </table>
                            {loanModel.cattleForm && 
                              <div>
                              <Row>
                                <Col md={2}>
                                  <Form.Label className="custom-form-label" >Cattle Type</Form.Label>
                                  <Form.Group controlId="cattle_cattletype">
                                    <Form.Select className="form-control" onChange={(e)=> {
                                        loanModel.cattle.cattletype = e.target.value;
                                      }} name="cattle_cattletype" defaultValue={getValue(loanModel.cattle,'cattletype')}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('cattleType').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={2}>
                                  <Form.Label className="custom-form-label" >Procurement Source</Form.Label>
                                  <Form.Group controlId="cattle_procurementsource">
                                    <Form.Select className="form-control" onChange={(e)=> {
                                        loanModel.cattle.procurementsource = e.target.value;
                                      }} name="cattle_procurementsource" defaultValue={getValue(loanModel.cattle,'procurementsource')}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('procurementSource').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={2}>
                                  <Form.Label className="custom-form-label" >Cattle Age (Years)</Form.Label>
                                  <Form.Group controlId="cattle_cattleage">
                                    <Form.Select className="form-control" onChange={(e)=> {
                                        loanModel.cattle.cattleage = e.target.value;
                                      }} name="cattle_cattleage" defaultValue={getValue(loanModel.cattle,'cattleage')}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('cattleAge').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={2}>
                                  <Form.Label className="custom-form-label" >Milk Yield (Litres Per Day) *</Form.Label>
                                  <FormControl type="number"  name="dariy_milkyield" onChange={(event)=> {
                                    loanModel.cattle.milkyield=event.target.value;
                                    if(parseInt(loanModel.cattle.milkyield)<=0){
                                      loanModel.cattle.milkyield=0;
                                    }  
                                  }} defaultValue={getValue(loanModel.cattle,'milkyield')} />    
                                </Col>
                                <Col md={2}>
                                  <Form.Label className="custom-form-label" >Rate Per Litre *</Form.Label>
                                  <FormControl type="number"  name="dariy_rateperliter" onChange={(event)=> {
                                    loanModel.cattle.rateperliter=event.target.value;
                                    if(parseInt(loanModel.cattle.rateperliter)<=0){
                                      loanModel.cattle.rateperliter=0;
                                    }  
                                  }} defaultValue={getValue(loanModel.cattle,'rateperliter')} />    
                                </Col>
                              </Row>
                              <Row style={{paddingTop:'20px'}}>
                                <Col md={9}></Col>
                                <Col md={3}>
                                <Button variant="danger" onClick={saveCattle}> Save</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                  <Button variant="dark" onClick={()=>{loanModel.cattleForm = false;setLoan(loanModel);}}> Cancel</Button>
                                </Col>
                              </Row>
                            </div>
                            }
                            <Row>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >No of Calves</Form.Label>
                                <FormControl type="number"  name="dariy_calves" onChange={(event)=> {
                                  applicant.business.dairy.calves=event.target.value;
                                  if(parseInt(applicant.business.dairy.calves)<=0){
                                    applicant.business.dairy.calves=0;
                                  }  
                                }} defaultValue={getValue(applicant.business.dairy,'calves')} />    
                              </Col>
                              <Col md={6}>
                                <Form.Label className="custom-form-label" >Plan for Next Cattle Batch
                                </Form.Label>
                                <FormControl type="text" maxLength={500} name="dariy_cattlebatch" onChange={(event)=> {
                                  applicant.business.dairy.cattlebatch=event.target.value;
                                }} defaultValue={getValue(applicant.business.dairy,'cattlebatch')} />    
                              </Col>
                            </Row>
                            <strong style={{paddingTop:'20px'}}>Customer Landscape</strong>
                            <hr/>
                            <Row>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Milk Distribution</Form.Label>
                                <Form.Group controlId="cattle_milkdistribution">
                                  <Form.Select className="form-control" onChange={(e)=> {
                                      applicant.business.dairy.milkdistribution = e.target.value;
                                    }} name="cattle_milkdistribution" defaultValue={getValue(applicant.business.dairy,'milkdistribution')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('milkDistribution').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Frequency of Milk Distribution</Form.Label>
                                <Form.Group controlId="cattle_distributionfrequency">
                                  <Form.Select className="form-control" onChange={(e)=> {
                                      applicant.business.dairy.distributionfrequency = e.target.value;
                                    }} name="cattle_distributionfrequency" defaultValue={getValue(applicant.business.dairy,'distributionfrequency')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('milkDistributionFrequency').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Label className="custom-form-label" >Closest Veterinary Service</Form.Label>
                                <FormControl type="text" maxLength={500} name="dariy_distributionroute" onChange={(event)=> {
                                  applicant.business.dairy.distributionroute=event.target.value;
                                }} defaultValue={getValue(applicant.business.dairy,'distributionroute')} />    
                              </Col>
                            </Row>
                            <strong style={{paddingTop:'20px'}}>Product Landscape</strong>
                            <hr/>
                            <Row>
                              <Col md={4}>
                                <Form.Check inline label="Allied Dairy Products" name="dariy_alliedproduct" type='checkbox' id="dariy_alliedproduct" onChange={(e)=>{ 
                                  applicant.business.dairy.alliedproduct = e.target.checked;
                                  if(!isDefined(applicant.business.dairy.product)){
                                    applicant.business.dairy.product={};
                                  }
                                  if(!applicant.business.dairy.alliedproduct){
                                    applicant.business.dairy.product.dahi=false;
                                    applicant.business.dairy.product.ghee=false;
                                    applicant.business.dairy.product.buttermilk=false;
                                    applicant.business.dairy.product.butter=false;
                                    applicant.business.dairy.product.lassi=false;
                                    applicant.business.dairy.product.paneer=false;
                                    applicant.business.dairy.product.cream=false;
                                  }
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.dairy,'alliedproduct')} />
                              </Col>
                            </Row>
                            <Row hidden={!applicant.business.dairy.alliedproduct}>
                              <Col md={2}>
                                <Form.Check inline label="Dahi" name="product_dahi" type='checkbox' id="product_dahi" onChange={(e)=>{ 
                                  applicant.business.dairy.product.dahi = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.dairy.product,'dahi')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Ghee" name="product_ghee" type='checkbox' id="product_ghee" onChange={(e)=>{ 
                                  applicant.business.dairy.product.ghee = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.dairy.product,'ghee')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Buttermilk" name="product_buttermilk" type='checkbox' id="product_buttermilk" onChange={(e)=>{ 
                                  applicant.business.dairy.product.buttermilk = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.dairy.product,'buttermilk')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Butter" name="product_butter" type='checkbox' id="product_butter" onChange={(e)=>{ 
                                  applicant.business.dairy.product.butter = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.dairy.product,'butter')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Lassi" name="product_lassi" type='checkbox' id="product_lassi" onChange={(e)=>{ 
                                  applicant.business.dairy.product.lassi = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.dairy.product,'lassi')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Paneer" name="product_paneer" type='checkbox' id="product_paneer" onChange={(e)=>{ 
                                  applicant.business.dairy.product.paneer = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.dairy.product,'paneer')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Cream" name="product_cream" type='checkbox' id="product_cream" onChange={(e)=>{ 
                                  applicant.business.dairy.product.cream = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.dairy.product,'cream')} />
                              </Col>
                            </Row>
                          </Tab>}

                           {applicant!==null && applicant.business!==null && applicant.business.type=='SVC' && applicant.business.profile==='Garage' && loanModel.garageflag && <Tab eventKey="btabs20  " title="Garage">
                            <strong style={{paddingTop:'20px'}}>Business</strong>
                            <hr/>
                            <Row>
                              <Col md={3}>
                                Services Offered to *
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Two Wheelers" name="garage_twowheelers" type='checkbox' onChange={(e)=>{ 
                                  applicant.business.garage.services.twowheelers = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.services,'twowheelers')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Four Wheelers" name="garage_fourwheelers" type='checkbox' onChange={(e)=>{ 
                                  applicant.business.garage.services.fourwheelers = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.services,'fourwheelers')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Three Wheelers (Auto-Rickshaw, Tempo)" name="garage_threewheelers" type='checkbox' onChange={(e)=>{ 
                                  applicant.business.garage.services.threewheelers = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.services,'threewheelers')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Commercial Vehicles" name="garage_commercialvehicles" type='checkbox' onChange={(e)=>{ 
                                  applicant.business.garage.services.commercialvehicles = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.services,'commercialvehicles')} />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                Services Offered *
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Battery Service" name="garage_bs" type='checkbox' onChange={(e)=>{ 
                                  applicant.business.garage.serviceoffered.bs = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.serviceoffered,'bs')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Car Air Conditioner service" name="garage_airConditioner" type='checkbox' onChange={(e)=>{ 
                                  applicant.business.garage.serviceoffered.airConditioner = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.serviceoffered,'airConditioner')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Car Body Polish service" name="garage_cbps" type='checkbox' onChange={(e)=>{ 
                                  applicant.business.garage.serviceoffered.cbps = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.serviceoffered,'cbps')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Car Body repair service like Painting and Denting" name="garage_carRepair" type='checkbox' onChange={(e)=>{ 
                                  applicant.business.garage.serviceoffered.carRepair = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.serviceoffered,'carRepair')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Several Oil change services like Engine Oil, Gear Oil, Steering Oil etc" name="garage_socs" type='checkbox' onChange={(e)=>{ 
                                  applicant.business.garage.serviceoffered.socs = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.serviceoffered,'socs')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Vehicle wash" name="garage_vw" type='checkbox' onChange={(e)=>{ 
                                  applicant.business.garage.serviceoffered.vw = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.serviceoffered,'vw')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Electrical Jobs like Horn, Light etc" name="garage_ej" type='checkbox' onChange={(e)=>{ 
                                  applicant.business.garage.serviceoffered.ej = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.serviceoffered,'ej')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Wheel balancing and alignment" name="garage_ba" type='checkbox' onChange={(e)=>{ 
                                  applicant.business.garage.serviceoffered.ba = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.serviceoffered,'ba')} />
                              </Col>
                              <Col md={3}>
                                <Form.Check inline label="Mechanical Jobs like the Engine, Gear, Accelerator, Shock-Absorber etc" name="garage_mj" type='checkbox' onChange={(e)=>{ 
                                  applicant.business.garage.serviceoffered.mj = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.serviceoffered,'mj')} />
                              </Col>
                              
                            </Row>
                            <Row>
                              <Col md={2}>Value Adds *</Col>
                            </Row>
                            <Row>
                              <Col md={2}>
                                <Form.Check inline label="None" name="garage_none" type='checkbox' onChange={(e)=>{ 
                                  applicant.business.garage.valueadds.none = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.valueadds,'none')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Lubricants" name="garage_lubricants" type='checkbox' disabled={applicant.business.garage.valueadds && applicant.business.garage.valueadds.none} onChange={(e)=>{ 
                                  applicant.business.garage.valueadds.lubricants = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.valueadds,'lubricants')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Selling of Accessories" name="garage_sshcr" type='checkbox' disabled={applicant.business.garage.valueadds && applicant.business.garage.valueadds.none} onChange={(e)=>{ 
                                  applicant.business.garage.valueadds.sshcr = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.valueadds,'sshcr')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Selling of Oil" name="garage_soa" type='checkbox' disabled={applicant.business.garage.valueadds && applicant.business.garage.valueadds.none} onChange={(e)=>{ 
                                  applicant.business.garage.valueadds.soa = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.valueadds,'soa')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Selling of Spares" name="garage_sooil" type='checkbox' disabled={applicant.business.garage.valueadds && applicant.business.garage.valueadds.none} onChange={(e)=>{ 
                                  applicant.business.garage.valueadds.sooil = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage.valueadds,'sooil')} />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Number of Mechanics *</Form.Label>
                                <Form.Group controlId="garage_noofmechanics">
                                  <Form.Select className="form-control" onChange={(e)=> {
                                      applicant.business.garage.noofmechanics = e.target.value;
                                    }} name="garage_noofmechanics" defaultValue={getValue(applicant.business.garage,'noofmechanics')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('numberOfMechanics').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Average experience of the mechanic  *</Form.Label>
                                <Form.Group controlId="garage_expofmechanic">
                                  <Form.Select className="form-control" onChange={(e)=> {
                                      applicant.business.garage.expofmechanic = e.target.value;
                                    }} name="garage_expofmechanic" defaultValue={getValue(applicant.business.garage,'expofmechanic')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('avgExpMechanic').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Size of Garage Covered / Uncovered</Form.Label>
                                <FormControl type="number" name="garage_size" onChange={(event)=> {
                                  applicant.business.garage.size=event.target.value;
                                }} defaultValue={getValue(applicant.business.garage,'size')} />    
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Average experience of the mechanic  *</Form.Label>
                                <Form.Group controlId="garage_nobays">
                                  <Form.Select className="form-control" onChange={(e)=> {
                                      applicant.business.garage.nobays = e.target.value;
                                    }} name="garage_nobays" defaultValue={getValue(applicant.business.garage,'nobays')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('noBays').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Label className="custom-form-label" >Who looks after the Garage when you are ill / away? *</Form.Label>
                                <FormControl type="text" maxLength={50} name="garage_looksafter" onChange={(event)=> {
                                  applicant.business.garage.looksafter=event.target.value;
                                }} defaultValue={getValue(applicant.business.garage,'looksafter')} />    
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <Form.Check inline label="Equipment / Machinery Owned" name="garage_equipment" type='checkbox' onChange={(e)=>{ 
                                  applicant.business.garage.equipment = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage,'equipment')} />
                              </Col>
                            </Row>
                            <div hidden ={!applicant.business.garage.equipment}>
                              <Row>
                              <strong style={{paddingTop:'20px'}}>Machine<a onClick={addGarageMachine}><i className="fa fa-plus fa-lg"></i></a></strong>
                              <hr/>
                              </Row>
                              <table className="table table-striped table-emails table-hover tablemobileview" id="folder-view" >
                                <thead>
                                <tr>
                                  <th id="folder-actions">
                                    <strong>TYPE OF MACHINE / EQUIPMENT</strong>
                                  </th>
                                  <th><strong>PURPOSE FOR WHICH REQUIRED</strong></th>
                                  <th><strong>TOTAL COST OF MACHINE</strong></th>
                                  <th><strong>HOW WAS PAYMENT MADE</strong></th>
                                  <th></th>
                                </tr>
                                </thead>
                                {(!isDefined(applicant.business.dairy.machines) || applicant.business.dairy.machines.length === 0) && 
                                  <tr >
                                  <td colSpan={6}><span className="col-lg-4 col-sm-6 col-xs-8 col-lg-offset-5 col-sm-offset-5 col-xs-offset-4">No Machines</span></td>
                                  </tr>
                                }
                                {isDefined(applicant.business.dairy.machines) && applicant.business.dairy.machines.length > 0 && applicant.business.dairy.machines.map((machine:any,i:any)=>(
                                  <tr>
                                    <td>{i+1}</td>
                                    <td><a  onClick={()=>showGarageMachine(i)}>{getTypeValue('GARAGETYPE',machine.type)}</a></td>
                                    <td>{machine.purpose}</td>
                                    <td>{machine.cost}</td>
                                    <td>{getTypeValue('CRAFTSMANMACHINE',machine.payment)}</td>
                                    <td><a ng-click={removeGarageMachine(i)}><i className="fa fa-times fa-lg"></i></a></td>
                                  </tr>
                                ))} 
                              </table>
                              <div hidden={!loanModel.showGarageMachineForm}>
                                <Row>
                                  <Col md={3}>
                                    <Form.Label className="custom-form-label" >Type of Machine / Equipment *</Form.Label>
                                    <Form.Group controlId="garage_machine_type">
                                      <Form.Select className="form-control" onChange={(e)=> {
                                          loanModel.garageMachine.type = e.target.value;
                                        }} name="garage_machine_type" defaultValue={getValue(loanModel.garageMachine,'type')}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('garageMachineorEquipment').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col md={3}>
                                    <Form.Label className="custom-form-label" >Purpose for which required</Form.Label>
                                    <FormControl type="text" name="garage_machine_purpose" onChange={(event)=> {
                                      loanModel.garageMachine.purpose=event.target.value;
                                    }} defaultValue={getValue(loanModel.garageMachine,'purpose')} />    
                                  </Col>
                                  <Col md={3}>
                                    <Form.Label className="custom-form-label" >Total cost of Machine</Form.Label>
                                    <FormControl type="number" name="garage_matchine_cost" onChange={(event)=> {
                                      loanModel.garageMachine.cost=event.target.value;
                                    }} defaultValue={getValue(loanModel.garageMachine,'cost')} />    
                                  </Col>
                                  <Col md={3}>
                                    <Form.Label className="custom-form-label" >How was payment made *</Form.Label>
                                    <Form.Group controlId="garage_machine_payment">
                                      <Form.Select className="form-control" onChange={(e)=> {
                                          loanModel.garageMachine.payment = e.target.value;
                                        }} name="garage_machine_payment" defaultValue={getValue(loanModel.garageMachine,'payment')}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('paymentMade').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={9}></Col>
                                  <Col md={3}>
                                    <Button variant="dark" onClick={cancelGarageMachine}> Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button variant="danger" onClick={saveGarageMachine}> Save</Button>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            <strong style={{paddingTop:'20px'}}>Customer Landscape</strong>
                            <hr/>
                            <Row>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Average Customers per day *</Form.Label>
                                <FormControl type="number" name="garage_matchine_avgcustomers" onChange={(event)=> {
                                  applicant.business.garage.avgcustomers=event.target.value;
                                }} defaultValue={getValue(applicant.business.garage,'avgcustomers')} />    
                              </Col>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Regular repeat customers / Households *</Form.Label>
                                <FormControl type="number" name="garage_matchine_repeatcustomers" onChange={(event)=> {
                                  applicant.business.garage.repeatcustomers=event.target.value;
                                }} defaultValue={getValue(applicant.business.garage,'repeatcustomers')} />    
                              </Col>
                              <Col md={3} hidden={isDefined(applicant.business.garage.avgcustomers) && parseInt(applicant.business.garage.avgcustomers)<=0}>
                                <Form.Label className="custom-form-label" >Average Monthly Billing *</Form.Label>
                                <FormControl type="number" name="garage_matchine_avgbilling" onChange={(event)=> {
                                  applicant.business.garage.avgbilling=event.target.value;
                                }} defaultValue={getValue(applicant.business.garage,'avgbilling')} />    
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                <Form.Check inline label="Large Customers" name="garabse_largecustomer" type='checkbox' id="garabse_largecustomer" onChange={(e)=>{ 
                                  applicant.business.garage.largecustomer = e.target.checked;
                                  if(!applicant.business.garage.largecustomer){
                                    if(!isDefined(applicant.business.garage.customer)){
                                      applicant.business.garage.customer = {};
                                    }
                                    applicant.business.garage.customer.authorizedcontracting=false;
                                    applicant.business.garage.customer.bigcontracting=false;
                                    applicant.business.garage.customer.companies=false;
                                    applicant.business.garage.customer.housingsocieties=false;
                                    applicant.business.garage.customer.avgcustperday=0;
                                    applicant.business.garage.customer.avgmonthlybilling=0;
                                  }
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.garage,'largecustomer')} />
                              </Col>
                            </Row>
                            <label hidden={applicant.business.garage && !applicant.business.garage.largecustomer}> Customer *</label>
                            <Row hidden={applicant.business.garage && !applicant.business.garage.largecustomer}>
                                <Col md={3}>
                                  <Form.Check inline label="Authorized Garages Sub Contracting" name="authorizedcontracting" type='checkbox' id="authorizedcontracting" onChange={(e)=>{ 
                                    applicant.business.garage.customer.authorizedcontracting = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.garage.customer,'authorizedcontracting')} />
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Big Garages Sub Contracting" name="bigcontracting" type='checkbox' id="bigcontracting" onChange={(e)=>{ 
                                    applicant.business.garage.customer.bigcontracting = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.garage.customer,'bigcontracting')} />
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Big Garages Sub Contracting" name="garage_cusomer_companies" type='checkbox' id="garage_cusomer_companies" onChange={(e)=>{ 
                                    applicant.business.garage.customer.companies = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.garage.customer,'companies')} />
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Housing Societies" name="garage_cusomer_housingsocieties" type='checkbox' id="garage_cusomer_housingsocieties" onChange={(e)=>{ 
                                    applicant.business.garage.customer.housingsocieties = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.garage.customer,'housingsocieties')} />
                                </Col>
                            </Row>
                            <Row hidden={applicant.business.garage && !applicant.business.garage.largecustomer}>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Average Customers per day *</Form.Label>
                                <FormControl type="number" name="garage_matchine_avgcustperday" onChange={(event)=> {
                                  applicant.business.garage.avgcustperday=event.target.value;
                                }} defaultValue={getValue(applicant.business.garage,'avgcustperday')} />    
                              </Col>
                              <Col md={3} hidden={isDefined(applicant.business.garage.avgcustperday) && parseInt(applicant.business.garage.avgcustperday)<=0}>
                                <Form.Label className="custom-form-label" >Average Monthly Billing *</Form.Label>
                                <FormControl type="number" name="garage_matchine_avgmonthlybilling" onChange={(event)=> {
                                  applicant.business.garage.avgmonthlybilling=event.target.value;
                                }} defaultValue={getValue(applicant.business.garage,'avgmonthlybilling')} />    
                              </Col>
                            </Row>
                            <strong style={{paddingTop:'20px'}}>Landscape</strong>
                            <hr />
                            <Row>
                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Number of Garages close by *</Form.Label>
                                <FormControl type="number" name="garage_matchine_noofclose" onChange={(event)=> {
                                  applicant.business.garage.noofclose=event.target.value;
                                }} defaultValue={getValue(applicant.business.garage,'noofclose')} />    
                              </Col>
                            </Row>
                            <strong style={{paddingTop:'20px'}}>Training</strong>
                            <hr />
                            <Row>
                              <Col md={4}>
                                <Form.Check inline label="No Vocational Course / On job training" name="garage_certificates_jobtraining" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.garage.certificates.jobtraining = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.garage.certificates,'jobtraining')} />   
                              </Col>
                              <Col md={4}>
                                <Form.Check inline label="Vocational Certificates" name="garage_certificates_vc" type='checkbox' disabled={applicant.business.garage.certificates && applicant.business.garage.certificates.jobtraining} onChange={(e)=>{ 
                                    applicant.business.garage.certificates.vc = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.garage.certificates,'vc')} />   
                              </Col>
                              <Col md={4}>
                                <Form.Check inline label="Automobile Technical Training Institutes" name="garage_certificates_ats" type='checkbox' disabled={applicant.business.garage.certificates && applicant.business.garage.certificates.jobtraining}  onChange={(e)=>{ 
                                    applicant.business.garage.certificates.ats = e.target.checked;
                                    if(!applicant.business.garage.certificates.ats){
                                      applicant.business.garage.duration = null;
                                    }
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.garage.certificates,'ats')} />   
                              </Col>
                            </Row>
                            <Row hidden={!isDefined(applicant.business.garage.certificates) && applicant.business.garage.certificates.ats}>
                              <Col md={4}>
                                <Form.Label className="custom-form-label" >Course Duration</Form.Label>
                                <Form.Group controlId="garage_machine_payment">
                                  <Form.Select className="form-control" onChange={(e)=> {
                                      applicant.business.garage.duration = e.target.value;
                                    }} name="garage_machine_payment" defaultValue={getValue(applicant.business.garage,'duration')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('courseDuration').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Tab>}
                           {applicant!==null && applicant.business!==null && applicant.business.type=='MFR' && applicant.business.profile==='Handloom' &&  <Tab eventKey="btabs21  " title="Handloom">
                            <strong style={{paddingTop:'20px'}}>Business</strong>
                            <hr/>
                            <Row>
                              <Col md={4}>
                                <Form.Check inline label="Do you have Handloom Association Weaver Card?" name="handloom_weavercard" type='checkbox' onChange={(e)=>{ 
                                  applicant.business.handloom.weavercard = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.handloom,'weavercard')} />
                              </Col>
                              <Col md={3} hidden={!applicant.business.handloom.weavercard}>
                                <Form.Label className="custom-form-label" >Handloom Association Weaver Card Number</Form.Label>
                                <FormControl type="number" maxLength={50} name="handloom_cardnumber" onChange={(event)=> {
                                  applicant.business.handloom.cardnumber=event.target.value;
                                }} defaultValue={getValue(applicant.business.handloom,'cardnumber')} />    
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>Handloom Type</Col>
                              <Col md={2}>
                                <Form.Check inline label="Fabric" name="handloomtype_Fabric" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.handloomtype.fabric = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.handloomtype,'fabric')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Saree" name="handloomtype_Saree" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.handloomtype.saree = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.handloomtype,'saree')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Decorative" name="handloomtype_decorative" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.handloomtype.decorative = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.handloomtype,'decorative')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Textile" name="handloomtype_textile" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.handloomtype.textile = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.handloomtype,'textile')} />   
                              </Col>

                            </Row>
                            <Row>
                              <Col md={4}>Value added business activities</Col>
                              <Col md={2}>
                                <Form.Check inline label="Selling Yarn" name="activities_sellingyarn" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.activities.sellingyarn = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.sellingyarn,'fabric')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Sub-Contracted Jobs" name="activities_contractedjobs" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.activities.contractedjobs = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.activities,'contractedjobs')} />   
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <Form.Label className="custom-form-label" >Number of Looms</Form.Label>
                                <Form.Group controlId="handloom_nooflooms">
                                  <Form.Select className="form-control" onChange={(e)=> {
                                      applicant.business.handloom.nooflooms = e.target.value;
                                    }} name="handloom_nooflooms" defaultValue={getValue(applicant.business.handloom,'nooflooms')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('numberofLooms').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Label className="custom-form-label" >Number of Weavers</Form.Label>
                                <Form.Group controlId="handloom_noofweavers">
                                  <Form.Select className="form-control" onChange={(e)=> {
                                      applicant.business.handloom.noofweavers = e.target.value;
                                    }} name="handloom_noofweavers" defaultValue={getValue(applicant.business.handloom,'noofweavers')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('numberofWeavers').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={4} >
                                <Form.Label className="custom-form-label" >Total cost of looms</Form.Label>
                                <FormControl type="number" maxLength={50} name="handloom_totalcost" onChange={(event)=> {
                                  applicant.business.handloom.totalcost=event.target.value;
                                }} defaultValue={getValue(applicant.business.handloom,'totalcost')} />    
                              </Col>
                            </Row>
                            <Row style={{paddingTop:'20px'}}>Loom Type</Row>
                            <Row>
                              <Col md={2}>
                                <Form.Check inline label="Hand Loom" name="loomtype_hand" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.loomtype.hand = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.loomtype,'hand')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Power Loom" name="loomtype_power" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.loomtype.power = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.loomtype,'power')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Ground Loom" name="loomtype_ground" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.loomtype.ground = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.loomtype,'ground')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Frame Loom" name="loomtype_frame" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.loomtype.frame = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.loomtype,'frame')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Foot Treadle" name="loomtype_foottreadle" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.loomtype.foottreadle = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.loomtype,'foottreadle')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Floor Loom" name="loomtype_floorloom" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.loomtype.floorloom = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.loomtype,'floorloom')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Backstrap Loom" name="loomtype_backstrap" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.loomtype.backstrap = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.loomtype,'backstrap')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Shuttle Loom" name="loomtype_shuttle" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.loomtype.shuttle = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.loomtype,'shuttle')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Other" name="loomtype_other" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.loomtype.other = e.target.checked;
                                    if(!e.target.checked){
                                      applicant.business.handloom.typeother = null;
                                    }
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.loomtype,'other')} />   
                              </Col>
                            </Row>
                            <Row hidden={!isDefined(applicant.business.handloom.loomtype) || !applicant.business.handloom.loomtype.other}>
                              <Col md={4} >
                                <Form.Label className="custom-form-label" >Please Specify</Form.Label>
                                <FormControl type="text" maxLength={50} name="handloom_totalcost" onChange={(event)=> {
                                  applicant.business.handloom.typeother=event.target.value;
                                }} defaultValue={getValue(applicant.business.handloom, 'typeother')} />    
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <Form.Label className="custom-form-label" >Weaving Skill Set Acquired From</Form.Label>
                                <Form.Group controlId="handloom_skillsacquired">
                                  <Form.Select className="form-control" onChange={(e)=> {
                                      applicant.business.handloom.skillsacquired = e.target.value;
                                    }} name="handloom_skillsacquired" defaultValue={getValue(applicant.business.handloom,'skillsacquired')}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('skillsAcquired').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Check inline label="Do you get yarn at Mill Gate Price?" name="loomtype_yarnprice" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.yarnprice = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom,'yarnprice')} />   
                              </Col>
                            </Row>
                            <Row style={{paddingTop:'20px'}}>Registration with</Row>
                            <Row>
                              <Col md={4}>
                                <Form.Check inline label="Handloom Export Promotion Council (HEPC)" name="registration_hepc" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.registration.hepc = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.registration,'hepc')} />   
                              </Col>
                              <Col md={4}>
                                <Form.Check inline label="Other Export Promotion Council under the Ministry of Textiles" name="registration_epcmt" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.registration.epcmt = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.registration,'epcmt')} />   
                              </Col>
                              <Col md={4}>
                                <Form.Check inline label="Other" name="registration_other" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.registration.other = e.target.checked;
                                    if(!e.target.checked){
                                      applicant.business.handloom.regother = null;
                                    }
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.registration,'other')} />   
                              </Col>
                              <Col md={4} hidden={!isDefined(applicant.business.handloom.registration) || !applicant.business.handloom.registration.other}>
                                <Form.Label className="custom-form-label" >Please Specify</Form.Label>
                                <FormControl type="text" maxLength={50} name="handloom_regother" onChange={(event)=> {
                                  applicant.business.handloom.regother=event.target.value;
                                }} defaultValue={getValue(applicant.business.handloom, 'regother')} />    
                              </Col>
                            </Row>
                            <Row style={{paddingTop:'20px',fontWeight:'bold'}}>Customer Landscape</Row>
                            <hr/>
                            <Row>
                              <Col md={4}>
                                <Form.Check inline label="Are you associated with any single outlet as a supplier? " name="handloom_supplier" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.supplier = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom,'supplier')} />   
                              </Col>
                              
                              <Col md={4} hidden={!applicant.business.handloom.registration.specify}>
                                <Form.Label className="custom-form-label" >Please Specify</Form.Label>
                                <FormControl type="text" maxLength={50} name="handloom_specify" onChange={(event)=> {
                                  applicant.business.handloom.specify=event.target.value;
                                }} defaultValue={getValue(applicant.business.handloom, 'specify')} />    
                              </Col>
                            </Row>
                            <Row style={{paddingTop:'20px'}}>Customer</Row>
                            <Row>
                              <Col md={2}>
                                <Form.Check inline label="Individual / Retailer" name="customer_individuals" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.customer.individuals = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.customer,'individuals')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Wholesaler" name="customer_wholesaler" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.customer.wholesaler = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.customer,'wholesaler')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Co-op" name="customer_cooperatives" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.customer.cooperatives = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.customer,'cooperatives')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Large Stores" name="customer_largestores" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.customer.largestores = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.customer,'largestores')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="E-platforms" name="customer_eplatforms" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.customer.eplatforms = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.customer,'v')} />   
                              </Col>
                            </Row>
                            <Row style={{paddingTop:'20px'}}>Specialisation</Row>
                            <Row>
                              <Col md={2}>
                                <Form.Check inline label="Phulkari" name="specialisation_phulkari" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.specialisation.phulkari = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.specialisation,'phulkari')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Chanderi" name="specialisation_chanderi" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.specialisation.chanderi = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.specialisation,'chanderi')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Brocare" name="specialisation_brocare" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.specialisation.brocare = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.specialisation,'brocare')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Daccai" name="specialisation_daccai" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.specialisation.daccai = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.specialisation,'daccai')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Jacquard" name="specialisation_jacquard" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.specialisation.jacquard = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.specialisation,'jacquard')} />   
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Other" name="specialisation_other" type='checkbox' onChange={(e)=>{ 
                                    applicant.business.handloom.specialisation.other = e.target.checked;
                                    if(!e.target.checked){
                                      applicant.business.handloom.specother = null;
                                    }
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.handloom.specialisation,'other')} />   
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4} hidden={!isDefined(applicant.business.handloom.specialisation) || !applicant.business.handloom.specialisation.other}>
                                <Form.Label className="custom-form-label" >Please Specify</Form.Label>
                                <FormControl type="text" maxLength={50} name="handloom_specother" onChange={(event)=> {
                                  applicant.business.handloom.specother=event.target.value;
                                }} defaultValue={getValue(applicant.business.handloom, 'specother')} />    
                              </Col>
                            </Row>
                            
                          </Tab>
                        }
                        {applicant !== null && applicant.business !== null && applicant.business.type == 'SVC' && applicant.business.profile === 'Beauty Parlour / Spa / Hair Saloon' && loanModel.beautyparlourflag && <Tab eventKey="btabs22  " title="Beauty Parlour">
                          <div>
                            <div className="col-md-12 unset-padding">
                              <legend>
                                <label><strong>Beauty Parlour / Salon Type *</strong></label>
                              </legend>
                            </div>
                            <Col md={3}>
                              <Form.Label className="custom-form-label" >Weaving Skill Set Acquired From</Form.Label>
                                <Form.Select className="form-control" onChange={(e) => {
                                  applicant.business.beautyparlour.salontype = e.target.value;
                                  setLoan(loanModel)
                                }} name="salontype" defaultValue={getValue(applicant.business.beautyparlour, 'salontype')}>
                                  <option value=""></option>
                                  {
                                    getLookupProperty('salonType').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                  }
                                </Form.Select>
                            </Col>
                          </div>
                          <div className='mt-2'></div>
                          <div className="padding-top"><legend className="businessAddress "><strong>Business</strong></legend><br /></div>
                          <hr />

                          {(applicant.business.beautyparlour.salontype === 'G' || applicant.business.beautyparlour.salontype === 'L') &&
                            <div hidden={!applicant.business.beautyparlour.isfranchise}><label>Service Offered *<br /></label></div>
                          }
                          {applicant.business.beautyparlour.salontype !== "" &&

                            <Row className='mt-2'>
                              <Col md={2}>
                                <Form.Check inline label="Hair Cut" name="service_haircut" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.service.haircut = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour.service, 'haircut')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Colouring" name="service_colouring" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.service.colouring = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour.service, 'colouring')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Cleanup & Facial" name="service_cleanupfacial" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.service.cleanupfacial = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour.service, 'cleanupfacial')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Bleach & Detan" name="service_bleachdetan" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.service.bleachdetan = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour.service, 'bleachdetan')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Massage" name="service_massage" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.service.massage = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour.service, 'massage')} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label="Cosmetic Product Sale" name="service_cosmeticproductsale" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.service.cosmeticproductsale = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour.service, 'cosmeticproductsale')} />
                              </Col>
                            </Row>}
                          <Row>
                            <Col md={2}>
                              <Form.Check inline label="Manicure" name="service_manicure" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.service.manicure = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.service, 'manicure')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Shampooing" name="service_shampooing" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.service.shampooing = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.service, 'shampooing')} />
                            </Col>
                            {!(applicant.business.beautyparlour.salontype == null || applicant.business.beautyparlour.salontype == 'G') &&
                              <Col md={2}>
                                <Form.Check inline label="Mehendi Application" name="service_mehendiapplication" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.service.mehendiapplication = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour.service, 'mehendiapplication')} />
                              </Col>}
                            {!(applicant.business.beautyparlour.salontype == null || applicant.business.beautyparlour.salontype == 'G') &&
                              <Col md={2}>
                                <Form.Check inline label="Bridal Makeup" name="service_bridalmakeup" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.service.bridalmakeup = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour.service, 'bridalmakeup')} />
                              </Col>}
                            {!(applicant.business.beautyparlour.salontype == null || applicant.business.beautyparlour.salontype.salontype == 'L') &&
                              <Col md={2}>
                                <Form.Check inline label="Groom Makeup" name="service_groommakup" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.service.groommakup = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour.service, 'groommakup')} />
                              </Col>}
                            {!(applicant.business.beautyparlour.salontype == null || applicant.business.beautyparlour.salontype.salontype == 'L') &&
                              <Col md={2}>
                                <Form.Check inline label="Shave / Beard Grooming" name="service_shavegrooming" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.service.shavegrooming = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour.service, 'shavegrooming')} />
                              </Col>}
                            {!(applicant.business.beautyparlour.salontype == null) &&
                              <Col md={2}>
                                <Form.Check inline label="Pedicure" name="service_pedicure" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.service.pedicure = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour.service, 'pedicure')} />
                              </Col>}
                            {!(applicant.business.beautyparlour.salontype == null) &&
                              <Col md={2}>
                                <Form.Check inline label="Hair Treatment" name="service_hairtreatment" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.service.hairtreatment = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour.service, 'hairtreatment')} />
                              </Col>}
                            {!(applicant.business.beautyparlour.salontype == null || applicant.business.beautyparlour.salontype == 'G') &&
                              <Col md={2}>
                                <Form.Check inline label="Ear Piercing" name="service_earpiercing" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.service.earpiercing = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour.service, 'earpiercing')} />
                              </Col>}
                            {!(applicant.business.beautyparlour.salontype == null || applicant.business.beautyparlour.salontype == 'G') &&
                              <Col md={2}>
                                <Form.Check inline label="Nails" name="service_nails" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.service.nails = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour.service, 'nails')} />
                              </Col>}
                            {!(applicant.business.beautyparlour.salontype == null || applicant.business.beautyparlour.salontype == 'G') &&
                              <Col md={2}>
                                <Form.Check inline label="Threading" name="service_threading" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.service.threading = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour.service, 'threading')} />
                              </Col>}
                            {!(applicant.business.beautyparlour.salontype == null || applicant.business.beautyparlour.salontype == 'G') &&
                              <Col md={2}>
                                <Form.Check inline label="Waxing" name="service_Waxing" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.service.Waxing = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour.service, 'Waxing')} />
                              </Col>}
                          </Row>
                          <Row className='mt-3'>
                            <Col md={3}>
                              <Form.Label className="custom-form-label" >Beauty Parlour/Salon Set-Up</Form.Label>
                              <Form.Select className="form-control" onChange={(e) => {
                                applicant.business.beautyparlour.salonSetup = e.target.value;
                                setLoan(loanModel);
                              }} name="salonSetup" defaultValue={getValue(applicant.business.beautyparlour, 'salonSetup')}>
                                <option value=""></option>
                                {
                                  getLookupProperty('salonSetup').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                }
                              </Form.Select>
                            </Col>
                            <Col md={3}>
                                <Form.Check className='mt-4' inline label="Is this franchise?" name="isfranchise" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.isfranchise = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour, 'isfranchise')} />
                              </Col>
                              <Col md={3} hidden={!applicant.business.beautyparlour.isfranchise}>
                                <Form.Label className="custom-form-label" >Franchise Name</Form.Label>
                                <FormControl type="text" maxLength={50} name="franchisename" onChange={(event)=> {
                                  applicant.business.beautyparlour.franchisename=event.target.value;
                                }} defaultValue={getValue(applicant.business.beautyparlour, 'franchisename')} />    
                              </Col>
                              <Col md={3}>
                                <Form.Check className='mt-3' inline label="Is the applicant also Beautician / Hair-Stylist?" name="isbeautician" type='checkbox' onChange={(e) => {
                                  applicant.business.beautyparlour.isbeautician = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.beautyparlour, 'isbeautician')} />
                              </Col>
                          </Row>
                          <Row className='mt-2'>
                            <Col md={3} hidden={applicant.business.beautyparlour.salonsetup == 'Door Step Service / Home Visit only'}>
                              <Form.Label className="custom-form-label" >Number Of Chairs</Form.Label>
                              <FormControl type="number" maxLength={50} name="noofchairs" onChange={(event) => {
                                applicant.business.beautyparlour.noofchairs = event.target.value;
                              }} defaultValue={getValue(applicant.business.beautyparlour, 'noofchairs')} />
                            </Col>
                            <Col md={3} hidden={applicant.business.beautyparlour.salonsetup == 'Door Step Service / Home Visit only'}>
                              <Form.Check className='mt-2' inline label="Air-conditioned Shop" name="isairconditionshopbeautician" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.airconditionshop = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour, 'airconditionshop')} />
                            </Col>
                          </Row>

                          <Row className='mt-2'>
                          <Col md={3}>
                              <Form.Label className="custom-form-label" >Parlour skill set acquired from</Form.Label>
                              <Form.Select className="form-control" onChange={(e) => {
                                applicant.business.beautyparlour.parlourskill = e.target.value;
                                setLoan(loanModel);
                              }} name="parlourskill" defaultValue={getValue(applicant.business.beautyparlour, 'parlourskill')}>
                                <option value=""></option>
                                {
                                  getLookupProperty('parlourSkill').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                }
                              </Form.Select>
                            </Col>
                          
                            <Col md={3} >
                              <Form.Label className="custom-form-label" >Number of Beautician/Hair-Stylist?</Form.Label>
                              <FormControl type="number" maxLength={50} name="noofbeautician" onChange={(event) => {
                                applicant.business.beautyparlour.noofbeautician = event.target.value;
                                setLoan(loanModel);
                              }} defaultValue={getValue(applicant.business.beautyparlour, 'noofbeautician')} onKeyDown={allowPositiveIntegersOnly}/>
                            </Col>

                            <Col md={3} hidden={applicant.business.beautyparlour.noofbeautician ==null || applicant.business.beautyparlour.noofbeautician <1}>
                              <Form.Label className="custom-form-label" >Beautician / Hair-Stylist payout?</Form.Label>
                              <Form.Select className="form-control" onChange={(e) => {
                                applicant.business.beautyparlour.beauticianpayout = e.target.value;
                                setLoan(loanModel);
                              }} name="beauticianpayout" defaultValue={getValue(applicant.business.beautyparlour, 'beauticianpayout')}>
                                <option value=""></option>
                                {
                                  getLookupProperty('beauticianPayout').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                }
                              </Form.Select>
                            </Col>

                            <Col md={3}>
                              <Form.Label className="custom-form-label" >Area / Market assessment</Form.Label>
                              <Form.Select className="form-control" onChange={(e) => {
                                applicant.business.beautyparlour.areaassessment = e.target.value;
                                setLoan(loanModel);
                              }} name="areaassessment" defaultValue={getValue(applicant.business.beautyparlour, 'areaassessment')}>
                                <option value=""></option>
                                {
                                  getLookupProperty('areaAssessment').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                }
                              </Form.Select>
                            </Col>
                          </Row>
                          <div className='mt-2'>
                          <Form.Label className="custom-form-label" >Equipments Seen *</Form.Label>
                          </div>
                          

                          {/* <Row className='mt-2'>

                          <Col md={3}>
                              <Form.Check  inline label="Hair Trimmers" name="salonequipment_hairtrimmers" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.hairtrimmers = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'hairtrimmers')} />
                            </Col>

                            <Col md={3}>
                              <Form.Check  inline label="Hair Removal Laser Machine" name="salonequipment_hairremovallasermachine" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.hairremovallasermachine = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'hairremovallasermachine')} />
                            </Col>

                            <Col md={3}>
                              <Form.Check  inline label="Beauty Therapy Machine" name="salonequipment_beautytherapymachine" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.beautytherapymachine = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'beautytherapymachine')} />
                            </Col>

                            <Col md={3}>
                              <Form.Check  inline label="Wax Heaters" name="salonequipment_waxheaters" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.waxheaters = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'waxheaters')} />
                            </Col>
                          </Row> */}

                          <Row className='mt-2'>

                          <Col md={3}>
                              <Form.Check  inline label="Hair Trimmers" name="salonequipment_hairtrimmers" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.hairtrimmers = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'hairtrimmers')} />
                            </Col>

                            <Col md={3}>
                              <Form.Check  inline label="Hair Removal Laser Machine" name="salonequipment_hairremovallasermachine" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.hairremovallasermachine = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'hairremovallasermachine')} />
                            </Col>

                            <Col md={3}>
                              <Form.Check  inline label="Beauty Therapy Machine" name="salonequipment_beautytherapymachine" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.beautytherapymachine = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'beautytherapymachine')} />
                            </Col>

                            <Col md={3}>
                              <Form.Check  inline label="Wax Heaters" name="salonequipment_waxheaters" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.waxheaters = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'waxheaters')} />
                            </Col>
                          </Row>

                          <Row className='mt-2'>
                          <Col md={3}>
                              <Form.Check  inline label="Facial Steamers" name="salonequipment_facialsteamers" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.facialsteamers = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'facialsteamers')} />
                            </Col>

                            <Col md={3}>
                              <Form.Check  inline label="Blackhead Extractor" name="salonequipment_blackheadextractor" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.blackheadextractor = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'blackheadextractor')} />
                            </Col>

                            <Col md={3}>
                              <Form.Check  inline label="Massage Equipment" name="salonequipment_massageequipment" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.massageequipment = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'massageequipment')} />
                            </Col>

                            <Col md={3}>
                              <Form.Check  inline label="Ear Piercing Gun" name="salonequipment_earpiercinggun" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.earpiercinggun = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'earpiercinggun')} />
                            </Col>
                          </Row >

                          <Row className='mt-2'>

                          <Col md={3}>
                              <Form.Check  inline label="Shampoo Wash Unit" name="salonequipment_shampoowashunit" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.shampoowashunit = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'shampoowashunit')} />
                            </Col>

                            <Col md={3}>
                              <Form.Check  inline label="Foot Spa" name="salonequipment_footspa" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.footspa = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'footspa')} />
                            </Col>

                            <Col md={3}>
                              <Form.Check  inline label="Dressing Tables" name="salonequipment_dressingtables" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.dressingtables = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'dressingtables')} />
                            </Col>

                            <Col md={3}>
                              <Form.Check  inline label="Massage Bench" name="salonequipment_massagebench" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.massagebench = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'massagebench')} />
                            </Col>
                          </Row>

                              
                          <Row className='mt-2'>

                          <Col md={3}>
                              <Form.Check  inline label="Rotating Chairs" name="salonequipment_rotatingchairs" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.rotatingchairs = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'rotatingchairs')} />
                            </Col>

                            <Col md={3}>
                              <Form.Check  inline label="Equipment Trolleys" name="salonequipment_equipmenttrolleys" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.salonequipment.equipmenttrolleys = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.salonequipment, 'equipmenttrolleys')} />
                            </Col>

                          </Row>
                          <div className='mt-3'>
                          <Form.Label className="custom-form-label" ><strong>Customer Landscape</strong></Form.Label>
                          <hr />
                          </div>

                          <Form.Check className='mt-2'  inline label="Attached to E-Platforms" name="isattachedplatform" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.isattachedplatform = e.target.checked;
                            setLoan(loanModel);
                          }} checked={getValue(applicant.business.beautyparlour, 'isattachedplatform')} />

                          <Row hidden={!applicant.business.beautyparlour.isattachedplatform} className='mt-3'>

                            <Col md={2}>
                              <Form.Label className="custom-form-label" >E-Platforms *</Form.Label>
                            </Col>
                            <Col md={2}>
                              <Form.Check className='mt-2' inline label="Housejoy" name="eplatforms_housejoy" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.eplatforms.housejoy = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.eplatforms, 'housejoy')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check className='mt-2' inline label="Justdail" name="eplatforms_justdail" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.eplatforms.justdail = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.eplatforms, 'justdail')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check className='mt-2' inline label="QuikrEasy" name="eplatforms_quikreasy" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.eplatforms.quikreasy = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.eplatforms, 'quikreasy')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check className='mt-2' inline label="Sulekha" name="eplatforms_sulekha" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.eplatforms.sulekha = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.eplatforms, 'sulekha')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check className='mt-2' inline label="Urban Company" name="eplatforms_urbancompany" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.eplatforms.urbancompany = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.eplatforms, 'hourbancompanyusejoy')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check className='mt-2' inline label="Zimmber" name="eplatforms_zimmber" type='checkbox' onChange={(e) => {
                                applicant.business.beautyparlour.eplatforms.zimmber = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.beautyparlour.eplatforms, 'zimmber')} />
                            </Col>
                          </Row>
                        </Tab>}
                        {applicant!==null && applicant.business!==null && applicant.business.type=='SVC' && applicant.business.profile==='Tutors' &&  
                        <Tab eventKey="btabs22  " title="Tutors">
                          <div className="padding-top"><legend className="businessAddress "><strong>Business</strong></legend><br /></div>
                          <hr />
                          <Row>
                            <Col md={2}>
                              <Form.Label className="custom-form-label" >Education Board being Taught *</Form.Label>
                            </Col>
                            <Col md={1}>
                              <Form.Check inline label="SSC" name="educationboard_ssc" type='checkbox' id="educationboard_ssc" onChange={(e) => {
                                applicant.business.tutor.educationboard.ssc = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.educationboard, 'ssc')} />
                            </Col>
                            <Col md={1}>
                              <Form.Check inline label="HSC" name="educationboard_hsc" type='checkbox' id="educationboard_hsc" onChange={(e) => {
                                applicant.business.tutor.educationboard.hsc = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.educationboard, 'hsc')} />
                            </Col>
                            <Col md={1}>
                              <Form.Check inline label="CBSE" name="educationboard_cbse" type='checkbox' id="educationboard_cbse" onChange={(e) => {
                                applicant.business.tutor.educationboard.cbse = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.educationboard, 'cbse')} />
                            </Col>
                            <Col md={1}>
                              <Form.Check inline label="ICSE" name="educationboard_icse" type='checkbox' id="educationboard_icse" onChange={(e) => {
                                applicant.business.tutor.educationboard.icse = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.educationboard, 'icse')} />
                            </Col>
                            <Col md={1}>
                              <Form.Check inline label="ISC" name="educationboard_isc" type='checkbox' id="educationboard_isc" onChange={(e) => {
                                applicant.business.tutor.educationboard.isc = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.educationboard, 'isc')} />
                            </Col>
                            <Col md={1}>
                              <Form.Check inline label="IB" name="educationboard_ib" type='checkbox' id="educationboard_ib" onChange={(e) => {
                                applicant.business.tutor.educationboard.ib = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.educationboard, 'ib')} />
                            </Col>
                            <Col md={1}>
                              <Form.Check inline label="NIOS" name="educationboard_nios" type='checkbox' id="educationboard_nios" onChange={(e) => {
                                applicant.business.tutor.educationboard.nios = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.educationboard, 'nios')} />
                            </Col>
                          </Row>
                          <div className='mt-3 mb-3'><label>Standards being Taught *</label></div>
                          <Row>
                            <Col md={2}>
                              <Form.Check inline label="Nursery" name="standards_nursery" type='checkbox' id="educationboard_ib" onChange={(e) => {
                                applicant.business.tutor.standards.nursery = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.standards, 'nursery')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Kindergarten" name="standards_kindergarten" type='checkbox' id="standards_kindergarten" onChange={(e) => {
                                applicant.business.tutor.standards.kindergarten = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.standards, 'kindergarten')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Class 1 - 2" name="standards_class1" type='checkbox' id="standards_class1" onChange={(e) => {
                                applicant.business.tutor.standards.class1 = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.standards, 'class1')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Class 3 - 5" name="standards_class3" type='checkbox' id="standards_class3" onChange={(e) => {
                                applicant.business.tutor.standards.class3 = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.standards, 'class3')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Class 6 - 8" name="standards_class6" type='checkbox' id="standards_class6" onChange={(e) => {
                                applicant.business.tutor.standards.class6 = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.standards, 'class6')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Class 9" name="standards_class9" type='checkbox' id="standards_class9" onChange={(e) => {
                                applicant.business.tutor.standards.class9 = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.standards, 'class9')} />
                            </Col>
                            </Row>
                            <div className='mt-2'></div>
                            <Row>
                            <Col md={2}>
                              <Form.Check inline label="Class 10" name="standards_class10" type='checkbox' id="standards_class10" onChange={(e) => {
                                applicant.business.tutor.standards.class10 = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.standards, 'class10')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Class 11 - 12" name="standards_class11" type='checkbox' id="standards_class11" onChange={(e) => {
                                applicant.business.tutor.standards.class11 = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.standards, 'class11')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Under-Graduate" name="standards_ug" type='checkbox' id="standards_ug" onChange={(e) => {
                                applicant.business.tutor.standards.ug = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.standards, 'ug')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Graduate" name="standards_graduate" type='checkbox' id="standards_graduate" onChange={(e) => {
                                applicant.business.tutor.standards.graduate = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.standards, 'graduate')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Post-Graduate" name="standards_pg" type='checkbox' id="standards_pg" onChange={(e) => {
                                applicant.business.tutor.standards.pg = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.standards, 'pg')} />
                            </Col>
                          </Row>
                          <div className='mt-3'></div>
                          {(applicant.business.tutor.standards.class11 || applicant.business.tutor.standards.ug || applicant.business.tutor.standards.graduate || applicant.business.tutor.standards.pg) &&
                            <Col md={6}>
                              <Form.Label className="custom-form-label" >Subjects being Taugh</Form.Label>
                              <FormControl type="text" maxLength={500} name="dariy_cattlebatch" onChange={(event) => {
                                applicant.business.tutor.collegesubject = event.target.value;
                              }} defaultValue={getValue(applicant.business.tutor, 'collegesubject')} />
                            </Col>
                          }
                          {(applicant.business.tutor.standards.nursery || applicant.business.tutor.standards.kindergarten || applicant.business.tutor.standards.class1 || applicant.business.tutor.standards.class3 || applicant.business.tutor.standards.class6 || applicant.business.tutor.standards.class9 || applicant.business.tutor.standards.class10) &&
                            <div className='mt-3'>
                              <Form.Label className="custom-form-label" >Subjects being Taugh</Form.Label>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Maths" name="subject_maths" type='checkbox' id="subject_maths" onChange={(e) => {
                                    applicant.business.tutor.subject.maths = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.standards, 'maths')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Science" name="subject_science" type='checkbox' id="subject_science" onChange={(e) => {
                                    applicant.business.tutor.subject.science = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.subject, 'science')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="English" name="subject_english" type='checkbox' id="subject_english" onChange={(e) => {
                                    applicant.business.tutor.subject.english = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.subject, 'english')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Hindi" name="subject_hindi" type='checkbox' id="subject_hindi" onChange={(e) => {
                                    applicant.business.tutor.subject.hindi = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.subject, 'hindi')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Regional Language" name="subject_rl" type='checkbox' id="subject_rl" onChange={(e) => {
                                    applicant.business.tutor.subject.rl = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.subject, 'rl')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="International Language" name="subject_il" type='checkbox' id="subject_il" onChange={(e) => {
                                    applicant.business.tutor.subject.il = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.subject, 'il')} />
                                </Col>
                              </Row>
                              <div className='mt-2'></div>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="History" name="subject_history" type='checkbox' id="subject_history" onChange={(e) => {
                                    applicant.business.tutor.subject.history = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.subject, 'history')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Geography" name="subject_geography" type='checkbox' id="subject_geography" onChange={(e) => {
                                    applicant.business.tutor.subject.geography = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.subject, 'geography')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Current Affairs" name="subject_ca" type='checkbox' id="subject_ca" onChange={(e) => {
                                    applicant.business.tutor.subject.ca = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.subject, 'ca')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Computer" name="subject_computer" type='checkbox' id="subject_computer" onChange={(e) => {
                                    applicant.business.tutor.subject.computer = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.subject, 'computer')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Sanskrit" name="subject_sanskrit" type='checkbox' id="subject_sanskrit" onChange={(e) => {
                                    applicant.business.tutor.subject.sanskrit = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.subject, 'sanskrit')} />
                                </Col>
                              </Row>
                            </div>
                          }

                          <Row>
                            <div className='mt-3'></div>
                          <Col md={4}>
                            <Form.Group controlId="tuitiongroup">
                              <Form.Label className="custom-form-label" >Tuition Group</Form.Label>
                              <Form.Select className="form-control" onChange={(e) => {
                                applicant.business.tutor.tuitiongroup = e.target.value;
                                setLoan(loanModel);
                              }} name="tuitiongroup" defaultValue={applicant.business.tutor.tuitiongroup}>
                                <option value=""></option>
                                {
                                  getLookupProperty('tuitiongroup').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                }
                              </Form.Select>
                            </Form.Group>
                            </Col>
                            <Col md={4}>
                            <Form.Group controlId="tuitionplace">
                              <Form.Label className="custom-form-label" >Tuition Place</Form.Label>
                              <Form.Select className="form-control" onChange={(e) => {
                                applicant.business.tutor.tuitionplace = e.target.value;
                                setLoan(loanModel);
                              }} name="tuitionplace" defaultValue={applicant.business.tutor.tuitionplace}>
                                <option value=""></option>
                                {
                                  getLookupProperty('tuitionplace').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                }
                              </Form.Select>
                            </Form.Group>
                            </Col>
                            <Col md={4}>
                            <Form.Group controlId="tuitionfrequency">
                              <Form.Label className="custom-form-label" >Tuition Frequency</Form.Label>
                              <Form.Select className="form-control" onChange={(e) => {
                                applicant.business.tutor.tuitionfrequency = e.target.value;
                                setLoan(loanModel);
                              }} name="tuitionfrequency" defaultValue={applicant.business.tutor.tuitionfrequency}>
                                <option value=""></option>
                                {
                                  getLookupProperty('tuitionfrequency').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                }
                              </Form.Select>
                            </Form.Group>
                            </Col>
                          </Row>

                          <div className='mt-3'></div>

                          <Row>
                            <Col md={2}>
                              <Form.Label className="custom-form-label" >Student's School *</Form.Label>
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Municipal" name="student_municipal" type='checkbox' id="student_municipal" onChange={(e) => {
                                applicant.business.tutor.student.municipal = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.student, 'municipal')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Government" name="student_government" type='checkbox' id="student_government" onChange={(e) => {
                                applicant.business.tutor.student.government = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.student, 'government')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Convent" name="student_convent" type='checkbox' id="student_convent" onChange={(e) => {
                                applicant.business.tutor.student.convent = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.student, 'convent')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Private" name="student_private" type='checkbox' id="student_private" onChange={(e) => {
                                applicant.business.tutor.student.private = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.student, 'private')} />
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="International" name="student_international" type='checkbox' id="student_international" onChange={(e) => {
                                applicant.business.tutor.student.international = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.student, 'international')} />
                            </Col>
                          </Row>

                          <Row>
                            <Col md={2}>
                              <Form.Label className="custom-form-label" >Value added Activities *</Form.Label>
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="None" name="student_international" type='checkbox' id="valueaddedactivities_none" onChange={(e) => {
                                applicant.business.tutor.valueaddedactivities.none = e.target.checked;
                                setLoan(loanModel);
                                if(applicant.business.tutor.valueaddedactivities.none == true) {
                                  applicant.business.tutor.valueaddedactivities = {"none": true};
                                }
                              }} checked={getValue(applicant.business.tutor.valueaddedactivities, 'none')}  />
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Test-Series" name="valueaddedactivities_testSeries" type='checkbox' id="valueaddedactivities_testSeries" onChange={(e) => {
                                applicant.business.tutor.valueaddedactivities.testSeries = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.valueaddedactivities, 'testSeries')} disabled = {applicant.business.tutor.valueaddedactivities.none}/>
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Notes Sale" name="valueaddedactivities_notesSale" type='checkbox' id="valueaddedactivities_notesSale" onChange={(e) => {
                                applicant.business.tutor.valueaddedactivities.notesSale = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.valueaddedactivities, 'notesSale')} disabled = {applicant.business.tutor.valueaddedactivities.none}/>
                            </Col>
                            <Col md={2}>
                              <Form.Check inline label="Examiner" name="valueaddedactivities_examiner" type='checkbox' id="valueaddedactivities_examiner" onChange={(e) => {
                                applicant.business.tutor.valueaddedactivities.examiner = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.tutor.valueaddedactivities, 'examiner')} disabled = {applicant.business.tutor.valueaddedactivities.none}/>
                            </Col>
                          </Row>
                          <div className='mt-2'></div>
                          <Row>
                            <Col md={3}>
                              <Form.Group controlId="totalteachingexperience">
                                <Form.Label className="custom-form-label" >Total Teaching Experience</Form.Label>
                                <Form.Select className="form-control" onChange={(e) => {
                                  applicant.business.tutor.experience = e.target.value;
                                  setLoan(loanModel);
                                }} name="totalteachingexperience" defaultValue={applicant.business.tutor.experience}>
                                  <option value=""></option>
                                  {
                                    getLookupProperty('totalteachingexperience').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                  }
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Group controlId="skillacquired">
                                <Form.Label className="custom-form-label" >Tuition Frequency</Form.Label>
                                <Form.Select className="form-control" onChange={(e) => {
                                  applicant.business.tutor.skillacquired = e.target.value;
                                  setLoan(loanModel);
                                }} name="skillacquired" defaultValue={applicant.business.tutor.skillacquired}>
                                  <option value=""></option>
                                  {
                                    getLookupProperty('tuitionskillacquired').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                  }
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          </Row>
                          {applicant.business.tutor.skillacquired == 'PTES' && 
                            <div>
                              <Row>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >School Name</Form.Label>
                                  <FormControl type="text" maxLength={500} name="tutor_schoolname " onChange={(event) => {
                                    applicant.business.tutor.schoolname = event.target.value;
                                  }} defaultValue={getValue(applicant.business.tutor, 'schoolname')} />
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="schoolboard">
                                    <Form.Label className="custom-form-label" >School Board</Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      applicant.business.tutor.schoolboard = e.target.value;
                                      setLoan(loanModel);
                                    }} name="schoolboard" defaultValue={applicant.business.tutor.schoolboard}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('schoolboard').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Duration of Employment in School (in Years)</Form.Label>
                                  <FormControl type="text" maxLength={500} name="tutor_schoolemployment" onChange={(event) => {
                                    applicant.business.tutor.schoolemployment = event.target.value;
                                  }} defaultValue={getValue(applicant.business.tutor, 'schoolemployment')} />
                                </Col>
                              </Row>
                              <div className='mt-3 mb-3'><label>Standards being Taught *</label></div>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Nursery" name="standards_nursery" type='checkbox' id="educationboard_ib" onChange={(e) => {
                                    applicant.business.tutor.schoolstandardstaught.nursery = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.schoolstandardstaught, 'nursery')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Kindergarten" name="standards_kindergarten" type='checkbox' id="standards_kindergarten" onChange={(e) => {
                                    applicant.business.tutor.schoolstandardstaught.kindergarten = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.schoolstandardstaught, 'kindergarten')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Class 1 - 2" name="standards_class1" type='checkbox' id="standards_class1" onChange={(e) => {
                                    applicant.business.tutor.schoolstandardstaught.class1 = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.schoolstandardstaught, 'class1')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Class 3 - 5" name="standards_class3" type='checkbox' id="standards_class3" onChange={(e) => {
                                    applicant.business.tutor.schoolstandardstaught.class3 = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.schoolstandardstaught, 'class3')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Class 6 - 8" name="standards_class6" type='checkbox' id="standards_class6" onChange={(e) => {
                                    applicant.business.tutor.schoolstandardstaught.class6 = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.schoolstandardstaught, 'class6')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Class 9" name="standards_class9" type='checkbox' id="standards_class9" onChange={(e) => {
                                    applicant.business.tutor.schoolstandardstaught.class9 = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.schoolstandardstaught, 'class9')} />
                                </Col>
                              </Row>
                              <div className='mt-2'></div>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Class 10" name="standards_class10" type='checkbox' id="standards_class10" onChange={(e) => {
                                    applicant.business.tutor.schoolstandardstaught.class10 = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.schoolstandardstaught, 'class10')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Class 11 - 12" name="standards_class11" type='checkbox' id="standards_class11" onChange={(e) => {
                                    applicant.business.tutor.schoolstandardstaught.class11 = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.schoolstandardstaught, 'class11')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Under-Graduate" name="schoolstandardstaught_ug" type='checkbox' id="schoolstandardstaught_ug" onChange={(e) => {
                                    applicant.business.tutor.schoolstandardstaught.ug = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.schoolstandardstaught, 'ug')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Graduate" name="schoolstandardstaught_graduate" type='checkbox' id="schoolstandardstaught_graduate" onChange={(e) => {
                                    applicant.business.tutor.schoolstandardstaught.graduate = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.schoolstandardstaught, 'graduate')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Post-Graduate" name="schoolstandardstaught_pg" type='checkbox' id="schoolstandardstaught_pg" onChange={(e) => {
                                    applicant.business.tutor.schoolstandardstaught.pg = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.tutor.schoolstandardstaught, 'pg')} />
                                </Col>
                              </Row>
                            </div>
                          }
                          
                        </Tab>
                        }
                           {applicant!==null && applicant.business!==null && applicant.business.type=='SVC' && applicant.business.profile==='Priest / Astrologer'  && loanModel.priestflag && 
                            <Tab eventKey="btabs23  " title="Priest">
                              <strong style={{marginTop:'20px'}}>Business</strong>
                              <hr/>
                              <Row>
                               
                                <Col md={3}>
                                  <Form.Group controlId="priest_associated">
                                    <Form.Label className="custom-form-label" >Associated with *</Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      applicant.business.priest.associated = e.target.value;
                                      setLoan(loanModel);
                                    }} name="priest_associated" defaultValue={applicant.business.priest.associated}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('associated').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="priest_religiousentity">
                                    <Form.Label className="custom-form-label" >Position within Religious Entity *</Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      applicant.business.priest.religiousentity = e.target.value;
                                      setLoan(loanModel);
                                    }} name="priest_religiousentity" defaultValue={applicant.business.priest.religiousentity}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('religiousentity').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>

                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Level of Religious Education attained *</Form.Label>
                                  <FormControl type="text" maxLength={500} name="priest_religiouseducation " onChange={(event) => {
                                    applicant.business.priest.religiouseducation = event.target.value;
                                  }} defaultValue={getValue(applicant.business.priest, 'religiouseducation')} />
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="priest_induction">
                                    <Form.Label className="custom-form-label" >Induction by *</Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      applicant.business.priest.induction = e.target.value;
                                      setLoan(loanModel);
                                    }} name="priest_induction" defaultValue={applicant.business.priest.induction}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('induction').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <Form.Label className="custom-form-label" >Specialization *</Form.Label>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Wedding Rituals" name="specialization_wr" type='checkbox'  onChange={(e) => {
                                    applicant.business.priest.specialization.wr = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.priest.specialization, 'wr')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Specific Deity Rituals" name="specialization_sdr" type='checkbox' onChange={(e) => {
                                    applicant.business.priest.specialization.sdr = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.priest.specialization, 'sdr')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Sacred thread / Upanayana Ceremony" name="specialization_st" type='checkbox' onChange={(e) => {
                                    applicant.business.priest.specialization.st = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.priest.specialization, 'st')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Lead Prayer" name="specialization_lp" type='checkbox' onChange={(e) => {
                                    applicant.business.priest.specialization.lp = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.priest.specialization, 'lp')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Preaching / Delivering Sermons" name="specialization_pds" type='checkbox' onChange={(e) => {
                                    applicant.business.priest.specialization.pds = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.priest.specialization, 'pds')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="House-warming" name="specialization_hw" type='checkbox' onChange={(e) => {
                                    applicant.business.priest.specialization.hw = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.priest.specialization, 'hw')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Communions" name="specialization_communions" type='checkbox' onChange={(e) => {
                                    applicant.business.priest.specialization.communions = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.priest.specialization, 'communions')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Funeral" name="specialization_funeral" type='checkbox' onChange={(e) => {
                                    applicant.business.priest.specialization.funeral = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.priest.specialization, 'funeral')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Baptism" name="specialization_baptism" type='checkbox' onChange={(e) => {
                                    applicant.business.priest.specialization.baptism = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.priest.specialization, 'baptism')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Assist in Religious / Missionary work" name="specialization_armw" type='checkbox' onChange={(e) => {
                                    applicant.business.priest.specialization.armw = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.priest.specialization, 'armw')} />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Other" name="specialization_other" type='checkbox' onChange={(e) => {
                                    applicant.business.priest.specialization.other = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.priest.specialization, 'other')} />
                                </Col>
                              </Row>

                              <Row>
                                <Col md={2}>
                                  <Form.Label className="custom-form-label" >Value added Activities *</Form.Label>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="None" name="student_international" type='checkbox' id="valueaddedactivities_none" onChange={(e) => {
                                    applicant.business.priest.valueaddedactivities.none = e.target.checked;
                                    setLoan(loanModel);
                                    if(applicant.business.priest.valueaddedactivities.none == true) {
                                      applicant.business.priest.valueaddedactivities = {"none": true};
                                    }
                                  }} checked={getValue(applicant.business.priest.valueaddedactivities, 'none')}  />
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Astrology" name="valueaddedactivities_astrology" type='checkbox' onChange={(e) => {
                                    applicant.business.priest.valueaddedactivities.testSeries = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.priest.valueaddedactivities, 'astrology')} disabled = {applicant.business.priest.valueaddedactivities.none}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Numerology" name="valueaddedactivities_numerology" type='checkbox'  onChange={(e) => {
                                    applicant.business.priest.valueaddedactivities.numerology = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.priest.valueaddedactivities, 'numerology')} disabled = {applicant.business.priest.valueaddedactivities.none}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Auspicious Day / Time Reveals" name="valueaddedactivities_adtr" type='checkbox'  onChange={(e) => {
                                    applicant.business.priest.valueaddedactivities.adtr = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.priest.valueaddedactivities, 'adtr')} disabled = {applicant.business.priest.valueaddedactivities.none}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Group Member of Priests for Rituals" name="valueaddedactivities_gmpr" type='checkbox'  onChange={(e) => {
                                    applicant.business.priest.valueaddedactivities.gmpr = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.priest.valueaddedactivities, 'gmpr')} disabled = {applicant.business.priest.valueaddedactivities.none}/>
                                </Col>
                              </Row>
                              <strong style={{paddingTop:'20px'}}>Customer Landscape</strong>
                              <hr/>
                              <Row>
                                <Col md={3}>Customer Type *</Col>
                                <Col md={3}>
                                  <Form.Check inline label="Individual / Family" name="customertype_if" type='checkbox'  onChange={(e) => {
                                    applicant.business.priest.customertype.if = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.priest.customertype, 'if')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Non-individual" name="customertype_ni" type='checkbox'  onChange={(e) => {
                                    applicant.business.priest.customertype.ni = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.priest.customertype, 'ni')}/>
                                </Col>
                              </Row>
                              <div hidden={!applicant.business.priest.customertype || !applicant.business.priest.customertype.ni}>
                                  <Row>
                                    <Col md={3}>
                                      <Form.Label className="custom-form-label" >Number of individual / family customers *</Form.Label>
                                      <FormControl type="number"  placeholder = "0" name="priest_nocustomer" onChange={(event) => {
                                        applicant.business.priest.nocustomer = event.target.value;
                                      }} defaultValue={getValue(applicant.business.priest, 'nocustomer')} />
                                    </Col>
                                    <Col md={3}>
                                      <Form.Label className="custom-form-label" >Number of regular repeat individual / families *</Form.Label>
                                      <FormControl type="number" placeholder = "0" name="priest_norepeat" onChange={(event) => {
                                        applicant.business.priest.norepeat = event.target.value;
                                      }} defaultValue={getValue(applicant.business.priest, 'norepeat')} />
                                    </Col>
                                    <Col md={3}>
                                      <Form.Label className="custom-form-label" >Average monthly billing - Individual / Family*</Form.Label>
                                      <FormControl type="number" placeholder = "0" name="priest_avgbilling" onChange={(event) => {
                                        applicant.business.priest.avgbilling = event.target.value;
                                      }} defaultValue={getValue(applicant.business.priest, 'avgbilling')} />
                                    </Col>
                                  </Row>
                              </div> 
                              <div hidden={!applicant.business.priest.customertype || !applicant.business.priest.customertype.if}>
                                <Row style={{paddingTop:'20px'}}>Large customer type *</Row>
                                <Row>
                                  <Col md={1}>
                                    <Form.Check inline label="Temples" name="largecustomer_temples" type='checkbox'  onChange={(e) => {
                                      applicant.business.priest.largecustomer.temples = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.priest.largecustomer, 'temples')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Wedding halls" name="largecustomer_wh" type='checkbox'  onChange={(e) => {
                                      applicant.business.priest.largecustomer.wh = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.priest.largecustomer, 'wh')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Banquet Halls" name="largecustomer_bh" type='checkbox'  onChange={(e) => {
                                      applicant.business.priest.largecustomer.bh = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.priest.largecustomer, 'bh')}/>
                                  </Col>
                                  <Col md={1}>
                                    <Form.Check inline label="Hospitals" name="largecustomer_hospitals" type='checkbox'  onChange={(e) => {
                                      applicant.business.priest.largecustomer.hospitals = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.priest.largecustomer, 'hospitals')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Corporates" name="largecustomer_corporates" type='checkbox'  onChange={(e) => {
                                      applicant.business.priest.largecustomer.corporates = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.priest.largecustomer, 'corporates')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Schools" name="largecustomer_schools" type='checkbox'  onChange={(e) => {
                                      applicant.business.priest.largecustomer.schools = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.priest.largecustomer, 'schools')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Societies / Groups" name="largecustomer_sg" type='checkbox'  onChange={(e) => {
                                      applicant.business.priest.largecustomer.sg = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.priest.largecustomer, 'sg')}/>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <Form.Label className="custom-form-label" >Number of Large Customers *</Form.Label>
                                    <FormControl type="number"  placeholder = "0" name="priest_nolargecustomer" onChange={(event) => {
                                      applicant.business.priest.nolargecustomer = event.target.value;
                                    }} defaultValue={getValue(applicant.business.priest, 'nolargecustomer')} />
                                  </Col>
                                  <Col md={3}>
                                    <Form.Label className="custom-form-label" >Average monthly billing *</Form.Label>
                                    <FormControl type="number" placeholder = "0" name="priest_avglargebilling" onChange={(event) => {
                                      applicant.business.priest.avglargebilling = event.target.value;
                                    }} defaultValue={getValue(applicant.business.priest, 'avglargebilling')} />
                                  </Col>
                                </Row>
                              </div> 
                            </Tab>
                            }
                           
                        {applicant !== null && applicant.business !== null && applicant.business.type == 'SVC' && applicant.business.profile === 'Transport' && loanModel.transportflag &&
                          <Tab eventKey="btabs25  " title="Transport">
                            <Row className='mt-3'>
                              <Col md={4}>
                                <Form.Label className="custom-form-label" >Total Count of Vehicle</Form.Label>
                                <FormControl type="number" placeholder="0" name="totalvehicles" onChange={(event) => {
                                  applicant.business.transport.totalvehicles = event.target.value;
                                }} defaultValue={getValue(applicant.business.transport, 'totalvehicles')} />
                              </Col>

                              <Col md={3}>
                                <Form.Group controlId="vehicleclassification">
                                  <Form.Label className="custom-form-label" >Vehicle Classification</Form.Label>
                                  <Form.Select className="form-control" onChange={(e) => {
                                    applicant.business.transport.vehicleclassification = e.target.value;
                                    setLoan(loanModel);
                                  }} name="priest_induction" defaultValue={applicant.business.transport.vehicleclassification}>
                                    {
                                      getLookupProperty('vehicletype').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>

                              <Col md={4}>
                                <Form.Label className="custom-form-label" >Days Covered Monthly</Form.Label>
                                <FormControl type="number" placeholder="0" name="dayscovered" onChange={(event) => {
                                  if (applicant.business.transport.dayscovered != null && applicant.business.transport.dayscovered > 31) {
                                    applicant.business.transport.dayscovered = 0;
                                  } else {
                                    applicant.business.transport.dayscovered = event.target.value;
                                  }
                                  setLoan(loanModel);
                                }} defaultValue={getValue(applicant.business.transport, 'dayscovered')} />
                              </Col>

                            </Row>

                            <Row className='mt-3'>

                              <Col className='mt-4' >
                                <Form.Check inline label="Transportation interstate or intrastate?" name="interstate" type='checkbox' onChange={(e) => {
                                  applicant.business.transport.interstate = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.transport, 'interstate')} />
                              </Col>

                              <Col hidden={!applicant.business.transport.interstate}>
                                <Form.Label className="custom-form-label" >Regions covered</Form.Label>
                                <FormControl type="text" name="routes" onChange={(event) => {
                                  applicant.business.transport.routes = event.target.value;
                                  setLoan(loanModel);
                                }} defaultValue={getValue(applicant.business.transport, 'routes')} />
                              </Col>

                              <Col>
                                <Form.Label className="custom-form-label" >Locality</Form.Label>
                                <FormControl type="text" name="localitytransport" onChange={(event) => {
                                  applicant.business.transport.localitytransport = event.target.value;
                                  setLoan(loanModel);
                                }} defaultValue={getValue(applicant.business.transport, 'localitytransport')} />
                              </Col>

                              <Col >
                                <Form.Check inline label="Loading/Unloading Services Offered?" name="loadingservice" type='checkbox' onChange={(e) => {
                                  applicant.business.transport.loadingservice = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.transport, 'loadingservice')} />
                                <div hidden={!applicant.business.transport.loadingservice}>
                                  <Form.Label className="custom-form-label" >Monthly Amount</Form.Label>
                                  <FormControl type="text" name="transmonthlyamount" onChange={(event) => {
                                    applicant.business.transport.transmonthlyamount = event.target.value;
                                    setLoan(loanModel);
                                  }} defaultValue={getValue(applicant.business.transport, 'transmonthlyamount')} />
                                </div>
                              </Col>
                            </Row>

                            <div className='mt-3'>
                              <fieldset>
                                <legend>
                                  <label className="padding-top">
                                    <strong>Independent vehicle Assessment*&nbsp;&nbsp;&nbsp;</strong>
                                    <a className="" onClick={addTransportVehicle}>
                                      <i className="fa fa-plus fa-lg"></i>
                                    </a>
                                  </label>
                                </legend>
                              </fieldset>
                            </div>

                            <div className="col-md-12 col-sm-12">
                              <table className="table table-striped table-emails table-hover tablemobileview col-sm-12" id="folder-view">
                                <thead>
                                  <tr>
                                    <th><strong>Vehicle Type</strong></th>
                                    <th><strong>Total Count</strong></th>
                                    <th><strong>Ownership</strong></th>
                                    <th><strong>Avg. Fuel Mileage km/litre</strong></th>
                                    <th><strong>Avg. Age of Vehicle/s (Yrs)</strong></th>
                                    <th><strong>Distance travelled in km?</strong></th>
                                    <th><strong>Nature of goods transported</strong></th>
                                    <th><strong>Rate (₹) Charged/km</strong></th>
                                    <th><strong>Registration Number</strong></th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {applicant.business.transport.machines == undefined||applicant?.business?.transport?.machines?.length === 0 ? (
                                    <tr>
                                      <td colSpan={10}>
                                        <span className="col-lg-4 col-sm-6 col-xs-8 col-lg-offset-5 col-sm-offset-5 col-xs-offset-4">
                                          No Income
                                        </span>
                                      </td>
                                    </tr>
                                  ) : (
                                    applicant.business.transport.machines.map((machine:any, index:any) => (
                                      <tr key={index}>
                                        <td className="col-sm-2">
                                          <a href="#" onClick={() => showTransportVehicle(index)}>
                                            {getTypeValue('TRANSPORTTYPE', machine.type)}
                                          </a>
                                        </td>
                                        <td>{machine.count}</td>
                                        <td>{machine.ownership}</td>
                                        <td>{machine.avgmilaeage}</td>
                                        <td>{machine.avgage}</td>
                                        <td>{machine.distancetravelled}</td>
                                        <td>{machine.natureofgoods}</td>
                                        <td>{machine.ratecharges}</td>
                                        <td>{machine.regnumber}</td>
                                        <td className="col-sm-1">
                                          <a onClick={() => removeTransportVehicle(index)}>
                                            <i className="glyphicon glyphicon-remove"></i>
                                          </a>
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </table>
                            </div>

                            {showTransportMachineForm && <div className='mt-2'>
                              <Row >
                                
                                  <Col md={4}>
                                    <Form.Group controlId="type">
                                      <Form.Label className="custom-form-label" >Vehicle Type</Form.Label>
                                      <Form.Select className="form-control" onChange={(e) => {
                                       transportMachine.type = e.target.value;
                                        setLoan(loanModel);
                                      }} name="priest_induction" defaultValue={transportMachine.type}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('transporttype').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                

                                <Col md={4}>
                                <Form.Label className="custom-form-label" >Total Count</Form.Label>
                                <FormControl type="text" name="transport_count" onChange={(event) => {
                                  transportMachine.count = event.target.value;
                                  setLoan(loanModel);
                                }} defaultValue={getValue(transportMachine, 'count')} />
                              </Col>

                              <Col md={4}>
                                    <Form.Group controlId="type">
                                      <Form.Label className="custom-form-label" >Ownership</Form.Label>
                                      <Form.Select className="form-control" onChange={(e) => {
                                        transportMachine.ownership = e.target.value;
                                        setLoan(loanModel);
                                      }} name="transport_ownership" defaultValue={transportMachine.type}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('transportownsership').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>

                              </Row>
                              <Row className='mt-2'>
                              <Col md={4}>
                                <Form.Label className="custom-form-label" >Avg. Fuel Mileage km/litre</Form.Label>
                                <FormControl type="text" name="transport_avgmilaeage" onChange={(event) => {
                                  transportMachine.avgmilaeage = event.target.value;
                                  setLoan(loanModel);
                                }} defaultValue={getValue(transportMachine, 'avgmilaeage')} />
                              </Col>

                              <Col md={4}>
                                <Form.Label className="custom-form-label" >Avg. Age of Vehicle/s (Yrs)</Form.Label>
                                <FormControl type="text" name="transport_avgage" onChange={(event) => {
                                  transportMachine.avgage = event.target.value;
                                  setLoan(loanModel);
                                }} defaultValue={getValue(transportMachine, 'avgage')} />
                              </Col>

                              <Col md={4}>
                                <Form.Label className="custom-form-label" >Distance travelled in km (Monthly)</Form.Label>
                                <FormControl type="text" name="transport_distancetravelled" onChange={(event) => {
                                  transportMachine.distancetravelled = event.target.value;
                                  setLoan(loanModel);
                                }} defaultValue={getValue(transportMachine, 'distancetravelled')} />
                              </Col>

                              </Row>
                              <Row className='mt-2'>
                                <Col md={4}>
                                  <Form.Group controlId="natureofgoods">
                                    <Form.Label className="custom-form-label" >Ownership</Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      transportMachine.natureofgoods = e.target.value;
                                      setLoan(loanModel);
                                    }} name="transport_natureofgoods" defaultValue={transportMachine.natureofgoods}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('transportnatureofgood').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>

                                <Col md={4}>
                                  <Form.Label className="custom-form-label" >Rate (₹) Charged/km</Form.Label>
                                  <FormControl type="text" name="transport_ratecharges" onChange={(event) => {
                                    transportMachine.ratecharges = event.target.value;
                                    setLoan(loanModel);
                                  }} defaultValue={getValue(transportMachine, 'ratecharges')} />
                                </Col>

                                <Col md={4}>
                                  <Form.Label className="custom-form-label" >Registration Number</Form.Label>
                                  <FormControl type="text" name="transport_regnumber" onChange={(event) => {
                                    transportMachine.regnumber = event.target.value;
                                    console.log('transportMachine==>',transportMachine)
                                    setLoan(loanModel);
                                  }} defaultValue={getValue(transportMachine, 'regnumber')} />
                                </Col>

                              </Row>
                              <div className="mt-3 col-md-12">
                                <button className="btn btn-primary submit-button pull-right" style={{marginLeft:'5px'}}
                                  onClick={saveTransportVehicle}>Save</button>
                                <button className="btn btn-inverse submit-button pull-right"
                                   onClick={cancelTransportVehicle}>Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;
                              </div>
                            </div>}

                            <Row className='mt-3'>

                            <Col md={4}>
                                  <Form.Label className="custom-form-label" >Total No. of Driver</Form.Label>
                                  <FormControl type="number" name="transport_countofdrivers" onChange={(event) => {
                                    applicant.business.transport.countofdrivers = event.target.value;
                                    setLoan(loanModel);
                                  }} defaultValue={getValue(applicant.business.transport, 'countofdrivers')} />
                                </Col>

                                <Col md={4}>
                                  <Form.Label className="custom-form-label" >Total No. of other helpers</Form.Label>
                                  <FormControl type="number" name="transport_countofotherhelp" onChange={(event) => {
                                    applicant.business.transport.countofotherhelp = event.target.value;
                                    setLoan(loanModel);
                                  }} defaultValue={getValue(applicant.business.transport, 'countofotherhelp')} />
                                </Col>

                                <Col md={4}>
                                  <Form.Group controlId="avgexperienceofdriver">
                                    <Form.Label className="custom-form-label" >Avg Experience of Drivers (In yrs)</Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      applicant.business.transport.avgexperienceofdriver = e.target.value;
                                      setLoan(loanModel);
                                    }} name="transport_natureofgoods" defaultValue={applicant.business.transport.avgexperienceofdriver}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('avgexpofdriver').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                            </Row>

                            <Row className='mt-3'>
                              <Col md={4}>
                                <Form.Group controlId="additionalservicesoffered">
                                  <Form.Label className="custom-form-label" >Additional Services Offered?</Form.Label>
                                  <Form.Select className="form-control" onChange={(e) => {
                                    applicant.business.transport.additionalservicesoffered = e.target.value;
                                    setLoan(loanModel);
                                  }} name="transport_natureofgoods" defaultValue={applicant.business.transport.additionalservicesoffered}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('transportadditionalservices').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>

                            <div className="row overflow padding-top">
                            <div><legend className="businessAddress"><strong>Business Expenses</strong></legend><br/></div>
                            <hr />
                              <Row className='mt-3'>

                              <Col md={4}>
                                  <Form.Label className="custom-form-label" >Insurance Cost + Annual Permit cost</Form.Label>
                                  <FormControl type="number" name="transport_insurancecost" onChange={(event) => {
                                    applicant.business.transport.insurancecost = event.target.value;
                                    setLoan(loanModel);
                                  }} defaultValue={getValue(applicant.business.transport, 'insurancecost')} />
                                </Col>

                                <Col md={4}>
                                  <Form.Label className="custom-form-label" >Vehicle Taxes (Average Per month)</Form.Label>
                                  <FormControl type="number" name="transport_vehicletaxes" onChange={(event) => {
                                    applicant.business.transport.vehicletaxes = event.target.value;
                                    setLoan(loanModel);
                                  }} defaultValue={getValue(applicant.business.transport, 'vehicletaxes')} />
                                </Col>

                                <Col md={4}>
                                  <Form.Label className="custom-form-label" >Toll Tax enroute (average per month)</Form.Label>
                                  <FormControl type="number" name="transport_tolltax" onChange={(event) => {
                                    applicant.business.transport.tolltax = event.target.value;
                                    setLoan(loanModel);
                                  }} defaultValue={getValue(applicant.business.transport, 'tolltax')} />
                                </Col>

                                <Col md={4}>
                                  <Form.Label className="custom-form-label" >Total Monthly Salary Amount</Form.Label>
                                  <FormControl type="number" name="transport_avgsalary" onChange={(event) => {
                                    applicant.business.transport.avgsalary = event.target.value;
                                    setLoan(loanModel);
                                  }} defaultValue={getValue(applicant.business.transport, 'avgsalary')} />
                                </Col>

                                <Col md={4}>
                                  <Form.Label className="custom-form-label" >Total Monthly Allowance</Form.Label>
                                  <FormControl type="number" name="transport_avgallowance" onChange={(event) => {
                                    applicant.business.transport.avgallowance = event.target.value;
                                    setLoan(loanModel);
                                  }} defaultValue={getValue(applicant.business.transport, 'avgallowance')} />
                                </Col>

                                <Col md={4}>
                                  <Form.Label className="custom-form-label" >Other miscellaneous expenses</Form.Label>
                                  <FormControl type="number" name="transport_othermscexp" onChange={(event) => {
                                    applicant.business.transport.othermscexp = event.target.value;
                                    setLoan(loanModel);
                                  }} defaultValue={getValue(applicant.business.transport, 'othermscexp')} required />
                                </Col>

                                <Col md={4}>
                                  <Form.Label className="custom-form-label" >Monthly Fuel Cost</Form.Label>
                                  <FormControl type="number" name="transport_costperlitre" onChange={(event) => {
                                    applicant.business.transport.costperlitre = event.target.value;
                                    setLoan(loanModel);
                                  }} defaultValue={getValue(applicant.business.transport, 'costperlitre')} required />
                                </Col>

                                <Col md={4}>
                                  <Form.Label className="custom-form-label" >Total New tyres Required/Month</Form.Label>
                                  <FormControl type="number" name="transport_tyrecount" onChange={(event) => {
                                    applicant.business.transport.tyrecount = event.target.value;
                                    setLoan(loanModel);
                                  }} defaultValue={getValue(applicant.business.transport, 'tyrecount')} required />
                                </Col>

                                <Col md={4}>
                                  <Form.Label className="custom-form-label" >New Tyre replacement cost</Form.Label>
                                  <FormControl type="number" name="transport_tyrereplacementcost" onChange={(event) => {
                                    applicant.business.transport.tyrereplacementcost = event.target.value;
                                    setLoan(loanModel);
                                  }} defaultValue={getValue(applicant.business.transport, 'tyrereplacementcost')} required />
                                </Col>


                                <Col md={4}>
                                <Form.Group controlId="frequencygaraevissit">
                                  <Form.Label className="custom-form-label" >Frequency of Garage visits</Form.Label>
                                  <Form.Select className="form-control" onChange={(e) => {
                                    applicant.business.transport.frequencygaraevissit = e.target.value;
                                    setLoan(loanModel);
                                    }} name="transport_frequencygaraevissit" defaultValue={applicant.business.transport.frequencygaraevissit}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('freqofgaragevisits').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>

                                <Col md={4}>
                                  <Form.Label className="custom-form-label" >Average Lifespan of New Tyres (In Months)</Form.Label>
                                  <FormControl type="number" name="transport_lifeofnewtyre" onChange={(event) => {
                                    applicant.business.transport.lifeofnewtyre = event.target.value;
                                    setLoan(loanModel);
                                  }} defaultValue={getValue(applicant.business.transport, 'lifeofnewtyre')} required pattern="^[a-zA-Z0-9 ]*$" />
                                </Col>

                              </Row>
                            </div>

                            <div className="mt-3 col-sm-12 col-md-12">
                            <div ><h5><strong>Maintenance Cost & Licences</strong></h5></div>
                              <style>
                                {`
          .label-th {
            width: 100%;
            font-weight: bold;
            color: black;
          }
          #transport {
            border-collapse: unset;
            border-spacing: 8px;
          }
        `}
                              </style>
                              <table className="col-md-12" id="transport" style={{ width: '50%' }}>
                                
                                <thead>
                                  <tr>
                                    <th className="col-md-2">Maintenance Cost</th>
                                    <th className="col-md-2">Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <td className="col-md-2">Regular Maintenance</td>
                                    <td className="col-md-2">
                                      <input
                                        type="number"
                                        name="amount_regular"
                                        placeholder="0"
                                        min="0"
                                        value={applicant.business.transport.maintainence1}
                                        onChange={(e) => {applicant.business.transport.maintainence1 = e.target.value;setLoan(loanModel)}}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                    
                                  </tr>
                                  <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <td className="col-md-2">Engine repairs</td>
                                    <td className="col-md-2">
                                      <input
                                        type="number"
                                        name="amount_repairs"
                                        min="0"
                                        placeholder="0"
                                        value={applicant.business.transport.maintainence2}
                                        onChange={(e) => applicant.business.transport.maintainence2 = e.target.value}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                  <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <td className="col-md-2">Transmission Repairs</td>
                                    <td className="col-md-2">
                                      <input
                                        type="number"
                                        name="amount_transmission"
                                        min="0"
                                        placeholder="0"
                                        value={applicant.business.transport.maintainence3}
                                        onChange={(e) => applicant.business.transport.maintainence3 = e.target.value}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                  <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <td className="col-md-2">Suspension and Steering repairs</td>
                                    <td className="col-md-2">
                                      <input
                                        type="number"
                                        name="amount_suspension"
                                        placeholder="0"
                                        min="0"
                                        value={applicant.business.transport.maintainence4}
                                        onChange={(e) => applicant.business.transport.maintainence4 = e.target.value}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                  <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <td className="col-md-2">Brake repairs</td>
                                    <td className="col-md-2">
                                      <input
                                        type="number"
                                        name="amount_brake"
                                        placeholder="0"
                                        min="0"
                                        value={applicant.business.transport.maintainence5}
                                        onChange={(e) => applicant.business.transport.maintainence5 = e.target.value}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="col-md-2"><strong>Total Maintenance Cost</strong></td>
                                    <td className="col-md-2">
                                      <a href="#" className="label label-warning">
                                      {applicant.business.transport.maintainence1 + applicant.business.transport.maintainence2 + applicant.business.transport.maintainence3 + applicant.business.transport.maintainence4 + applicant.business.transport.maintainence5}
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>


                            <div className="mt-3 col-sm-12 col-md-12">
                              <style>
                                {`
          .label-th {
            width: 100%;
            font-weight: bold;
            color: black;
          }
          #transportinsurance {
            border-collapse: unset;
            border-spacing: 8px;
          }
        `}
                              </style>
                              <div ><h5><strong>Insurance</strong></h5></div>
                              <table className="col-md-12" id="transportinsurance" style={{ width: '50%' }}>
                                
                                <thead>
                                  <tr>
                                    <th className="col-md-2">Insurance</th>
                                    <th className="col-md-2" style={{ textAlign: 'center' }}>Verified</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <td className="col-md-2">Third Party Liability Policy</td>
                                    <td className="col-md-2">
                                      <input
                                        type="checkbox"
                                        name="amount_regular"
                                        checked={getValue(applicant.business.transport, 'insurance1')}
                                        onChange={(e) => {applicant.business.transport.insurance1 = e.target.value;  setLoan(loanModel)}}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                  <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <td className="col-md-2">Product Liability Insurance</td>
                                    <td className="col-md-2">
                                      <input
                                        type="checkbox"
                                        name="amount_repairs"
                                        checked={getValue(applicant.business.transport, 'insurance2')}
                                        onChange={(e) => {applicant.business.transport.insurance2 = e.target.value;  setLoan(loanModel)}}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                  <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <td className="col-md-2">Employers' Liability Insurance</td>
                                    <td className="col-md-2">
                                      <input
                                        type="checkbox"
                                        name="amount_transmission"
                                        checked={getValue(applicant.business.transport, 'insurance3')}
                                        onChange={(e) => {applicant.business.transport.insurance3 = e.target.value;  setLoan(loanModel)}}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                  <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <td className="col-md-2">Comprehensive insurance</td>
                                    <td className="col-md-2">
                                      <input
                                        type="checkbox"
                                        name="amount_suspension"
                                        checked={getValue(applicant.business.transport, 'insurance4')}
                                        onChange={(e) => {applicant.business.transport.insurance4 = e.target.value;  setLoan(loanModel)}}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                  <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <td className="col-md-2">Collision insurance</td>
                                    <td className="col-md-2">
                                      <input
                                        type="checkbox"
                                        name="amount_brake"
                                        checked={getValue(applicant.business.transport, 'insurance5')}
                                        onChange={(e) => {applicant.business.transport.insurance5 = e.target.value;  setLoan(loanModel)}}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="col-md-2">Cargo insurance</td>
                                    <td className="col-md-2">
                                      <input
                                        type="checkbox"
                                        name="amount_brake"
                                        checked={getValue(applicant.business.transport, 'insurance6')}
                                        onChange={(e) => {applicant.business.transport.insurance6 = e.target.value;  setLoan(loanModel)}}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div className="mt-3 col-sm-12 col-md-12">
                              <style>
                                {`
          .label-th {
            width: 100%;
            font-weight: bold;
            color: black;
          }
          #transportlicense {
            border-collapse: unset;
            border-spacing: 8px;
          }
        `}
                              </style>
                              <h5><strong>Licenses</strong></h5>
                              <table className="col-md-12" id="transportlicense" style={{ width: '50%' }}>
                               
                                <thead>
                                  <tr>
                                    <th className="col-md-2">License</th>
                                    <th className="col-md-2" style={{ textAlign: 'center' }}>Verified</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <td className="col-md-2">Commercial Vehicle Registration</td>
                                    <td className="col-md-2">
                                      <input
                                        type="checkbox"
                                        name="amount_regular"
                                        checked={applicant.business.transport.license1}
                                        onChange={(e) => {applicant.business.transport.license1 = e.target.value;  setLoan(loanModel)}}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                  <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <td className="col-md-2">Fitness Certificate</td>
                                    <td className="col-md-2">
                                      <input
                                        type="checkbox"
                                        name="amount_repairs"
                                        checked={applicant.business.transport.license2}
                                        onChange={(e) => {applicant.business.transport.license2 = e.target.value;  setLoan(loanModel)}}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                  <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <td className="col-md-2">PUC</td>
                                    <td className="col-md-2">
                                      <input
                                        type="checkbox"
                                        name="amount_transmission"
                                        checked={applicant.business.transport.license3}
                                        onChange={(e) => {applicant.business.transport.license3 = e.target.value;  setLoan(loanModel)}}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                  <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <td className="col-md-2">Motor Vehicle Taxation</td>
                                    <td className="col-md-2">
                                      <input
                                        type="checkbox"
                                        name="amount_suspension"
                                        checked={applicant.business.transport.license4}
                                        onChange={(e) => {applicant.business.transport.license4 = e.target.value;  setLoan(loanModel)}}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                  <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <td className="col-md-2">National Permit</td>
                                    <td className="col-md-2">
                                      <input
                                        type="checkbox"
                                        name="amount_brake"
                                        checked={applicant.business.transport.license5}
                                        onChange={(e) => {applicant.business.transport.license5 = e.target.value;  setLoan(loanModel)}}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                  <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <td className="col-md-2">State Goods Carriage Permit</td>
                                    <td className="col-md-2">
                                      <input
                                        type="checkbox"
                                        name="amount_brake"
                                        checked={applicant.business.transport.license6}
                                        onChange={(e) => {applicant.business.transport.license6 = e.target.value;  setLoan(loanModel)}}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                  <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <td className="col-md-2">Permit for Oversized Vehicles</td>
                                    <td className="col-md-2">
                                      <input
                                        type="checkbox"
                                        name="amount_brake"
                                        checked={applicant.business.transport.license7}
                                        onChange={(e) => {applicant.business.transport.license7 = e.target.value;  setLoan(loanModel)}}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="col-md-2">All India Tourist Permit (AITP)</td>
                                    <td className="col-md-2">
                                      <input
                                        type="checkbox"
                                        name="amount_brake"
                                        checked={applicant.business.transport.license8}
                                        onChange={(e) => {applicant.business.transport.license8 = e.target.value;  setLoan(loanModel)}}
                                        style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div>
                            <Col md={3}>
                                <Form.Group controlId="truckingagency">
                                  <Form.Label className="custom-form-label" >Trucking Agency/portals?</Form.Label>
                                  <Form.Select className="form-control" onChange={(e) => {
                                    applicant.business.transport.truckingagency = e.target.value;
                                    setLoan(loanModel);
                                    }} name="transport_truckingagency" defaultValue={applicant.business.transport.truckingagency}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('transporttruckingagency').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                            </div>


                          </Tab>}
                            {applicant!==null && applicant.business!==null && applicant.business.type=='TRD' && applicant.business.profile==='Livestock - Poultry / Meat Products' && loanModel.livestockflag && 
                            <Tab eventKey="btabs26  " title="Livestock">
                              <legend style={{paddingTop:'20px'}}>Business</legend>
                              <hr/>
                              <Row>Poultry Type*</Row>
                              <Row>
                                  <Col md={2}>
                                    <Form.Check inline label="Broiler Farmers" name="poultry_brf" type='checkbox'  onChange={(e) => {
                                      applicant.business.livestock.poultry.brf = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.livestock.poultry, 'brf')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Layer Farmers" name="poultry_laf" type='checkbox'  onChange={(e) => {
                                      applicant.business.livestock.poultry.laf = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.livestock.poultry, 'laf')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Backyard Farmers" name="poultry_baf" type='checkbox'  onChange={(e) => {
                                      applicant.business.livestock.poultry.baf = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.livestock.poultry, 'baf')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Hatchery Owners" name="poultry_hao" type='checkbox'  onChange={(e) => {
                                      applicant.business.livestock.poultry.hao = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.livestock.poultry, 'hao')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Integrated Poultry Farmers" name="poultry_ipf" type='checkbox'  onChange={(e) => {
                                      applicant.business.livestock.poultry.ipf = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.livestock.poultry, 'ipf')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Free Range Poultry Farmers" name="poultry_frp" type='checkbox'  onChange={(e) => {
                                      applicant.business.livestock.poultry.frp = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.livestock.poultry, 'frp')}/>
                                  </Col>
                              </Row>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Organic Poultry Farmers" name="poultry_opf" type='checkbox'  onChange={(e) => {
                                    applicant.business.livestock.poultry.opf = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.poultry, 'opf')}/>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Number of Workers *</Form.Label>
                                  <FormControl type="number" placeholder = "0" name="livestock_workers" onChange={(event) => {
                                    applicant.business.livestock.workers = event.target.value;
                                  }} defaultValue={getValue(applicant.business.livestock, 'workers')} />
                                </Col>
                              </Row>
                              <Row>Infrastructure Seen*</Row>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Feeders" name="infra_fed" type='checkbox'  onChange={(e) => {
                                    applicant.business.livestock.infra.fed = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.infra, 'fed')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Incubators" name="infra_inc" type='checkbox'  onChange={(e) => {
                                    applicant.business.livestock.infra.inc = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.infra, 'inc')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Brooders" name="infra_bro" type='checkbox'  onChange={(e) => {
                                    applicant.business.livestock.infra.bro = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.infra, 'bro')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Poultry Shed" name="infra_pos" type='checkbox'  onChange={(e) => {
                                    applicant.business.livestock.infra.pos = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.infra, 'pos')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Bedding Material: straw/wood" name="infra_bms" type='checkbox'  onChange={(e) => {
                                    applicant.business.livestock.infra.bms = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.infra, 'bms')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Feeding and watering Systems" name="infra_fws" type='checkbox'  onChange={(e) => {
                                    applicant.business.livestock.infra.fws = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.infra, 'fws')}/>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Waste Management Facilities" name="infra_wmf" type='checkbox'  onChange={(e) => {
                                    applicant.business.livestock.infra.wmf = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.infra, 'wmf')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Egg Collection System" name="infra_ecs" type='checkbox'  onChange={(e) => {
                                    applicant.business.livestock.infra.ecs = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.infra, 'ecs')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Perches" name="infra_pel" type='checkbox'  onChange={(e) => {
                                    applicant.business.livestock.infra.pel = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.infra, 'pel')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Nesting Boxes" name="infra_neb" type='checkbox'  onChange={(e) => {
                                    applicant.business.livestock.infra.neb = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.infra, 'neb')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Basic Medical Supplies" name="infra_bme" type='checkbox'  onChange={(e) => {
                                    applicant.business.livestock.infra.bme = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.infra, 'bme')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Fencing and Enclosures" name="infra_fee" type='checkbox'  onChange={(e) => {
                                    applicant.business.livestock.infra.fee = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.infra, 'fee')}/>
                                </Col>
                              </Row>
                              <Row>
                                <Form.Group controlId="ventilation">
                                  <Form.Label className="custom-form-label" >Ventilation and Lighting</Form.Label>
                                  <Form.Select className="form-control" onChange={(e) => {
                                    applicant.business.livestock.ventilation = e.target.value;
                                    setLoan(loanModel);
                                  }} name="livestock_ventilation" defaultValue={applicant.business.livestock.ventilation}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('poultryVentilation').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Row>
                              <Row style={{paddingTop:'20px'}}>Sourcing of Chicks</Row>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Hatchers" name="source_hat" type='checkbox'  onChange={(e) => {
                                    applicant.business.livestock.source.hat = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.source, 'hat')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Suppliers" name="source_sup" type='checkbox'  onChange={(e) => {
                                    applicant.business.livestock.source.sup = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.source, 'sup')}/>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={4}>
                                  <Form.Group controlId="ventilation">
                                    <Form.Label className="custom-form-label" >Type of Water Source *</Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      applicant.business.livestock.watersrc = e.target.value;
                                      setLoan(loanModel);
                                    }} name="livestock_watersrc" defaultValue={applicant.business.livestock.watersrc}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('poultryWater').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={4}>
                                  <Form.Label className="custom-form-label" >Distance from Market (In km) *</Form.Label>
                                  <FormControl type="number" placeholder = "0" name="livestock_distmarket" onChange={(event) => {
                                    applicant.business.livestock.distmarket = event.target.value;
                                  }} defaultValue={getValue(applicant.business.livestock, 'distmarket')} />
                                </Col>
                                <Col md={4}>
                                  <Form.Label className="custom-form-label" >Distance from Market (In km) *</Form.Label>
                                  <FormControl type="number" placeholder = "0" name="livestock_distservice" onChange={(event) => {
                                    applicant.business.livestock.distservice = event.target.value;
                                  }} defaultValue={getValue(applicant.business.livestock, 'distservice')} />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <Form.Label className="custom-form-label" >Distance from Nearest Transportation Services (In km)</Form.Label>
                                  <FormControl type="number" placeholder = "0" name="livestock_disttrans" onChange={(event) => {
                                    applicant.business.livestock.disttrans = event.target.value;
                                  }} defaultValue={getValue(applicant.business.livestock, 'disttrans')} />
                                </Col>
                                <Col md={6}>
                                  <Form.Group controlId="ventilation">
                                    <Form.Label className="custom-form-label" >Own Transportation available?</Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      applicant.business.livestock.transport = e.target.value;
                                      setLoan(loanModel);
                                    }} name="livestock_transport" defaultValue={applicant.business.livestock.transport}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('poultryTransport').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <Form.Group controlId="livestock_valproduct">
                                    <Form.Label className="custom-form-label" >Value Added Products</Form.Label>
                                    <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                      applicant.business.livestock.valproduct = e.target.value;
                                      setLoan(loanModel);
                                    }} name="livestock_valproduct" defaultValue={applicant.business.livestock.valproduct}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('poultryValueadded').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group controlId="custland">
                                    <Form.Label className="custom-form-label" >Customer Landscape *</Form.Label>
                                    <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                      applicant.business.livestock.custland = e.target.value;
                                      setLoan(loanModel);
                                    }} name="livestock_custland" defaultValue={applicant.business.livestock.custland}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('poultryCust').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <div hidden={!applicant.business.livestock.custland || applicant.business.livestock.custlan.indexOf('EPL') == -1}>
                                <Row>
                                  <Col md={6}>
                                    <Form.Label className="custom-form-label" >Please Specify</Form.Label>
                                    <FormControl type="number" placeholder = "0" name="livestock_eplatspec" onChange={(event) => {
                                      applicant.business.livestock.eplatspec = event.target.value;
                                    }} defaultValue={getValue(applicant.business.livestock, 'eplatspec')} />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6}>
                                    <Form.Check inline label="Is there a monthly subscription fee for Selected E-platforms." name="livestock_esubs" type='checkbox'  onChange={(e) => {
                                      applicant.business.livestock.esubs = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.livestock.esubs, 'sup')}/>
                                  </Col>
                                  <Col md={6} hidden={!applicant.business.livestock.esubs}>
                                    <Form.Label className="custom-form-label" >Please Specify</Form.Label>
                                    <FormControl type="number" placeholder = "0" name="livestock_eplatspec" onChange={(event) => {
                                      applicant.business.livestock.eplatspec = event.target.value;
                                    }} defaultValue={getValue(applicant.business.livestock, 'eplatspec')} />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6}>
                                    <Form.Check inline label="Is there a Per client Referral Fee Amount" name="livestock_ecli" type='checkbox'  onChange={(e) => {
                                      applicant.business.livestock.ecli = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.livestock, 'ecli')}/>
                                  </Col>
                                  <Col md={6} hidden={!applicant.business.livestock.esubs}>
                                    <Form.Label className="custom-form-label" >Please Specify</Form.Label>
                                    <FormControl type="number" placeholder = "0" name="livestock_eclispec" onChange={(event) => {
                                      applicant.business.livestock.eclispec = event.target.value;
                                    }} defaultValue={getValue(applicant.business.livestock, 'eclispec')} />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6}>
                                    <Form.Check inline label="Average Monthly billing Guaranteed by selected E-platforms." name="livestock_eavg" type='checkbox'  onChange={(e) => {
                                      applicant.business.livestock.eavg = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.livestock, 'eavg')}/>
                                  </Col>
                                  <Col md={6} hidden={!applicant.business.livestock.esubs}>
                                    <Form.Label className="custom-form-label" >Please Specify</Form.Label>
                                    <FormControl type="number" placeholder = "0" name="livestock_eavgspec" onChange={(event) => {
                                      applicant.business.livestock.eavgspec = event.target.value;
                                    }} defaultValue={getValue(applicant.business.livestock, 'eavgspec')} />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6}>
                                    <Form.Check inline label="Number of Clients guaranteed by Selected E-platforms." name="livestock_eslec" type='checkbox'  onChange={(e) => {
                                      applicant.business.livestock.eslec = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.livestock, 'eslec')}/>
                                  </Col>
                                  <Col md={6} hidden={!applicant.business.livestock.esubs}>
                                    <Form.Label className="custom-form-label" >Please Specify</Form.Label>
                                    <FormControl type="number" placeholder = "0" name="livestock_eslecspec" onChange={(event) => {
                                      applicant.business.livestock.eslecspec = event.target.value;
                                    }} defaultValue={getValue(applicant.business.livestock, 'eslecspec')} />
                                  </Col>
                                </Row>
                              </div>
                              <label>Do you practice other forms of Animal Rearing?</label>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Dairy Farming" name="additional_df" type='checkbox'  onChange={(e) => {
                                    applicant.business.additional.df = e.target.checked;
                                    if(!applicant.business.livestock.additional.df){
                                      applicant.business.livestock.dairyfarming=null;
                                    }
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.additional, 'df')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Goat Rearing" name="additional_gr" type='checkbox'  onChange={(e) => {
                                    applicant.business.additional.gr = e.target.checked;
                                    if(!applicant.business.livestock.additional.gr){
                                      applicant.business.livestock.goatrearing=null;
                                    }
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.additional, 'gr')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Pig Farming" name="additional_pf" type='checkbox'  onChange={(e) => {
                                    applicant.business.additional.pf = e.target.checked;
                                    if(!applicant.business.livestock.additional.pf){
                                      applicant.business.livestock.pigfarming=null;
                                    }
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.additional, 'pf')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Sheep Farming" name="additional_sf" type='checkbox'  onChange={(e) => {
                                    applicant.business.additional.sf = e.target.checked;
                                    if(!applicant.business.livestock.additional.sf){
                                      applicant.business.livestock.sheepfarming=null;
                                    }
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.additional, 'sf')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Horticulture/Integrated Farming Systems" name="additional_hfs" type='checkbox'  onChange={(e) => {
                                    applicant.business.additional.hfs = e.target.checked;
                                    if(!applicant.business.livestock.additional.hfs){
                                      applicant.business.livestock.horticulturefarming=null;
                                    }
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.additional, 'hfs')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Fish Farming (Aquaculture)" name="additional_ffa" type='checkbox'  onChange={(e) => {
                                    applicant.business.additional.ffa = e.target.checked;
                                    if(!applicant.business.livestock.additional.ffa){
                                      applicant.business.livestock.fishfarming=null;
                                    }
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.livestock.additional, 'ffa')}/>
                                </Col>
                              </Row>
                              <Row> 
                                <Col md={3} hidden={!applicant.business.livestock.additional.df}>
                                  <Form.Group controlId="livestock_dairyfarming">
                                    <Form.Label className="custom-form-label" >Dairy Farming *</Form.Label>
                                    <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                      applicant.business.livestock.dairyfarming = e.target.value;
                                      setLoan(loanModel);
                                    }} name="livestock_dairyfarming" defaultValue={applicant.business.livestock.dairyfarming}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('poultrydairyfarm').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="livestock_goatrearing" hidden={!applicant.business.livestock.additional.df}>
                                    <Form.Label className="custom-form-label" >Goat Rearing *</Form.Label>
                                    <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                      applicant.business.livestock.goatrearing = e.target.value;
                                      setLoan(loanModel);
                                    }} name="livestock_goatrearing" defaultValue={applicant.business.livestock.goatrearing}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('poultrygoatfarm').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="livestock_fishfarming" hidden={!applicant.business.livestock.additional.ffa}>
                                    <Form.Label className="custom-form-label" >Fish Farming (Aquaculture) *</Form.Label>
                                    <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                      applicant.business.livestock.fishfarming = e.target.value;
                                      setLoan(loanModel);
                                    }} name="livestock_fishfarming" defaultValue={applicant.business.livestock.fishfarming}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('poultryseafarm').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="livestock_pigfarming" hidden={!applicant.business.livestock.additional.pf}>
                                    <Form.Label className="custom-form-label" >Pig Farming *</Form.Label>
                                    <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                      applicant.business.livestock.pigfarming = e.target.value;
                                      setLoan(loanModel);
                                    }} name="livestock_pigfarming" defaultValue={applicant.business.livestock.pigfarming}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('poultrypigfarm').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="livestock_sheepfarming" hidden={!applicant.business.livestock.additional.sf}>
                                    <Form.Label className="custom-form-label" >Pig Farming *</Form.Label>
                                    <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                      applicant.business.livestock.sheepfarming = e.target.value;
                                      setLoan(loanModel);
                                    }} name="livestock_sheepfarming" defaultValue={applicant.business.livestock.sheepfarming}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('poultrysheepfarm').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="livestock_horticulturefarming" hidden={!applicant.business.livestock.additional.hfs}>
                                    <Form.Label className="custom-form-label" >Pig Farming *</Form.Label>
                                    <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                      applicant.business.livestock.horticulturefarming = e.target.value;
                                      setLoan(loanModel);
                                    }} name="livestock_horticulturefarming" defaultValue={applicant.business.livestock.horticulturefarming}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('poultryhorticulurefarm').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="livestock_venture" hidden={!applicant.business.livestock.additional.hfs}>
                                    <Form.Label className="custom-form-label" >Have you enrolled for Poultry venture capital scheme</Form.Label>
                                    <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                      applicant.business.livestock.venture = e.target.value;
                                      setLoan(loanModel);
                                    }} name="livestock_venture" defaultValue={applicant.business.livestock.venture}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('poultryTransport').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="livestock_tourism" hidden={!applicant.business.livestock.additional.hfs}>
                                    <Form.Label className="custom-form-label" >Is Farm Tours and Agro-Tourism allowed?</Form.Label>
                                    <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                      applicant.business.livestock.tourism = e.target.value;
                                      setLoan(loanModel);
                                    }} name="livestock_tourism" defaultValue={applicant.business.livestock.tourism}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('poultryTransport').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3} hidden={!applicant.business.livestock.tourism || applicant.business.livestock.tourism != 'Yes'}>
                                  <Form.Label className="custom-form-label" >Number of Tours  per year</Form.Label>
                                  <FormControl type="number" placeholder = "0" name="livestock_tours" onChange={(event) => {
                                    applicant.business.livestock.tours = event.target.value;
                                  }} defaultValue={getValue(applicant.business.livestock, 'tours')} />
                                </Col>
                                <Col md={3} hidden={!applicant.business.livestock.tourism ||  applicant.business.livestock.tourism != 'Yes'}>
                                  <Form.Label className="custom-form-label" >Revenue per tour</Form.Label>
                                  <FormControl type="number" placeholder = "0" name="livestock_revtours" onChange={(event) => {
                                    applicant.business.livestock.revtours = event.target.value;
                                  }} defaultValue={getValue(applicant.business.livestock, 'revtours')} />
                                </Col>
                              </Row>
                              <Row style={{paddingTop:'20px'}}>
                                <label className="padding-top"><strong>Income&nbsp;&nbsp;&nbsp;<a  onClick={addLivestockMachine}><i className="fa fa-plus fa-lg"></i></a></strong></label>
                              </Row>
                              <table className="table table-striped table-emails table-hover tablemobileview col-sm-12 " id="folder-view">
                                <thead>
                                  <tr>
                                    <th id="folder-actions" className="col-sm-2" ><strong>SALES SITE</strong></th>
                                    <th className="col-sm-2"><strong>PRODUCT TYPE</strong></th>
                                    <th className="col-sm-1"><strong>MEASURING UNIT</strong></th>
                                    <th className="col-sm-1"><strong>AVG RATE/UNIT</strong></th>
                                    <th className="col-sm-1"><strong>PERIOD CONSIDERED</strong></th>
                                    <th className="col-sm-1"><strong>AVG UNIT SOLD</strong></th>
                                    <th className="col-sm-1"><strong>TOTAL SALES</strong></th>
                                  </tr>
                                </thead>
                                {!isDefined(applicant.business.livestock.machines) || applicant.business.livestock.machines.length == 0 && <tr>
                                    <td colSpan={6}><span className="col-lg-4 col-sm-6 col-xs-8 col-lg-offset-5 col-sm-offset-5 col-xs-offset-4">No Income</span></td>
                                  </tr>}
                                  {isDefined(applicant.business.livestock.machines) && applicant.business.livestock.machines.length>0 && applicant.business.livestock.machines.map((machine:any,i:any)=>(
                                    <tr>
                                      <td className="col-sm-2"><a onClick={()=>showLivestockMachine(i)}>{getTypeValue('POSITE',machine.site)}</a></td>
                                      <td className="col-sm-2">{getTypeValue('POTYPE',machine.type)}</td>
                                      <td className="col-sm-2">{getTypeValue('POUNIT',machine.unit)}</td>
                                      <td className="col-sm-1">{machine.rate}</td>
                                      <td className="col-sm-1">{getTypeValue('POPERIOD',machine.period)}</td>
                                      <td className="col-sm-1">{machine.cost}</td>
                                      <td className="col-sm-1">{calculatelivestockincomeTotal(machine.rate,machine.cost)}</td>
                                      <td className="col-sm-1"><a data-widgster="close" onClick={()=>removeLivestockMachine(i)}><i className="fa fa-times fa-lg"></i></a></td>
                                    </tr>
                                  ))}
                              </table>
                              {loanModel.showLivestockMachineForm && <div>
                                <Row>
                                  <Col md={3}>
                                    <Form.Group controlId="LivestockMachine_site">
                                      <Form.Label className="custom-form-label" >Sales Site</Form.Label>
                                      <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                        loanModel.LivestockMachine.site = e.target.value;
                                        setLoan(loanModel);
                                      }} name="LivestockMachine_site" defaultValue={loanModel.LivestockMachine.site}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('poultrysalesite').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col md={3}>
                                    <Form.Group controlId="LivestockMachine_type">
                                      <Form.Label className="custom-form-label" >Product Type</Form.Label>
                                      <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                        loanModel.LivestockMachine.type = e.target.value;
                                        setLoan(loanModel);
                                      }} name="LivestockMachine_type" defaultValue={loanModel.LivestockMachine.type}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('poultryIncomeproduct').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col md={3}>
                                    <Form.Group controlId="LivestockMachine_unit">
                                      <Form.Label className="custom-form-label" >Product Type</Form.Label>
                                      <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                        loanModel.LivestockMachine.unit = e.target.value;
                                        setLoan(loanModel);
                                      }} name="LivestockMachine_unit" defaultValue={loanModel.LivestockMachine.unit}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('poultrymeasuring').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col md={3}>
                                    <Form.Label className="custom-form-label" >Revenue per tour</Form.Label>
                                    <FormControl type="number" placeholder = "0" name="livestock_rate" onChange={(event) => {
                                      loanModel.LivestockMachine.rate = event.target.value;
                                    }} defaultValue={getValue(loanModel.LivestockMachine, 'rate')} />
                                  </Col>
                                  <Col md={3}>
                                    <Form.Group controlId="LivestockMachine_period">
                                      <Form.Label className="custom-form-label" >Product Type</Form.Label>
                                      <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                        loanModel.LivestockMachine.period = e.target.value;
                                        setLoan(loanModel);
                                      }} name="LivestockMachine_period" defaultValue={loanModel.LivestockMachine.period}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('poultryperiod').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col md={3}>
                                    <Form.Label className="custom-form-label" >Average Number of Units sold per day/week/fortnight/month</Form.Label>
                                    <FormControl type="number" placeholder = "0" name="livestock_cost" onChange={(event) => {
                                      loanModel.LivestockMachine.cost = event.target.value;
                                    }} defaultValue={getValue(loanModel.LivestockMachine, 'cost')} />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={9}></Col>
                                  <Col md={3}>
                                  <Button variant="danger" onClick={saveLivestockMachine}> Save</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                  <Button variant="dark" onClick={cancelLivestockMachine}> Cancel</Button>
                                  </Col>
                                </Row>
                              </div>}
                              <Row style={{paddingTop:'20px'}}>
                                <label className="padding-top"><strong>Animal&nbsp;&nbsp;&nbsp;<a  onClick={addLivestockAnimal}><i className="fa fa-plus fa-lg"></i></a></strong></label>
                              </Row>
                                <table className="table table-striped table-emails table-hover tablemobileview col-sm-12 " id="folder-view">
                                  
                                  <tr>
                                    <th id="folder-actions" className="col-sm-2" ><strong>ANIMAL TYPE</strong></th>
                                    <th className="col-sm-2"><strong>NO OF ANIMALS</strong></th>
                                    <th className="col-sm-2"><strong>AGE CONSIDERED</strong></th>
                                    <th className="col-sm-2"><strong>APPROX AGE</strong></th>
                                  </tr>
                                  
                                  {!isDefined(applicant.business.livestock.animal) || applicant.business.livestock.animal.length == 0 && <tr>
                                    <td colSpan={6}><span className="col-lg-4 col-sm-6 col-xs-8 col-lg-offset-5 col-sm-offset-5 col-xs-offset-4">No Income</span></td>
                                  </tr>}
                                  {isDefined(applicant.business.livestock.animal) && applicant.business.livestock.animal.length >= 0 && applicant.business.livestock.animal.map((animal:any, i:any)=>(
                                  <tr>
                                    <td className="col-sm-2"><a onClick={()=>showLivestockAnimal(i)}>{getTypeValue('POANIMAL',animal.type)}</a> </td>
                                    <td className="col-sm-2">{animal.num}</td>
                                    <td className="col-sm-2">{getTypeValue('POAGE',animal.age)}</td>
                                    <td className="col-sm-2">{animal.approx}</td>
                                    <td className="col-sm-1"><a data-widgster="close" onClick={()=>removeLivestockAnimal(i)}><i className="glyphicon glyphicon-remove"></i></a></td>
                                  </tr>))}
                                </table>
                                {loanModel.showLivestockAnimalForm && <div>
                                  <Row>
                                    <Col md={3}>
                                      <Form.Group controlId="LivestockAnimal_type">
                                        <Form.Label className="custom-form-label" >Animal Type</Form.Label>
                                        <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                          loanModel.LivestockAnimal.type = e.target.value;
                                          setLoan(loanModel);
                                        }} name="LivestockAnimal_type" defaultValue={loanModel.LivestockAnimal.type}>
                                          <option value=""></option>
                                          {
                                            getLookupProperty('poultryAnimalType').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                          }
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                      <Form.Label className="custom-form-label" >Number of Animals</Form.Label>
                                      <FormControl type="number" placeholder = "0" name="LivestockAnimal_num" onChange={(event) => {
                                        loanModel.LivestockAnimal.num = event.target.value;
                                      }} defaultValue={getValue(loanModel.LivestockAnimal, 'num')} />
                                    </Col>
                                    <Col md={3}>
                                      <Form.Group controlId="LivestockAnimal_">
                                        <Form.Label className="custom-form-label" >Product Type</Form.Label>
                                        <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                          loanModel.LivestockAnimal.age = e.target.value;
                                          setLoan(loanModel);
                                        }} name="LivestockAnimal_age" defaultValue={loanModel.LivestockAnimal.age}>
                                          <option value=""></option>
                                          {
                                            getLookupProperty('poultryAge').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                          }
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                      <Form.Label className="custom-form-label" >Approx. Age (Weeks/Months)</Form.Label>
                                      <FormControl type="number" placeholder = "0" name="LivestockAnimal_approx" onChange={(event) => {
                                        loanModel.LivestockMachine.approx = event.target.value;
                                      }} defaultValue={getValue(loanModel.LivestockMachine, 'approx')} />
                                    </Col>
                                  </Row>
                                  <Row>
                                  <Col md={9}></Col>
                                    <Col md={3}>
                                      <Button variant="danger" onClick={saveLivestockAnimal}> Save</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                      <Button variant="dark" onClick={cancelLivestockAnimal}> Cancel</Button>
                                    </Col>
                                  </Row>
                                </div>}
                                <strong style={{paddingTop:'20px'}}>Initial Capital Investment by Proprietor</strong>
                                <hr/>
                                <Row>
                                  <Col md={3}></Col>
                                  <Col md={3}><strong>Amount</strong></Col>
                                  <Col md={3}><strong>How was payment made?</strong></Col>
                                </Row>
                                <hr/>
                                <Row>
                                  <Col md={3}>Infrastructure</Col>
                                  <Col md={3}>
                                    <FormControl type="number" placeholder = "0" name="amount_infrastructure" onChange={(event) => {
                                      applicant.business.livestock.investmentamount1 = event.target.value;
                                      
                                    }} defaultValue={getValue(applicant.business.livestock, 'investmentamount1')} />
                                  </Col>
                                  <Col md={3}>
                                    <FormControl type="text" name="payment_method_infrastructure" onChange={(event) => {
                                      applicant.business.livestock.investmentpayment1 = event.target.value;
                                      
                                    }} defaultValue={getValue(applicant.business.livestock, 'investmentpayment1')} />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={3}>Feed</Col>
                                  <Col md={3}>
                                    <FormControl type="number" placeholder = "0" name="amount_feed" onChange={(event) => {
                                      applicant.business.livestock.investmentamount2 = event.target.value;
                                      
                                    }} defaultValue={getValue(applicant.business.livestock, 'investmentamount2')} />
                                  </Col>
                                  <Col md={3}>
                                    <FormControl type="text"  name="payment_method_feed" onChange={(event) => {
                                      applicant.business.livestock.investmentpayment2 = event.target.value;
                                      
                                    }} defaultValue={getValue(applicant.business.livestock, 'investmentpayment2')} />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={3}>Stock</Col>
                                  <Col md={3}>
                                    <FormControl type="number" placeholder = "0" name="amount_stock" onChange={(event) => {
                                      applicant.business.livestock.investmentamount3 = event.target.value;
                                    }} defaultValue={getValue(applicant.business.livestock, 'investmentamount3')} />
                                  </Col>
                                  <Col md={3}>
                                    <FormControl type="text" name="payment_method_stock" onChange={(event) => {
                                      applicant.business.livestock.investmentpayment3 = event.target.value;
                                    }} defaultValue={getValue(applicant.business.livestock, 'investmentpayment3')} />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={3}><strong>Total</strong></Col>
                                  <Col md={3}>
                                    {parseVal(applicant.business.livestock.investmentamount1) + parseVal(applicant.business.livestock.investmentamount2) + parseVal(applicant.business.livestock.investmentamount3)}
                                  </Col>
                                </Row>
                                <strong style={{paddingTop:'20px'}}>Regular Expenses*</strong>
                                <hr/>
                                <Row>
                                  <Col md={3}></Col>
                                  <Col md={3}><strong>Period to be considered for Expense</strong></Col>
                                  <Col md={3}><strong>Average Expense</strong></Col>
                                </Row>
                                <hr/>
                                <Row>
                                  <Col md={3}>Purchasing Chicks / birds</Col>
                                  <Col md={3}>
                                    <Form.Select className="form-control" onChange={(e) => {
                                        applicant.business.livestock.expenseperiod1 = e.target.value;
                                        setLoan(loanModel);
                                      }} name="livestock_expenseperiod1" defaultValue={applicant.business.livestock.expenseperiod1}>
                                          <option value="daily">Daily</option>
                                          <option value="weekly">Weekly</option>
                                          <option value="monthly">Monthly</option>
                                      </Form.Select>
                                  </Col>
                                  <Col md={3}>
                                    <FormControl type="text" name="livestock_expenseavg1" onChange={(event) => {
                                      applicant.business.livestock.expenseavg1 = event.target.value;
                                    }} defaultValue={getValue(applicant.business.livestock, 'expenseavg1')} />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={3}>Labour</Col>
                                  <Col md={3}>
                                    <Form.Select className="form-control" onChange={(e) => {
                                        applicant.business.livestock.expenseperiod2 = e.target.value;
                                        setLoan(loanModel);
                                      }} name="livestock_expenseperiod2" defaultValue={applicant.business.livestock.expenseperiod2}>
                                          <option value="daily">Daily</option>
                                          <option value="weekly">Weekly</option>
                                          <option value="monthly">Monthly</option>
                                      </Form.Select>
                                  </Col>
                                  <Col md={3}>
                                    <FormControl type="text" name="livestock_expenseavg2" onChange={(event) => {
                                      applicant.business.livestock.expenseavg2 = event.target.value;
                                    }} defaultValue={getValue(applicant.business.livestock, 'expenseavg2')} />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={3}>Transportation</Col>
                                  <Col md={3}>
                                    <Form.Select className="form-control" onChange={(e) => {
                                        applicant.business.livestock.expenseperiod3 = e.target.value;
                                        setLoan(loanModel);
                                      }} name="livestock_expenseperiod3" defaultValue={applicant.business.livestock.expenseperiod3}>
                                          <option value="daily">Daily</option>
                                          <option value="weekly">Weekly</option>
                                          <option value="monthly">Monthly</option>
                                      </Form.Select>
                                  </Col>
                                  <Col md={3}>
                                    <FormControl type="text" name="livestock_expenseavg3" onChange={(event) => {
                                      applicant.business.livestock.expenseavg3 = event.target.value;
                                    }} defaultValue={getValue(applicant.business.livestock, 'expenseavg3')} />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={3}>Medicines, Vaccinations</Col>
                                  <Col md={3}>
                                    <Form.Select className="form-control" onChange={(e) => {
                                        applicant.business.livestock.expenseperiod4 = e.target.value;
                                        setLoan(loanModel);
                                      }} name="livestock_expenseperiod4" defaultValue={applicant.business.livestock.expenseperiod4}>
                                          <option value="daily">Daily</option>
                                          <option value="weekly">Weekly</option>
                                          <option value="monthly">Monthly</option>
                                      </Form.Select>
                                  </Col>
                                  <Col md={3}>
                                    <FormControl type="text" name="livestock_expenseavg4" onChange={(event) => {
                                      applicant.business.livestock.expenseavg4 = event.target.value;
                                    }} defaultValue={getValue(applicant.business.livestock, 'expenseavg4')} />
                                  </Col>
                                </Row>  
                            </Tab>
                            }
                            {applicant!==null && applicant.business!==null && applicant.business.type=='SVC' && applicant.business.profile==='Agent - Insurance' && loanModel.agentflag && 
                            <Tab eventKey="btabs27  " title="Agent - Insurance">
                            <div className='mt-2'>
                              <h3><strong>Business</strong></h3>
                              <hr />
                            </div>

                            <div className='mt-2'>
                              <label htmlFor="">Type *</label>
                            </div>
                            <div className='ml-3' style={{marginLeft:'10px'}}>

                            <Row style={{padding:'25px'}}>
                              <Col md={5}>
                                <Form.Check inline label="Insurance" name="agenttype_insurance" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.agenttype.insurance = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.agenttype, 'insurance')} />
                              </Col>

                              <Col md={5}>
                                <Form.Check inline label="Loans" name="agenttype_loans" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.agenttype.loans = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.agenttype, 'loans')} />
                              </Col>

                              <Col md={5}>
                                <Form.Check inline label="Mutual Funds" name="agenttype_mutualfunds" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.agenttype.mutualfunds = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.agenttype, 'mutualfunds')} />
                              </Col>

                              <Col md={5}>
                                <Form.Check inline label="Deposits" name="agenttype_deposits" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.agenttype.deposits = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.agenttype, 'deposits')} />
                              </Col>

                              <Col md={5}>
                                <Form.Check inline label="Banking Products" name="agenttype_bankingproducts" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.agenttype.bankingproducts = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.agenttype, 'bankingproducts')} />
                              </Col>

                              <Col md={5}>
                                <Form.Check inline label="PPF" name="agenttype_ppf" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.agenttype.ppf = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.agenttype, 'ppf')} />
                              </Col>

                              <Col md={5}>
                                <Form.Check inline label="Post Office" name="agenttype_postoffice" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.agenttype.postoffice = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.agenttype, 'postoffice')} />
                              </Col>

                              <Col md={5}>
                                <Form.Check inline label="Post Office" name="agenttype_finadv" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.agenttype.finadv = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.agenttype, 'finadv')} />
                              </Col>

                              <Col md={5}>
                                <Form.Check inline label="Stock Brokers" name="agenttype_stockbrok" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.agenttype.stockbrok = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.agenttype, 'stockbrok')} />
                              </Col>
                            </Row>
                            </div>

                            <div className='mt-3'><strong>Nature*</strong><br/></div>
                            <Row style={{padding:'15px'}}>
                            <Col md={3}>
                                <Form.Check inline label="Exclusive" name="nature_excl" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.nature.excl = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.nature, 'excl')} />
                              </Col>

                              <Col md={3}>
                                <Form.Check inline label="Non Exclusive" name="nature_nonexcl" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.nature.nonexcl = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.nature, 'nonexcl')} />
                              </Col>
                            </Row>
                            <Col md={3}>
                              <Form.Group controlId="ceb">
                                <Form.Label className="custom-form-label" >Are you from a commerce educational background?</Form.Label>
                                <Form.Select  className="form-control" onChange={(e) => {
                                  applicant.business.agent.ceb = e.target.value;
                                  setLoan(loanModel);
                                }} name="LivestockAnimal_age" defaultValue={applicant.business.agent.ceb}>
                                  <option value=""></option>
                                  {
                                    getLookupProperty('ceb').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                  }
                                </Form.Select>
                              </Form.Group>
                            </Col>

                            
                            <div className='mt-3'><label>How are your leads generated?*<br/></label></div>
                            

                            <Row style={{ padding: '25px' }}>

                              <Col md={5}>
                                <Form.Check inline label="Referrals" name="leads_referrals" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.leads.referrals = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.leads, 'referrals')} />
                              </Col>

                              <Col md={5}>
                                <Form.Check inline label="Cold-calling & Outreach" name="leads_ccor" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.leads.ccor = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.leads, 'ccor')} />
                              </Col>

                              <Col md={5}>
                                <Form.Check inline label="Digital marketing strategies" name="leads_dms" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.leads.dms = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.leads, 'dms')} />
                              </Col>

                              <Col md={5}>
                                <Form.Check inline label="Direct Mail and Flyers" name="leads_dmf" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.leads.dmf = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.leads, 'dmf')} />
                              </Col>

                              <Col md={5}>
                                <Form.Check inline label="Local Advertising: Newspaper, Articles etc" name="leads_lanae" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.leads.lanae = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.leads, 'lanae')} />
                              </Col>

                              <Col md={5}>
                                <Form.Check inline label="Business Card Exchanges" name="leads_bce" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.leads.bce = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.leads, 'bce')} />
                              </Col>

                              <Col md={5}>
                                <Form.Check inline label="Online Forums and Groups" name="leads_ofag" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.leads.ofag = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.leads, 'ofag')} />
                              </Col>
                            </Row>

                            <div hidden={!applicant.business.agent.leads.dms}>
                            <div><label>Digital marketing strategies <br/></label></div>
                            <Row style={{padding:'25px'}}>

                            <Col md={5}>
                                <Form.Check inline label="Email Marketing" name="dmstrategies_em" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.dmstrategies.em = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.dmstrategies, 'em')} />
                              </Col>

                              <Col md={5}>
                                <Form.Check inline label="Social Media Networking" name="dmstrategies_smng" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.dmstrategies.smng = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.dmstrategies, 'smng')} />
                              </Col>

                              <Col md={5}>
                                <Form.Check inline label="Video Marketing" name="dmstrategies_vm" type='checkbox' onChange={(e) => {
                                  applicant.business.agent.dmstrategies.vm = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.agent.dmstrategies, 'vm')} />
                              </Col>
                              
                            </Row>
                            </div>

                            <div className='mt-3'><label>Marketing Engagement Approach <br/></label></div>

                              <Row style={{ padding: '25px' }}>

                                <Col md={5}>
                                  <Form.Check inline label="Workshops" name="part_work" type='checkbox' onChange={(e) => {
                                    applicant.business.agent.part.work = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.agent.part, 'work')} />
                                </Col>

                                <Col md={5}>
                                  <Form.Check inline label="Seminars/Webinars" name="part_semweb" type='checkbox' onChange={(e) => {
                                    applicant.business.agent.part.semweb = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.agent.part, 'semweb')} />
                                </Col>

                                <Col md={5}>
                                  <Form.Check inline label="Events & Function Participation" name="part_efp" type='checkbox' onChange={(e) => {
                                    applicant.business.agent.part.efp = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.agent.part, 'efp')} />
                                </Col>

                                <Col md={5}>
                                  <Form.Check inline label="Partnerships" name="part_partnership" type='checkbox' onChange={(e) => {
                                    applicant.business.agent.part.partnership = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.agent.part, 'partnership')} />
                                </Col>

                                <Col md={3} hidden = {!applicant.business.agent.part.efp}>
                                <Form.Label className="custom-form-label" >Please specify events you have attended</Form.Label>
                                <FormControl type="text" name="eventspecify" onChange={(event) => {
                                      applicant.business.agent.eventspecify = event.target.value;
                                    }} defaultValue={getValue(applicant.business.agent, 'eventspecify')} />
                                </Col>


                              </Row>

                              <div className='mt-3' hidden={ !applicant.business.agent.part.partnership}>
                              <div className='mt-3'><label>Partnerships <br/></label></div>

                              <Row>
                              <Col md={5}>
                                  <Form.Check inline label="Mutual Fund Companies" name="partnership_mfc" type='checkbox' onChange={(e) => {
                                    applicant.business.agent.partnership.mfc = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.agent.partnership, 'mfc')} />
                                </Col>

                                <Col md={5}>
                                  <Form.Check inline label="Insurance Companies" name="partnership_ic" type='checkbox' onChange={(e) => {
                                    applicant.business.agent.partnership.ic = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.agent.partnership, 'ic')} />
                                </Col>

                                <Col md={5}>
                                  <Form.Check inline label="Real Estate" name="partnership_re" type='checkbox' onChange={(e) => {
                                    applicant.business.agent.partnership.re = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.agent.partnership, 're')} />
                                </Col>

                                <Col md={5}>
                                  <Form.Check inline label="Banks & Financial Institutions" name="partnership_bfi" type='checkbox' onChange={(e) => {
                                    applicant.business.agent.partnership.bfi = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.agent.partnership, 'bfi')} />
                                </Col>

                                <Col md={5}>
                                  <Form.Check inline label="Asset Management Firms" name="partnership_amf" type='checkbox' onChange={(e) => {
                                    applicant.business.agent.partnership.amf = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.agent.partnership, 'amf')} />
                                </Col>

                              </Row>
                              </div>

                            <div className='mt-3'>
                              <Col md={3}>
                                <Form.Group controlId="profilemanagementtool">
                                  <Form.Label className="custom-form-label" >Business Profile Management Tools</Form.Label>
                                  <Form.Select className="form-control" onChange={(e) => {
                                    applicant.business.agent.profilemanagementtool = e.target.value;
                                    setLoan(loanModel);
                                  }} name="profilemanagementtool" defaultValue={applicant.business.agent.profilemanagementtool}>
                                    <option value=""></option>
                                    {
                                      getLookupProperty('profilemanagementtool').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </div>
                            <div className='mt-3'>
                              <div><label>Do you have a website?<br /></label></div>
                              <Row>
                                <Col md={5}>
                                  <Form.Check inline label="Yes" name="website_yes" type='checkbox' onChange={(e) => {
                                    applicant.business.agent.website.yes = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.agent.website, 'yes')}
                                    onClick={() => function () {
                                      if (!applicant.business.agent.website.yes) {
                                        applicant.business.agent.url = null
                                      }
                                    }} />
                                </Col>

                                <Col md={5}>
                                  <Form.Check inline label="No" name="website_no" type='checkbox' onChange={(e) => {
                                    applicant.business.agent.website.no = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.agent.website, 'no')} />
                                </Col>

                                <Col md={5} hidden={!applicant.business.agent.website.yes}>
                                  <Form.Label className="custom-form-label" >Website Url</Form.Label>
                                  <FormControl type="text" name="url" onChange={(event) => {
                                    applicant.business.agent.url = event.target.value;
                                  }} defaultValue={getValue(applicant.business.agent, 'url')} required/>
                                </Col>
                              </Row>
                            </div>
                            <div className="padding-top"><legend className="businessAddress "><strong>Client</strong></legend><br/> <hr /></div>

                            <div className='mt-3'>
                              <Row>
                                <Col md={3}>
                                  <Form.Group controlId="ceb">
                                    <Form.Label className="custom-form-label" >Who are your Clients?</Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      applicant.business.agent.clients = e.target.value;
                                      setLoan(loanModel);
                                    }} name="clients" defaultValue={applicant.business.agent.clients}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('clients').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>

                                <Col md={4}>
                                  <Form.Label className="custom-form-label" >Business Sourcing Cities</Form.Label>
                                  <FormControl type="text" name="eventspecify" onChange={(event) => {
                                    applicant.business.agent.eventspecify = event.target.value;
                                  }} defaultValue={getValue(applicant.business.agent, 'eventspecify')} onKeyDown={validateText} />
                                </Col>

                                <Col md={3}>
                                  <Form.Group controlId="sourcemodel">
                                    <Form.Label className="custom-form-label" >Sourcing Model</Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      applicant.business.agent.sourcemodel = e.target.value;
                                      setLoan(loanModel);
                                    }} name="sourcemodel" defaultValue={applicant.business.agent.sourcemodel}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('sourcemodel').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>

                                <Col md={3}>
                                  <Form.Group controlId="setup">
                                    <Form.Label className="custom-form-label" >Set up</Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      applicant.business.agent.setup = e.target.value;
                                      setLoan(loanModel);
                                    }} name="setup" defaultValue={applicant.business.agent.setup}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('setup').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>

                            </div>

                            <div className='mt-3'><label>Competitive Advantages<br/></label></div>
                            
                            <Row>
                            <Col md={3}>
                                  <Form.Check inline label="Free Consultations" name="competitiveadvantage_fc" type='checkbox' onChange={(e) => {
                                    applicant.business.agent.competitiveadvantage.fc = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.agent.competitiveadvantage, 'fc')} />
                            </Col>

                            <Col md={3}>
                                  <Form.Check inline label="Professional Associations" name="competitiveadvantage_pa" type='checkbox' onChange={(e) => {
                                    applicant.business.agent.competitiveadvantage.pa = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.agent.competitiveadvantage, 'pa')} />
                            </Col>

                            <Col md={3}>
                                  <Form.Check inline label="Refundable Consulting fee" name="competitiveadvantage_rcf" type='checkbox' onChange={(e) => {
                                    applicant.business.agent.competitiveadvantage.rcf = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.agent.competitiveadvantage, 'rcf')} />
                            </Col>
                            </Row>

                            <Row className='mt-3'>
                            <Col md={4}>
                                <Form.Label className="custom-form-label" >Languages Spoken </Form.Label>
                                <FormControl type="text" name="languagespoken" onChange={(event) => {
                                      applicant.business.agent.languagespoken = event.target.value;
                                    }} defaultValue={getValue(applicant.business.agent, 'languagespoken')} onKeyDown={validateText} />
                            </Col>

                            <Col md={4}>
                                <Form.Label className="custom-form-label" > Monthly Self Generated Leads</Form.Label>
                                <FormControl type="text" name="monthlyselflead" onChange={(event) => {
                                      applicant.business.agent.monthlyselflead = event.target.value;
                                    }} defaultValue={getValue(applicant.business.agent, 'monthlyselflead')} />
                            </Col>

                            <Col md={4}>
                                <Form.Label className="custom-form-label" >Monthly Conversion No </Form.Label>
                                <FormControl type="text" name="conversionno" onChange={(event) => {
                                      applicant.business.agent.conversionno = event.target.value;
                                    }} defaultValue={getValue(applicant.business.agent, 'conversionno')} />
                            </Col>
                            

                            </Row>

                              

                           
                            
                            </Tab>
                            }
                            {applicant!==null && applicant.business!==null && applicant.business.type=='TRD' && applicant.business.profile==='Clothing - Retailer' && loanModel.clothingflag && 
                            <Tab eventKey="btabs28  " title="Clothing - Retailer">
                              <strong style={{paddingTop:'20px'}}>Business</strong>
                              <hr/>
                              <Row>
                                <strong>Clothing Type</strong>
                              </Row>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Women" name="clothing_type_wm" type='checkbox'  onChange={(e) => {
                                    applicant.business.clothing.type.wm = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.clothing.type, 'wm')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Men" name="clothing_type_mn" type='checkbox'  onChange={(e) => {
                                    applicant.business.clothing.type.mn = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.clothing.type, 'mn')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Children" name="clothing_type_ch" type='checkbox'  onChange={(e) => {
                                    applicant.business.clothing.type.ch = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.clothing.type, 'ch')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Combination" name="clothing_type_co" type='checkbox'  onChange={(e) => {
                                    applicant.business.clothing.type.co = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.clothing.type, 'co')}/>
                                </Col>
                              </Row>
                              <Row>
                                {applicant.business.clothing.type && applicant.business.clothing.type.wm && 
                                <Col md={2}>
                                  <Form.Group controlId="clothing_womenswear">
                                      <Form.Label className="custom-form-label" >Women's wear</Form.Label>
                                      <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                        applicant.business.clothing.womenswear = e.target.value;
                                        setLoan(loanModel);
                                      }} name="clothing_womenswear" defaultValue={applicant.business.clothing.womenswear}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('Womenswear').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                </Col>}
                                {applicant.business.clothing.type && applicant.business.clothing.type.mn &&  <Col md={2}>
                                  <Form.Group controlId="clothing_menswear">
                                      <Form.Label className="custom-form-label" >Men's wear</Form.Label>
                                      <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                        applicant.business.clothing.menswear = e.target.value;
                                        setLoan(loanModel);
                                      }} name="clothing_menswear" defaultValue={applicant.business.clothing.menswear}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('Menswear').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                </Col>}
                                {applicant.business.clothing.type && applicant.business.clothing.type.ch && <Col md={2}>
                                  <Form.Group controlId="clothing_childernwears">
                                      <Form.Label className="custom-form-label" >Children's wear</Form.Label>
                                      <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                        applicant.business.clothing.childernwears = e.target.value;
                                        setLoan(loanModel);
                                      }} name="clothing_childernwears" defaultValue={applicant.business.clothing.childernwears}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('Childernwears').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                </Col>}
                                {applicant.business.clothing.type && applicant.business.clothing.type.co && <Col md={2}>
                                  <Form.Group controlId="clothing_combination">
                                      <Form.Label className="custom-form-label" >Combination's wear</Form.Label>
                                      <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                        applicant.business.clothing.combination = e.target.value;
                                        setLoan(loanModel);
                                      }} name="clothing_combination" defaultValue={applicant.business.clothing.combination}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('Combination').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                </Col>}
                              </Row>
                              <Row><strong>Price Range</strong></Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Check inline label="Economical /low-end products" name="clothing_price_" type='checkbox'  onChange={(e) => {
                                    applicant.business.clothing.price.wm = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.clothing.price, 'wm')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Mid-Market" name="clothing_price_mm" type='checkbox'  onChange={(e) => {
                                    applicant.business.clothing.price.mm = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.clothing.price, 'mm')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Luxury" name="clothing_price_ly" type='checkbox'  onChange={(e) => {
                                    applicant.business.clothing.price.ly = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.clothing.price, 'ly')}/>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Group controlId="clothing_areamarketassessment">
                                      <Form.Label className="custom-form-label" >Area / Market assessment</Form.Label>
                                      <Form.Select className="form-control" onChange={(e) => {
                                        applicant.business.clothing.areamarketassessment = e.target.value;
                                        setLoan(loanModel);
                                      }} name="clothing_areamarketassessment" defaultValue={applicant.business.clothing.areamarketassessment}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('Areamarketassessment').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Trial / Fitting room" name="clothing_istfr" type='checkbox'  onChange={(e) => {
                                    applicant.business.clothing.istfr = e.target.checked;
                                    if(applicant.business.clothing.istfr)
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.clothing, 'istfr')}/>
                                </Col>
                                {applicant.business.clothing.istfr &&
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Number of Room</Form.Label>
                                  <FormControl type="number" placeholder = "0" name="clothing_numberofroom" onChange={(event) => {
                                    applicant.business.clothing.numberofroom = event.target.value;
                                  }} defaultValue={getValue(applicant.business.clothing, 'numberofroom')} />
                                </Col>}
                                <Col md={3}>
                                  <Form.Group controlId="clothing_valueaddedservices">
                                      <Form.Label className="custom-form-label" >Value Added Services</Form.Label>
                                      <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                        applicant.business.clothing.valueaddedservices = e.target.value;
                                        setLoan(loanModel);
                                      }} name="clothing_valueaddedservicest" defaultValue={applicant.business.clothing.valueaddedservices}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('Addedservices').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="clothing_additionalgoods">
                                      <Form.Label className="custom-form-label" >Additional Goods </Form.Label>
                                      <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                        applicant.business.clothing.additionalgoods = e.target.value;
                                        setLoan(loanModel);
                                      }} name="clothing_additionalgoods" defaultValue={applicant.business.clothing.additionalgoods}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('Addedservices').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="clothing_amenitiesprovided">
                                      <Form.Label className="custom-form-label" >Amenities Provided</Form.Label>
                                      <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                        applicant.business.clothing.amenitiesprovided = e.target.value;
                                        setLoan(loanModel);
                                      }} name="clothing_amenitiesprovided" defaultValue={applicant.business.clothing.amenitiesprovided}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('Addedservices').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Logistic Courier Partner</Form.Label>
                                  <FormControl type="number" placeholder = "0" name="clothing_logisticpartner" onChange={(event) => {
                                    applicant.business.clothing.logisticpartner = event.target.value;
                                  }} defaultValue={getValue(applicant.business.clothing, 'logisticpartner')} />
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Signage License</Form.Label>
                                  <FormControl type="number" placeholder = "0" name="clothing_signagelicense" onChange={(event) => {
                                    applicant.business.clothing.signagelicense = event.target.value;
                                  }} defaultValue={getValue(applicant.business.clothing, 'signagelicense')} />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Group controlId="clothing_sourcemerchandise">
                                      <Form.Label className="custom-form-label" >Source of Merchandise</Form.Label>
                                      <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                        applicant.business.clothing.sourcemerchandise = e.target.value;
                                        setLoan(loanModel);
                                      }} name="clothing_sourcemerchandise" defaultValue={applicant.business.clothing.sourcemerchandise}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('Sourcemerchandise').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="clothing_socialmedia">
                                      <Form.Label className="custom-form-label" >Social Media</Form.Label>
                                      <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                        applicant.business.clothing.socialmedia = e.target.value;
                                        setLoan(loanModel);
                                      }} name="clothing_socialmedia" defaultValue={applicant.business.clothing.socialmedia}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('Socialmedia').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="clothing_currentvalueinventory">
                                      <Form.Label className="custom-form-label" >Current Value of Inventory</Form.Label>
                                      <Form.Select className="form-control" onChange={(e) => {
                                        applicant.business.clothing.currentvalueinventory = e.target.value;
                                        setLoan(loanModel);
                                      }} name="clothing_currentvalueinventory" defaultValue={applicant.business.clothing.currentvalueinventory}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('Currentvalueinventory').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="clothing_advertising">
                                      <Form.Label className="custom-form-label" >Advertising</Form.Label>
                                      <Form.Select className="form-control" onChange={(e) => {
                                        applicant.business.clothing.advertising = e.target.value;
                                        setLoan(loanModel);
                                      }} name="clothing_advertising" defaultValue={applicant.business.clothing.advertising}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('Advertising').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Group controlId="clothing_compete">
                                    <Form.Label className="custom-form-label" >How Do You Compete</Form.Label>
                                    <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                      applicant.business.clothing.compete = e.target.value;
                                      setLoan(loanModel);
                                    }} name="clothing_compete" defaultValue={applicant.business.clothing.compete}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('Compete').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="clothing_onlinepresence">
                                    <Form.Label className="custom-form-label" >Online Presence</Form.Label>
                                    <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                      applicant.business.clothing.onlinepresence = e.target.value;
                                      setLoan(loanModel);
                                    }} name="clothing_onlinepresence" defaultValue={applicant.business.clothing.onlinepresence}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('Onlinepresence').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="clothing_location">
                                    <Form.Label className="custom-form-label" >Location *</Form.Label>
                                    <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                      applicant.business.clothing.location = e.target.value;
                                      setLoan(loanModel);
                                    }} name="clothing_location" defaultValue={applicant.business.clothing.location}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('Location').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="clothing_frontage">
                                    <Form.Label className="custom-form-label" >Frontage *</Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      applicant.business.clothing.frontage = e.target.value;
                                      setLoan(loanModel);
                                    }} name="clothing_frontage" defaultValue={applicant.business.clothing.frontage}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('Location').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="clothing_frontage">
                                    <Form.Label className="custom-form-label" >Frontage *</Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      applicant.business.clothing.frontage = e.target.value;
                                      setLoan(loanModel);
                                    }} name="clothing_frontage" defaultValue={applicant.business.clothing.frontage}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('Location').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Average Footfall No.</Form.Label>
                                  <FormControl type="number" placeholder = "0" name="clothing_avgfootfallno" onChange={(event) => {
                                    applicant.business.clothing.avgfootfallno = event.target.value;
                                  }} defaultValue={getValue(applicant.business.clothing, 'avgfootfallno')} />
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Average Customers</Form.Label>
                                  <FormControl type="number" placeholder = "0" name="clothing_avgcustomers" onChange={(event) => {
                                    applicant.business.clothing.avgcustomers = event.target.value;
                                  }} defaultValue={getValue(applicant.business.clothing, 'avgcustomers')} />
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Repeat Customers</Form.Label>
                                  <FormControl type="number" placeholder = "0" name="clothing_repcustomers" onChange={(event) => {
                                    applicant.business.clothing.repcustomers = event.target.value;
                                  }} defaultValue={getValue(applicant.business.clothing, 'repcustomers')} />
                                </Col>
                                <Col md={3}>
                                  <Form.Group controlId="clothing_periodconsidered">
                                    <Form.Label className="custom-form-label" >Period to be considered</Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      applicant.business.clothing.periodconsidered = e.target.value;
                                      setLoan(loanModel);
                                    }} name="clothing_periodconsidered" defaultValue={applicant.business.clothing.periodconsidered}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('Location').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Hours Worked in a Day *</Form.Label>
                                  <Form.Group controlId="clothing_workinghours">
                                    <Form.Select className="form-control" disabled={true} onChange={(e)=> {
                                        applicant.business.clothing.workinghours = e.target.value;
                                      }} name="clothing_workinghours" defaultValue={getValue(applicant.business.clothing,'workinghours')}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('workinghours').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" tooltip>
                                    Select Hours Worked in a Day from Business / Shop Operation module
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Working days in a week *</Form.Label>
                                  <Form.Group controlId="clothing_workingdays">
                                    <Form.Select className="form-control" disabled={true} onChange={(e)=> {
                                        applicant.business.clothing.workingdays = e.target.value;
                                      }} name="clothing_workingdays" defaultValue={getValue(applicant.business.clothing,'workingdays')}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('workingdays').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" tooltip>
                                      Select Working Days in a week from Business / Shop Operation module
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                                {loanModel.totalEmployee>0 && <Col md={3}>
                                  <Form.Label className="custom-form-label" >Monthly Salaries</Form.Label>
                                  <FormControl type="number" placeholder = "0" name="clothing_avgsalary" onChange={(event) => {
                                    applicant.business.clothing.avgsalary = event.target.value;
                                  }} defaultValue={getValue(applicant.business.clothing, 'avgsalary')} />
                                </Col>}
                              </Row>
                              <strong>Clothing Income&nbsp;<a  onClick={addClothingIncome}><i className="fa fa-plus fa-lg"></i></a></strong>
                              <table className="table table-striped table-emails table-hover tablemobileview" id="folder-view" >
                                <thead>
                                <tr>
                                  <th id="folder-actions">
                                    <strong>SR NO#</strong>
                                  </th>
                                  <th><strong>Particulars</strong></th>
                                  <th><strong>Avg Purchase Rate per piece</strong></th>
                                  <th><strong>Avg Sales Rate per piece</strong></th>
                                  <th><strong>Avg No of Sale in month</strong></th>
                                  <th><strong>Monthly purchase</strong></th>
                                  <th><strong>Monthly sales</strong></th>
                                  <th><strong>Diff purchase and sales</strong></th>
                                  
                                  <th></th>
                                </tr>
                                </thead>
                                {(!isDefined(applicant.business.clothing.income) || applicant.business.clothing.income.length === 0)&&
                                  <tr>
                                    <td colSpan={8}><span className="col-lg-4 col-sm-6 col-xs-8 col-lg-offset-5 col-sm-offset-5 col-xs-offset-4">No Clothing Income</span></td>
                                  </tr>
                                }
                                {isDefined(applicant.business.clothing.income) && applicant.business.clothing.income.length > 0 && applicant.business.clothing.income.map((clothingincome:any, i:any)=>(
                                <tr>
                                  <td>{i+1}</td>
                                  <td><a onClick={()=>showClothingIncome(i)}>{getTypeValue('ClothingCombination',clothingincome.particulars)}</a></td>
                                  <td>{clothingincome.avgpurchase}</td>
                                  <td>{clothingincome.avgsales}</td>
                                  <td>{clothingincome.avgnoofsale}</td>
                                  <td>{parseVal(clothingincome.avgpurchase) * parseVal(clothingincome.avgnoofsale)}</td>
                                  <td>{parseVal(clothingincome.avgsales) * parseVal(clothingincome.avgnoofsale)}</td>
                                  <td>{(parseVal(clothingincome.avgsales) * parseVal(clothingincome.avgnoofsale)) - (parseVal(clothingincome.avgpurchase) * parseVal(clothingincome.avgnoofsale))}</td>
                                  <td>{(!isDefined(loanModel.clothingIndex) || loanModel.clothingIndex != i) && <a onClick={()=>removeClothing(i)}><i className="fa fa-times fa-lg"></i></a> }</td>
                                </tr>  
                                ))}
                              </table>
                             
                              {loanModel.clothingincomeForm &&
                              <div>
                              <Row>
                                <Col md={3}>
                                  <Form.Group controlId="clothing_particulars">
                                    <Form.Label className="custom-form-label" >Particulars</Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      loanModel.clothingincome.particulars = e.target.value;
                                      setLoan(loanModel);
                                    }} name="clothing_particulars" defaultValue={loanModel.clothingincome.particulars}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('Combination').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Avg Purchase Rate per piece</Form.Label>
                                  <FormControl type="number" placeholder = "0" name="clothing_avgpurchase" onChange={(event) => {
                                    loanModel.clothingincome.avgpurchase = event.target.value;
                                  }} defaultValue={getValue(loanModel.clothingincome, 'avgpurchase')} />
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Avg Purchase Rate per piece</Form.Label>
                                  <FormControl type="number" placeholder = "0" name="clothing_avgsales" onChange={(event) => {
                                    loanModel.clothingincome.avgsales = event.target.value;
                                  }} defaultValue={getValue(loanModel.clothingincome, 'avgsales')} />
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Avg Purchase Rate per piece</Form.Label>
                                  <FormControl type="number" placeholder = "0" name="clothing_avgnoofsale" onChange={(event) => {
                                    loanModel.clothingincome.avgnoofsale = event.target.value;
                                  }} defaultValue={getValue(loanModel.clothingincome, 'avgnoofsale')} />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={9}></Col>
                                <Col md={3}>
                                  <Button variant="danger" onClick={saveClothingIncome}> Save</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                  <Button variant="dark" onClick={cancleClothingIncome}> Cancel</Button>
                                </Col>
                              </Row>
                              </div>
                              }
                            </Tab>
                            }
                            {applicant!==null && applicant.business!==null && applicant.business.type=='TRD' && applicant.business.profile==='Mobile / Recharge / Repairing / Accessories' && loanModel.mobileflag && 
                            <Tab eventKey="btabs29  " title="Mobile">
                              <strong style={{paddingTop:'20px'}}>Business</strong>
                              <hr/>
                              <Row><strong>Business / Shop Locality</strong></Row>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="In market" name="mobilelocalityofbiz_market" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.mobilelocalityofbiz.market = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.mobilelocalityofbiz, 'market')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="At highway" name="mobilelocalityofbiz_highway" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.mobilelocalityofbiz.highway = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.mobilelocalityofbiz, 'highway')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Near temple" name="mobilelocalityofbiz_temple" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.mobilelocalityofbiz.temple = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.mobilelocalityofbiz, 'temple')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Near offices" name="mobilelocalityofbiz_offices" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.mobilelocalityofbiz.offices = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.mobilelocalityofbiz, 'offices')}/>
                                </Col>
                                <Col md={1}>
                                  <Form.Check inline label="Industrial" name="mobilelocalityofbiz_industrial" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.mobilelocalityofbiz.industrial = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.mobilelocalityofbiz, 'industrial')}/>
                                </Col>
                                <Col md={1}>
                                  <Form.Check inline label="In Slum" name="mobilelocalityofbiz_slum" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.mobilelocalityofbiz.slum = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.mobilelocalityofbiz, 'slum')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Residential area" name="mobilelocalityofbiz_area" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.mobilelocalityofbiz.area = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.mobilelocalityofbiz, 'area')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Near railway / bus station" name="mobilelocalityofbiz_busyroad" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.mobilelocalityofbiz.busyroad = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.mobilelocalityofbiz, 'busyroad')}/>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Number of Workers</Form.Label>
                                  <FormControl type="number" placeholder = "0" name="mobile_workers" onChange={(event) => {
                                    loanModel.mobile.workers = event.target.value;
                                  }} defaultValue={getValue(loanModel.mobile, 'workers')} />
                                </Col>
                              </Row>
                              <Row><strong>Service Provided *</strong></Row>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Accessories" name="service_acc" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.service.acc = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.service, 'acc')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Recharge" name="service_rec" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.service.rec = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.service, 'rec')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Repair" name="service_rp" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.service.rp = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.service, 'rp')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Mobile Insurance" name="service_mi" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.service.mi = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.service, 'mi')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Sale of Second Hand Mobile/Tablet" name="service_smt" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.service.smt = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.service, 'smt')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Sale of New Hand Mobile/Tablet" name="service_nmt" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.service.nmt = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.service, 'nmt')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Registered Franchise/ Service center" name="service_rfsc" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.service.rfsc = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.service, 'rfsc')}/>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}><strong>Small Bank Merchant / Agent</strong></Col>
                                <Col md={3}>
                                  <Form.Check inline label="Yes" name="bank_yes" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.bank.yes = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.bank, 'yes')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="No" name="bank_no" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.bank.no = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.bank, 'no')}/>
                                </Col>
                              </Row>
                              <Row style={{paddingTop:'20px',fontWeight:'bold'}}>Activities Done *</Row>
                              <Row>
                                 <Col md={2}>
                                  <Form.Check inline label="Account opening" name="activities_acc" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.activities.acc = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.activities, 'acc')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Passport printing" name="activities_pp" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.activities.pp = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.activities, 'pp')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Cash deposit" name="activities_cd" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.activities.cd = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.activities, 'cd')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Cash withdrawl" name="activities_cw" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.activities.cw = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.activities, 'cw')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Micro ATM services" name="activities_mas" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.activities.mas = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.activities, 'mas')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Aadhar seeding" name="activities_as" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.activities.as = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.activities, 'as')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Loan and insurance services" name="activities_lis" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.activities.lis = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.activities, 'lis')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="RD & FD Account opening" name="activities_rfa" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.activities.rfa = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.activities, 'rfa')}/>
                                </Col>
                              </Row>
                              <Row style={{paddingTop:'20px',fontWeight:'bold'}}>Mobile Product Range</Row>
                              <Row>
                                 <Col md={2}>
                                  <Form.Check inline label="Branded" name="product_br" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.product.br = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.product, 'br')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Premium" name="product_pr" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.product.pr = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.product, 'pr')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Mass Market" name="product_mm" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.product.mm = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.product, 'mm')}/>
                                </Col>
                              </Row>
                              {(applicant.business.mobile.service && (applicant.business.mobile.service.nmt || applicant.business.mobile.service.smt)) && <Row>
                                 <Col md={3}>
                                  <Form.Group controlId="mobile_productrange">
                                    <Form.Label className="custom-form-label" >Mobile/Tablet Brands *</Form.Label>
                                    <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                      applicant.business.mobile.productrange = e.target.value;
                                      setLoan(loanModel);
                                    }} name="mobile_productrange" defaultValue={applicant.business.mobile.productrange}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('mobilebrands').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>}
                              {(applicant.business.mobile.service && applicant.business.mobile.service.smt) && <div>
                                <Row style={{paddingTop:'20px'}}><strong>Source of Second Hand Mobile</strong></Row>
                                <Row>
                                  <Col md={2}>
                                    <Form.Check inline label="Individual Sellers" name="source_is" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.source.is = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.source, 'is')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Online Marketplaces" name="source_om" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.source.om = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.source, 'om')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Wholesale Distributors" name="source_wd" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.source.wd = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.source, 'wd')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Trade-Ins" name="source_ti" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.source.ti = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.source, 'ti')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Corporate Buybacks" name="source_cb" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.source.cb = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.source, 'cb')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Local Marketplaces" name="source_lm" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.source.lm = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.source, 'lm')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="E-Waste Collection Centers" name="source_ecc" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.source.ecc = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.source, 'ecc')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Direct Customers" name="source_dc" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.source.dc = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.source, 'dc')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Repair and Service Centers" name="source_rsc" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.source.rsc = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.source, 'rsc')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Auctions and Liquidation Sales" name="source_als" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.source.als = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.source, 'als')}/>
                                  </Col>
                                </Row>
                              </div>}
                              {(applicant.business.mobile.service && applicant.business.mobile.service.acc) && <Row>
                                 <Col md={3}>
                                  <Form.Group controlId="mobile_accessoriessold">
                                    <Form.Label className="custom-form-label" >Mobile/Tablet Brands *</Form.Label>
                                    <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e) => {
                                      applicant.business.mobile.accessoriessold = e.target.value;
                                      setLoan(loanModel);
                                    }} name="mobile_accessoriessold" defaultValue={applicant.business.mobile.accessoriessold}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('mobileaccesories').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>}
                              <Row style={{paddingTop:'20px'}}>
                                <Col md={3}><strong>Do You sale Customized Accessories</strong></Col>
                                <Col md={3}>
                                  <Form.Check inline label="Yes" name="mobaccessories_yes" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.mobaccessories.yes = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.mobaccessories, 'yes')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="No" name="mobaccessories_no" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.mobaccessories.no = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.mobaccessories, 'no')}/>
                                </Col>
                              </Row>
                              {applicant.business.mobile.service && applicant.business.mobile.service.rp && <div>
                                <Row>
                                  <Col md={2}>
                                    <Form.Check inline label="Screen Replacement" name="repair_sr" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.sr = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'sr')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Battery Replacement" name="repair_br" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.br = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'br')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Charging Port Repair" name="repair_cpr" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.cpr = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'cpr')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Water Damage Repair" name="repair_wdr" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.wdr = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'wdr')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Camera Repair" name="repair_cr" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.cr = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'cr')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Data Recovery" name="repair_dr" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.dr = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'dr')}/>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={2}>
                                    <Form.Check inline label="SIM Card Tray Repair" name="repair_str" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.str = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'str')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Housing Replacement" name="repair_hr" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.hr = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'hr')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="IC Replacement" name="repair_ic" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.ic = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'ic')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Device Unlocking" name="repair_du" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.du = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'du')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="E-Waste Recycling" name="repair_ewr" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.ewr = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'ewr')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Software Troubleshooting" name="repair_st" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.st = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'st')}/>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={2}>
                                    <Form.Check inline label="Network and Connectivity Issues" name="repair_nci" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.nci = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'nci')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Speaker and Microphone Repair" name="repair_smr" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.smr = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'smr')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Button and Switch Repair" name="repair_bsr" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.bsr = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'bsr')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Touchscreen Digitizer Replacement" name="repair_tdr" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.tdr = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'tdr')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Virus and Malware Removal" name="repair_vmr" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.vmr = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'vmr')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Headphone Jack Repair" name="repair_hjr" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.hjr = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'hjr')}/>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={2}>
                                    <Form.Check inline label="Data Transfer and Backup" name="repair_dtb" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.repair.dtb = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.repair, 'dtb')}/>
                                  </Col>
                                </Row>
                              </div>}
                              

                              <Row style={{paddingTop:'20px'}}>
                                <Col md={3}><strong>Authorized Reseller/Preferred Retailer?</strong></Col>
                                <Col md={3}>
                                  <Form.Check inline label="Yes" name="authorized_yes" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.authorized.yes = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.authorized, 'yes')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="No" name="authorized_no" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.authorized.no = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.authorized, 'no')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Authorized brand/s? *</Form.Label>
                                  <FormControl type="text"  name="mobile_authorizedbrands" onChange={(event) => {
                                    applicant.business.mobile.authorizedbrands = event.target.value;
                                  }} defaultValue={getValue(applicant.business.mobile, 'authorizedbrands')} />
                                </Col>
                              </Row>
                              <Row style={{paddingTop:'20px',fontWeight:'bold'}}>
                                <Col md={3}><strong>Benefits</strong></Col>
                              </Row>

                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Product Training and Support" name="benefit_pts" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.benefit.pts = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.benefit, 'pts')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Early Access to Products" name="benefit_ap" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.benefit.ap = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.benefit, 'ap')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Exclusive Offers" name="benefit_" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.benefit.eo = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.benefit, 'eo')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Marketing and Advertising Support" name="benefit_" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.benefit.mas = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.benefit, 'mas')}/>
                                </Col><Col md={2}>
                                  <Form.Check inline label="Channel Exclusivity" name="benefit_ce" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.benefit.ce = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.benefit, 'ce')}/>
                                </Col><Col md={2}>
                                  <Form.Check inline label="Other" name="benefit_ot" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.benefit.ot = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.benefit, 'ot')}/>
                                </Col>
                              </Row>
                              <Row style={{paddingTop:'20px'}}>
                                <Col md={3}><strong>Promotional Tie-Ups</strong></Col>
                                <Col md={3}>
                                  <Form.Check inline label="Yes" name="promotional_yes" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.promotional.yes = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.promotional, 'yes')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="No" name="promotional_no" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.promotional.no = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.promotional, 'no')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Brands *</Form.Label>
                                  <FormControl type="text"  name="mobile_promotionalbrands" onChange={(event) => {
                                    applicant.business.mobile.promotionalbrands = event.target.value;
                                  }} defaultValue={getValue(applicant.business.mobile, 'promotionalbrands')} />
                                </Col>
                              </Row>
                              <Row style={{paddingTop:'20px'}}>
                                <Col md={3}><strong>Partnership of Accessories and Add-ons</strong></Col>
                                <Col md={3}>
                                  <Form.Check inline label="Yes" name="accessories_yes" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.accessories.yes = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.accessories, 'yes')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="No" name="accessories_no" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.accessories.no = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.accessories, 'no')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Brands *</Form.Label>
                                  <FormControl type="text"  name="mobile_accessoriesbrands" onChange={(event) => {
                                    applicant.business.mobile.accessoriesbrands = event.target.value;
                                  }} defaultValue={getValue(applicant.business.mobile, 'accessoriesbrands')} />
                                </Col>
                              </Row>
                              <Row style={{paddingTop:'20px'}}>
                                <Col md={12}><strong>Inventory source</strong></Col>
                                <Col md={2}>
                                  <Form.Check inline label="Wholesalers and Distributors" name="inventory_" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.inventory.wd = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.inventory, 'wd')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Authorized Dealerships" name="inventory_" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.inventory.ad = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.inventory, 'ad')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Manufacturers" name="inventory_" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.inventory.ma = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.inventory, 'ma')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Online Marketplaces" name="inventory_" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.inventory.om = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.inventory, 'om')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Liquidation and Clearance Sales" name="inventory_" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.inventory.lc = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.inventory, 'lc')}/>
                                </Col>
                              </Row>
                              <Row style={{paddingTop:'20px'}}>
                                <Col md={3}><strong>Do you have Aadhaar UIDAI Authentication?</strong></Col>
                                <Col md={3}>
                                  <Form.Check inline label="Yes" name="adhar_yes" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.adhar.yes = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.adhar, 'yes')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="No" name="adhar_no" type='checkbox'  onChange={(e) => {
                                    applicant.business.mobile.adhar.no = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.mobile.adhar, 'no')}/>
                                </Col>
                              </Row>
                              {applicant.business.mobile.service && applicant.business.mobile.service.rp && <div>
                                <Row style={{paddingTop:'20px'}}>
                                  <Col md={3}><strong>Equipments seen *</strong></Col>
                                </Row>
                                <Row>
                                  <Col md={2}>
                                    <Form.Check inline label="Screwdrivers and Toolkits" name="equipment_st" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.st = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'st')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Spudgers and Opening Tools" name="equipment_sop" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.sop = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'sop')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Tweezers and Precision Tools" name="equipment_tpt" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.tpt = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'tpt')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Heat Guns and Hot Air Stations" name="equipment_hga" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.hga = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'hga')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Soldering Irons and Stations" name="equipment_sis" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.sis = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'sis')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Microscope or Magnifying Glass" name="equipment_mmg" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.mmg = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'mmg')}/>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={2}>
                                    <Form.Check inline label="ESD Protection Equipment" name="equipment_esd" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.esd = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'esd')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Adhesive and Adhesive Remover" name="equipment_aar" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.aar = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'aar')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Screen Separation Machine" name="equipment_ssm" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.ssm = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'ssm')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="OCA (Optically Clear Adhesive) Machine" name="equipment_oca" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.oca = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'oca')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Cleaning Solutions and Brushes" name="equipment_csb" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.csb = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'csb')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Hot Plate or Preheating Station" name="equipment_hpp" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.hpp = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'hpp')}/>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={2}>
                                    <Form.Check inline label="Flux and Solder Paste" name="equipment_fsp" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.fsp = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'fsp')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Eeprom Programmers" name="equipment_eep" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.eep = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'eep')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Laminating Machine" name="equipment_lm" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.lm = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'lm')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Multimeter" name="equipment_mul" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.mul = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'mul')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Battery Tester" name="equipment_bt" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.bt = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'bt')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Ultrasonic Cleaner" name="equipment_uc" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.uc = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'uc')}/>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={2}>
                                    <Form.Check inline label="Microsoldering Equipment" name="equipment_mip" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.mip = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'mip')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="MicroSD Card Reader/Writer" name="equipment_mcr" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.mcr = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'mcr')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Diagnostic Tools and Software" name="equipment_dts" type='checkbox'  onChange={(e) => {
                                      applicant.business.mobile.equipment.dts = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.mobile.equipment, 'dts')}/>
                                  </Col>
                                </Row>
                              </div>
                              }
                              <strong>Income&nbsp;&nbsp;&nbsp;<a onClick={addMobileMachine}><i className="fa fa-plus fa-lg"></i></a></strong>
                              <hr/>
                              <table className="table table-striped table-emails table-hover tablemobileview col-sm-12 "
                                id="folder-view">
                                <thead>
                                  <tr>
                                    <th id="folder-actions" className="col-sm-2">
                                      <strong>SERVICE SOURCE</strong>
                                    </th>
                                    <th className="col-sm-2" ><strong>PRODUCT SOLD</strong></th>
                                    <th className="col-sm-2"><strong>TIME PERIOD</strong></th>
                                    <th className="col-sm-1"><strong>WORKING DAYS/MONTH</strong></th>
                                    <th className="col-sm-1"><strong>AVG NO OF PRODUCT SOLD</strong></th>
                                    <th className="col-sm-1"><strong>AVG COST/Product</strong></th>
                                    <th className="col-sm-1"><strong>CONSIDERED TURNOVER</strong></th>
                                  </tr>
                                </thead>
                                {(!isDefined(applicant.business.mobile.machines) || applicant.business.mobile.machines.length==0 )&&
                                  <tr>
                                    <td colSpan={6}><span
                                        className="col-lg-4 col-sm-6 col-xs-8 col-lg-offset-5 col-sm-offset-5 col-xs-offset-4">No
                                        Income</span>
                                    </td>
                                  </tr>}
                                  {(isDefined(applicant.business.mobile.machines) && applicant.business.mobile.machines.length > 0 ) && applicant.business.mobile.machines.length.map((machine:any, i:any)=>(
                                  <tr>
                                    <td className="col-sm-2"><a onClick={()=>showMobileMachine(i)}>{getTypeValue('MOBTYPE',machine.type)}</a>
                                    </td>
                                    <td className="col-sm-2">{getTypeValue('MOBPURPOSE',machine.purpose)}</td>
                                    <td className="col-sm-2">{getTypeValue('MOBTIME',machine.time)}</td>
                                    <td className="col-sm-1">{setmobiledays(machine.time,machine.days)}</td>
                                    <td className="col-sm-1">{machine.average}</td>
                                    <td className="col-sm-1">{machine.cost}</td>
                                    <td className="col-sm-1">{calculateMobileTotal(machine.time,machine.days,machine.average,machine.cost)}</td>
                                    <td className="col-sm-1"><a onClick={()=>removeMobileMachine(i)}><i className="fa fa-times fa-lg"></i></a></td>
                                  </tr>
                                  ))}
                              </table>
                              { loanModel.showMobileMachineForm && <div>
                                <Row>
                                  <Col md={3}>
                                    <Form.Group controlId="mobileservice">
                                      <Form.Label className="custom-form-label" >Service Source</Form.Label>
                                      <Form.Select className="form-control" onChange={(e)=>{
                                        applicant.business.mobile.type = e.target.value;
                                        setLoan(loanModel);
                                      }} name="mobileservice" defaultValue={applicant.business.mobile.type}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('mobileservice').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col md={3}>
                                    <Form.Group controlId="mobile_purpose">
                                      <Form.Label className="custom-form-label" >Service/Product sold</Form.Label>
                                      <Form.Select className="form-control" onChange={(e)=>{
                                        applicant.business.mobile.purpose = e.target.value;
                                        setLoan(loanModel);
                                      }} name="mobile_purpose" defaultValue={applicant.business.mobile.purpose}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('mobileproduct').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col md={3}>
                                    <Form.Group controlId="mobile_time">
                                      <Form.Label className="custom-form-label" >Time Period to be considered</Form.Label>
                                      <Form.Select className="form-control" onChange={(e)=>{
                                        applicant.business.mobile.time = e.target.value;
                                        setLoan(loanModel);
                                      }} name="mobile_time" defaultValue={applicant.business.mobile.time}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('mobileperiod').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  {isDefined(applicant.business.mobile.model) && applicant.business.mobile.model.time!='DAILY' &&
                                    <Col md={3}>
                                      <Form.Label className="custom-form-label" >Working days in a month</Form.Label>
                                      <FormControl type="number" placeholder = "0" name="mobile_days" onChange={(event) => {
                                        applicant.business.mobile.days = event.target.value;
                                      }} defaultValue={getValue(applicant.business.mobile, 'days')} />
                                    </Col>
                                  }
                                  <Col md={3}>
                                    <Form.Label className="custom-form-label" >Average Number of Service/Product sold</Form.Label>
                                    <FormControl type="number" placeholder = "0" name="mobile_average" onChange={(event) => {
                                      applicant.business.mobile.average = event.target.value;
                                    }} defaultValue={getValue(applicant.business.mobile, 'average')} />
                                  </Col>
                                  <Col md={3}>
                                    <Form.Label className="custom-form-label" >Average Cost per Product</Form.Label>
                                    <FormControl type="number" placeholder = "0" name="mobile_cost" onChange={(event) => {
                                      applicant.business.mobile.cost = event.target.value;
                                    }} defaultValue={getValue(applicant.business.mobile, 'cost')} />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={9}></Col>
                                  <Col md={3}>
                                    <Button variant="dark" onClick={cancelMobileMachine}> Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button variant="danger" onClick={saveMobileMachine}> Save</Button>
                                  </Col>
                                </Row>
                              </div>
                              }
                              <strong style={{paddingTop:'20px'}}>Initial Capital Investment by Proprietor *</strong>
                              <Row>
                                <Col md={3}></Col>
                                <Col md={3}><strong>Amount</strong></Col>
                                <Col md={3}><strong>How was payment made?</strong></Col>
                              </Row>
                              <Row>
                                <Col md={3}>Shop premise / Shop deposit</Col>
                                <Col md={3}>
                                  <FormControl type="number" placeholder = "0" name="amount_infrastructure" onChange={(event) => {
                                      applicant.business.mobile.mobinvest1 = event.target.value;
                                    }} defaultValue={getValue(applicant.business.mobile, 'mobinvest1')} />
                                </Col>
                                <Col md={3}>
                                    <FormControl type="text"  name="amount_shop" onChange={(event) => {
                                      applicant.business.mobile.mobinvestpay1 = event.target.value;
                                    }} defaultValue={getValue(applicant.business.mobile, 'mobinvestpay1')} />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>Shop ambience / furniture</Col>
                                <Col md={3}>
                                  <FormControl type="number" placeholder = "0" name="amount_feed" onChange={(event) => {
                                      applicant.business.mobile.mobinvest2 = event.target.value;
                                    }} defaultValue={getValue(applicant.business.mobile, 'mobinvest2')} />
                                </Col>
                                <Col md={3}>
                                    <FormControl type="text"  name="amount_ambience" onChange={(event) => {
                                      applicant.business.mobile.mobinvestpay2 = event.target.value;
                                    }} defaultValue={getValue(applicant.business.mobile, 'mobinvestpay2')} />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>Stock</Col>
                                <Col md={3}>
                                  <FormControl type="number" placeholder = "0" name="amount_stock" onChange={(event) => {
                                      applicant.business.mobile.mobinvest3 = event.target.value;
                                    }} defaultValue={getValue(applicant.business.mobile, 'mobinvest3')} />
                                </Col>
                                <Col md={3}>
                                    <FormControl type="text"  name="amount_stock_pay" onChange={(event) => {
                                      applicant.business.mobile.mobinvestpay3 = event.target.value;
                                    }} defaultValue={getValue(applicant.business.mobile, 'mobinvestpay3')} />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}><strong>Total</strong></Col>
                                <Col md={3}><label className='label label-warning'>
                                      {parseVal(applicant.business.mobile.mobinvest1)+parseVal(applicant.business.mobile.mobinvest2)+parseVal(applicant.business.mobile.mobinvest3)}
                                      </label>
                                </Col>
                              </Row>
                              {(isDefined(applicant.business.mobile.service) && (applicant.business.mobile.service.acc || applicant.business.mobile.service.smt || applicant.business.mobile.service.nmt)) &&
                                <div>
                                  <Col md={2}><strong>Stock Value *</strong></Col>
                                  <Col md={2}>
                                    <Form.Label className="custom-form-label" >Current</Form.Label>
                                    <FormControl type="number" placeholder = "0" name="mobile_currentstock" onChange={(event) => {
                                      applicant.business.mobile.currentstock = event.target.value;
                                      var value=""+event.target.value;
                                      var maxlength=15;
                                      var data=value.split(".");
                                      if(data.length<=2){
                                        value=data[0];
                                      }
                                      if(value.length>=maxlength){
                                        value=value.substring(0,maxlength);
                                        applicant.business.mobile.currentstock=parseInt(value);
                                      }
                                    }} defaultValue={getValue(applicant.business.mobile, 'currentstock')} />
                                  </Col>
                                  <Col md={2}>
                                    <Form.Label className="custom-form-label" >Average</Form.Label>
                                    <FormControl type="number" placeholder = "0" name="mobile_avgstock" onChange={(event) => {
                                      applicant.business.mobile.avgstock = event.target.value;
                                      var value=""+event.target.value;
                                      var maxlength=15;
                                      var data=value.split(".");
                                      if(data.length<=2){
                                        value=data[0];
                                      }
                                      if(value.length>=maxlength){
                                        value=value.substring(0,maxlength);
                                        applicant.business.mobile.avgstock=parseInt(value);
                                      }
                                    }} defaultValue={getValue(applicant.business.mobile, 'avgstock')} />
                                  </Col>
                                  <Col md={2}>
                                    <Form.Label className="custom-form-label" >Maximum</Form.Label>
                                    <FormControl type="number" placeholder = "0" name="mobile_maximumstock" onChange={(event) => {
                                      applicant.business.mobile.maximumstock = event.target.value;
                                      var value=""+event.target.value;
                                      var maxlength=15;
                                      var data=value.split(".");
                                      if(data.length<=2){
                                        value=data[0];
                                      }
                                      if(value.length>=maxlength){
                                        value=value.substring(0,maxlength);
                                        applicant.business.mobile.maximumstock=parseInt(value);
                                      }
                                    }} defaultValue={getValue(applicant.business.mobile, 'maximumstock')} />
                                  </Col>
                                  <Col md={2}>
                                    <Form.Label className="custom-form-label" >Minimum</Form.Label>
                                    <FormControl type="number" placeholder = "0" name="mobile_minimumstock" onChange={(event) => {
                                      applicant.business.mobile.minimumstock = event.target.value;
                                      var value=""+event.target.value;
                                      var maxlength=15;
                                      var data=value.split(".");
                                      if(data.length<=2){
                                        value=data[0];
                                      }
                                      if(value.length>=maxlength){
                                        value=value.substring(0,maxlength);
                                        applicant.business.mobile.minimumstock=parseInt(value);
                                      }
                                    }} defaultValue={getValue(applicant.business.mobile, 'minimumstock')} />
                                  </Col>
                                </div>}
                            </Tab>
                            }
                            {applicant!==null && applicant.business!==null && applicant.business.type=='TRD' && applicant.business.profile==='Street Food Stall/Cart' && loanModel.streetfoodflag && 
                            <Tab eventKey="btabs30  " title="Street Food Stall/Cart">
                              <strong className='paddingTop20'>Food Street vendor Documentation *</strong>
                              <hr/>
                              <Row>
                                <Col md={3}>
                                  <Form.Check inline label="No Documentation" name="streetfood_documentation_no" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.documentation.no = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.documentation, 'no')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Certificate of Vending (CoV)" name="streetfood_documentation_cov" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.documentation.cov = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.documentation, 'cov')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Identity Card issued by Urban Local Bodies (ULB)" name="streetfood_documentation_ulb" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.documentation.ulb = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.documentation, 'ulb')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Letter of Recommendation(LoR) by ULB or Town Vending Committee (TVC)" name="streetfood_documentation_tvs" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.documentation.tvs = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.documentation, 'tvs')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="License for Hawker / Squatter Seller under section 313 A" name="streetfood_documentation_" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.documentation.lh = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.documentation, 'lh')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="FSSAI license" name="streetfood_documentation_" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.documentation.fssai = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.documentation, 'fssai')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Vehicle license (if you own a food truck/bike)" name="streetfood_documentation_" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.documentation.vl = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.documentation, 'vl')}/>
                                </Col>
                              </Row>
                              <strong className='paddingTop20'>Business</strong>
                              <hr/>
                              <Row>
                                <Col md={3}>
                                  <Form.Check inline label="Have you availed the PM SVANidhi scheme?" name="streetfood_ispmsvanidhischeme" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.ispmsvanidhischeme = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood, 'ispmsvanidhischeme')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Member of National Association of Street Vendors of India (NASVI)?*" name="streetfood_isnasvi" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.isnasvi = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood, 'isnasvi')}/>
                                </Col>
                                {applicant.business.streetfood && applicant.business.streetfood.isnasvi &&
                                <Col md={3}>
                                    <Form.Label className="custom-form-label" >Registeration Number</Form.Label>
                                    <FormControl type="number"  name="streetfood_registerationnumber" onChange={(event) => {
                                      applicant.business.streetfood.registerationnumber = event.target.value;
                                    }} defaultValue={getValue(applicant.business.streetfood, 'registerationnumber')} />
                                </Col>}
                              </Row>
                              <strong className='paddingTop20'>Street Food Vendor Category*</strong>
                              
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Mobile: Vendors carrying baskets" name="streetfoodvendorcategory_mvcb" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.streetfoodvendorcategory.mvcb = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.streetfoodvendorcategory, 'mvcb')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Cycles/Motorbikes" name="streetfoodvendorcategory_cm" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.streetfoodvendorcategory.cm = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.streetfoodvendorcategory, 'cm')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Semi Mobile : Carts" name="streetfoodvendorcategory_smc" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.streetfoodvendorcategory.smc = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.streetfoodvendorcategory, 'smc')}/>
                                </Col>

                                <Col md={3}>
                                  <Form.Check inline label="Stationary : Structure in market or in front of shops" name="streetfoodvendorcategory_sss" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.streetfoodvendorcategory.sss = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.streetfoodvendorcategory, 'sss')}/>
                                </Col>
                                {isDefined(applicant.business.streetfood) && isDefined(applicant.business.streetfood.streetfoodvendorcategory) && isDefined(applicant.business.streetfood.streetfoodvendorcategory.sss) && applicant.business.streetfood.streetfoodvendorcategory.sss === true &&
                                <Col md={3}>
                                    <Form.Label className="custom-form-label" >Separate Seating Arrangement ?</Form.Label>
                                    <FormControl type="number"  name="streetfood_separateseatingarrangement" onChange={(event) => {
                                      applicant.business.streetfood.separateseatingarrangement = event.target.value;
                                    }} defaultValue={getValue(applicant.business.streetfood, 'separateseatingarrangement')} />
                                </Col>}
                              </Row>
                              <strong className='paddingTop20'>Food Category</strong>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Snacks" name="foodcategory_sns" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.foodcategory.sns = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.foodcategory, 'sns')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Main Meals" name="foodcategory_mms" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.foodcategory.mms = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.foodcategory, 'mms')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Beverages" name="foodcategory_bgs" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.foodcategory.bgs = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.foodcategory, 'bgs')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Fruits" name="foodcategory_fts" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.foodcategory.fts = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.foodcategory, 'fts')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Ice Creams /Kulfi" name="foodcategory_ice" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.foodcategory.ice = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.foodcategory, 'ice')}/>
                                </Col>
                              </Row>
                              <strong className='paddingTop20'>Type of Food</strong>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Dry" name="streetfood_type_dry" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.type.dry = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.type, 'dry')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Pre Cooked" name="streetfood_type_pcd" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.type.pcd = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.type, 'pcd')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Freshly Cooked On the Spot" name="streetfood_type_fcs" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.type.fcs = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.type, 'fcs')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Cut Fruits" name="streetfood_type_cft" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.type.cft = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.type, 'cft')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Tea/Coffee/Drinks" name="streetfood_type_tcd" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.type.tcd = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.type, 'tcd')}/>
                                </Col>
                              </Row>
                              {applicant.business.streetfood.type && (applicant.business.streetfood.type.dry || applicant.business.streetfood.type.pcd || applicant.business.streetfood.type.cft) && 
                                <div>
                                    <strong className='paddingTop20'>How is food transported?</strong>
                                    <Row>
                                      <Col md={3}>
                                        <Form.Check inline label="Doorstep delivery by supplier" name="howisfoodtransported_dds" type='checkbox'  onChange={(e) => {
                                          applicant.business.streetfood.howisfoodtransported.dds = e.target.checked;
                                          setLoan(loanModel);
                                        }} checked={getValue(applicant.business.streetfood.howisfoodtransported, 'dds')}/>
                                      </Col>
                                      <Col md={3}>
                                        <Form.Check inline label="Shared delivery with other vendors" name="howisfoodtransported_sdv" type='checkbox'  onChange={(e) => {
                                          applicant.business.streetfood.howisfoodtransported.sdv = e.target.checked;
                                          setLoan(loanModel);
                                        }} checked={getValue(applicant.business.streetfood.howisfoodtransported, 'sdv')}/>
                                      </Col>
                                      <Col md={3}>
                                        <Form.Check inline label="Use of hired tempo / vehicle" name="howisfoodtransported_uhtv" type='checkbox'  onChange={(e) => {
                                          applicant.business.streetfood.howisfoodtransported.uhtv = e.target.checked;
                                          setLoan(loanModel);
                                        }} checked={getValue(applicant.business.streetfood.howisfoodtransported, 'uhtv')}/>
                                      </Col>
                                      <Col md={3}>
                                        <Form.Check inline label="Use of own tempo / vehicle" name="howisfoodtransported_uotv" type='checkbox'  onChange={(e) => {
                                          applicant.business.streetfood.howisfoodtransported.uotv = e.target.checked;
                                          setLoan(loanModel);
                                        }} checked={getValue(applicant.business.streetfood.howisfoodtransported, 'uotv')}/>
                                      </Col>
                                    </Row>
                                </div>
                              }
                              <strong className='paddingTop20'>Source of Food *</strong>
                              <Row>
                                <Col md={3}>
                                  <Form.Check inline label="Self / Family Cooked" name="sourceoffood_sfc" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.sourceoffood.sfc = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.sourceoffood, 'sfc')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Bought from Middle Man" name="sourceoffood_bmm" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.sourceoffood.bmm = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.sourceoffood, 'bmm')}/>
                                </Col>
                              </Row>
                              <strong className='paddingTop20'>Power/Gas Source</strong>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Stove" name="streetfoodpower_st" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.power.st = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.power, 'st')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="LPG Cylinder" name="streetfoodpower_lpg" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.power.lpg = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.power, 'lpg')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Gas Stove" name="streetfoodpower_gas" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.power.gas = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.power, 'gas')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Coal" name="streetfoodpower_cal" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.power.cal = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.power, 'cal')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Induction/Electric" name="streetfoodpower_ie" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.power.ie = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.power, 'ie')}/>
                                </Col>
                              </Row>
                              <strong className='paddingTop20'>Do you change your menu based on the season?</strong>

                              <Row>
                                <Col md={3}>
                                  <Form.Check inline label="Menu Stays Same Throughout" name="streetfood_season_msst" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.season.msst = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.season, 'msst')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Menu Changes" name="streetfood_season_mc" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.season.mc = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.season, 'mc')}/>
                                </Col>
                                
                                <Col md={3}>
                                    <Form.Label className="custom-form-label" >How many Peak Hours in a day?</Form.Label>
                                    <FormControl type="number"  name="streetfood_peakhours" onChange={(event) => {
                                      applicant.business.streetfood.peakhours = event.target.value;
                                    }} defaultValue={getValue(applicant.business.streetfood, 'peakhours')} />
                                </Col>
                              </Row>
                              <strong className='paddingTop20'>Location *</strong>

                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="School/College" name="streetfood_location_sc" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.location.sc = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood, 'sc')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Near Offices" name="streetfood_location_no" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.location.no = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood, 'no')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Near Offices" name="streetfood_location_tl" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.location.tl = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood, 'tl')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Food Hawker Market" name="streetfood_location_fhm" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.location.fhm = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood, 'fhm')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Street Market" name="streetfood_location_sm" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.location.sm = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood, 'sm')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Residential Locality" name="streetfood_location_rs" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.location.rs = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood, 'rs')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Main Station/bus road" name="streetfood_location_msbr" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.location.msbr = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood, 'msbr')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Hospitals" name="streetfood_location_hp" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.location.hp = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood, 'hp')}/>
                                </Col>
                              </Row>
                              <strong className='paddingTop20'>Location Type</strong>
                              <hr/>
                              <Row>
                                <Col md={3}>
                                  <Form.Check inline label="Mobile" name="streetfood_season_msst" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.location.mo = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.location, 'mo')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Constant" name="streetfood_season_mc" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.location.co = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.location, 'co')}/>
                                </Col>
                                
                                <Col md={3}>
                                    <Form.Label className="custom-form-label" >Permitted Hawker Designated Zone</Form.Label>
                                    <Form.Select className="form-control" onChange={(e)=>{
                                        applicant.business.streetfood.permittedhawkerdesignatedzone = e.target.value;
                                        setLoan(loanModel);
                                      }} name="streetfood_permittedhawkerdesignatedzone" defaultValue={applicant.business.streetfood.permittedhawkerdesignatedzone}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('Permittedhawkerdesignatedzone').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                    </Form.Select>
                                </Col>
                              </Row>
                              <strong className='paddingTop20'>Operational Expenses *</strong>
                              
                              <Row>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >No of times purchases are made</Form.Label>
                                  <Form.Select className="form-control" onChange={(e)=>{
                                      applicant.business.streetfood.nooftimespurchasesaremade = e.target.value;
                                      setLoan(loanModel);
                                    }} name="streetfood_nooftimespurchasesaremade" defaultValue={applicant.business.streetfood.nooftimespurchasesaremade}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('Nooftimespurchasesaremade').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                  </Form.Select>
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Amount Spent on Purchase</Form.Label>
                                  <FormControl type="number"  name="streetfood_amountspentonpurchase" onChange={(event) => {
                                    applicant.business.streetfood.amountspentonpurchase = event.target.value;
                                  }} defaultValue={getValue(applicant.business.streetfood, 'amountspentonpurchase')} />
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Initial Capital Investment on Premise</Form.Label>
                                  <FormControl type="number"  name="howmuchinitialcapitalinvestmentonpremise" onChange={(event) => {
                                    applicant.business.streetfood.howmuchinitialcapitalinvestmentonpremise = event.target.value;
                                  }} defaultValue={getValue(applicant.business.streetfood, 'howmuchinitialcapitalinvestmentonpremise')} />
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Daily Stock Value</Form.Label>
                                  <FormControl type="number"  name="streetfood_dailystockvalue" onChange={(event) => {
                                    applicant.business.streetfood.dailystockvalue = event.target.value;
                                  }} defaultValue={getValue(applicant.business.streetfood, 'dailystockvalue')} />
                                </Col>
                              </Row>
                              <strong className='paddingTop20'>Equipment</strong>
                              <hr/>
                              <Row>Equipments Seen *</Row>
                              <Row>
                               
                                <Col md={2}>
                                  <Form.Check inline label="Fridge" name="streetfoodequipment_fg" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.streetfoodequipment.fg = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.streetfoodequipment, 'fg')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Grills" name="streetfoodequipment_gs" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.streetfoodequipment.gs = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.streetfoodequipment, 'gs')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Fryers" name="streetfoodequipment_fs" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.streetfoodequipment.fs = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.streetfoodequipment, 'fs')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Steamers" name="streetfoodequipment_ss" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.streetfoodequipment.ss = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.streetfoodequipment, 'ss')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Cooking Pans" name="streetfoodequipment_cp" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.streetfoodequipment.cp = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.streetfoodequipment, 'cp')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Microwave" name="streetfoodequipment_mv" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.streetfoodequipment.mv = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.streetfoodequipment, 'mv')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Water Dispenser" name="streetfoodequipment_wd" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.streetfoodequipment.wd = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.streetfoodequipment, 'wd')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Cutlery - Plates, Spoons, Glasses etc." name="streetfoodequipment_ce" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.streetfoodequipment.ce = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.streetfoodequipment, 'ce')}/>
                                </Col>
                              </Row>
                              <strong className='paddingTop20'>Customer Landscape</strong>
                              <hr/>
                              <Row><strong className='paddingTop20'>Customer Type*</strong></Row>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Local Residents" name="streetfoodcustomertype_lr" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.customertype.lr = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.customertype, 'lr')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="College / School Students" name="streetfoodcustomertype_css" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.customertype.css = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.customertype, 'css')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Bus / Train Commuters" name="streetfoodcustomertype_btc" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.customertype.btc = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.customertype, 'btc')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Bar Patrons" name="streetfoodcustomertype_bp" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.customertype.bp = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.customertype, 'bp')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Office Goers" name="streetfoodcustomertype_og" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.customertype.og = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood.customertype, 'og')}/>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Area / Market assessment *</Form.Label>
                                  <Form.Select className="form-control" onChange={(e)=>{
                                      applicant.business.streetfood.areamarketassessment = e.target.value;
                                      setLoan(loanModel);
                                    }} name="areamarketassessment" defaultValue={applicant.business.streetfood.areamarketassessment}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('Areamarketassessment').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                  </Form.Select>
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Average customers per day</Form.Label>
                                  <FormControl type="number"  name="averagecustomersplatessoldperday" onChange={(event) => {
                                    applicant.business.streetfood.averagecustomersplatessoldperday = event.target.value;
                                  }} defaultValue={getValue(applicant.business.streetfood, 'averagecustomersplatessoldperday')} />
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Regular Repeat Customers</Form.Label>
                                  <FormControl type="number"  name="regularrepeatcustomershouseholds" onChange={(event) => {
                                    applicant.business.streetfood.regularrepeatcustomershouseholds = event.target.value;
                                  }} defaultValue={getValue(applicant.business.streetfood, 'regularrepeatcustomershouseholds')} />
                                </Col>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Average Monthly Billing</Form.Label>
                                  <FormControl type="number"  name="averagemonthlybilling" onChange={(event) => {
                                    applicant.business.streetfood.averagemonthlybilling = event.target.value;
                                  }} defaultValue={getValue(applicant.business.streetfood, 'averagemonthlybilling')} />
                                </Col>
                              </Row>
                             
                              <Row>
                                <Col md={3} style={{fontWeight:'bold'}}>
                                  <Form.Check inline label="Large Customers" style={{fontWeight:'bold'}} name="streetfood_largecustomer" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.largecustomer = e.target.checked;
                                    if(!isDefined(applicant.business.streetfood.largecustomer)){
                                      applicant.business.streetfood.largecustomertype.hotels=false;
                                      applicant.business.streetfood.largecustomertype.restaurants=false;
                                      applicant.business.streetfood.largecustomertype.marriagehalls=false;
                                      applicant.business.streetfood.largecustomertype.housingsocieties=false;
                                      applicant.business.streetfood.largecustomertype.catering=false;
                                      applicant.business.streetfood.largecustomertype.mess=false;
                                      applicant.business.streetfood.largecustomertype.canteens=false;
                                      applicant.business.streetfood.largecustomertype.hostels=false;
                                      applicant.business.streetfood.largecustomertype.other=false;
                                      applicant.business.streetfood.bulkordersinamonth=null;
                                      applicant.business.streetfood.averagebilling=null;
                                    }
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood, 'largecustomer')}/>
                                </Col>
                              </Row>
                              {applicant.business.streetfood && applicant.business.streetfood.largecustomer  &&
                              <div>
                                  <Row><strong className='paddingTop20'>Large Customer Type *</strong></Row>
                                  <Row>
                                    <Col md={2}>
                                      <Form.Check inline label="Hotels" name="largecustomertype_hotels" type='checkbox'  onChange={(e) => {
                                        applicant.business.streetfood.largecustomertype.hotels = e.target.checked;
                                        setLoan(loanModel);
                                      }} checked={getValue(applicant.business.streetfood.largecustomertype, 'hotels')}/>
                                    </Col>
                                    <Col md={2}>
                                      <Form.Check inline label="Restaurants" name="largecustomertype_restaurants" type='checkbox'  onChange={(e) => {
                                        applicant.business.streetfood.largecustomertype.restaurants = e.target.checked;
                                        setLoan(loanModel);
                                      }} checked={getValue(applicant.business.streetfood.largecustomertype, 'restaurants')}/>
                                    </Col>
                                    <Col md={2}>
                                      <Form.Check inline label="Marriage Halls" name="largecustomertype_marriagehalls" type='checkbox'  onChange={(e) => {
                                        applicant.business.streetfood.largecustomertype.marriagehalls = e.target.checked;
                                        setLoan(loanModel);
                                      }} checked={getValue(applicant.business.streetfood.largecustomertype, 'marriagehalls')}/>
                                    </Col>
                                    <Col md={2}>
                                      <Form.Check inline label="Housing Societies" name="largecustomertype_housingsocieties" type='checkbox'  onChange={(e) => {
                                        applicant.business.streetfood.largecustomertype.housingsocieties = e.target.checked;
                                        setLoan(loanModel);
                                      }} checked={getValue(applicant.business.streetfood.largecustomertype, 'housingsocieties')}/>
                                    </Col>
                                    <Col md={2}>
                                      <Form.Check inline label="Catering Services" name="largecustomertype_" type='checkbox'  onChange={(e) => {
                                        applicant.business.streetfood.largecustomertype.catering = e.target.checked;
                                        setLoan(loanModel);
                                      }} checked={getValue(applicant.business.streetfood.largecustomertype, 'catering')}/>
                                    </Col>
                                    <Col md={2}>
                                      <Form.Check inline label="Mess" name="largecustomertype_mess" type='checkbox'  onChange={(e) => {
                                        applicant.business.streetfood.largecustomertype.mess = e.target.checked;
                                        setLoan(loanModel);
                                      }} checked={getValue(applicant.business.streetfood.largecustomertype, 'mess')}/>
                                    </Col>
                                    <Col md={2}>
                                      <Form.Check inline label="Canteens" name="largecustomertype_canteens" type='checkbox'  onChange={(e) => {
                                        applicant.business.streetfood.largecustomertype.canteens = e.target.checked;
                                        setLoan(loanModel);
                                      }} checked={getValue(applicant.business.streetfood.largecustomertype, 'canteens')}/>
                                    </Col>
                                    <Col md={2}>
                                      <Form.Check inline label="Hostels" name="largecustomertype_hostels" type='checkbox'  onChange={(e) => {
                                        applicant.business.streetfood.largecustomertype.hostels = e.target.checked;
                                        setLoan(loanModel);
                                      }} checked={getValue(applicant.business.streetfood.largecustomertype, 'hostels')}/>
                                    </Col>
                                    <Col md={2}>
                                      <Form.Check inline label="Other" name="largecustomertype_other" type='checkbox'  onChange={(e) => {
                                        applicant.business.streetfood.largecustomertype.other = e.target.checked;
                                        setLoan(loanModel);
                                      }} checked={getValue(applicant.business.streetfood.largecustomertype, 'other')}/>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={3}>
                                      <Form.Label className="custom-form-label" >How often do you get bulk orders in a month</Form.Label>
                                      <Form.Select className="form-control" onChange={(e)=>{
                                          applicant.business.streetfood.bulkordersinamonth = e.target.value;
                                          setLoan(loanModel);
                                        }} name="howoftendoyougetbulkordersinamonth" defaultValue={applicant.business.streetfood.bulkordersinamonth}>
                                          <option value=""></option>
                                          {
                                            getLookupProperty('Howoftendoyougetbulkordersinamonth').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                          }
                                      </Form.Select>
                                    </Col>
                                    <Col md={3}>
                                      <Form.Label className="custom-form-label" >Average Monthly Billing</Form.Label>
                                      <FormControl type="number"  name="streetfood_averagebilling" onChange={(event) => {
                                        applicant.business.streetfood.averagebilling = event.target.value;
                                      }} defaultValue={getValue(applicant.business.streetfood, 'averagebilling')} />
                                    </Col>
                                  </Row>
                              </div>
                              }
                              
                              <Row>
                                <Col md={6}>
                                  <Form.Check inline label="Is parcel takeaway available?" name="isparceltakeawayavailable" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.isparceltakeawayavailable = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood, 'isparceltakeawayavailable')}/>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <Form.Check inline label="Presence on Food delivery App" style={{fontWeight:'bold'}} name="presenceonfooddeliveryapp" type='checkbox'  onChange={(e) => {
                                    applicant.business.streetfood.presenceonfooddeliveryapp = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.streetfood, 'presenceonfooddeliveryapp')}/>
                                </Col>
                              </Row>
                              {applicant.business.streetfood.presenceonfooddeliveryapp && 
                              <div>
                                <Row><strong className='paddingTop20'>Food App Type *</strong></Row>
                                <Row>
                                  <Col md={2}>
                                    <Form.Check inline label="Zomato" name="foodapptype_zomato" type='checkbox'  onChange={(e) => {
                                      applicant.business.streetfood.foodapptype.zomato = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.streetfood.foodapptype, 'zomato')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Swiggy" name="foodapptype_" type='checkbox'  onChange={(e) => {
                                      applicant.business.streetfood.foodapptype.swiggy = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.streetfood.foodapptype, 'swiggy')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Scootsy" name="foodapptype_scootsy" type='checkbox'  onChange={(e) => {
                                      applicant.business.streetfood.foodapptype.scootsy = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.streetfood.foodapptype, 'scootsy')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Dunzo" name="foodapptype_dunzo" type='checkbox'  onChange={(e) => {
                                      applicant.business.streetfood.foodapptype.dunzo = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.streetfood.foodapptype, 'dunzo')}/>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Check inline label="Eatsure" name="foodapptype_eatsure" type='checkbox'  onChange={(e) => {
                                      applicant.business.streetfood.foodapptype.eatsure = e.target.checked;
                                      setLoan(loanModel);
                                    }} checked={getValue(applicant.business.streetfood.foodapptype, 'eatsure')}/>
                                  </Col>
                                </Row>
                              </div>}
                              <Row>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >What percentage of sales coming from food delivery apps *</Form.Label>
                                  <FormControl type="number" placeholder='0 - 100%'  name="percentageoffooddeliveryapps" onChange={(event) => {
                                    applicant.business.streetfood.percentageoffooddeliveryapps = event.target.value;
                                  }} defaultValue={getValue(applicant.business.streetfood, 'percentageoffooddeliveryapps')} />
                                </Col>
                              </Row>
                              <Row className='paddingTop20'>Surroundings</Row>
                              <hr/>
                              <Row>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Other Street Food Carts Observed *</Form.Label>
                                  <FormControl type="number" placeholder='0 - 100%'  name="streetfood_othercarts" onChange={(event) => {
                                    applicant.business.streetfood.othercarts = event.target.value;
                                  }} defaultValue={getValue(applicant.business.streetfood, 'othercarts')} />
                                </Col>
                              </Row>
                            </Tab>
                            }
                            {applicant!==null && applicant.business!==null && applicant.business.type=='SVC' && applicant.business.profile==='Laundry and Dry Cleaning' && loanModel.laundryflag && 
                            <Tab eventKey="btabs31  " title="Laundry and Dry Cleaning">
                              <Row className='paddingTop20' style={{fontWeight:'bold'}}>Business</Row>
                              <hr/>
                              <Row className='paddingTop20'>Laundry Service *</Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Check inline label="Pick up and delivery" name="laundry_pickup" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.service.pickup = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.service, 'pickup')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Shop" name="laundry_shop" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.service.shop = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.service, 'shop')}/>
                                </Col>
                              </Row>
                              <Row>Targert Market *</Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Check inline label="Residential" name="market_residental" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.market.residental = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.market, 'residental')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Commercial" name="market_commercial" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.market.commercial = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.market, 'commercial')}/>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Designated Route *</Form.Label>
                                  <FormControl type="text"  name="laundry_designatedroute" onChange={(event) => {
                                    applicant.business.laundry.designatedroute = event.target.value;
                                  }} defaultValue={getValue(applicant.business.laundry, 'designatedroute')} />
                                </Col>
                              </Row>
                              <Row className='paddingTop20'>Service Offered *</Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Check inline label="Ironing" name="serviceoffered_ironing" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.serviceoffered.ironing = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.serviceoffered, 'ironing')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Washing" name="serviceoffered_washing" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.serviceoffered.washing = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.serviceoffered, 'washing')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Steam Pressing" name="serviceoffered_steampressing" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.serviceoffered.steampressing = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.serviceoffered, 'steampressing')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Dry Clean" name="serviceoffered_dryclean" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.serviceoffered.dryclean = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.serviceoffered, 'dryclean')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Stain Removal" name="serviceoffered_stainremoval" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.serviceoffered.stainremoval = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.serviceoffered, 'stainremoval')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Alterations and Repairs" name="serviceoffered_alterationsandrepairs" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.serviceoffered.alterationsandrepairs = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.serviceoffered, 'alterationsandrepairs')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Express Dry Cleaners within 24 hrs." name="serviceoffered_expressdrycleaners" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.serviceoffered.expressdrycleaners = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.serviceoffered, 'expressdrycleaners')}/>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <Form.Label className="custom-form-label" >Average Turnaround time (In Days)</Form.Label>
                                  <Form.Select className="form-control" onChange={(e)=>{
                                      applicant.business.laundry.turnaroundtime = e.target.value;
                                      setLoan(loanModel);
                                    }} name="laundry_turnaroundtime" defaultValue={applicant.business.laundry.turnaroundtime}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('turnaroundtime').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                  </Form.Select>
                                </Col>
                              </Row>
                              <Row className='paddingTop20'>Iron Type</Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Check inline label="Coal Fired" name="irontype_coalfired" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.irontype.coalfired = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.irontype, 'coalfired')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Electric" name="irontype_" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.irontype.electric = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.irontype, 'electric')}/>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <Form.Label className="custom-form-label" >Type of garment *</Form.Label>
                                  <Form.Select multiple={true} htmlSize={4} className="form-control" onChange={(e)=>{
                                      applicant.business.laundry.garment = e.target.value;
                                      setLoan(loanModel);
                                    }} name="laundry_garment" defaultValue={applicant.business.laundry.garment}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('garment').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                  </Form.Select>
                                </Col>
                              </Row>
                              <Row>Type of Fabrics</Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Check inline label="Natural Fabrics" name="typoffabrics_naturalfabrics" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.typoffabrics.naturalfabrics = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.typoffabrics, 'naturalfabrics')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Cotton" name="typoffabrics_cotton" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.typoffabrics.cotton = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.typoffabrics, 'cotton')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Silk" name="typoffabrics_silk" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.typoffabrics.silk = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.typoffabrics, 'silk')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Wool" name="typoffabrics_wool" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.typoffabrics.wool = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.typoffabrics, 'wool')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Linen" name="typoffabrics_linen" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.typoffabrics.linen = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.typoffabrics, 'linen')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Synthetic Fabrics" name="typoffabrics_syntheticfabrics" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.typoffabrics.syntheticfabrics = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.typoffabrics, 'syntheticfabrics')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Polyester" name="typoffabrics_polyester" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.typoffabrics.polyester = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.typoffabrics, 'polyester')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Nylon" name="typoffabrics_nylon" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.typoffabrics.nylon = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.typoffabrics, 'nylon')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Rayon" name="typoffabrics_rayon" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.typoffabrics.rayon = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.typoffabrics, 'rayon')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Acrylic" name="typoffabrics_acrylic" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.typoffabrics.acrylic = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.typoffabrics, 'acrylic')}/>
                                </Col>
                              </Row>
                              <Row>Delivery Packaging</Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Check inline label="Paper" name="laundrydelivery_paper" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.laundrydelivery.paper = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.laundrydelivery, 'paper')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Customer Cloth" name="laundrydelivery_customercloth" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.laundrydelivery.customercloth = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.laundrydelivery, 'customercloth')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Plastic Cover" name="laundrydelivery_plasticcover" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.laundrydelivery.plasticcover = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.laundrydelivery, 'plasticcover')}/>
                                </Col>
                              </Row>
                              <Row>Clothes Drying Area</Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Check inline label="Open Area" name="dryingarea_openarea" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.dryingarea.openarea = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.dryingarea, 'openarea')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Roof Tops" name="dryingarea_rooftops" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.dryingarea.rooftops = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.dryingarea, 'rooftops')}/>
                                </Col>
                              </Row>
                              <Row>Customers *</Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Check inline label="Individual / Families" name="laundry_lcustomers_individualfamilies" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.lcustomers.individualfamilies = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.lcustomers, 'individualfamilies')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Hotels" name="laundry_lcustomers_hotels" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.lcustomers.hotels = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.lcustomers, 'hotels')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Hostels" name="laundry_lcustomers_hostels" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.lcustomers.hostels = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.lcustomers, 'hostels')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Gyms and Spas" name="laundry_lcustomers_gyms" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.lcustomers.gyms = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.lcustomers, 'gyms')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Salons" name="laundry_lcustomers_salons" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.lcustomers.salons = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.lcustomers, 'salons')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Hospitals" name="laundry_lcustomers_hospitals" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.lcustomers.hospitals = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.lcustomers, 'hospitals')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Fashion and Clothing Boutiques" name="laundry_lcustomers_fashionboutiques" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.lcustomers.fashionboutiques = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.lcustomers, 'fashionboutiques')}/>
                                </Col>
                                
                              </Row>
                              <Row>Pick up & Delivery Vehicle</Row>
                              <Row>
                                <Col md={2}>
                                  <Form.Check inline label="Cycle" name="deliveryvehicle_cycle" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.deliveryvehicle.cycle = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.deliveryvehicle, 'cycle')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Scooter" name="deliveryvehicle_scooter" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.deliveryvehicle.scooter = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.deliveryvehicle, 'scooter')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Bike" name="deliveryvehicle_bike" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.deliveryvehicle.bike = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.deliveryvehicle, 'bike')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Auto" name="deliveryvehicle_auto" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.deliveryvehicle.auto = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.deliveryvehicle, 'auto')}/>
                                </Col>
                                <Col md={2}>
                                  <Form.Check inline label="Tempo" name="deliveryvehicle_tempo" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.deliveryvehicle.tempo = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.deliveryvehicle, 'tempo')}/>
                                </Col>
                              </Row>
                              <Row>Equipments *</Row>
                              <Row>
                                <Col md={3}>
                                  <Form.Check inline label="Washing Stone" name="equipments_washingstone" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.equipments.washingstone = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.equipments, 'washingstone')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Washing Machines" name="equipments_washingmachines" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.equipments.washingmachines = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.equipments, 'washingmachines')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Dryers" name="equipments_dryers" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.equipments.dryers = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.equipments, 'dryers')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Steam Press Machines" name="equipments_steampressmachines" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.equipments.steampressmachines = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.equipments, 'steampressmachines')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Dry Cleaning Machines" name="equipments_drycleaningmachines" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.equipments.drycleaningmachines = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.equipments, 'drycleaningmachines')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Ironing Board" name="equipments_ironingboard" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.equipments.ironingboard = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.equipments, 'ironingboard')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Folding Tables" name="equipments_foldingtables" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.equipments.foldingtables = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.equipments, 'foldingtables')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Steamers" name="equipments_steamers" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.equipments.steamers = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.equipments, 'steamers')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Sewing Machines" name="equipments_sewingmachines" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.equipments.sewingmachines = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.equipments, 'sewingmachines')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Hangers and Racks" name="equipments_hangersandracks" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.equipments.hangersandracks = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.equipments, 'hangersandracks')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Detergent and Chemical Dispensers" name="equipments_detergent" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.equipments.detergent = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.equipments, 'detergent')}/>
                                </Col>
                                <Col md={3}>
                                  <Form.Check inline label="Water Softeners and Filtration Systems" name="equipments_water" type='checkbox'  onChange={(e) => {
                                    applicant.business.laundry.equipments.water = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.laundry.equipments, 'water')}/>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <Form.Label className="custom-form-label" >Record Maintenance</Form.Label>
                                  <Form.Select className="form-control" onChange={(e)=>{
                                      applicant.business.laundry.recordmaintenance = e.target.value;
                                      setLoan(loanModel);
                                    }} name="laundry_recordmaintenancee" defaultValue={applicant.business.laundry.recordmaintenance}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('recordmaintenance').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                  </Form.Select>
                                </Col>
                              </Row>
                              
                                <strong className='paddingTop20' style={{fontWeight:'bold'}}>Laundry Income&nbsp; <a onClick={addLaundryIncome}><i className="fa fa-plus fa-lg"></i></a></strong>
                                <table className="table table-striped table-emails table-hover tablemobileview" id="folder-view" >
                                <thead>
                                  <tr>
                                    <th id="folder-actions">
                                      <strong>Sr No</strong>
                                    </th>
                                    <th><strong>Service Source</strong></th>
                                    <th><strong>Service Offered</strong></th>
                                    <th><strong>Type of Garment</strong></th>
                                    <th><strong>Period to be Considered</strong></th>
                                    <th><strong>Number of Garments</strong></th>
                                    <th><strong>Rate per Piece Charged</strong></th>
                                    <th><strong>Number of Days Open</strong></th>
                                    <th><strong>Total Income</strong></th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(!applicant.business.laundry.laundryincome || applicant.business.laundry.laundryincome.length === 0) && (
                                    <tr>
                                      <td colSpan={9}>
                                        <span className="col-lg-4 col-sm-6 col-xs-8 col-lg-offset-5 col-sm-offset-5 col-xs-offset-4">No laundry income</span>
                                      </td>
                                    </tr>
                                  )}
                                  {applicant.business.laundry.laundryincome && applicant.business.laundry.laundryincome.map((laundryincome:any, index:any) => (
                                    <tr key={index}>
                                      <td>
                                        <a href="#" onClick={() => showLaundryIncome(index)}>{getTypeValue('SERVICESOURCE',laundryincome.servicesource)}</a>
                                      </td>
                                      <td>{getTypeValue('SERVICEOFFERED',laundryincome.serviceoffered)}</td>
                                      <td>{getTypeValue('TYPEOFGARMENT',laundryincome.typeofgarment)}</td>
                                      <td>{getTypeValue('PERIODTOBECONSIDERED',laundryincome.periodtobeconsidered)}</td>
                                      <td>{laundryincome.numberofgarments}</td>
                                      <td>{laundryincome.rateperunit}</td>
                                      <td>{laundryincome.numberofdaysopen}</td>
                                      <td>
                                        {laundryincome.periodtobeconsidered === 'Daily' &&
                                          <label>{parseVal(laundryincome.numberofdaysopen) * parseVal(laundryincome.numberofgarments) * parseVal(laundryincome.rateperunit)}</label>
                                        }
                                         {laundryincome.periodtobeconsidered === 'Weekly' &&
                                          <label>{parseVal(laundryincome.numberofgarments) * parseVal(laundryincome.rateperunit) * 1}</label>
                                         }
                                         {laundryincome.periodtobeconsidered === 'Monthly' && 
                                         <label>{parseVal(laundryincome.numberofgarments) * parseVal(laundryincome.rateperunit) * 4}</label>
                                         }
                                        
                                      </td>
                                      <td>
                                        <a href="#" onClick={() => removeLaundryIncome(index)}>
                                          <i className="glyphicon glyphicon-remove"></i>
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              {loanModel.laundryincomeForm &&
                                <div>
                                  <Row>
                                    <Col md={2}>
                                      <Form.Label className="custom-form-label" >Service Source</Form.Label>
                                      <Form.Select className="form-control" onChange={(e)=>{
                                          applicant.business.laundry.servicesource = e.target.value;
                                          setLoan(loanModel);
                                        }} name="servicesource" defaultValue={applicant.business.laundry.servicesource}>
                                          <option value=""></option>
                                          {
                                            getLookupProperty('servicesource').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                          }
                                      </Form.Select>
                                    </Col>
                                    <Col md={3}>
                                      <Form.Label className="custom-form-label" >Service Offered</Form.Label>
                                      <Form.Select className="form-control" onChange={(e)=>{
                                          applicant.business.laundry.serviceoffered = e.target.value;
                                          setLoan(loanModel);
                                        }} name="serviceoffered" defaultValue={applicant.business.laundry.serviceoffered}>
                                          <option value=""></option>
                                          {
                                            getLookupProperty('serviceoffered').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                          }
                                      </Form.Select>
                                    </Col>
                                    <Col md={2}>
                                      <Form.Label className="custom-form-label" >Type of garment</Form.Label>
                                      <Form.Select className="form-control" onChange={(e)=>{
                                          applicant.business.laundry.typeofgarment = e.target.value;
                                          setLoan(loanModel);
                                        }} name="typeofgarment" defaultValue={applicant.business.laundry.typeofgarment}>
                                          <option value=""></option>
                                          {
                                            getLookupProperty('typeofgarment').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                          }
                                      </Form.Select>
                                    </Col>
                                    <Col md={2}>
                                      <Form.Label className="custom-form-label" >Period to be considered</Form.Label>
                                      <Form.Select className="form-control" onChange={(e)=>{
                                          applicant.business.laundry.periodtobeconsidered = e.target.value;
                                          setLoan(loanModel);
                                        }} name="periodtobeconsidered" defaultValue={applicant.business.laundry.periodtobeconsidered}>
                                          <option value=""></option>
                                          {
                                            getLookupProperty('periodtobeconsidered').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                                          }
                                      </Form.Select>
                                    </Col>
                                    <Col md={2}>
                                      <Form.Label className="custom-form-label" >Number of garments *</Form.Label>
                                      <FormControl type="number"  name="numberofgarments" onChange={(event) => {
                                        applicant.business.laundry.numberofgarments = event.target.value;
                                      }} defaultValue={getValue(applicant.business.laundry, 'numberofgarments')} />
                                    </Col>
                                    <Col md={2}>
                                      <Form.Label className="custom-form-label" >Rate per unit *</Form.Label>
                                      <FormControl type="number"  name="rateperunit" onChange={(event) => {
                                        applicant.business.laundry.rateperunit = event.target.value;
                                      }} defaultValue={getValue(applicant.business.laundry, 'rateperunit')} />
                                    </Col>
                                    {isDefined(applicant.business.laundry.periodtobeconsidered) && applicant.business.laundry.periodtobeconsidered === 'Daily' && 
                                      <Col md={2}>
                                        <Form.Label className="custom-form-label" >Number of Days Open</Form.Label>
                                        <FormControl type="number"  name="numberofdaysopen" onChange={(event) => {
                                          applicant.business.laundry.numberofdaysopen = event.target.value;
                                        }} defaultValue={getValue(applicant.business.laundry, 'numberofdaysopen')} />
                                      </Col>
                                    }
                                    
                                  </Row>
                                  <Row>
                                    <Col md={9}></Col>
                                    <Col md={3}>
                                      <Button variant="dark" onClick={cancelLaundryIncome}> Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                      <Button variant="danger" onClick={saveLaundryIncome}> Save</Button>
                                    </Col>
                                  </Row>
                                </div>
                              }
                              <strong className='paddingTop20'>Monthly Expenses *</strong>
                              <Row>
                                <Col md={3}><strong>Maintenance/Expansion Cost</strong></Col>
                                <Col md={3}><strong>Amount</strong></Col>
                              </Row>
                              <Row>
                                <Col md={3}>Cleaning Supplies (Detergents and Chemicals)</Col>
                                <Col md={3}>
                                  <FormControl type="number" placeholder='0'  name="amount_supplies" onChange={(event) => {
                                        applicant.business.laundry.investmentamt1 = event.target.value;
                                      }} defaultValue={getValue(applicant.business.laundry, 'investmentamt1')} />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>Washing Machines</Col>
                                <Col md={3}>
                                  <FormControl type="number" placeholder='0'  name="amount_washingmachine" onChange={(event) => {
                                        applicant.business.laundry.investmentamt2 = event.target.value;
                                      }} defaultValue={getValue(applicant.business.laundry, 'investmentamt2')} />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>Dryers</Col>
                                <Col md={3}>
                                  <FormControl type="number" placeholder='0'  name="amount_dryers" onChange={(event) => {
                                        applicant.business.laundry.investmentamt3 = event.target.value;
                                      }} defaultValue={getValue(applicant.business.laundry, 'investmentamt3')} />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>Iron Pressing</Col>
                                <Col md={3}>
                                  <FormControl type="number" placeholder='0'  name="amount_ironpressing" onChange={(event) => {
                                        applicant.business.laundry.investmentamt4 = event.target.value;
                                      }} defaultValue={getValue(applicant.business.laundry, 'investmentamt4')} />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>Steaming Equipment</Col>
                                <Col md={3}>
                                  <FormControl type="number" placeholder='0'  name="amount_steamingequipments" onChange={(event) => {
                                        applicant.business.laundry.investmentamt5 = event.target.value;
                                      }} defaultValue={getValue(applicant.business.laundry, 'investmentamt5')} />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>Sorting and Storage Equipment</Col>
                                <Col md={3}>
                                  <FormControl type="number" placeholder='0'  name="amount_sortingequ" onChange={(event) => {
                                        applicant.business.laundry.investmentamt6 = event.target.value;
                                      }} defaultValue={getValue(applicant.business.laundry, 'investmentamt6')} />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>Garment Bags and Packaging</Col>
                                <Col md={3}>
                                  <FormControl type="number" placeholder='0'  name="amount_garmentbags" onChange={(event) => {
                                        applicant.business.laundry.investmentamt7 = event.target.value;
                                      }} defaultValue={getValue(applicant.business.laundry, 'investmentamt7')} />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}><strong>Total</strong></Col>
                                <Col md={3}>
                                      {parseVal(applicant.business.laundry.investmentamt1) + parseVal(applicant.business.laundry.investmentamt2)+parseVal(applicant.business.laundry.investmentamt3)+parseVal(applicant.business.laundry.investmentamt4)+
                                      parseVal(applicant.business.laundry.investmentamt5) + parseVal(applicant.business.laundry.investmentamt6)+parseVal(applicant.business.laundry.investmentamt7)}
                                </Col>
                              </Row>
                              <strong className='paddingTop20'>Initial Capital Investment by Proprietor *</strong>
                              <Row>
                                <Col md={3}><strong>Equipmel;annt Type</strong></Col>
                                <Col md={3}><strong>Quantity</strong></Col>
                                <Col md={3}><strong>Price per Unit</strong></Col>
                              </Row>
                              <Row>
                                <Col md={3}>Washing Machines</Col>
                                <Col md={3}>
                                  <FormControl type="number" placeholder='0'  name="amount_washingmachines" onChange={(event) => {
                                    applicant.business.laundry.launinvest1 = event.target.value;
                                  }} defaultValue={getValue(applicant.business.laundry, 'launinvest1')} /> 
                                </Col>
                                <Col md={3}>
                                  <FormControl type="text" name="amount_shop" onChange={(event) => {
                                    applicant.business.laundry.launinvestpay1 = event.target.value;
                                  }} defaultValue={getValue(applicant.business.laundry, 'launinvestpay1')} /> 
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>Dryers</Col>
                                <Col md={3}>
                                  <FormControl type="number" placeholder='0'  name="amount_dryers" onChange={(event) => {
                                    applicant.business.laundry.launinvest2 = event.target.value;
                                  }} defaultValue={getValue(applicant.business.laundry, 'launinvest2')} /> 
                                </Col>
                                <Col md={3}>
                                  <FormControl type="text" name="amount_ambience" onChange={(event) => {
                                    applicant.business.laundry.launinvestpay2 = event.target.value;
                                  }} defaultValue={getValue(applicant.business.laundry, 'launinvestpay2')} /> 
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>Iron Pressing</Col>
                                <Col md={3}>
                                  <FormControl type="number" placeholder='0'  name="amount_ironpressing" onChange={(event) => {
                                    applicant.business.laundry.launinvest3 = event.target.value;
                                  }} defaultValue={getValue(applicant.business.laundry, 'launinvest3')} /> 
                                </Col>
                                <Col md={3}>
                                  <FormControl type="text" name="amount_stock" onChange={(event) => {
                                    applicant.business.laundry.launinvestpay3 = event.target.value;
                                  }} defaultValue={getValue(applicant.business.laundry, 'launinvestpay3')} /> 
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>Steaming Equipment</Col>
                                <Col md={3}>
                                  <FormControl type="number" placeholder='0'  name="amount_streamingeq" onChange={(event) => {
                                    applicant.business.laundry.launinvest4 = event.target.value;
                                  }} defaultValue={getValue(applicant.business.laundry, 'launinvest4')} /> 
                                </Col>
                                <Col md={3}>
                                  <FormControl type="text" name="amount_streamingeq_stock" onChange={(event) => {
                                    applicant.business.laundry.launinvestpay4 = event.target.value;
                                  }} defaultValue={getValue(applicant.business.laundry, 'launinvestpay4')} /> 
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>Sorting and Storage Equipment</Col>
                                <Col md={3}>
                                  <FormControl type="number" placeholder='0'  name="amount_sortingequ" onChange={(event) => {
                                    applicant.business.laundry.launinvest5 = event.target.value;
                                  }} defaultValue={getValue(applicant.business.laundry, 'launinvest5')} /> 
                                </Col>
                                <Col md={3}>
                                  <FormControl type="text" name="amount_sortingequ_stock" onChange={(event) => {
                                    applicant.business.laundry.launinvestpay5 = event.target.value;
                                  }} defaultValue={getValue(applicant.business.laundry, 'launinvestpay5')} /> 
                                </Col>
                              </Row>
                            </Tab>
                            }
                            {applicant!==null && applicant.business!==null && applicant.business.type=='SVC' && applicant.business.profile==='Xerox / Stationary Shop' && loanModel.xeroxflag && 
                          <Tab eventKey="btabs32  " title="Xerox">
                            <div className="padding-top"><legend className="Business ">Business</legend><br /></div>
                            <hr />

                            <div><label><b>Location *</b><br /></label></div>
                            <Row>
                              <Col md={2}>
                                <Form.Check inline label="School / Colleges" name="location_sc" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.location.sc = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.location, 'sc')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Near Court" name="location_co" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.location.co = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.location, 'co')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Government Offices" name="location_go" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.location.go = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.location, 'go')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Residential Area" name="location_ra" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.location.ra = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.location, 'ra')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Tution Classes" name="location_tc" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.location.tc = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.location, 'tc')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="In market" name="location_market" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.location.market = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.location, 'market')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="At highway" name="location_highway" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.location.highway = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.location, 'highway')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Near temple" name="location_temple" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.location.temple = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.location, 'temple')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Industrial" name="location_industrial" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.location.industrial = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.location, 'industrial')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="In slum" name="location_slum" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.location.slum = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.location, 'slum')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Near offices" name="location_offices" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.location.offices = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.location, 'offices')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Near railway / bus station" name="location_busyroad" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.location.busyroad = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.location, 'busyroad')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Private Offices / Business " name="location_pob" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.location.pob = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.location, 'pob')} />
                              </Col>

                            </Row>

                            <Row className='mt-3'>

                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Number of Employees</Form.Label>
                                <FormControl type="number" name="employees" onChange={(event) => {
                                  applicant.business.xerox.employees = event.target.value;
                                }} defaultValue={getValue(applicant.business.xerox, 'employees')} />
                              </Col>

                              <Col md={3}>
                                <Form.Label className="custom-form-label" > Internet Speed</Form.Label>
                                <FormControl type="text" name="internetspeed" onChange={(event) => {
                                  applicant.business.xerox.internetspeed = event.target.value;
                                }} defaultValue={getValue(applicant.business.xerox, 'internetspeed')} />
                              </Col>

                              <Col md={3}>
                                <Form.Label className="custom-form-label" >How many times do you order supplies in a month</Form.Label>
                                <FormControl type="number" name="monthlysupplies" onChange={(event) => {
                                  applicant.business.xerox.monthlysupplies = event.target.value;
                                }} defaultValue={getValue(applicant.business.xerox, 'monthlysupplies')} />
                              </Col>

                              <Col md={3}>
                                <Form.Label className="custom-form-label" >Average amount spent per delivery </Form.Label>
                                <FormControl type="number" name="avgamount" onChange={(event) => {
                                  applicant.business.xerox.avgamount = event.target.value;
                                }} defaultValue={getValue(applicant.business.xerox, 'avgamount')} />
                              </Col>

                            </Row>


                            <div><label><b>Types of Printers</b><br /></label></div>

                            <Row>

                              <Col md={2}>
                                <Form.Check inline label="Multi Function Printer" name="printer_mfc" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.printer.mfc = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.printer, 'mfc')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Inkjet" name="printer_ip" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.printer.ip = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.printer, 'ip')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Laser" name="printer_lp" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.printer.lp = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.printer, 'lp')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Digital Press" name="printer_dpp" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.printer.dpp = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.printer, 'dpp')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Wide Format" name="printer_wfp" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.printer.wfp = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.printer, 'wfp')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Sublimation" name="printer_sp" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.printer.sp = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.printer, 'sp')} />
                              </Col>

                            </Row>

                            <div className="padding-top"><legend className="businessAddress "><strong>Business Details</strong></legend><br /></div>

                            <div className='mt-2'><label><b>Operating Systems and Software</b><br /></label></div>
                            <Row>

                              <Col md={2}>
                                <Form.Check inline label="Excel" name="system_ex" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.system.ex = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.system, 'ex')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Power Point" name="system_pp" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.system.pp = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.system, 'pp')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Corel Draw" name="system_cd" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.system.cd = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.system, 'cd')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Word" name="system_wo" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.system.wo = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.system, 'wo')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Photoshop" name="system_ps" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.system.ps = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.system, 'ps')} />
                              </Col>

                            </Row>

                            <div className='mt-2'><label><b>Value Added Services</b><br /></label></div>
                            <Row>

                              <Col md={2}>
                                <Form.Check inline label="Booking / Binding" name="valueservicesadded_bb" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.valueservicesadded.bb = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.valueservicesadded, 'bb')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Lamination" name="valueservicesadded_la" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.valueservicesadded.la = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.valueservicesadded, 'la')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Print outs" name="valueservicesadded_po" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.valueservicesadded.po = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.valueservicesadded, 'po')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Notary Services" name="valueservicesadded_ns" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.valueservicesadded.ns = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.valueservicesadded, 'ns')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Sale of Stationery" name="valueservicesadded_sos" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.valueservicesadded.sos = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.valueservicesadded, 'sos')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Large Format Printing" name="valueservicesadded_lfp" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.valueservicesadded.lfp = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.valueservicesadded, 'lfp')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Scanning" name="valueservicesadded_scan" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.valueservicesadded.scan = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.valueservicesadded, 'scan')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Passport/ID Photo service" name="valueservicesadded_pps" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.valueservicesadded.pps = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.valueservicesadded, 'pps')} />
                              </Col>

                              <Col md={2}>
                                <Form.Check inline label="Customization and Personalisation" name="valueservicesadded_cp" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.valueservicesadded.cp = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.valueservicesadded, 'cp')} />
                              </Col>

                            </Row>

                            <div className='mt-2' hidden={!applicant.business.xerox.valueservicesadded.sos}>
                              <div><label><b>Stationary Products</b><br /></label></div>

                              <Row>

                                <Col md={2}>
                                  <Form.Check inline label="Calculator" name="stationary_cal" type='checkbox' onChange={(e) => {
                                    applicant.business.xerox.stationary.cal = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.xerox.stationary, 'cal')} />
                                </Col>

                                <Col md={2}>
                                  <Form.Check inline label="Fevicol" name="stationary_fev" type='checkbox' onChange={(e) => {
                                    applicant.business.xerox.stationary.fev = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.xerox.stationary, 'fev')} />
                                </Col>

                                <Col md={2}>
                                  <Form.Check inline label="Stapler" name="stationary_st" type='checkbox' onChange={(e) => {
                                    applicant.business.xerox.stationary.st = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.xerox.stationary, 'st')} />
                                </Col>

                                <Col md={2}>
                                  <Form.Check inline label="Writing Materials (Pen / Marker)" name="stationary_wm" type='checkbox' onChange={(e) => {
                                    applicant.business.xerox.stationary.wm = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.xerox.stationary, 'wm')} />
                                </Col>

                                <Col md={2}>
                                  <Form.Check inline label="Note Books / Text Books" name="stationary_nt" type='checkbox' onChange={(e) => {
                                    applicant.business.xerox.stationary.nt = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.xerox.stationary, 'nt ')} />
                                </Col>

                                <Col md={2}>
                                  <Form.Check inline label="Art and Craft Materials" name="stationary_ac" type='checkbox' onChange={(e) => {
                                    applicant.business.xerox.stationary.ac = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.xerox.stationary, 'ac')} />
                                </Col>

                                <Col md={2}>
                                  <Form.Check inline label="Other" name="stationary_ot" type='checkbox' onChange={(e) => {
                                    applicant.business.xerox.stationary.ot = e.target.checked;
                                    setLoan(loanModel);
                                  }} checked={getValue(applicant.business.xerox.stationary, 'ot')} />
                                </Col>

                              </Row>

                            </div>

                            <div className='mt-2' hidden={applicant.business.xerox.valueservicesadded.pps}>
                              <div><label><b>Photograph </b><br /></label></div>

                              <Row>
                                <Col md={3}>
                                  <Form.Group controlId="phototype">
                                    <Form.Label className="custom-form-label" >Photograph Types </Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      applicant.business.xerox.phototype = e.target.value;
                                      setLoan(loanModel);
                                    }} name="phototype" defaultValue={applicant.business.xerox.phototype}>
                                      <option value=""></option>
                                      {
                                        getLookupProperty('photographTypes').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </Col>

                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Average Monthly Income from Photography</Form.Label>
                                  <FormControl type="text" name="avgphotoincome" onChange={(event) => {
                                    applicant.business.xerox.avgphotoincome = event.target.value;
                                  }} defaultValue={getValue(applicant.business.xerox, 'avgphotoincome')} />
                                </Col>

                              </Row>
                            </div>

                            <div className='mt-3'><label><b>Equipment Seen</b><br /></label></div>
                            <Row>

                              <Col md={3}>
                                <Form.Check inline label="Lamination Machine" name="equip_lm" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.equip.lm = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.equip, 'lm')} />
                              </Col>

                              <Col md={3}>
                                <Form.Check inline label="Spiral Binding Machine" name="equip_spm" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.equip.spm = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.equip, 'spm')} />
                              </Col>

                              <Col md={3}>
                                <Form.Check inline label="Colour Printer" name="equip_cp" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.equip.cp = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.equip, 'cp')} />
                              </Col>

                              <Col md={3}>
                                <Form.Check inline label="Softwares" name="equip_so" type='checkbox' onChange={(e) => {
                                  applicant.business.xerox.equip.so = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={getValue(applicant.business.xerox.equip, 'so')} />
                              </Col>

                            </Row>

                            <div>
                              <fieldset className="padding-top mt-3">
                                <legend>
                                  <label>
                                    <strong>Peak Hours&nbsp;</strong>
                                    <a onClick={addxeroxPeakHour}>
                                      <i className="fa fa-plus fa-lg"></i>
                                    </a>
                                  </label>
                                  &nbsp;
                                  {xeroxPeakError && (
                                    <label className="error-msg">
                                      Peak entries exceed threshold. Please add a maximum of 5 Peak.
                                    </label>
                                  )}
                                </legend>
                              </fieldset>
                            </div>
                            <div className="col-md-12 unsetpadding-left">
                              <table className="table table-striped table-emails table-hover tablemobileview" id="folder-view">
                                <thead>
                                  <tr>
                                    <th id="folder-actions">
                                      <strong>Peak Start</strong>
                                    </th>
                                    <th><strong>Peak Start time</strong></th>
                                    <th><strong>Peak End</strong></th>
                                    <th><strong>Peak End Time</strong></th>
                                    <th><strong>Footfall during Peak Hours</strong></th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(!applicant?.business?.xerox?.peak || applicant?.business?.xerox?.peak?.length === 0) ? (
                                    <tr>
                                      <td colSpan={6}>
                                        <span className="col-lg-4 col-sm-6 col-xs-8 col-lg-offset-5 col-sm-offset-5 col-xs-offset-4">
                                          No Peak Hours
                                        </span>
                                      </td>
                                    </tr>
                                  ) : (
                                    applicant.business.xerox.peak.map((peak: any, index: any) => (
                                      <tr key={index}>
                                        <td><a href="#" onClick={() => showXeroxPeakHour(index)}>{peak.start}</a></td>
                                        <td>{getTypeValue('XEROXTIME', peak.starttime)}</td>
                                        <td>{peak.end}</td>
                                        <td>{getTypeValue('XEROXTIME', peak.endtime)}</td>
                                        <td>{peak.footfall}</td>
                                        <td>
                                          <a data-widgster="close" onClick={() => removeXeroxPeakHour(index)}>
                                            <i className="glyphicon glyphicon-remove"></i>
                                          </a>
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div>
                              <div className="padding-top"><legend className="businessAddress "><strong>Business Time</strong></legend><br /></div>

                              <div style={{ paddingTop: '10px', }}> <h5>Peak Hours</h5><br /></div>
                              <Row hidden={!showXeroxmanPeakForm}>
                                <Col md={3} >
                                  <Form.Label className="custom-form-label" >Start</Form.Label>
                                  <FormControl type="number" name="start" onChange={(event) => {
                                    applicant.business.xerox.start = event.target.value;
                                  }} defaultValue={getValue(applicant.business.xerox, 'start')} placeholder='12' min={1} max={12} onKeyDown={(e) => {
                                    var value: any = e.target;
                                    if (!value) return true; // No value is valid
                                    return value >= 0 && value <= 12;
                                  }} />
                                </Col>

                                <Col md={3}>
                                  <Form.Group controlId=" ">
                                    <Form.Label className="custom-form-label" >Start Time</Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      applicant.business.xerox.starttime = e.target.value;
                                      setLoan(loanModel);
                                    }} name="starttime" defaultValue={applicant.business.xerox.starttime}>
                                      <option value=""></option>
                                      <option value="AM">AM</option>
                                      <option value="PM">PM</option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>

                                <Col md={3} >
                                  <Form.Label className="custom-form-label" >End</Form.Label>
                                  <FormControl type="number" name="end" onChange={(event) => {
                                    applicant.business.xerox.end = event.target.value;
                                  }} defaultValue={getValue(applicant.business.xerox, 'end')} placeholder='12' min={1} max={12} onKeyDown={(e) => {
                                    var value: any = e.target;
                                    if (!value) return true; // No value is valid
                                    return value >= 0 && value <= 12;
                                  }} />
                                </Col>

                                <Col md={3}>
                                  <Form.Group controlId=" ">
                                    <Form.Label className="custom-form-label" > End Time</Form.Label>
                                    <Form.Select className="form-control" onChange={(e) => {
                                      applicant.business.xerox.endtime = e.target.value;
                                      setLoan(loanModel);
                                    }} name="endtime" defaultValue={applicant.business.xerox.endtime}>
                                      <option value=""></option>
                                      <option value="AM">AM</option>
                                      <option value="PM">PM</option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>

                                <Col md={3}>
                                  <Form.Label className="custom-form-label" >Footfall during Peak Hours</Form.Label>
                                  <FormControl type="text" name="footfall" onChange={(event) => {
                                    applicant.business.xerox.footfall = event.target.value;
                                  }} defaultValue={getValue(applicant.business.xerox, 'footfall')} />
                                </Col>
                              </Row>

                              <div className="col-md-12 mt-3" >
                                <button className="btn btn-primary submit-button pull-right" style={{ marginLeft: '5px' }} onClick={saveXeroxPeakHour}>
                                  Save
                                </button>
                                <button className="btn btn-inverse submit-button pull-right" onClick={cancelxeroxPeakHour}>
                                  Cancel
                                </button>
                              </div>

                            </div>

                            <div className="col-md-12 checkbox checkbox-primary padding-top mt-3">
                              <Form.Check inline label="" name="" type='checkbox' onChange={(e) => {
                                applicant.business.xerox.equipment = e.target.checked;
                                setLoan(loanModel);
                              }} checked={getValue(applicant.business.xerox, 'equipment')} onClick={resetxeroxmanMachine} />
                              <label>Equipment / Machinery Owned *</label>
                            </div>

                            <div hidden={!applicant.business.xerox.equipment}>

                              <fieldset>
                                <legend>
                                  <label>
                                    <strong>Machine&nbsp;</strong>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault(); // Prevent default link behavior
                                        addxeroxMachine();
                                      }}
                                    >
                                      <i className="fa fa-plus fa-lg"></i>
                                    </a>
                                  </label>
                                </legend>
                              </fieldset>

                              <div className="col-md-12 unsetpadding-left mt-2">
                                <table className="table table-striped table-emails table-hover tablemobileview" id="folder-view">
                                  <thead>
                                    <tr>
                                      <th id="folder-actions">
                                        <strong>TYPE OF MACHINE / EQUIPMENT</strong>
                                      </th>
                                      <th>
                                        <strong>OWNERSHIP TYPE</strong>
                                      </th>
                                      <th>
                                        <strong>NUMBER OF MACHINES OWNED</strong>
                                      </th>
                                      <th>
                                        <strong>PURCHASING COST</strong>
                                      </th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {applicant?.business?.xerox?.machines?.length === 0 || applicant?.business?.xerox?.machines === null ? (
                                      <tr>
                                        <td colSpan={5}>
                                          <span className="col-lg-4 col-sm-6 col-xs-8 col-lg-offset-5 col-sm-offset-5 col-xs-offset-4">
                                            No Machines
                                          </span>
                                        </td>
                                      </tr>
                                    ) : (
                                      applicant?.business?.xerox?.machines.map((machine: any, index: any) => (
                                        <tr key={index}>
                                          <td>
                                            <a href="#" onClick={(e) => {
                                              e.preventDefault();
                                              showXeroxMachine(index);
                                            }}>
                                              {getTypeValue('XEROXMACHINE', machine.type)}
                                            </a>
                                          </td>
                                          <td>{getTypeValue('XEROXOWNERSHIP', machine.ownershipType)}</td>
                                          <td>{machine.cost}</td>
                                          <td>{machine.payment}</td>
                                          <td>
                                            <a href="#" onClick={(e) => {
                                              e.preventDefault();
                                              removeXeroxMachine(index);
                                            }}>
                                              <i className="glyphicon glyphicon-remove"></i>
                                            </a>
                                          </td>
                                        </tr>
                                      ))
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              <div className='mt-3' hidden={showXeroxmanMachineForm}>
                                <Row>
                                  <Col md={3}>
                                    <Form.Group controlId="type">
                                      <Form.Label className="custom-form-label" >Type of Machine / Equipment</Form.Label>
                                      <Form.Select className="form-control" onChange={(e) => {
                                        xeroxMachine.type = e.target.value;
                                        setLoan(loanModel);
                                      }} name="xeroxMachine_type" defaultValue={xeroxMachine.type}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('xeroxmachine').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                        onKeyDown={validateText}</Form.Select>
                                    </Form.Group>
                                  </Col>

                                  <Col md={3}>
                                    <Form.Group controlId="purpose">
                                      <Form.Label className="custom-form-label" >Ownership Types</Form.Label>
                                      <Form.Select className="form-control" onChange={(e) => {
                                        xeroxMachine.purpose = e.target.value;
                                        setLoan(loanModel);
                                      }} name="xeroxMachine_purpose" defaultValue={xeroxMachine.purpose}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('xeroxOwnership').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                        onKeyDown={validateText}</Form.Select>
                                    </Form.Group>
                                  </Col>

                                  <Col md={3}>
                                    <Form.Label className="custom-form-label" >Number of Machines Owned</Form.Label>
                                    <FormControl type="text" name="xeroxMachine_cost" onChange={(event) => {
                                      xeroxMachine.cost = event.target.value;
                                    }} defaultValue={getValue(xeroxMachine, 'cost')} />
                                  </Col>

                                  <Col md={3}>
                                    <Form.Label className="custom-form-label" >Purchasing Cost</Form.Label>
                                    <FormControl type="text" name="xeroxMachine_payment" onChange={(event) => {
                                      xeroxMachine.payment = event.target.value;
                                    }} defaultValue={getValue(xeroxMachine, 'payment')} />
                                  </Col>
                                </Row>
                                <div className="col-md-12">
                                  <button className="btn btn-primary submit-button pull-right" style={{ marginLeft: '5px' }}
                                    onClick={saveXeroxMachine}>Save</button>
                                  <button className="btn btn-inverse submit-button pull-right"
                                    onClick={cancelxeroxMachine}>Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                              </div>

                            </div>

                            <div className='mt-3'>
                              <fieldset>
                                <legend>
                                  <label>
                                    <strong>Xerox Income*&nbsp;</strong>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault(); // Prevent default link behavior
                                        addxeroxincome();
                                      }}
                                    >
                                      <i className="fa fa-plus fa-lg"></i>
                                    </a>
                                  </label>
                                  &nbsp;
                                </legend>
                              </fieldset>

                              <div className="col-md-12 unsetpadding-left">
                                <table className="table table-striped table-emails table-hover tablemobileview" id="folder-view">
                                  <thead>
                                    <tr>
                                      <th id="folder-actions">
                                        <strong>SERVICE TYPE</strong>
                                      </th>
                                      <th><strong>TIME PERIOD CONSIDERED FOR SALES COMPUTATION</strong></th>
                                      <th><strong>TOTAL NUMBER OF SERVICES (MONTHLY)</strong></th>
                                      <th><strong>TOTAL NUMBER OF SERVICES FOR LARGE CUSTOMERS (MONTHLY)</strong></th>
                                      <th><strong>RATE PER PIECE</strong></th>
                                      <th><strong>RATE PER PIECE FOR LARGE CUSTOMERS</strong></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {applicant?.business?.xerox?.income?.length === 0 || applicant?.business?.xerox?.income == null ? (
                                      <tr>
                                        <td colSpan={7}>
                                          <span className="col-lg-4 col-sm-6 col-xs-8 col-lg-offset-5 col-sm-offset-5 col-xs-offset-4">
                                            No Income
                                          </span>
                                        </td>
                                      </tr>
                                    ) : (
                                      applicant.business.xerox.income.map((income: any, index: number) => (
                                        <tr key={index}>
                                          <td>
                                            <a
                                              href="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                showXeroxIncome(index);
                                              }}
                                            >
                                              {getTypeValue('XEROXINCOMETYPE', income.type)}
                                            </a>
                                          </td>
                                          <td>{getTypeValue('XEROXTIMEPERIOD', income.time)}</td>
                                          <td>{income.tc}</td>
                                          <td>{income.slc}</td>
                                          <td>{income.rpp}</td>
                                          <td>{income.ratelc}</td>
                                          <td>
                                            <a
                                              href="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                removeXeroxIncome(index);
                                              }}
                                              className="glyphicon glyphicon-remove"
                                            >

                                            </a>
                                          </td>
                                        </tr>
                                      ))
                                    )}
                                  </tbody>
                                </table>
                              </div>

                            </div>

                            <div className='mt-3' hidden={!showXeroxIncomeForm}>

                              <div>
                                <Row>
                                  <Col md={3}>
                                    <Form.Group controlId="xeroxIncome_type">
                                      <Form.Label className="custom-form-label" >Type of Services</Form.Label>
                                      <Form.Select className="form-control" onChange={(e) => {
                                        xeroxIncome.type = e.target.value;
                                        setLoan(loanModel);
                                      }} onKeyDown={validateText} name="xeroxIncome_type" defaultValue={xeroxIncome.type}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('xeroxincometype').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>

                                  <Col md={3}>
                                    <Form.Group controlId="xeroxIncome_time">
                                      <Form.Label className="custom-form-label" >Type of Services</Form.Label>
                                      <Form.Select className="form-control" onChange={(e) => {
                                        xeroxIncome.time = e.target.value;
                                        setLoan(loanModel);
                                      }} onKeyDown={validateText} name="xeroxIncome_time" defaultValue={xeroxIncome.time}>
                                        <option value=""></option>
                                        {
                                          getLookupProperty('xeroxtimeperiod').map((item: any) => (<option value={item['code']}>{item['type']}</option>))
                                        }
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>

                                  <Col md={3}>
                                    <Form.Label className="custom-form-label" >Total number of services</Form.Label>
                                    <FormControl type="number" name="xeroxIncome_tc" onChange={(event) => {
                                      xeroxIncome.tc = event.target.value;
                                    }} defaultValue={getValue(xeroxIncome, 'tc')} />
                                  </Col>

                                  <Col md={3}>
                                    <Form.Label className="custom-form-label" >Total number of services for Large Customer</Form.Label>
                                    <FormControl type="number" name="xeroxIncome_slc" onChange={(event) => {
                                      xeroxIncome.slc = event.target.value;
                                    }} defaultValue={getValue(xeroxIncome, 'slc')} />
                                  </Col>

                                  <Col md={3}>
                                    <Form.Label className="custom-form-label" >Rate per piece</Form.Label>
                                    <FormControl type="number" name="xeroxIncome_rpp" onChange={(event) => {
                                      xeroxIncome.rpp = event.target.value;
                                    }} defaultValue={getValue(xeroxIncome, 'rpp')} />
                                  </Col>

                                  <Col md={3}>
                                    <Form.Label className="custom-form-label" >Rate per piece for large customer</Form.Label>
                                    <FormControl type="number" name="xeroxIncome_ratelc" onChange={(event) => {
                                      xeroxIncome.ratelc = event.target.value;
                                    }} defaultValue={getValue(xeroxIncome, 'ratelc')} />
                                  </Col>
                                </Row>

                                <div className="col-md-12">
                                  <button className="btn btn-primary submit-button pull-right" style={{ marginLeft: '5px' }}
                                    onClick={saveXeroxIncome}>Save</button>
                                  <button className="btn btn-inverse submit-button pull-right"
                                    onClick={cancelxeroxIncome}>Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                              </div>
                            </div>

                            <div>
                              <div className="container">
                                <div className="col-sm-12 col-md-6 mt-4">
                                  <style>
                                    {`
            .label-th {
              width: 100%;
              font-weight: bold;
              color: black;
            }
            #xerox {
              border-collapse: unset;
              border-spacing: 8px;
              margin-top: 20px;
            }
          `}
                                  </style>
                                  <table className="col-md-12" id="xerox" style={{ width: '100%' }}>
                                    <caption className="label-th"><strong>Maintenance Cost*</strong></caption>
                                    <tbody>
                                      <tr style={{ borderBottom: '1px solid #ccc' }}>
                                        <td className="col-md-2">Roller Cleaning and Replacement</td>
                                        <td className="col-md-2">
                                          <input
                                            type="number"
                                            name="roller"
                                            placeholder="0"
                                            min="0"
                                            value={applicant.business.xerox.xinvestamt1}
                                            onChange={(event) => { applicant.business.xerox.xinvestamt1 = event.target.value }}
                                            style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                          />
                                        </td>
                                      </tr>
                                      <tr style={{ borderBottom: '1px solid #ccc' }}>
                                        <td className="col-md-2">Software Updates</td>
                                        <td className="col-md-2">
                                          <input
                                            type="number"
                                            name="software"
                                            min="0"
                                            placeholder="0"
                                            value={applicant.business.xerox.xinvestamt2}
                                            onChange={(event) => { applicant.business.xerox.xinvestamt2 = event.target.value }}
                                            style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                          />
                                        </td>
                                      </tr>
                                      <tr style={{ borderBottom: '1px solid #ccc' }}>
                                        <td className="col-md-2">Greasing and Lubrication</td>
                                        <td className="col-md-2">
                                          <input
                                            type="number"
                                            name="greasing"
                                            min="0"
                                            placeholder="0"
                                            value={applicant.business.xerox.xinvestamt3}
                                            onChange={(event) => { applicant.business.xerox.xinvestamt3 = event.target.value }}
                                            style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                          />
                                        </td>
                                      </tr>
                                      <tr style={{ borderBottom: '1px solid #ccc' }}>
                                        <td className="col-md-2">Scheduled Servicing</td>
                                        <td className="col-md-2">
                                          <input
                                            type="number"
                                            name="servicing"
                                            min="0"
                                            placeholder="0"
                                            value={applicant.business.xerox.xinvestamt4}
                                            onChange={(event) => { applicant.business.xerox.xinvestamt4 = event.target.value }}
                                            style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                          />
                                        </td>
                                      </tr>
                                      <tr style={{ borderBottom: '1px solid #ccc' }}>
                                        <td className="col-md-2">Fuser Unit Maintenance</td>
                                        <td className="col-md-2">
                                          <input
                                            type="number"
                                            name="fuser"
                                            min="0"
                                            placeholder="0"
                                            value={applicant.business.xerox.xinvestamt5}
                                            onChange={(event) => { applicant.business.xerox.xinvestamt5 = event.target.value }}
                                            style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                          />
                                        </td>
                                      </tr>
                                      <tr style={{ borderBottom: '1px solid #ccc' }}>
                                        <td className="col-md-2">Computer</td>
                                        <td className="col-md-2">
                                          <input
                                            type="number"
                                            name="computer"
                                            min="0"
                                            placeholder="0"
                                            value={applicant.business.xerox.xinvestamt6}
                                            onChange={(event) => { applicant.business.xerox.xinvestamt6 = event.target.value }}
                                            style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-md-2">Camera (If Photo Printing selected)</td>
                                        <td className="col-md-2">
                                          <input
                                            type="number"
                                            name="camera"
                                            min="0"
                                            placeholder="0"
                                            value={applicant.business.xerox.xinvestamt7}
                                            onChange={(event) => { applicant.business.xerox.xinvestamt7 = event.target.value }}
                                            style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-md-2"><strong>Total</strong></td>
                                        <td className="col-md-2">
                                          <label className="control-label">
                                            <span className="label label-warning">
                                              {applicant.business.xerox.xinvestamt1 + applicant.business.xerox.xinvestamt2 + applicant.business.xerox.xinvestamt3 + applicant.business.xerox.xinvestamt4 + applicant.business.xerox.xinvestamt5 + applicant.business.xerox.xinvestamt6 + applicant.business.xerox.xinvestamt7}
                                            </span>
                                          </label>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                                <div className="col-sm-12 col-md-6">
                                  <style>
                                    {`
            .label-th {
              width: 100%;
              font-weight: bold;
              color: black;
            }
            #xeroxsup {
              border-collapse: unset;
              border-spacing: 8px;
              margin-top: 20px;
            }
          `}
                                  </style>
                                  <table className="col-md-12" id="xeroxsup" style={{ width: '100%' }}>
                                    <caption className="label-th"><strong>Supplies & Consumables*</strong></caption>
                                    <tbody>
                                      <tr style={{ borderBottom: '1px solid #ccc' }}>
                                        <td className="col-md-2">Toner and Ink Cartridge Replacement</td>
                                        <td className="col-md-2">
                                          <input
                                            type="number"
                                            name="toner"
                                            min="0"
                                            placeholder="0"
                                            value={applicant.business.xerox.xsupamt1}
                                            onChange={(event) => { applicant.business.xerox.xsupamt1 = event.target.value }}
                                            style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                          />
                                        </td>
                                      </tr>
                                      <tr style={{ borderBottom: '1px solid #ccc' }}>
                                        <td className="col-md-2">Paper</td>
                                        <td className="col-md-2">
                                          <input
                                            type="number"
                                            name="paper"
                                            min="0"
                                            placeholder="0"
                                            value={applicant.business.xerox.xsupamt2}
                                            onChange={(event) => { applicant.business.xerox.xsupamt2 = event.target.value }}
                                            style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                          />
                                        </td>
                                      </tr>
                                      <tr style={{ borderBottom: '1px solid #ccc' }}>
                                        <td className="col-md-2">Lamination sheets</td>
                                        <td className="col-md-2">
                                          <input
                                            type="number"
                                            name="lamination"
                                            min="0"
                                            placeholder="0"
                                            value={applicant.business.xerox.xsupamt3}
                                            onChange={(event) => { applicant.business.xerox.xsupamt3 = event.target.value }}
                                            style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                          />
                                        </td>
                                      </tr>
                                      <tr style={{ borderBottom: '1px solid #ccc' }}>
                                        <td className="col-md-2">Stationery</td>
                                        <td className="col-md-2">
                                          <input
                                            type="number"
                                            name="stationery"
                                            min="0"
                                            placeholder="0"
                                            value={applicant.business.xerox.xsupamt4}
                                            onChange={(event) => { applicant.business.xerox.xsupamt4 = event.target.value }}
                                            style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-md-2">Wifi Bills</td>
                                        <td className="col-md-2">
                                          <input
                                            type="number"
                                            name="wifi"
                                            min="0"
                                            placeholder="0"
                                            value={applicant.business.xerox.xsupamt5}
                                            onChange={(event) => { applicant.business.xerox.xsupamt5 = event.target.value }}
                                            style={{ border: '1px solid #ccc', padding: '8px', width: '100%' }}
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="col-md-2"><strong>Total</strong></td>
                                        <td className="col-md-2">
                                          <label className="control-label">
                                            <span className="label label-warning">
                                              {applicant.business.xerox.xsupamt1 + applicant.business.xerox.xsupamt2 + applicant.business.xerox.xsupamt3 + applicant.business.xerox.xsupamt4 + applicant.business.xerox.xsupamt5}
                                            </span>
                                          </label>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                          </Tab>
                            }
                             {applicant!==null && applicant.business!==null && applicant.business.type=='TRD' && applicant.business.profile==='Sweets / Farsan Shop' && loanModel.sweetflag && 
                            <Tab eventKey="btabs33  " title="Sweets / Farsan Shop">
                            <label>Separate banking facility availed by Business *</label>
                            </Tab>
                            }


                        <Tab eventKey="BusinessDocuments" title="Business / Documents">
                          <Row className="mt-3">
                            <Col md={2}><strong>Document</strong></Col>
                            <Col md={2}><strong>Applicability</strong></Col>
                            <Col md={2}><strong>Verified</strong></Col>
                            <Col md={2}><strong>Remarks</strong></Col>
                            <Col md={2}></Col>
                          </Row>
                          {applicant.business.documents && applicant.business.documents.map((item:any,i:any)=>(
                            <Row className="mt-3">
                              <Col md={2}>{item.name}</Col>
                              <Col md={2}>
                                <Form.Check inline label="" name={"applicability"+i} type='checkbox' id={"applicability"+i} onChange={(e)=>{ 
                                  item.applicability = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={item.applicability} />
                              </Col>
                              <Col md={2}>
                                <Form.Check inline label=""  name={"verified_"+i} type='checkbox' onChange={(e)=> {
                                  item.verified = e.target.checked;
                                  setLoan(loanModel);
                                }} checked={item.verified} />
                              </Col>
                              <Col md={2}>
                                <Form.Group controlId="remarks">
                                  <FormControl type="text"  name={"remarks"+i} onChange={(e)=> {
                                    item.remarks = e.target.value;
                                    setLoan(loanModel);
                                  }} value={item.remarks} />
                                </Form.Group>
                              </Col>
                              <Col md={1}>
                                <input id={"business"+item.name+item.id} type="file" className="sitevisitfilechooser" multiple/>
                                
                              </Col>
                              <Col md={2}>
                              <a className="btn btn-lg sitevisit-remove-padding" onClick={()=>uploadDocument('business'+item.name+item.id,'business',item.id,item.name)} ><i className="fa fa-cloud-upload-alt fa-lg"></i></a>  
                              </Col>
                            </Row>)
                            )
                          }
                          <Row className="mt-3">
                            <Form.Group controlId="earningmembers">
                              <Form.Label className="custom-form-label" >Remarks</Form.Label>
                              <FormControl onChange={(e)=>{applicant.remarks = e.target.value;setLoan(loanModel)}} as="textarea" rows={6}
                                style={{ width: '100%', height: '120px' }} value={applicant.remarks}
                              />
                            </Form.Group>
                          </Row>
                        </Tab>
                        
                      </Tabs>
                        
                    </Tab>}
                    
                    {applicant.type ==='informal_selfemployed' && <Tab eventKey="BusinessIncome" title="Business Income">
                      <label>Business Income</label>
                      <Row>
                        <Col md={5}>
                          <Form.Group controlId="applicant.businessincome.itr" >
                            <Form.Check inline label="Are Income Tax Returns (ITR) available?" name="applicant.businessincome.itr"  type='checkbox' id='itr' onChange={(e)=> {applicant.businessincome.itr = e.target.checked;setLoan(loanModel);}} checked={applicant.businessincome.itr} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5} hidden={!applicant.businessincome.itr} >
                          <Form.Group controlId="applicant.businessincome.itrregular" >
                            <Form.Check inline label="Are ITRs filed regularly?" name="applicant.businessincome.itrregular"  type='checkbox' id='consideritrincome' onChange={(e)=> {applicant.businessincome.itrregular = e.target.checked;setLoan(loanModel);}} checked={applicant.businessincome.itrregular}/>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5} hidden={!applicant.businessincome.itr} >
                          <Form.Group controlId="applicant.businessincome.consideritrincome" >
                            <Form.Check inline label="Do you want to consider ITR income for income computation?" name="applicant.businessincome.consideritrincome"  type='checkbox' id='consideritrincome' onChange={(e)=> {applicant.businessincome.consideritrincome = e.target.checked;setLoan(loanModel);}} checked={applicant.businessincome.consideritrincome} />
                          </Form.Group>
                        </Col>
                      </Row>
                      {applicant.businessincome.consideritrincome && 
                        <Row>
                          <Col md={3}>Latest ITR of Financial Year</Col>
                          <Col md={4}>
                            <Form.Group controlId="financialyear">
                              <FormControl type="number" min={0} name="financialyear" onChange={(e)=> {
                                applicant.businessincome.financialyear = e.target.value;
                                setLoan(loanModel);
                              }}  defaultValue={applicant.businessincome.financialyear} />
                            </Form.Group>
                          </Col>
                        </Row>
                      }
                      {applicant.businessincome.consideritrincome && (
                      <div>
                        <Row>
                          <Col md={6}></Col>
                          <Col md={3}>PREVIOUS YEAR</Col>
                        </Row>
                        <Row>
                          <Col md={3}>Gross Sales / Receipts</Col>

                          <Col md={3}>
                            <FormControl type="number" min={0} name="amount" onChange={(e)=> {
                              applicant.businessincome.itrs[0][0].amount = e.target.value;
                              setLoan(loanModel);
                            }} onBlur={()=>calculateShareIncome} defaultValue={applicant.businessincome.itrs[0][0].amount} />
                          </Col>
                          <Col md={3}>
                            <FormControl type="number" min={0} name="amount" onChange={(e)=> {
                              applicant.businessincome.itrs[1][0].amount = e.target.value;
                              setLoan(loanModel);
                            }} onBlur={()=>calculateShareIncome} defaultValue={applicant.businessincome.itrs[1][0].amount} />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>Net Profit After Tax (Annual)</Col>
                          <Col md={3}>
                            <FormControl type="number" min={0} name="amount" onChange={(e)=> {
                              applicant.businessincome.itrs[0][1].amount = e.target.value;
                              setLoan(loanModel);
                            }} onBlur={()=>calculateShareIncome} defaultValue={applicant.businessincome.itrs[0][1].amount} />
                          </Col>
                          <Col md={3}>
                            <FormControl type="number" min={0} name="amount" onChange={(e)=> {
                              applicant.businessincome.itrs[1][1].amount = e.target.value;
                              setLoan(loanModel);
                            }} onBlur={()=>calculateShareIncome} defaultValue={applicant.businessincome.itrs[1][1].amount} />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>Depreciation (Annual)</Col>
                          <Col md={3}>
                            <FormControl type="number" min={0} name="amount" onChange={(e)=> {
                              applicant.businessincome.itrs[0][2].amount = e.target.value;
                              setLoan(loanModel);
                            }} onBlur={()=>calculateShareIncome} defaultValue={applicant.businessincome.itrs[0][2].amount} />
                          </Col>
                          <Col md={3}>
                            <FormControl type="number" min={0} name="amount" onChange={(e)=> {
                              applicant.businessincome.itrs[1][2].amount = e.target.value;
                              setLoan(loanModel);
                            }} onBlur={()=>calculateShareIncome} defaultValue={applicant.businessincome.itrs[1][2].amount} />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}> </Col>
                          <Col md={3}><label className="label label-success">{loanModel.itr1} </label></Col>
                          <Col md={3}><label className="label label-success">{loanModel.itr2} </label></Col>
                        </Row>
                        <Row>
                          <Col md={3}>Monthly Appraised Income to be considered ({applicant.businessincome.itrsalesconsideration})</Col>
                          <Col md={3} > 
                            <Form.Select className="form-control" onChange={(e)=> {
                                applicant.businessincome.itrsalesconsideration = e.target.value;
                                calculateSalesITRMonthly();
                                setLoan(loanModel);
                              }} name="emergencyfund" defaultValue={applicant.businessincome.itrsalesconsideration}>
                                <option value=""></option>
                                <option value="LATEST">Latest Year</option>
                                <option value="PREVIOUS">Previous Year</option>
                                <option value="AVERAGE">Average</option>
                                <option value="MAXIMUM">Maximum</option>
                                <option value="MINIMUM">Minimum</option>
                            </Form.Select>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>Monthly Appraised Business Income</Col>
                          <Col md={3}><label className="label label-primary"> {applicant.businessincome.itrsalesincome}</label></Col>
                        </Row>
                        <Row>
                          <Col md={3}>% Share of profit of Applicant</Col>
                          <Col md={3}>
                            <FormControl type="number" min={0} name="profitshare" placeholder="0.0"   onChange={(e)=> {
                              applicant.businessincome.profitshare = e.target.value;
                              setLoan(loanModel);
                            }} onBlur={()=>calculateByProfit} defaultValue={applicant.businessincome.profitshare} />
                          </Col>
                        </Row>									
                        <Row>
                          <Col md={3}>Applicant's Share in Business Income</Col>
                          <Col md={3}> {applicant.businessincome.shareincome}</Col>  
                        </Row>
                        <Row>
                          <Col md={3}>Interest on Capital</Col>
                          <Col md={3}>
                            <FormControl type="number" min={0} name="interestoncapital" placeholder="0"   onChange={(e)=> {
                              applicant.businessincome.interestoncapital = e.target.value;
                              setLoan(loanModel);
                            }} onBlur={()=>calculateByProfit} defaultValue={applicant.businessincome.interestoncapital} />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>Remuneration / Salary</Col>
                          <Col md={3}>
                            <FormControl type="number" min={0} name="remuneration" placeholder="0"   onChange={(e)=> {
                              applicant.businessincome.remuneration = e.target.value;
                              setLoan(loanModel);
                            }} onBlur={()=>calculateByProfit} defaultValue={applicant.businessincome.remuneration} />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>Dividends from business</Col>
                          <Col md={3}>
                              <FormControl type="number" min={0} name="dividends" placeholder="0"   onChange={(e)=> {
                              applicant.businessincome.dividends = e.target.value;
                              setLoan(loanModel);
                            }} onBlur={()=>calculateByProfit} defaultValue={applicant.businessincome.dividends} />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}><strong>Applicant's Monthly Appraised Business Income</strong></Col>: 0
                          <Col md={3}><label className='label label-primary'>{applicant.businessincome.totalincome ? applicant.businessincome.totalincome : 0 }</label></Col>
                        </Row>
                      </div>)}
                      {!applicant.businessincome.consideritrincome && !applicant.businessincome.monthlysale &&
                        (<div>
                         <strong>Service/Product<a onClick={() => addService()}> <i className="fa fa-plus fa-lg"></i> </a></strong>

                          <table className="table table-striped table-emails table-hover tablemobileview" id="folder-view" >
                            <thead>
                              <tr>
                                <th id="folder-actions">
                                  <strong>Service/ Product</strong>
                                </th>
                                <th><strong>Considered Revenue</strong></th>
                                <th><strong>Input Source</strong></th>
                              </tr>
                            </thead>
                           
                            <tr hidden={applicant.businessincome.services  && applicant.businessincome.services.length !== 0}>
                              <td colSpan={6}><span className="col-lg-4 col-sm-6 col-xs-8 col-lg-offset-5 col-sm-offset-3 col-xs-offset-4">No Service/Product</span></td>
                            </tr>
                            {applicant.businessincome.services && applicant.businessincome.services.map((item:any, i:any)=>(
                                <tr onClick={()=>editService(i)}>
                                  <td className="name"><strong><a  onClick={()=>editService(i)}>{ service.name }</a></strong></td>
                                  <td className="name">{ service.revenue  }</td>
                                  <td>{service.source }</td>
                                  <td><a data-widgster="close" onClick={()=>removeService(i)}><i className="fa fa-times fa-lg"></i></a></td>
                                </tr>
                              ))
                            }
                            <tr>
                              <td>Total Sales / Receipts / Turnover</td>
                              <td colSpan={2}>  <label className="label label-warning"> {loanModel.totalrevenue} </label></td>
                            </tr>
                          </table>
                        </div>
                      )}
                      <div hidden={!showService}>
                        <strong>Manage Service/Product</strong>
                        <hr/>
                        <Row>
                          <Col md={3}><strong>Service/ Product</strong></Col>
                          <Col md={3}>
                            <FormControl type="text" name="service.name" placeholder="0"   onChange={(e)=> {
                              service.name = e.target.value;
                              setLoan(loanModel);
                            }}  defaultValue={service.name} />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            <Form.Check inline label="Do you want assistance to compute monthly sales?" name="service.assistance"  type='checkbox' id='service.assistance' onChange={(e)=> {service.assistance = e.target.checked;setLoan(loanModel);}} 
                              checked={service.assistance} />
                          </Col>
                        </Row>
                        {service.assistance ? 'test' :'no'}
                        {service.assistance &&
                        <Row>
                          <Col md={3}>Period to be considered for workings</Col>
                          <Col md={3}>
                          <Form.Select className="form-control" onChange={(e)=> {
                                service.workingperiod = e.target.value;
                                calculateSalesUnits();
                                setLoan(loanModel);
                              }} name="service.workingperiod" defaultValue={service.workingperiod}>
                                <option value="">Select</option>
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Fortnightly">Fortnightly</option>
                                <option value="Monthly">Monthly</option>
                            </Form.Select>
                          </Col>
                        </Row>}
                        {service.assistance &&<Row>
                          <Col md={3}><strong>Monthly</strong></Col>
                          {applicant.businessincome.transactionperiod && applicant.businessincome.transactionperiod.map((e:any,i:any)=>
                            (
                              <Col md={3}>{e}</Col>
                            )
                          )}
                        </Row>}
                        {service.workingperiod =='Daily'&&(
                          <Row>
                          <Col md={3}>Working days in a month</Col>
                          <Col md={3}>
                            <FormControl type="number" min="0" name="service.workingdays[0]" placeholder="0"   onChange={(e)=> {
                              service.workingdays[0] = e.target.value;
                              calculateWorkingDays();
                              setLoan(loanModel);
                            }}  onBlur={()=>calculateSalesUnits} defaultValue={service.workingdays[0]} />
                          </Col>
                          <Col md={3}>
                            <FormControl type="number" min="0" name="service.workingdays[1]" placeholder="0"   onChange={(e)=> {
                              service.workingdays[1] = e.target.value;
                              calculateWorkingDays();
                              setLoan(loanModel);
                            }}  onBlur={()=>calculateSalesUnits} defaultValue={service.workingdays[1]} />
                          </Col>
                          <Col md={3}>
                            <FormControl type="number" min="0" name="service.workingdays[2]" placeholder="0"   onChange={(e)=> {
                              service.workingdays[2] = e.target.value;
                              calculateWorkingDays();
                              setLoan(loanModel);
                            }}  onBlur={()=>calculateSalesUnits} defaultValue={service.workingdays[2]} />
                          </Col>
                        </Row>
                        )}
                        {service.assistance && <Row>
                          <Col md={3}>Number of units Per Day / Week / Fortnight / Month</Col>
                          <Col md={3}>
                            <FormControl type="number" min="0" name="service.units[0]" placeholder="0"   onChange={(e)=> {
                              service.units[0] = e.target.value;
                              calculateSalesUnits();
                              setLoan(loanModel);
                            }}  defaultValue={service.units[0]} />
                          </Col>
                          <Col md={3}>
                            <FormControl type="number" min="0" name="service.units[1]" placeholder="0"   onChange={(e)=> {
                              service.units[1] = e.target.value;
                              calculateSalesUnits();
                              setLoan(loanModel);
                            }}  defaultValue={service.units[1]} />
                          </Col>
                          <Col md={3}>
                            <FormControl type="number" min="0" name="service.units[2]" placeholder="0"   onChange={(e)=> {
                              service.units[2] = e.target.value;
                              calculateSalesUnits();
                              setLoan(loanModel);
                            }}  defaultValue={service.units[2]} />
                          </Col>
                        </Row>}
                        {service.assistance && <Row>
                          <Col md={3}>Sales / Receipt Price per unit</Col>
                          <Col md={3}>
                            <FormControl type="number" min="0" name="service.unitprice[0]" placeholder="0"   onChange={(e)=> {
                              service.unitprice[0] = e.target.value;
                              calculateSalesUnits();
                              setLoan(loanModel);
                            }}  defaultValue={service.unitprice[0]} />
                          </Col>
                          <Col md={3}>
                            <FormControl type="number" min="0" name="service.unitprice[1]" placeholder="0"   onChange={(e)=> {
                              service.units[1] = e.target.value;
                              calculateSalesUnits();
                              setLoan(loanModel);
                            }}  defaultValue={service.units[1]} />
                          </Col>
                          <Col md={3}>
                            <FormControl type="number" min="0" name="service.unitprice[2]" placeholder="0"   onChange={(e)=> {
                              service.unitprice[2] = e.target.value;
                              calculateSalesUnits();
                              setLoan(loanModel);
                            }}  defaultValue={service.unitprice[2]} />
                          </Col>
                        </Row>}
                        {!service.assistance &&<Row>
                          <Col md={3}><strong>Sales</strong></Col>
                          {applicant.businessincome.transactionperiod && applicant.businessincome.transactionperiod.map((e:any,i:any)=>
                            (
                              <Col md={3}>{e}</Col>
                            )
                          )}
                        </Row>}
                        {!service.assistance && <Row>
                          <Col md={3}>Sales / Receipts / Turnover</Col>
                          <Col md={3}>
                            <FormControl type="number" min="0" name="service.sales.month0" placeholder="0.0"   onChange={(e)=> {
                              service.sales.month0 = e.target.value;
                              calculateSalesMonthly();
                              setLoan(loanModel);
                            }}  defaultValue={service.sales.month0} />
                          </Col>
                          <Col md={3}>
                            <FormControl type="number" min="0" name="service.sales.month1" placeholder="0.0"   onChange={(e)=> {
                              service.sales.month1 = e.target.value;
                              calculateSalesMonthly();
                              setLoan(loanModel);
                            }}  defaultValue={service.sales.month1} />
                          </Col>
                          <Col md={3}>
                            <FormControl type="number" min="0" name="service.sales.month2" placeholder="0.0"   onChange={(e)=> {
                              service.sales.month2 = e.target.value;
                              calculateSalesMonthly();
                              setLoan(loanModel);
                            }}  defaultValue={service.sales.month2} />
                          </Col>
                        </Row>}
                        <Row>
                          <Col md={3}>Input Source</Col>
                          <Col md={3}>
                          <Form.Select className="form-control" onChange={(e)=> {
                                service.source = e.target.value;
                                calculateSalesMonthly();
                                setLoan(loanModel);
                              }} name="service.source" defaultValue={service.source}>
                                <option value="">Select</option>
                                <option value="Sales Register">Sales Register</option>
                                <option value="Purchase Book">Purchase Book</option>
                                <option value="Inventory Register">Inventory Register</option>
                                <option value="Bills / Invoices / Bill Book">Bills / Invoices / Bill Book</option>
                                <option value="Expense Bills / Vouchers">Expense Bills / Vouchers</option>
                                <option value="Lease Agreement">Lease Agreement</option>
                                <option value="Receipts">Receipts</option>
                                <option value="Credit Book">Credit Book</option>
                                <option value="Bank Deposits">Bank Deposits</option>
                                <option value="Mandi Receipts">Mandi Receipts</option>
                                <option value="Purchase Orders">Purchase Orders</option>
                                <option value="Purchase / Work Orders">Purchase / Work Orders</option>
                                <option value="Contracts with Suppliers">Contracts with Suppliers</option>
                                <option value="Contracts with Clients">Contracts with Clients</option>
                                <option value="Appraiser observation">Appraiser observation</option>
                            </Form.Select>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>Sales / Turnover to be considered (AVERAGE)</Col>
                          <Col md={3}>
                            <Form.Select className="form-control" onChange={(e)=> {
                                service.salesconsideration = e.target.value;
                                calculateSalesMonthly();
                                setLoan(loanModel);
                              }} name="service.salesconsideration" defaultValue={service.salesconsideration}>
                                <option value="LATEST">Latest Month</option>
                                <option value="PREVIOUS">Previous Month</option>
                                <option value="AVERAGE">Average</option>
                                <option value="MAXIMUM">Maximum</option>
                                <option value="MINIMUM">Minimum</option>
                            </Form.Select>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>Considered Sales / Turnover</Col>
                          <Col md={3}><label className='label label-danger'>{service.revenue}</label></Col>
                        </Row>
                        <Row>
                          <Col md={6}></Col>
                          <Col md={3}>
                            <Button variant="dark" onClick={serviceproduct}> Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button variant="danger" onClick={saveService}> Save</Button>
                          </Col>
                        </Row>
                      </div>
                      <strong>Other Income<a onClick={() => addBizOtherIncome()}> <i className="fa fa-plus fa-lg"></i> </a></strong>
                      <hr/>
                      <Row className="mt-3">
                        <Col md={2}><strong>Other Income</strong></Col>
                        <Col md={2}><strong>Monthly Amount</strong></Col>
                        <Col md={2}><strong>% to be considered</strong></Col>
                        <Col md={2}><strong>Eligible Amount</strong></Col>
                        <Col md={2}><strong>Input Source</strong></Col>
                        <Col md={2}></Col>
                      </Row>
                      {applicant.businessincome.otherincomes && applicant.businessincome.otherincomes.map((income:any,i:any)=>(
                        <div>
                        <Row className="mt-3">
                          <Col md={2} hidden={income.type !=='OTHER'}>{income.type}</Col>
                          <Col md={2} hidden={income.type ==='OTHER'}>{income.type ? income.type : 'empty'}</Col>
                          <Col md={2}>
                            <FormControl type="number" min="0" name="income.monthlyamount" placeholder="0.0"   onChange={(e)=> {
                              income.monthlyamount = Math.abs(parseFloat(e.target.value));
                              setLoan(loanModel);
                            }} onBlur={()=>{
                              income.eligibleamount=income.monthlyamount*income.percentage/100;
                              calcBizTotalIncome()
                            }}  defaultValue={income.monthlyamount} />
                          </Col>
                          <Col md={2}>
                             <FormControl type="number" min="0" name="income.percentage" placeholder="0.0"   onChange={(e)=> {
                              const value = parseInt(e.target.value);
                              if (isNaN(value)) {
                                income.percentage = 0.0; 
                              } else if(value < 0 || value > 100){
                                income.percentage = 0.0;
                              }
                              setLoan(loanModel);
                            }} onBlur={()=>{
                              income.eligibleamount=income.monthlyamount*income.percentage/100;
                              calcBizTotalIncome()
                            }}  defaultValue={income.percentage} />
                          </Col>
                          <Col md={2}>
                            <FormControl type="number" min="0" name="income.monthlyamount" placeholder="0.0" defaultValue={income.eligibleamount} readOnly={true} />
                          </Col>
                          <Col md={2}>
                            <Form.Select className="form-control" onChange={(e)=> {
                                income.source = e.target.value;
                                setLoan(loanModel);
                              }} name="income.source" defaultValue={income.source}>
                                <option value="">Select</option>
                                <option value="Sales Register">Sales Register</option>
                                <option value="Purchase Book">Purchase Book</option>
                                <option value="Inventory Register">Inventory Register</option>
                                <option value="Bills / Invoices / Bill Book">ills / Invoices / Bill Book</option>
                                <option value="Expense Bills / Vouchers">Expense Bills / Vouchers</option>
                                <option value="Lease Agreement">Lease Agreement</option>
                                <option value="Receipts">Receipts</option>
                                <option value="Credit Book">Credit Book</option>
                                <option value="Bank Deposits">Bank Deposits</option>
                                <option value="Mandi Receipts">Mandi Receipts</option>
                                <option value="Purchase Orders">Purchase Orders</option>
                                <option value="Purchase / Work Orders">Purchase / Work Orders</option>
                                <option value="Contracts with Suppliers">Contracts with Suppliers</option>
                                <option value="Contracts with Clients">Contracts with Clients</option>
                                <option value="Appraiser observation">Appraiser observation</option>
                            </Form.Select>
                          </Col>
                          <Col md={2} hidden={income.type =='OTHER'}>
                            <a  data-widgster="close" onClick={()=>removeBizOtherIncome(i)}><i className="fa fa-times fa-lg"></i></a>
                          </Col>
                        </Row>
                        </div>
                      ))}
                      <strong>Income Proof Documents</strong>
                      <hr/>
                      {applicant.income.documents && 
                          <Row className="mt-3">
                            <Col md={2}><strong>Document</strong></Col>
                            <Col md={2}><strong>Applicability</strong></Col>
                            <Col md={2}><strong>Verified</strong></Col>
                            <Col md={2}><strong>Remarks</strong></Col>
                            <Col md={2}></Col>
                          </Row>
                      }
                      {applicant.income.documents && applicant.income.documents.map((item:any,i:any)=>(
                        <Row className="mt-3">
                          <Col md={2}>{item.name}</Col>
                          <Col md={2}>
                            <Form.Check inline label="" name={"applicability"+i} type='checkbox' id={"applicability"+i} onChange={(e)=>{ 
                              item.applicability = e.target.checked;
                              setLoan(loanModel);
                            }} checked={item.applicability} />
                          </Col>
                          <Col md={2}>
                            <Form.Check inline label=""  name={"verified_"+i} type='checkbox' onChange={(e)=> {
                              item.verified = e.target.checked;
                              setLoan(loanModel);
                            }} checked={item.verified} />
                          </Col>
                          <Col md={2}>
                            <Form.Group controlId="remarks">
                              <FormControl type="text"  name={"remarks"+i} onChange={(e)=> {
                                item.remarks = e.target.value;
                                setLoan(loanModel);
                              }} defaultValue={item.remarks} />
                            </Form.Group>
                          </Col>
                          <Col md={1}>
                            <input id={"income"+item.name+item.id} type="file" className="sitevisitfilechooser" multiple/>
                            
                          </Col>
                          <Col md={2}>
                          <a className="btn btn-lg sitevisit-remove-padding" onClick={()=>uploadDocument('income'+item.name+item.id,'income',item.id,item.name)} ><i className="fa fa-cloud-upload-alt fa-lg"></i></a>  
                          </Col>
                        </Row>)
                      )}
                      <Row className="mt-3">
                        <Form.Group controlId="earningmembers">
                          <Form.Label className="custom-form-label" >Remarks</Form.Label>
                          <FormControl onChange={(e)=>{applicant.remarks = e.target.value;setLoan(loanModel)}} as="textarea" rows={6}
                            style={{ width: '100%', height: '120px' }} defaultValue={applicant.remarks}
                          />
                        </Form.Group>
                      </Row>    
                    </Tab>}
                    
                    {(applicant.type ==='informal_selfemployed' || applicant.type ==='informal_salaried') && <Tab eventKey="Eligibility" title="Eligibility">
                      <div className="tab-content">
                          <div className="title-header" style={{ marginBottom: '2px' }}><strong>Loan Eligibility Calculation</strong></div>
                          <hr/>
                          <div className="title-header" style={{ marginBottom: '2px' }}><strong>Obligations</strong></div>
                          <hr/>
                          <div className="card-box mh-50">
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Loan Type</th>
                                    <th scope="col">EMI</th>
                                    <th scope="col">Obligation to be considered</th>
                                  </tr>
                                </thead>
                                {(!isDefined(applicant.repayments) || applicant.repayments.length===0) && (
                                    <tr>
                                      <td colSpan={6}><span className="col-lg-4 col-sm-6 col-xs-8 col-lg-offset-5 col-sm-offset-3 col-xs-offset-4">No Repayments</span></td>
                                    </tr>
                                  )}
                                  {applicant.repayments && applicant.repayments.length > 0 && applicant.repayments.map((repayment:any, i:any)=>(
                                    <tr>
                                      <td>{repayment.loantype}</td>
                                      <td>{repayment.emi}</td>
                                      <td colSpan={3}>
                                      <Form.Check inline label="" name={"consider"+i} type='checkbox' id={"consider"+i} onChange={(e)=>{ 
                                        repayment.consider = e.target.checked;
                                        refreshApplicantObligation();
                                        setLoan(loanModel);
                                      }} checked={repayment.consider} />
                                      </td>

                                    </tr>
                                  ))}
                              </table>
                              <Row className='mt-3'>
                                <Col md={2}><strong>Considered Obligation</strong></Col>
                                <Col md={2}>{loanModel.check.applicant.totalObligation}</Col>
                              </Row>

                              <div className="title-header" style={{ marginBottom: '2px' }}><strong>Income</strong></div>
                              <hr/>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col" className='mt-4'>APPRAISED INCOME</th>
                                    <th scope="col" className='mt-2'>AMOUNT</th>
                                  </tr>
                                </thead>
                               
                                <tr>
                                  <td className='mt-4'>Monthly Appraised Main Income</td>
                                  <td className='mt-2'>{loanModel.check.applicant.monthlyamount}</td>
                                </tr>
                                <tr>
                                  <td className='mt-4'>Monthly Other Income</td>
                                  <td className='mt-2'>{loanModel.check.applicant.totalotherincome}</td>
                                </tr>
                                <tr>
                                  <td className='mt-4'>Monthly Total Income</td>
                                  <td className='mt-2'><label className="label label-danger">{loanModel.check.applicant.totalincome}</label></td>
                                </tr>
                              
                              </table>
                              <Row className='mt-3'>
                                <Col md={2}>FOIR (%)*</Col>
                                <Col md={2}>Tenure (in years) *</Col>
                                <Col md={2}>Rate of Interest *</Col>
                              </Row>
                              <Row className='mt-3'>
                                <Col md={2}>
                                  <FormControl type="number" placeholder='(0%-100%)' onKeyDown={allowPositiveIntegersOnly}  name="foir" onChange={(e)=>{
                                    applicant.eligibility.foir = e.target.value;
                                    setLoan(loanModel);
                                  }} value={applicant.eligibility.foir}/>
                                </Col>
                                <Col md={2}>
                                  <FormControl type="number" placeholder='(10-30)' onKeyDown={allowPositiveIntegersOnly}  name="tenure" onChange={(e)=>{
                                    applicant.eligibility.tenure = e.target.value;
                                    setLoan(loanModel);
                                  }} value={applicant.eligibility.tenure} onBlur={()=>{
                                    if(applicant.eligibility.tenure >30){
                                      applicant.eligibility.tenure =0;
                                    }
                                  }}/>
                                </Col>
                                <Col md={2}>
                                  <FormControl type="number" placeholder='(10-30)' onKeyDown={allowPositiveIntegersOnly}  name="roi" onChange={(e)=>{

                                    var copy=""+e.target.value;
                                    copy = copy.replace(/[^0-9.]/g, '');
                                    var decimalIndex = copy.indexOf('.');
                                    if(decimalIndex !== -1 && copy.length > decimalIndex + 2){
                                      copy = copy.substring(0, decimalIndex + 3);
                                    }

                                    applicant.eligibility.roi = parseFloat(copy);
                                    setLoan(loanModel);
                                  }} value={applicant.eligibility.roi}/>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}></Col>
                                <Col md={2}>
                                  <Button variant="success" onClick={calLoanApplicantEligibility}>Finalize <span style={{ borderRadius: '50%', backgroundColor: '#fff', display: 'inline-block', width: '23px', height: '23px' }}><i className="fa fa-arrow-right text-success"></i></span></Button>
                                </Col>
                              </Row>
                              <Row className='mt-3'>
                                <Col md={4}><strong>Loan Eligibility</strong></Col>
                                <Col md={2}><label className='label label-danger'>{applicant.eligibility.loaneligibility}</label></Col>
                              </Row>
                              <Row className='mt-3'>
                                <Col md={4}><strong>Total EMI Outflow</strong></Col>
                                <Col md={2}><label className='label label-danger'>{applicant.eligibility.totalEMI}</label></Col>
                              </Row>
                              <div className="title-header" style={{ marginBottom: '2px' }}><strong>Recommendation</strong></div>
                              <hr/>
                              <Row className='mt-3'>
                                <Col md={4}><strong>Loan Amount Recommendation *</strong></Col>
                                <Col md={2}>
                                  <FormControl type="number" placeholder='0.0' min={0} onKeyDown={allowPositiveIntegersOnly}  name="tenure" onChange={(e)=>{
                                    applicant.eligibility.loanrecommended = e.target.value;
                                    setLoan(loanModel);
                                    }} value={applicant.eligibility.loanrecommended}/>
                                </Col>
                                <Col md={2}>
                                  <Button variant="success" onClick={checkApplicantRecommendation}>Finalize <span style={{ borderRadius: '50%', backgroundColor: '#fff', display: 'inline-block', width: '23px', height: '23px' }}><i className="fa fa-arrow-right text-success"></i></span></Button>
                                </Col>
                              </Row>
                              <Row className='mt-3'>
                                <Col md={4}><strong>Applicable EMI</strong></Col>
                                <Col md={2}><label className='label label-warning'>{applicant.eligibility.applicableEMI}</label></Col>
                              </Row>
                              <Row className='mt-3'>
                                <Col md={4}><strong>Actual FOIR (%)</strong></Col>
                                <Col md={2}><label className='label label-warning'>{applicant.eligibility.actualfoir}</label></Col>
                              </Row>
                              <Row className='mt-3'>
                                <Col md={4}><strong>Value of Property</strong></Col>
                                { (loanModel.application.loanpurpose === 'LAP'|| loanModel.application.loanpurpose === 'HLAP') && <Col md={2}><label className='label label-warning'>{applicant.eligibility.marketvalue}</label></Col>}
                                { (loanModel.application.loanpurpose !== 'LAP'&& loanModel.application.loanpurpose !== 'HLAP') && <Col md={2}><label className='label label-warning'>{applicant.eligibility.transactioncost}</label></Col>}
                              </Row>
                              <Row className='mt-3'>
                                <Col md={4}><strong>LCR / LTV (%)</strong></Col>
                                <Col md={2}><label className='label label-warning'>{applicant.eligibility.loaneligibility}</label></Col>
                              </Row>
                              <Row className='mt-3'>
                                <Col md={4}><strong>Own Contribution Amount</strong></Col>
                                <Col md={2}><label className='label label-warning'>{applicant.eligibility.loaneligibility}</label></Col>
                              </Row>
                              <Row className="mt-3">
                                <Form.Group controlId="earningmembers">
                                  <Form.Label className="custom-form-label" >Remarks</Form.Label>
                                  <FormControl onChange={(e)=>{applicant.remarks = e.target.value;setLoan(loanModel)}} as="textarea" rows={6}
                                    style={{ width: '100%', height: '120px' }} value={applicant.remarks}
                                  />
                                </Form.Group>
                              </Row>
                            </div>
                          </div>
                        <hr/>
                      </div>
                    </Tab>}
                  </Tabs>
                }
              </div>
            </div>
          </Tab>

          <Tab eventKey="household" title="Household Expenses" tabClassName="outerTabs">
            <div className="tab-content">
              <div className="title-header" style={{ marginBottom: '2px' }}>
                <strong>Monthly Household Expenses
                  <a style={{ cursor: "pointer", textDecoration: 'none', color: 'blue  !important' }} onClick={()=>{
                    addExpense();
                    setLoan(loanModel);
                    }} >
                    <i className="fa fa-plus fa-lg" aria-hidden="true"></i>
                  </a>
                </strong>
              </div>
              <Form className="page-section">
                {loanModel.householdexpense.expenses && loanModel.householdexpense.expenses.map((map:any, i:any) => (
                  <div>
                  <Row className="mt-3">
                    <Col md={3}>
                      {map.item}
                    </Col>
                    <Col md={3}>
                      <Form.Control type='number' name={'expensesamount_'+i} onChange={((event) => {
                        map.amount = event.target.value;
                        calculateExpenses();
                        setLoan(loanModel);
                        })} value={map.amount} />
                    </Col>
                    <Col hidden={map.type !=='OTHER'}>
                    <a data-widgster="close"  onClick={()=>removeExpense(i)}><i className="fa fa-times fa-lg "></i></a>
                    </Col>
                  </Row>
                  {map.item==='Rent of residence premise'&& loanModel.householdexpense.pincodeminvalue > 0 && loanModel.householdexpense.pincodemaxvalue > 0 && <Row className="mt-3">
                    <Col md={3}>
                      {map.item}
                    </Col>
                    <Col md={3}>
                    <h6 className="mt-0 rentsqft" style={{fontSize: "14px"}}>
                          The average residential rent in the locality ranges from <b>{loanModel.householdexpense.pincodeminvalue} INR</b> to <b>{loanModel.householdexpense.pincodemaxvalue} INR</b>.<br/>
                          <div className="rentsqftsource"><b>Source</b> - Online Rental Portals</div>
                        </h6>
                    </Col>
                  </Row>}
                  </div>
                ))}
                <Row>
                  <Col md={3}>
                    <label>Total Monthly Household Expenses *</label>
                  </Col>
                  <Col md={3}>
                    <label className='label label-warning'>{loanModel.totalexpenses}</label>
                  </Col>
                </Row>
                <Row className="mt-3">
                <Form.Group controlId="earningmembers">
                  <Form.Label className="custom-form-label" >Remarks</Form.Label>
                  <FormControl onChange={(e)=>{applicant.remarks = e.target.value;setLoan(loanModel)}} as="textarea" rows={6}
                    style={{ width: '100%', height: '120px' }} value={applicant.remarks}
                  />
                </Form.Group>
              </Row>
              </Form>
            </div>
          </Tab>
          
          <Tab eventKey="property" title="Property" tabClassName="outerTabs">
            <div className="tab-content">
              <div className="title-header" style={{ marginBottom: '2px' }}> <strong>Property Details</strong> </div>
              <hr />
              <Row className="mt-3">
                <Col md={3}>
                  <Form.Group controlId="loanModel.property" >
                    <Form.Check inline label="Property Selected?" name="loanModel.property" type='checkbox' id='loanModel.property' onChange={(e)=>{
                      loanModel.property = e.target.checked;
                      setLoan(loanModel);
                    }} checked={loanModel.property} />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="loanModel.property.constructionstage">
                    <Form.Label className="custom-form-label" >Construction Progress in % *</Form.Label>
                    <FormControl type="number" name="loanModel.property.constructionstage" onChange={(e)=>{
                      loanModel.property.constructionstage = e.target.value;
                      setLoan(loanModel);
                    }} onKeyDown={allowPositiveIntegersOnly} defaultValue={loanModel.property.constructionstage} />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="area">
                    <Form.Label className="custom-form-label" >Estimated value of Construction *</Form.Label>
                    <FormControl type="number" name="area" onChange={(e)=>{
                      loanModel.property.constructionvalue = e.target.value;
                      setLoan(loanModel);
                    }} onKeyDown={allowPositiveIntegersOnly} defaultValue={loanModel.property.constructionvalue} />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="propertyLocations">
                    <Form.Label className="custom-form-label" >Property Location *</Form.Label>
                    <Form.Select className="form-control" onChange={(e)=>{
                      loanModel.property.propertylocation = e.target.value;
                      setLoan(loanModel);
                    }} name="propertyLocations" defaultValue={loanModel.property.propertylocation}>
                      <option value=""></option>
                      {
                        getLookupProperty('propertyLocations').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                      }
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={3}>
                  <Form.Group controlId="loanModel.property.area">
                    <Form.Label className="custom-form-label" >Carpet Area (sq ft) *</Form.Label>
                    <FormControl type="number" name="loanModel.property.area" onChange={(e)=>{
                      loanModel.property.area = e.target.value;
                      setLoan(loanModel);
                    }} defaultValue={loanModel.property.area} />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="loanModel.property.propertytype">
                    <Form.Label className="custom-form-label" >Property Type *</Form.Label>
                    <Form.Select className="form-control" onChange={(e)=>{
                      loanModel.property.propertytype = e.target.value;
                      setLoan(loanModel);
                    }} name="loanModel.property.propertytype" defaultValue={loanModel.property.propertytype}>
                      <option value=""></option>
                      {
                        getLookupProperty('propertyTypes').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                      }
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={10}><div className="title-header" style={{ marginBottom: '2px' }}> <strong>Property Address</strong> </div></Col>
                <Col md={2}><a className="float-right" onClick={locateAddress}>Detect my location</a></Col>
              </Row>
              
              <hr />
              <Row className="mt-3">

                <Col md={3}>
                  <Form.Group controlId="loanModel.property.address.zipcode">
                    <Form.Label className="custom-form-label" >Pin Code *</Form.Label>
                    <FormControl type="number" name="loanModel.property.address.zipcode" onChange={(e)=>{
                      loanModel.property.address.zipcode = e.target.value;
                      getProppincodelocation();
                    }} defaultValue={loanModel.property.address.zipcode} />
                  </Form.Group>
                </Col>
                <Col md={3}>
                    <Button className="form-control" style={{ color: "#fff", backgroundColor: "#ed1d25", borderColor: "#df121a", marginTop: "33px", marginLeft: "18px", width: "100px" }} onClick={()=>{
                      getProppincodelocation();
                    }}>Fetch</Button>
                </Col>
              </Row>

              <Row className="mt-3">

                <Col md={3}>
                  <Form.Group controlId="loanModel.property.address.address1">
                    <Form.Label className="custom-form-label" >Address Line 1 *</Form.Label>
                    <FormControl type="text" name="loanModel.property.address.address1" onChange={(e)=>{
                      loanModel.property.address.address1 = e.target.value;
                    }} defaultValue={loanModel.property.address.address1} />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="loanModel.property.address.address2">
                    <Form.Label className="custom-form-label" >Address Line 2 *</Form.Label>
                    <FormControl type="text" name="loanModel.property.address.address2" onChange={(e)=>{
                      loanModel.property.address.address2 = e.target.value;
                    }} defaultValue={loanModel.property.address.address2} />
                  </Form.Group>
                </Col>

              </Row>
              <Row className="mt-3">

                <Col md={3}>
                  <Form.Group controlId="loanModel.property.address.city">
                    <Form.Label className="custom-form-label" >City / Village *</Form.Label>
                    <FormControl type="text" name="loanModel.property.address.city" onChange={(e)=>{
                      loanModel.property.address.city = e.target.value;
                    }} defaultValue={loanModel.property.address.city} />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group controlId="loanModel.property.address.locality">
                    <Form.Label className="custom-form-label" >Locality *</Form.Label>
                    <FormControl type="text" name="loanModel.property.address.locality" onChange={(e)=>{
                      loanModel.property.address.locality = e.target.value;
                    }} defaultValue={loanModel.property.address.locality} />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="loanModel.property.address.district">
                    <Form.Label className="custom-form-label" >District </Form.Label>
                    <FormControl type="text" name="loanModel.property.address.district" onChange={(e)=>{
                      loanModel.property.address.district = e.target.value;
                    }} defaultValue={loanModel.property.address.district} />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={3}>
                  <Form.Group controlId="loanModel.property.address.state">
                    <Form.Label className="custom-form-label" >State / Union Territory * </Form.Label>
                    <FormControl type="text" name="loanModel.property.address.state" onChange={(e)=>{
                      loanModel.property.address.state = e.target.value;
                    }} defaultValue={loanModel.property.address.state} />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group controlId="loanModel.property.address.distance" >
                    <Form.Check inline label="Do you want to confirm this address for property distance calculation?" name="loanModel.property.address.distance" type='checkbox' id='spouseaware' onChange={(e)=>{
                      loanModel.property.address.distance = e.target.checked;
                      if(loanModel.property.address.distance==true){
                        loanModel.property.address.address1 = applicant.address.address1!=null ? applicant.address.address1 :'';
                        loanModel.property.address.address2 = applicant.address.address2!=null ? applicant.address.address2 :'';
                        loanModel.property.address.city = applicant.address.city!=null ? applicant.address.city :'';
                        loanModel.property.address.state = applicant.address.state!=null ? applicant.address.state :'';
                        loanModel.property.address.locality = applicant.address.locality!=null ? applicant.address.locality :'';
                        loanModel.property.address.district = applicant.address.district!=null ? applicant.address.district :'';
                        loanModel.property.address.zipcode = applicant.address.zipcode!=null ? applicant.address.zipcode :'';

                        var addressComponents = [
                          loanModel.property.address.address1 ,
                          loanModel.property.address.address2,
                          loanModel.property.address.city,
                          loanModel.property.address.locality,
                          loanModel.property.address.zipcode,
                          loanModel.property.address.district,
                          loanModel.property.address.state
                        ];
                        var filteredAddressComponents = addressComponents.filter(function(component) {
                          return component;
                        });
                        var formattedAddress = filteredAddressComponents.join('+');
                        var geocodingUrl = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURIComponent(formattedAddress) + '&key=' + googleMapKey;
                        
                        const config: AxiosRequestConfig = {
                          headers: {
                            "Content-Type": "application/json"
                          }
                        }
                        axios.get(geocodingUrl,  config).then((data:any) => {
                          if (data.status === 'OK') {
                            loanModel.property.address.latitude=data.results[0].geometry.location.lat;
                            loanModel.property.address.longitude=data.results[0].geometry.location.lng;

                          }
                          setLoan(loanModel);
                        })
                      }
                      setLoan(loanModel);
                    }} checked={loanModel.property.address.distance} />
                  </Form.Group>
                </Col>
              </Row>
              <div className="title-header" style={{ marginBottom: '2px' }}> <strong>Property Use *</strong> </div>
              <hr />
              <Row className="mt-3">
                <Col md={2}>
                  <Form.Group controlId="loanModel.property.propertyuse.selfoccupy" >
                    <Form.Check inline label="Self-Occupy" name="loanModel.property.propertyuse.selfoccupy" type='checkbox' id='loanModel.property.propertyuse.selfoccupy' 
                    onChange={(e)=>{
                      loanModel.property.propertyuse.selfoccupy = e.target.checked;
                      setLoan(loanModel);
                      }} checked={loanModel.property.propertyuse.selfoccupy}/>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  
                  <Form.Check inline label="Weekend Home" name="loanModel.property.propertyuse.weekendhome" type='checkbox' id='loanModel.property.propertyuse.weekendhome' 
                  onChange={(e)=>{
                    loanModel.property.propertyuse.weekendhome = e.target.checked;
                    setLoan(loanModel);
                    }} checked={loanModel.property.propertyuse.weekendhome}/>
                </Col>
                <Col md={2}>
                  <Form.Check inline label="Investment" name="loanModel.property.propertyuse.investment" type='checkbox' id='loanModel.property.propertyuse.investment' 
                    onChange={(e)=>{
                      loanModel.property.propertyuse.investment = e.target.checked;
                      setLoan(loanModel);
                      }} checked={loanModel.property.propertyuse.investment}/>
                  
                </Col>
                <Col md={2}>
                  <Form.Check inline label="Will Rent Out" name="loanModel.property.propertyuse.rentout" type='checkbox' id='loanModel.property.propertyuse.rentout' 
                    onChange={(e)=>{
                      loanModel.property.propertyuse.rentout = e.target.checked;
                      setLoan(loanModel);
                      }} checked={loanModel.property.propertyuse.rentout}/>
                </Col>
                <Col md={2}>
                  <Form.Check inline label="For 3rd Party" name="loanModel.property.propertyuse.thirdparty" type='checkbox' id='loanModel.property.propertyuse.thirdparty' 
                    onChange={(e)=>{
                      loanModel.property.propertyuse.thirdparty = e.target.checked;
                      setLoan(loanModel);
                      }} checked={loanModel.property.propertyuse.thirdparty}/>
                </Col>
              </Row>
              <Row className="mt-3">

                <Col md={4}>
                  <Form.Group controlId="loanModel.property.transactionorder">
                    <Form.Label className="custom-form-label" >This property is customer’s (Transaction Order) *</Form.Label>
                    <Form.Select className="form-control" onChange={(e)=>{
                      loanModel.property.transactionorder = e.target.value;
                      setLoan(loanModel);
                    }} name="placevisited" value={loanModel.property.transactionorder}>
                      <option value=""></option>
                      {
                        getLookupProperty('transactionOrder').map((item:any) => (<option value={item['code']}>{item['type']}</option>))
                      }
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group controlId="loanModel.property.transactioncost">
                    <Form.Label className="custom-form-label" >Transaction Cost *</Form.Label>
                    <FormControl type="number" name="loanModel.property.transactioncost" onChange={(e)=>{
                      loanModel.property.transactioncost = e.target.value;
                      setLoan(loanModel);
                    }} value={loanModel.property.transactioncost} />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="loanModel.property.marketvalue">
                    <Form.Label className="custom-form-label" >Market Value *</Form.Label>
                    <FormControl type="number" name="loanModel.property.marketvalue" onChange={(e)=>{
                      loanModel.property.marketvalue = e.target.value;
                      setLoan(loanModel);
                    }} value={loanModel.property.marketvalue} />
                  </Form.Group>
                </Col>
              </Row>
              <div className="title-header" style={{ marginBottom: '2px' }}> <strong>Own Contributions</strong> 
                <a onClick={addContribution} className="butn"><i className="fa fa-plus fa-lg"></i></a>
              </div>
              <hr />
              {loanModel.property.contributions && loanModel.property.contributions.map((item:any, i:any) => (
                item.type !=='OTHER' && <Row className="mt-3">
                  <Col md={4}>
                    {item.contribution}
                  </Col>
                  <Col md={3}>
                    <Form.Control type='number' name='item_{i}' onChange={(event) => {
                      item.amount = event.target.value;
                      calculateContribution();
                      setLoan(loanModel);
                    }} defaultValue={item.amount} />
                  </Col>
                  <Col md={3} hidden={item.type !='OTHER'}>
                    <a data-widgster="close"  onClick={()=>removeContribution(i)}><i className="fa fa-times fa-lg"></i></a>
                  </Col>
                </Row>
              ))}
              <Row >
                <Col md={4}>
                  Total Own Contribution *
                </Col>
                <Col md={4}>
                  <label className='label labe.-warning'>{loanModel.totalcontribution}</label>
                </Col>
              </Row>
              <Row className="mt-3">
                <Form.Group controlId="earningmembers">
                  <Form.Label className="custom-form-label" >Remarks</Form.Label>
                  <FormControl onChange={(e)=>{applicant.remarks = e.target.value;setLoan(loanModel)}} as="textarea" rows={6}
                    style={{ width: '100%', height: '120px' }} defaultValue={applicant.remarks}
                  />
                </Form.Group>
              </Row>
            </div>
          </Tab>

        </Tabs>
      </div>
      <div style={{ float: 'right' }}>
        <Button variant="danger" onClick={handleNavigation}><span style={{ borderRadius: '50%', backgroundColor: '#fff', display: 'inline-block', width: '23px', height: '23px' }} ><i className="fa fa-arrow-left text-danger"></i></span> Cancel</Button>
        <Button onClick={publishDraft} style={{ background: '#ffe600', margin: '3px', borderColor: '#ffe600' }}>Save & Continue <span style={{ borderRadius: '50%', backgroundColor: '#fff', color: '#ffe600', display: 'inline-block', width: '23px', height: '23px' }}><i className="fa fa-arrow-right text-yellow"></i></span></Button>
        <Button variant="success" onClick={publish}>Finalize <span style={{ borderRadius: '50%', backgroundColor: '#fff', display: 'inline-block', width: '23px', height: '23px' }}><i className="fa fa-arrow-right text-success"></i></span></Button>

      </div>
    </>
  );
}

export default LoanCreationPhases;