import React, { useEffect, useState } from 'react';
import SideMenu from './Layout/SideMenu';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import axios, { AxiosRequestConfig } from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsePagination from './Layout/pagination';

const Users = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const userData: any = localStorage.getItem("user_data");
  const user = JSON.parse(userData);


  const [activeTab, setActiveTab] = useState('tab1');
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState('');
  const [userType, setUserType] = useState('')
  const [loading, setLoading] = useState(false);
  const [pageSize,setPagesize] = useState(5)
  const [pageNo,setPageNo] = useState()

  const [Pagination, data] = UsePagination(users,pageNo,pageSize)

  const [formData, setFormData] = useState({
    userid: "",
    userpass: "",
    usertype: "",
    firstname: "",
    lastname: "",
    email: "",
    no: "",
    role: ""
  });

  const [deleteUser, setDeleteUser] = useState({
    userid: '',
    userpass: '',
    usertype: ''
  });
  const [updateUser, setUpdateUser] = useState({
    userid: '',
    usertype: '',
    newuserpass: ''
  });

  const headers = {
    "Authorization": "Bearer " + user.token,
    "Content-Type": "application/json"
  }

  const config: AxiosRequestConfig = {
    headers: headers
  };

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
    emptyValues();
  };

  const handleSidebarToggle = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const handleUserIdChange = (event: any) => {
    setUserId(event.target.value);
  }

  const handleUserTypeChng = (event: any) => {
    setUserType(event.target.value);
    console.log('userType ', userId, ' userType ', userType);
  }
  //Users Tab

  const fetchUsers = async () => {
    try {
      setUsers([])
      setLoading(true)
      const response = await axios.post(`${BASE_URL}/app/users/list`, {
        pageno: 2,
        size: 5,
        userid: userId,
        usertype: userType
      });
      setUsers(response.data);
      emptyValues();
      console.log('response.data ', response.data);
      setLoading(false)
    } catch (error) {
      toast.error("Error to Fetching Data "+error, {
        position: "top-right",
        autoClose: 5000, // 5 seconds
      });
      setLoading(false)
    }
  };

  const handleToggleStatus = async (rowData: any) => {
    const apiData = {
      userid: rowData.UserId,
      usertype: rowData.Type,
      toggleStatus: !rowData.toggleStatus // Toggle the current status
    };
    setLoading(true)
    try {
      const response = await axios.post(BASE_URL + `/app/user/modifyuser`,
        apiData,
        config
      );
      setLoading(false)
      toast.success("Toggle status updated successfully! ", {
        position: "top-right",
        autoClose: 5000, // 5 seconds
      });
      console.log("response==>",response.data.id);
      rowData.toggleStatus = response.data.toggleStatus;
    } catch (error) {
      toast.error("Error updating toggle status: " + error, {
        position: "top-right",
        autoClose: 5000, // 5 seconds
      });
      setLoading(false)
    }
  }

  ////Users Tab

  //create user

  const createUserChange = (e: any) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };

  const createUserSubmit = async (e: any) => {
    e.preventDefault();

    try {
      setLoading(true)
      setUsers([])
      const response = await axios.post(BASE_URL + `/app/user/saveuser`,
        formData,
        config
      );
      setLoading(false)
      toast.success("account is created successfully" + formData.userid, {
        position: "top-right",
        autoClose: 5000, // 5 seconds
      });
      emptyValues()
    } catch (error) {
      setLoading(false)
      toast.error("There is some problem in creation of account with userid " + formData.userid, {
        position: "top-right",
        autoClose: 5000, // 5 seconds
      });
    }
  };

  //create user

  //Delete User
  const handleDltUsrChange = (e: any) => {
    const { id, value } = e.target;
    setDeleteUser((prevModel) => ({
      ...prevModel,
      [id]: value
    }));
  };

  const deleteUserSubmit = async (e: any) => {
    // e.preventDefault();
    console.log('BASE_URL==>', BASE_URL)
    const headers = {
      "Authorization": "Bearer " + user.token,
      "Content-Type": "application/json"
    }
    try {
      setLoading(true)
      setUsers([])
      const response = await axios.post(`${BASE_URL}/app/user/deleteuser`,
        deleteUser,
        config
      );
      setLoading(false)
      toast.success("account is Deleted successfully" + deleteUser.userid, {
        position: "top-right",
        autoClose: 5000, // 5 seconds
      });
      emptyValues()
    } catch (error) {
      setLoading(false)
      toast.error("There is some problem in deletion of account with userid " + deleteUser.userid, {
        position: "top-right",
        autoClose: 5000, // 5 seconds
      });
    }
  };

  //Delete User

  //edit user

  const editProperty = (userId: string, userType: string) => {
    handleTabClick('tab4');

    setUpdateUser({
      userid: userId,
      usertype: userType,
      newuserpass: ''
    });
  }
  const handleEditUserChange = (e: any) => {
    const { name, value } = e.target;
    setUpdateUser({ ...updateUser, [name]: value });
  };

  const handleEditUserSubmit = async (e: any) => {
    e.preventDefault();
    const userDetails = {
      userid: updateUser.userid,
      usertype: updateUser.usertype,
      userpass: updateUser.newuserpass
    };

    try {
      setLoading(true)
      const response = await axios.post(`${BASE_URL}/app/user/updateuser`,
        userDetails,
        config
      );
      toast.success("account password is updated successfully.e" + updateUser.userid, {
        position: "top-right",
        autoClose: 5000, // 5 seconds
      });
      emptyValues()
      setLoading(false)
    } catch (error) {
      toast.error("Please check the userid and usertype" + updateUser.userid, {
        position: "top-right",
        autoClose: 5000, // 5 seconds
      });
      setLoading(false)
    }
  };
  //edit user
  useEffect(() => {
    fetchUsers();
  }, []);


  const emptyValues = () => {
    setFormData({
      userid: "",
      userpass: "",
      usertype: "",
      firstname: "",
      lastname: "",
      email: "",
      no: "",
      role: ""
    })

    setDeleteUser({
      userid: '',
      userpass: '',
      usertype: ''
    });
    setUpdateUser({
      userid: '',
      usertype: '',
      newuserpass: ''
    });
  }

  const handlePageSize = (e:any)=>{
    let pageSize:number = parseInt(e.target.value)
    setPagesize(pageSize);
    console.log('pageno',e.target.value)
  }

  return (
    <div className={`wrapper ${sidebarToggle ? 'sidebarToggle' : ''}`}>
      <SideMenu />

      <div className="content-wrapper-user">
        <img style={{ display: loading ? 'block' : 'none' }} className="loading-img" src={`${process.env.PUBLIC_URL}/loading-wheel.gif`} alt="" width="90" height="90" />

        <div className="col-md-4 col-lg-6 mb-2 mt-1">
          <div className="title-header">
            <h2>Users</h2>
          </div>
        </div>
        <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <a className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`} id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true" onClick={() => handleTabClick('tab1')}>User</a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`} id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false" onClick={() => handleTabClick('tab2')}>Create</a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`} id="tab3-tab" data-toggle="tab" href="#tab3" role="tab" aria-controls="tab3" aria-selected="false" onClick={() => handleTabClick('tab3')}>Delete</a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${activeTab === 'tab4' ? 'active' : ''}`} id="tab4-tab" data-toggle="tab" href="#tab4" role="tab" aria-controls="tab4" aria-selected="false" onClick={() => handleTabClick('tab4')}>Edit</a>
          </li>
        </ul>

        <div className="tab-content custom-tab-content" id="myTabContent">
          <div className={`tab-pane fade show ${activeTab === 'tab1' ? 'active' : ''}`} id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
            <div className="col-md-4 col-lg-6 mb-2 mt-3">
              <div className="title-header"><h2>Applicants List</h2></div>
            </div>
            <div className="row align-items-center">
              <div className="col-1 label-end">UserId:</div>
              <div className="col-3">
                <input type="text" className="form-control" id="userid" onChange={handleUserIdChange} />
              </div>
              <div className="col-3"></div>
              <div className="col-1">User Type:</div>
              <div className="col-3 form-group">
                <select name="cars" className="form-select form-select-lg mb-2" style={{ width: "210px", height: "45px" }} onChange={handleUserTypeChng}>
                  <option value="">---Select---</option>
                  <option value="Edelweiss">Edelweiss</option>
                  <option value="Admin">Admin</option>
                  <option value="Test">Test</option>
                  <option value="AdityaBirla">AdityaBirla</option>
                </select>
              </div>
            </div>
            <div className="form-actions">
              <div className="row justify-content-center">
                <div className="col-sm-12 text-center">
                  <button type="button" className="btn btn-danger" onClick={fetchUsers}>Search</button>
                </div>
              </div>
            </div>
            <div className="card-box p-4 mh-62">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">User Name</th>
                      <th scope="col">First Name</th>
                      <th scope="col">User Type</th>
                      <th scope="col">User Role</th>
                      <th scope="col">Edit</th>
                      <th scope="col">Lock | Unlock</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.length > 0 ? (
                      data.map((user: any) => (
                        <tr key={user.id}>
                          <td>{user.UserId}</td>
                          <td>{user.Name}</td>
                          <td>{user.Type}</td>
                          <td>{user.Role}</td>
                          <td>
                            <button className="btn btn-outline-primary btn-sm rounded-circle mr-1" onClick={() => editProperty(user.UserId, user.Type)}>
                              <span className="icon-pencil"></span>
                            </button>
                          </td>
                          <td>
                            <label className="toggle-button" >
                              <input type="checkbox" checked={user.toggleStatus} onChange={() => handleToggleStatus(user)} />
                              <span className="slider round"></span>
                            </label>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td >No users found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination/>
                {/* <div> <select name="cars" className="form-select form-select-lg mb-2" style={{ width: "210px", height: "45px" }}>
                  <option value="">---Select---</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select></div>  
                <div> <select name="cars" onChange={handlePageSize} className="form-select form-select-lg mb-2" style={{ width: "210px", height: "45px" }}>
                  <option value="">---Select---</option>
                  <option value={pageNo}>5</option>
                  <option value={pageNo}>10</option>
                  <option value={pageNo}>25</option>
                  <option value={pageNo}>50</option>
                </select></div>  */}
              </div>
            </div>
          </div>
          <div className={`tab-pane fade ${activeTab === 'tab2' ? 'show active' : ''}`} id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
            <div className="card-box p-4 mh-62">
              <div className="row justify-content-between align-items-center">
                <div className="col-md-4 col-lg-6 mb-2 mt-3">
                  <div className="title-header">
                    <h2>Create User</h2>
                  </div>
                </div>
              </div>
              <div className="create-form">
                <form onSubmit={createUserSubmit} className="page-section mt-3">
                  <h5><strong>Create User</strong></h5>
                  <div className="row align-items-center mb-2">
                    <div className="col-1">First name:</div>
                    <div className="col-3 form-group">
                      <input type="text" className="form-control" id="firstname" value={formData.firstname} onChange={createUserChange} />
                    </div>
                    <div className="col-1">Last name:</div>
                    <div className="col-3 form-group">
                      <input type="text" className="form-control" id="lastname" value={formData.lastname} onChange={createUserChange} />
                    </div>
                    <div className="col-1 form-group">E-mail:</div>
                    <div className="col-3 form-group">
                      <input type="text" className="form-control" id="email" value={formData.email} onChange={createUserChange} />
                    </div>
                  </div>
                  <div className="row align-items-center mb-2">
                    <div className="col-1">Mobile :</div>
                    <div className="col-3 form-group">
                      <input type="text" className="form-control" id="no" value={formData.no} onChange={createUserChange} />
                    </div>
                    <div className="col-1 form-group">User Id :</div>
                    <div className="col-3 form-group">
                      <input type="text" className="form-control" id="userid" value={formData.userid} onChange={createUserChange} />
                    </div>
                    <div className="col-1 form-group">Password:</div>
                    <div className="col-3 form-group">
                      <input type="password" className="form-control" id="userpass" value={formData.userpass} onChange={createUserChange} />
                    </div>
                  </div>
                  <div className="row align-items-center mb-2">
                    <div className="col-1">User Role :</div>
                    <div className="col-3 form-group">
                      <select id="role" className="form-select form-select-lg  mb-2" value={formData.role} onChange={createUserChange}>
                        <option value="">---select---</option>
                        <option value="2">Super User</option>
                        <option value="1">User</option>
                      </select>
                    </div>
                    <div className="col-1 form-group">User Type :</div>
                    <div className="col-3 form-group">
                      <select name="cars" className="form-select form-select-lg  mb-2" style={{ width: "210px", height: "45px" }} id="usertype" value={formData.usertype} onChange={createUserChange}>
                        <option value="">--Select--</option>
                        <option value="Edelweiss">Edelweiss</option>
                        <option value="Admin">Admin</option>
                        <option value="Test">Test</option>
                        <option value="AdityaBirla">AdityaBirla</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-actions">
                    <div className="row justify-content-center">
                      <div className="col-sm-12 text-center">
                        <button type="submit" className="btn btn-danger">Create User</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className={`tab-pane fade ${activeTab === 'tab3' ? 'show active' : ''}`} id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
            <div className="card-box p-4 mh-62">
              <div className="row justify-content-between align-items-center">
                <div className="col-md-4 col-lg-6 mb-2 mt-3">
                  <div className="title-header">
                    <h2>Delete User</h2>
                  </div>
                </div>
              </div>
              <div className="delete-form">
                <form onSubmit={deleteUserSubmit} className="page-section">
                  <h5><strong>Delete User</strong></h5>
                  <div className="row">
                    <div className="col-1">User id:</div>
                    <div className="col-3 form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="userid"
                        value={deleteUser.userid}
                        onChange={handleDltUsrChange}
                      />
                    </div>
                    {/* <div className="col-2"></div> */}
                    <div className="col-2">User Type:</div>
                    <div className="col-2 form-group">
                      <select
                        name="usertype"
                        className="form-select form-select-lg mb-2"
                        id="usertype"
                        value={deleteUser.usertype}
                        onChange={handleDltUsrChange}
                        style={{ width: '210px', height: '40px' }}
                      >
                        <option value="">---Select---</option>
                        <option value="Edelweiss">Edelweiss</option>
                        <option value="Admin">Admin</option>
                        <option value="Test">Test</option>
                        <option value="AdityaBirla">AdityaBirla</option>
                      </select>
                    </div>

                  </div>
                  <button type="submit" className="btn btn-danger">Delete User</button>
                </form>
              </div>
            </div>
          </div>
          <div className={`tab-pane fade ${activeTab === 'tab4' ? 'show active' : ''}`} id="tab4" role="tabpanel" aria-labelledby="tab4-tab">
            <div className="card-box p-4 mh-62">
              <div className="row justify-content-between align-items-center">
                <div className="col-md-4 col-lg-6 mb-2 mt-3">
                  <div className="title-header">
                    <h2>Edit User</h2>
                  </div>
                </div>
              </div>
              <div className="edit-form">
                <form onSubmit={handleEditUserSubmit} className="page-section">
                  <h5><strong>Edit Password</strong></h5>
                  <div className="row">
                    <div className="col-1">User id:</div>
                    <div className="col-3 form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="userid"
                        value={updateUser.userid}
                        onChange={handleEditUserChange}
                      />
                    </div>
                    <div className="col-1">User Type:</div>
                    <div className="col-3 form-group">
                      <select
                        name="usertype"
                        className="form-select form-select-lg mb-2"
                        value={updateUser.usertype}
                        onChange={handleEditUserChange}
                        style={{ width: '210px', height: '40px' }}
                      >
                        <option value="">---Select---</option>
                        <option value="Edelweiss">Edelweiss</option>
                        <option value="Admin">Admin</option>
                        <option value="Test">Test</option>
                        <option value="AdityaBirla">AdityaBirla</option>
                      </select>
                    </div>
                    <div className="col-1">New Password:</div>
                    <div className="col-3 form-group">
                      <input
                        type="password"
                        className="form-control"
                        name="newuserpass"
                        value={updateUser.newuserpass}
                        onChange={handleEditUserChange}
                      />
                    </div>
                  </div>
                  <div className="form-actions">
                    <div className="row justify-content-center">
                      <div className="col-sm-12 text-center">
                        <button type="submit" className="btn btn-danger">Update User Password</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>

  );
};

export default Users;
