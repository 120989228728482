import React from 'react';

const NotFound = () => {
    return (
        <>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <h2>404 - Page Not Found</h2>
                
            </div>

        </>
    )
}

export default NotFound;